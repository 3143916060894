import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import ImageUploadMinimized from "../../components/Dashboard/ImageUploadMinimized";
import SideMenuBar from "../../components/Dashboard/SideMenubar";
import CommonLayout from "../../components/Layouts/CommonLayout";

import { useHeader } from "../../Logic/Header/useHeader";

import { Uploady } from "@rpldy/uploady";

const DashboardPage = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const { userProfileData } = useHeader();

  let navigate = useNavigate();

  let api = process.env.REACT_APP_APIURL;
  const BASE_URL = process.env.REACT_APP_FPAUPLOADAPIURL;

  const fileUploadState = useSelector((state) => state.fileUpload);


  useEffect(() => {
    if (
      location.pathname.startsWith("/dashboard") &&
      location.pathname !== "/user-plans"
    ) {
      document.body.setAttribute(
        "data-theme",
        localStorage.getItem("photofindtheme")
      );
    } else {
      document.body.setAttribute("data-theme", "default");
    }
  }, []);

  useEffect(() => {
    if (userProfileData?.theme_class_name !== undefined) {
      localStorage.setItem("photofindtheme", userProfileData?.theme_class_name);
      document.body.setAttribute(
        "data-theme",
        userProfileData?.theme_class_name
      );
    }
  }, [userProfileData?.theme_class_name]);

  const token = window.localStorage.getItem("USER_ACCESS_TOKEN");


  useEffect(() => {
    if (userProfileData.plan_payment_status === 0) {
      navigate("/plans");
    }
  }, [userProfileData]);

  return (
    <CommonLayout
      pageClass={`dashboard ${isCollapsed ? "sidebar-collapsed" : ""}`}
      noFooter
    >
      <Uploady
        multiple
        method="POST"
        destination={{
          url:
            fileUploadState.uploadMode == "FPA"
              ? `${BASE_URL}v1/pf-image-upload`
              : `${api}album/upload-image`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          // filesParamName: "image",
        }}
      >
        {location.pathname.indexOf("payment") === -1 && (
          <SideMenuBar
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        )}

        <Outlet context={[isCollapsed]} />
      </Uploady>
    </CommonLayout>
  );
};

export default DashboardPage;
