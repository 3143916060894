import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDimensions } from "../../../Logic/Dimensions";
import { useFolderview } from "../../../Logic/UserFlow/useFolderview";
import { updatePreselect } from "../../../store/slices/UserFlow/userFlowSlice";
import ToggleSwitch from "../../../utils/ToggleSwitch";
import CustomBreadcrumb from "../../CustomBreadcrumb";
import Assets from "../../Layouts/Assets";
import Icon from "../../Layouts/Icons";
import CommonModal from "../../Modals/CommonModal";
import Style from "./FolderView.module.scss";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import { useUserFlow } from "../../../Logic/UserFlow/useUserFlow";
import { useParams } from "react-router-dom";

const FolderView = () => {
  let { user_token } = useParams();
  const navigate = useNavigate();

  const windowDimensions = useDimensions();
  const dispatch = useDispatch();
  const postSelectPic = useSelector((state) => state.userFlow);

  const [isSelected, setIsSelected] = useState(false);

  const { allPhotosData, currentTab, sections } = useSelector(
    (state) => state.userFlow
  );
  // const [allCount, selectedCount] = [sections[currentTab]?.data?.length];
  const { getUserFlowData } = useUserFlow();

  useEffect(() => {
    getUserFlowData();
  }, []);
  let count = [sections["All"]?.data?.length];
  const {
    postSelectImages,
    isSelectionModal,
    setIsSelectionModal,
    isThanksModal,
    setIsThanksModal,
    handleConfirm,
  } = useFolderview();

  let eventName =
    sections["All"]?.data?.[0]?.album_image?.[0]?.album?.event_category?.name;

  let albumName = sections["All"]?.data?.[0]?.album_image?.[0]?.album?.name;

  const handlePreSelect = () => {
    if (sections[currentTab]?.currentAction?.preSelect) {
      dispatch(updatePreselect(false));
    } else {
      dispatch(updatePreselect(true));
    }
  };


  return (
    <section className={Style.FolderView}>
      <Container>
        <div className={Style.user_flow_folderview}>
          <Row className={Style.user_flow_folderview_header}>
            <Col xl="6">
              {sections["All"]?.data && (
                <CustomBreadcrumb
                  levels={[
                    // { title: "Project", path: "" },
                    { title: eventName, path: `/userflow/${user_token}` },
                    { title: albumName, path: "" },
                  ]}
                />
              )}
              <h2 className="h3">{albumName}</h2>
            </Col>
            <Col xl="6" className={`text-center text-sm-end my-auto`}>
              <div className={Style.FolderView_header_btn_wrap}>
                {currentTab == "All" && (
                  <div className={Style.FolderView_header_select_btn_wrap}>
                    <button
                      className={`btn btn-primary ${Style.Select_photos_btn}`}
                      onClick={() => {
                        handlePreSelect();
                      }}
                    >
                      {sections[currentTab]?.currentAction?.preSelect ? (
                        <Icon size={16} icon={"icon-checked"} />
                      ) : (
                        <Icon size={16} icon={"icon-unchecked"} />
                      )}

                      {!sections[currentTab]?.currentAction?.preSelect ? (
                        <span>Select Photos</span>
                      ) : (
                        <span>Unselect Photos</span>
                      )}
                    </button>
                  </div>
                )}
                <div
                  className={`text-center text-sm-end order-md-2 order-1 ${Style.user_flow_folderview_header_btns} ${Style.FolderView_header_toggle_btn}`}
                >
                  <ToggleSwitch
                    className={`ms-auto ${Style.photo_select_toggle_btn}`}
                    firstBtn={`All Photos ${count ? count : ""}`}
                    secondBtn={`Selected ${
                      sections["Selected"]?.data?.length
                        ? sections["Selected"]?.data?.length
                        : ""
                    }`}
                    size={"md"}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Outlet />
        </div>
      </Container>
      <div className={Style.user_flow_folderview_footer}>
        <Container>
          <Row>
            {windowDimensions.width >= 768 ? (
              <>
                <Col sm="6" className="my-auto">
                  <p className={`mb-0`}>
                    *Choose the photos to add to selection.
                  </p>
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col
              md="6"
              className={`text-center text-md-end my-auto ${Style.user_flow_folderview_footer_btns}`}
            >
              {currentTab == "All" ? (
                <>
                  <button
                    className="btn btn-primary"
                    disabled={
                      sections[currentTab]?.preSelectedImage?.length > 0
                        ? false
                        : true
                    }
                    onClick={() =>{
                        postSelectImages(sections[currentTab]?.preSelectedImage);
                      }
                    }
                  >
                    Add to selection
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-primary ms-2 ms-sm-3"
                  disabled={
                    sections["Selected"]?.data?.length > 0 ? false : true
                  }
                  onClick={() => setIsSelectionModal(true)}
                >
                  Submit
                </button>
              )}
            </Col>
            {windowDimensions.width < 768 ? (
              <>
                <Col xs="12">
                  <p className={Style.user_flow_folderview_footer_label}>
                    *Choose the photos from all folders that you want to
                    showcase in your album.
                  </p>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </Container>
      </div>

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={isSelectionModal}
        setShowModal={setIsSelectionModal}
        centered={true}
        className={`type-sm confirm_modal ${Style.confirm_modal}`}
        title={<h4>Confirm selection</h4>}
        content={
          <>
            <p>
              You have successfully selected the photos, click confirm to
              complete your selection
            </p>
          </>
        }
        footer={
          <>
            <button
              className={`btn btn-green ${postSelectPic?.status === "loading" ? Style.status_loading : '' }`}
              onClick={() => {
                handleConfirm();
              }}
            >
              {postSelectPic?.status === "loading" ? (
                <InfiniteDotLoader />
              ) : (
                "Confirm"
              )}
            </button>
            <button
              className="btn btn-border-grey"
              onClick={() => setIsSelectionModal(false)}
            >
              Cancel
            </button>
          </>
        }
      />

      <CommonModal
        backdrop={windowDimensions.width >= 576 ? "static" : false}
        keyboard={false}
        showModal={isThanksModal}
        setShowModal={setIsThanksModal}
        centered={true}
        uploadSuccess={true}
        className={`type-lg ${Style.thanks_modal}`}
        content={
          <>
            <div className="row">
              <div className="col-12 text-center">
                <div className={Style.successfully_sent_img}>
                  <figure>
                    <img src={Assets.successfully_sent_gif} alt="wedding-img" />
                  </figure>
                </div>
                <h3 className={`mb-3 ${Style.thaks_you_modal_title}`}>
                  Thank you!
                </h3>
                <p className={`mb-4 ${Style.thaks_you_modal_content}`}>
                  You have successfully sent all photos you want to showcase on
                  your album.
                </p>
              </div>
            </div>
          </>
        }
        footer={
          <>
            <div className="w-100 text-center">
              <button
                onClick={() => {
                  navigate(`/userflow/${user_token}`);
                  window.location.reload();
                }}
                className="btn btn-primary px-md-4 footer-link"
              >
                <span className="px-sm-2">Back Home</span>
              </button>
            </div>
          </>
        }
      />
    </section>
  );
};

export default FolderView;
