import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFolderview } from "../../../../Logic/UserFlow/useFolderview";
import { useUserFlow } from "../../../../Logic/UserFlow/useUserFlow";
import PhotoFolderCard from "../../../Cards/PhotoFolderCard";
import ImagePreview from "../../ImagePreview";
import Style from "../FolderView.module.scss";
import { useSingleKey } from "../../../../utils/KeyBoardShortCuts";

const AllPhotos = () => {
  const { currentTab, sections } = useSelector((state) => state.userFlow);
  const {
    loadMore,
    isImagePreview,
    setIsImagePreview,
    isSlideKey,
    setIsSlideKey,
    handleSuggest
  } = useFolderview();

  const { getUserFlowData } = useUserFlow();

  useEffect(() => {
    getUserFlowData();
  }, []);

  const handleEscape = () => {
    setIsImagePreview(false);
  };

  useSingleKey("Escape", handleEscape);

  const imgItemRef = useRef(null);
  const imgRootRef = useRef(null);
  const observer = useRef();

  useEffect(() => {
    if (imgItemRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (entries.every((entry) => entry.isIntersecting)) {
          loadMore();
        }
      });
      observer.current.observe(imgItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [sections["All"]?.data, imgRootRef]);

  return (
    <div className={Style.user_flow_folderview_body} ref={imgRootRef}>
      <Row>
        {sections[currentTab]?.data?.map((data, index) => {
          const lastIndex = sections["All"]?.data?.length - 1;

          return (
            <Col
              sm="6"
              className={`img-loading-2-item ${Style.col_image_wrap}`}
              key={index}
            >
              <div ref={lastIndex == index ? imgItemRef : null}>
                <PhotoFolderCard
                  image={data?.src?.thumbnail_medium}
                  setIsImagePreview={setIsImagePreview}
                  setIsSlideKey={setIsSlideKey}
                  keyId={index}
                  id={data?.id}
                  lastIndex={lastIndex}
                />
              </div>
            </Col>
          );
        })}
      </Row>

      {isImagePreview === true ? (
        <ImagePreview
          slideData={sections["All"]?.data}
          setIsImagePreview={setIsImagePreview}
          activeSlideIndex={isSlideKey}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AllPhotos;
