import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAnimations } from "../../Logic/Animations";
import PlansCard from "../PlansCard";
import PlansCardImage from "../PlansCardImage";
import Style from "./PlansCardStack.module.scss";

const PlansCardStack = ({
  plansData,
  buttonText,
  cardType,
  buttonCallback,
}) => {
  const { userProfileData } = useSelector((state) => state.userData);

  const { slideTopRef } = useAnimations();
  const location = useLocation();

  const PlansCardWrapper = () => {
    return (
      <>
        {plansData
          ? plansData?.map((item) => {
              return (
                <div
                  className={`${Style.PlansCardStack__cardWrap} ${
                    item.recommended ? Style.plan_recommended : ""
                  }`}
                  key={item.id}
                  ref={slideTopRef}
                >
                  <Link to="">
                    <PlansCard
                      data={item}
                      buttonText={buttonText}
                      type={cardType}
                      buttonCallback={buttonCallback}
                    />
                  </Link>
                </div>
              );
            })
          : [1, 2, 3].map((item, index) => {
              return (
                <div
                  className={`${Style.PlansCardStack__cardWrap} data-loading`}
                  key={index}
                  ref={slideTopRef}
                >
                  <div className={`${Style.PlansCard} plans-card`}>
                    <div className={Style.PlansCard__top}>
                      <div
                        className={`${Style.plan_duration} plan-duration`}
                      ></div>
                      <h4 className={`${Style.plan_amount} plan-amount`}>
                        <span className={Style.plan_amount_value}></span>{" "}
                      </h4>
                      <p
                        className={`${Style.plan_description} plan-description`}
                      >
                        <span className={Style.lines}></span>
                        <span className={Style.lines}></span>
                        <span className={Style.lines}></span>
                      </p>
                      <ul className={`${Style.plan_features} plan-features`}>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                    <div
                      className={`${Style.PlansCard__button} plans-card-button`}
                    ></div>
                  </div>
                </div>
              );
            })}
      </>
    );
  };

  return (
    <div
      className={`${Style.PlansCardStack} ${
        location.pathname === "/plans" || location.pathname === "/"
          ? userProfileData.plan_payment_status === 1 && "payment_done"
          : ""
      } `}
    >
      <div className={`${Style.PlansCardStack__cards} anim load-anim`}>
        {plansData.length === 1 ? (
          <>
            <PlansCardImage
              className={location.pathname === "/" ? Style.PlansCard_image : ""}
            />
          </>
        ) : (
          <></>
        )}
        
        {location.pathname === "/plans" || location.pathname === "/"
          ? userProfileData.plan_payment_status === 0 || userProfileData.plan_payment_status === undefined
            ? PlansCardWrapper()
            : ""
          : PlansCardWrapper()}
      </div>
    </div>
  );
};

export default PlansCardStack;
