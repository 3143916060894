import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuthChecker from "../../../Logic/Auth/useAuthChecker";
import MainFooter from "../MainFooter";
import MainHeader from "../MainHeader";

const CommonLayout = ({ pageClass, children, style, noFooter }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <MainHeader />
      <main
        className={`
          ${pageClass} ${noFooter ? "noFooter" : ""}
        `}
        style={style}
      >
        {children}
      </main>
      {noFooter ? "" : <MainFooter />}
    </>
  );
};

export default CommonLayout;
