import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useAnimations } from "../../../Logic/Animations";
import useAuthChecker from "../../../Logic/Auth/useAuthChecker";
import { useHome } from "../../../Logic/useHome";
import PlansCardStack from "../../PlansCardStack";
import Style from "./HomeChoosePlans.module.scss";

const HomeChoosePlans = () => {
  const { slideTopRef } = useAnimations();

  const { plans, plansData, buttonCallback } = useHome();
  const { isAuthCompleted } = useAuthChecker();

  useEffect(() => {
    plans();
  }, []);

  return (
    <section className={Style.HomeChoosePlans}>
      <Container>
        {plansData.length === 1 ? (
          <></>
        ) : (
          <>
            <div className={`section-title-block title-block-white center`}>
              <h2 ref={slideTopRef}>Choose a Plan to Start</h2>
              <p ref={slideTopRef}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt dolore.
              </p>
            </div>
          </>
        )}
        <PlansCardStack
          plansData={plansData}
          buttonText={"Buy Now"}
          cardType={"main"}
          buttonCallback={buttonCallback}
          isAuthCompleted={isAuthCompleted}
        />
      </Container>
    </section>
  );
};

export default HomeChoosePlans;
