import gsap from "gsap";
import { ScrollTrigger, Power2 } from "gsap/all";
import { useLayoutEffect, useRef } from "react";
import splitting from "splitting";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

gsap.registerPlugin(ScrollTrigger);

export const useAnimations = () => {
    const slideTop = useRef([]);
    slideTop.current = [];
    const slideTopFooter = useRef([]);
    slideTopFooter.current = [];
    const slideRight = useRef([]);
    slideRight.current = [];
    const splitText = useRef([]);
    splitText.current = [];

    useLayoutEffect(() => {
        slideTop.current.map(item => {
            return (
                gsap.fromTo(
                    item,
                    {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power2,
                    },
                    {
                        y: 0,
                        duration: 0.5,
                        autoAlpha: 1,
                        ease: Power2,
                        scrollTrigger: {
                            trigger: item,
                            start: "top bottom-=100",
                        }
                    }
                )
            )
        });

        slideTopFooter.current.map(item => {
            return (
                gsap.fromTo(
                    item,
                    {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power2,
                    },
                    {
                        y: 0,
                        duration: 0.5,
                        autoAlpha: 1,
                        ease: Power2,
                        scrollTrigger: {
                            trigger: item,
                            start: "top bottom-=10",
                        }
                    }
                )
            )
        });

        slideRight.current.map(item => {
            return (
                gsap.fromTo(
                    item,
                    {
                        autoAlpha: 0,
                        x: -30,
                        ease: Power2,
                    },
                    {
                        x: 0,
                        duration: 0.5,
                        autoAlpha: 1,
                        ease: Power2,
                        scrollTrigger: {
                            trigger: item,
                            start: "top bottom-=100",
                        }
                    }
                )
            );
        });

        splitText.current.map(item => {
            splitting({
                target: splitText.current,
                by: "chars",
                key: null
            });
        })

        const splitChars = document.querySelectorAll('.split-lines .char');
        splitText.current.map(item => {
            return (
                gsap.fromTo(
                    splitChars,
                    {
                        y: 20,
                        opacity: 0,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        stagger: 0.05,
                        duration: 0.22,
                        ease: Power2,
                        scrollTrigger: {
                            trigger: splitChars,
                            start: "top bottom",
                        }
                    }
                )
            )
        })
    }, []);

    const slideTopRef = item => {
        if (item) {
            slideTop.current.push(item);
        }
    };
    const slideTopFooterRef = item => {
        if (item) {
            slideTopFooter.current.push(item);
        }
    };

    const slideRightRef = item => {
        if (item) {
            slideRight.current.push(item);
        }
    };

    const splitTextRef = item => {
        if (item) {
            splitText.current.push(item);
        }
    };

    return {
        slideTopRef,
        slideRightRef,
        splitTextRef,
        slideTopFooterRef,
    }
}