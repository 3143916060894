import React from "react";
import { useOutletContext } from "react-router-dom";
import ProjectCardGallery from "../ProjectCardGallery";
import Style from "./ProjectsList.module.scss";
import { useSelector } from "react-redux";
import BlankDataPlaceholderSearchResult from "../BlankDataPlaceholderSearchResult";

const ProjectsList = () => {
  const [isCollapsed] = useOutletContext();
  const { sections, currentTab, status } = useSelector(
    (state) => state.project
  );

  return (
    <div className={Style.ProjectsList}>
      {sections?.[currentTab]?.["searchResult"] === "empty" ? (
        <BlankDataPlaceholderSearchResult blankDescription="Sorry No Results Found." />
      ) : (
        <ProjectCardGallery
          isCollapsed={isCollapsed}
        />
      )}
    </div>
  );
};

export default ProjectsList;
