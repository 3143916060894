import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllPlans,
  getAllStudiosPlans,
  storePlanID,
  getOrderID,
  saveOrder,
  toggleProjectAddPopup,
} from "../store/slices/Project/ProjectSlice";

const usePayment = () => {
  const { allPlans, selectedPlanID, stepOneFormData, stepTwoFormData } =
    useSelector((state) => state.project.addProjects);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProjectID = () => {
    return stepOneFormData.result.id;
  };

  const getClientID = () => {
    return stepTwoFormData.result.id;
  };

  /****************************************************************
   * THIS FUNCTION CHECKS REQUEST TO SELECT PLAN IS VALID NOT NOT *
   ****************************************************************/

  const isItValidRequest = () => {
    if (stepOneFormData == "" || stepTwoFormData == "") {
      navigate("/dashboard");
    }
  };

  /**************************************************************************************************
   * THIS FUNCTION TRIGGER AN API CALL WHEN USER COMES TO SELECT A PLAN TO THE PAGE "PROJECT-PLANS" *
   **************************************************************************************************/

  const getClientPlans = () => {
    dispatch(getAllPlans());
  };


  /********************************************************************
   * THIS FUNCTION STORE THE VALUE OF SELECETD PLAN ID IN REDUX VARIABLE *
   ********************************************************************/

  const selectPlan = (data) => {
    /******************************************************************
     * IF CONDITION CHECKS CLICKED PLAN IS NOT ALREADY SELECTED BY USER *
     ******************************************************************/
   // navigate("/dashboard"); //xxx
    if (selectedPlanID != data?.id) {
      navigate(`/dashboard/payment/${data.id}/${data.clientID}/${data.projectID}`);
     // window.location = `/payment/${data.id}/`
     // handleGetOrderID({ plan_id: id });
    }
  };

  /*****************************************************************
   * THIS FUNCTION GENERATES ORDER ID BY PASSING THE SELECTED PLAN
   * THIS FUNCTION TRIGGERS ON USER CLICK ON ANY PLAN CARD TO BUY *
   *****************************************************************/

  const handleGetOrderID = (data) => {
    const dataPacket = {
      plan_id: data.plan_id,
      project_id: getProjectID(),
      client_id: getClientID(),
    };

    if (data.plan_id != "") {
      dispatch(getOrderID(dataPacket))
        .unwrap()
        .then((result) => {
          if (result?.data?.success === true) {
            handleSaveOrder(
              "pi_3M80QSSHpUE1Yic90N2ee8HQ", // PAYMENT ID IS DYNAMIC WHICH NEED TO CHANGE AFTER PAYMENT COMPLETE
              result.data.result.orderId
            );
            dispatch(toggleProjectAddPopup(false));
          }
        });
    }
  };

  const paymentCompleted = (Data) => {};

  /**********************************************************
   * THIS FUNCTION CALLS WHEN THE USER COMPLETE THE PAYMENT.
   * AFTER THE PAYMENT COMPLETION FINALL CALL WILL BE MADE WITH PAYMENT ID WHICH CAN BE COLLETED FROM PAYMENT GATEWAY AND ORDER ID *
   **********************************************************/

  const handleSaveOrder = (paymentID, order_id) => {
    let formData = {
      id: paymentID,
      order_id: order_id,
    };

    dispatch(saveOrder(formData))
      .unwrap()
      .then((result) => {
        const { success, message } = result.data;
        if (success === true) {
          navigate("/dashboard");
        } else {
          alert(message);
        }
      });
  };


  const getStudioPlans = () => {
    dispatch(getAllStudiosPlans());
  };

  return { getClientPlans,getStudioPlans, allPlans, selectPlan, isItValidRequest };
};

export default usePayment;
