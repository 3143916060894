import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStaffsAPI,
  customLoadMore,
  handleCustomSearchText,
  setSearchResultPlaceHolderValue,
} from "../../store/slices/Staffs/staffsSlice";

export const useSearch = () => {
  const dispatch = useDispatch();
  const [isMobileSearch, setIsMobileSearch] = useState(null);
  const { sections, currentTab } = useSelector((state) => state.staffs);

  /************************
   * SEARCH BAR VARIABLES *
   ************************/

  const [isFocused, setIsFocused] = useState(false);
  const [resetVisible, setResetVisible] = useState(false);

  const [searchResultPlaceHolderText, setSearchResultPlaceHolderText] =
    useState("You haven't created any projects");

    useEffect(() => {
      return ()=>{
        dispatch(handleCustomSearchText(""));
      }
    }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    dispatch(handleCustomSearchText(value));
  };

  const useDebounce = function (value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  };

  const debouncedSearchTerm = useDebounce(
    sections[currentTab]?.searchText,
    700
  );

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      fetchStaffs(currentTab, debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const getTabUrl = (slug) => {
    switch (slug) {
      case "activeTab":
        return "permanent-staff-list";
        break;
      case "contractStaffTab":
        return "contract-staff-list";
        break;
    }
  };

  const fetchStaffs = (slug, query = false) => {
    let obj = {
      url: getTabUrl(currentTab),
      page: "1",
      /* sort:
        sections[currentTab]?.sort != "" &&
        sections[currentTab]?.sort != undefined
          ? sections[currentTab]?.sort
          : "desc",*/
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    }

    return dispatch(getAllStaffsAPI(obj));
  };

  const loadMore = () => {
    if (sections[currentTab]?.current_page < sections[currentTab]?.last_page) {
      let obj = {
        url: getTabUrl(currentTab),
        page: parseInt(sections[currentTab]?.current_page) + 1,
        per_page: 20,
        sort:
          sections[currentTab]?.sort != ""
            ? sections[currentTab]?.sort
            : "desc",
      };

      if (
        sections?.[currentTab]?.["search"] != "" &&
        sections?.[currentTab]?.["search"] != undefined
      ) {
        obj.search = sections?.[currentTab]?.["search"];
      }

      dispatch(customLoadMore(obj));
    }
  };

  const handleClear = () => {
    // setText("");
    setResetVisible(false);
    fetchStaffs(currentTab);
  };

  /************************
   * SEARCH BAR FUNCTIONS *
   ************************/

  const handleResetVisible = (e) => {
    // setText(e.target.value);
    e.target.value.length > 0 ? setResetVisible(true) : setResetVisible(false);
  };

  return {
    handleChangeInput,
    searchText: sections[currentTab]?.searchText,
    handleClear,
    isFocused,
    setIsFocused,
    resetVisible,
    setResetVisible,
    searchResultPlaceHolderText,
    isMobileSearch,
    setIsMobileSearch,
  };
};
