import Style from "./StudioName.module.scss";
import React from "react";
import { authModalHandler } from "../../../../../store/slices/Preference/studioDetailstSlice";
import { useDispatch } from "react-redux";
import { useStudioDetails } from "../../../../../Logic/Preference/useStudioDetails";

const StudioDeleteAccount = () => {
  const dispatch = useDispatch();

  const { deletAccount } = useStudioDetails();

  return (
    <>
      <h4>Delete Account</h4>
      <p>Are you sure you want to delete this Account?</p>

      <div className={Style.actions}>
        <button className="btn btn-red me-2" onClick={deletAccount}>
          Delete
        </button>
        <button
          className="btn btn-border-grey"
          onClick={() => dispatch(authModalHandler(false))}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default StudioDeleteAccount;
