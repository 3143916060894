import React, { useState, useEffect, useRef } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import Assets from "../../Layouts/Assets";
import GalleryListView from "../GalleryListVIew";
import GalleryMasonryAllImages from "../GalleryMasonryAllImages";
import Style from "./ProjectGallery.module.scss";
import ImagePreview from "../../UserFlow/ImagePreview";
import useGallery from "../../../Logic/useGallery";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import MasonryCard from "../GalleryMasonryAllImages/MasonryCard";
import { useDispatch, useSelector } from "react-redux";
import { useGalleryTab } from "../../../Logic/useGalleryTab";
import DeleteProjectGalleryItemsPopup from "./DeleteProjectGalleryItemsPopup";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import BlankDataPlaceholder from "../BlankDataPlaceholder";
import useMasonry from "../../../Logic/useMasonry";
import { updateUploadMode } from "../../../store/slices/Gallery/fileUploadSlice";

const ProjectGalleryAll = () => {

  const dispatch = useDispatch()
  const { sections, currentTab, status } = useSelector(
    (state) => state.gallery
  );

  const {
    columnsCountBreakPoints,
    selectedImage,
    setSelectedImage,
    isImagePreview,
    setIsImagePreview,
    isSelectionOpen,
    setIsSelectionOpen,
    imgRef,
    observer,
  } = useMasonry();

  const {
    getImagesForSection,
    handleGalleryTab,
    isDataLoading,
    loadMore,
    handleResetGalleryData,
    handleClearGallery,
    handleSuggest,
  } = useGallery();

  const {
    handleSelectImage,
    openDeletePopup,
    handleDeleteSelectedImages,
    uploadModal,
  } = useGalleryTab();

  useEffect(() => {

    getImagesForSection("all");

  }, [sections[currentTab]?.["order"]]);


  useEffect(() => {
    if (imgRef?.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries?.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore("all");
        }
      });
      observer?.current?.observe(imgRef?.current);
      return () => {
        observer.current?.disconnect();
      };
    }
  }, [sections[currentTab]?.data]);

  return (
    <>
      <ResponsiveMasonry columnsCountBreakPoints={columnsCountBreakPoints}>
        <Masonry>
          {sections[currentTab]?.data?.map((data, i) => {
            return (
              <>
                <div ref={imgRef}>
                  <MasonryCard
                    key={i}
                    data={data}
                    isLiked={data?.album_image?.[0]?.is_suggested}
                    isImagePreview={isImagePreview}
                    setIsImagePreview={setIsImagePreview}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    isSelectionOpen={isSelectionOpen}
                    dataIndex={i}
                    preSelect={sections[currentTab]?.currentAction?.preSelect}
                    isCardSelected={sections[
                      currentTab
                    ]?.selectedImages?.includes(data?.id)}
                    handleSelectImage={handleSelectImage}
                    handleSuggest={handleSuggest}
                  />
                </div>
              </>
            );
          })}
        </Masonry>
      </ResponsiveMasonry>

      {sections[currentTab]?.data.length === 0 ? (
        <BlankDataPlaceholder
          title={"You haven't uploaded any images"}
          description={"The images you uploaded are shown here"}
          buttonText={"Add Photos"}
          template={"image"}
          onClick={() => {
            uploadModal(true);
            dispatch(updateUploadMode("photoFind"))
          }}
        />
      ) : (
        ""
      )}
      <div
        className={`${Style.gallery_image_wrap} gallery-infinitedotloader-wrap`}
      >
        {status === "loading" ? <InfiniteDotLoader type={"project"} /> : ""}
      </div>

      {isImagePreview ? (
        <ImagePreview
          slideData={sections[currentTab]?.data}
          setIsImagePreview={setIsImagePreview}
          activeSlideIndex={selectedImage}
          type="normal-lightbox"
        />
      ) : (
        ""
      )}
      <DeleteProjectGalleryItemsPopup
        openDeleteModal={
          sections[currentTab]?.currentAction?.deleteWarningPopup
        }
        modalControl={openDeletePopup}
        handleDeleteAction={handleDeleteSelectedImages}
        isItMulitple={
          sections[currentTab]?.selectedImages?.length === 1 ? false : true
        }
      />
    </>
  );
};

export default ProjectGalleryAll;
