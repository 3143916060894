import { Formik, Field } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";
import CommonInput from "../../../../Layouts/CommonInput";
import CommonModal from "../../../../Modals/CommonModal";
import Style from "./AddStaff.module.scss";
import { updateStaffPreSelect } from "../../../../../store/slices/Staffs/staffsSlice";
const AddStaff = ({
  showAddStaffModal,
  setShowAddStaffModal,
  staffValidationSchema,
  staffSubmit,
  preSelectstaffType,
  designations
}) => {
  const dispatch = useDispatch();
  const addStaffData = useSelector((state) => state.preferenceList);
  const { editStaff, staffPreSelect } = useSelector((state) => state.staffs);

  const [staffType, setStaffType] = useState(
    preSelectstaffType != undefined ? preSelectstaffType : null
  );

  useEffect(() => {
    if (Object.keys(editStaff?.staffData).length > 0) {
      setStaffType(editStaff?.staffData?.staff_type);
    }

    if (editStaff?.status === false) {
      setStaffType("");
    }
  }, [editStaff?.staffData]);

  useEffect(() => {
    setStaffType(staffPreSelect);
  }, [staffPreSelect]);

  const getCurrentDestination = (id) => {
    let index = designations?.findIndex((obj) => obj.value == id);
    if (index > -1) {
      return designations[index]
    }
    return null
  }


  return (
    <Formik
      validationSchema={staffValidationSchema}
      initialValues={{
        staff_name: editStaff?.status !== true ? "" : editStaff?.staffData.name,
        staff_mobile:
          editStaff?.status !== true ? "" : editStaff?.staffData.mobile,
        staff_email:
          editStaff?.status !== true ? "" : editStaff?.staffData.email,
        staff_designation: editStaff?.staffData.designation_id,
        country_dial_code:
          editStaff?.status !== true
            ? "+91"
            : editStaff?.staffData.country_dial_code,
        staff_type:
          editStaff?.status !== true
            ? staffPreSelect != ""
              ? staffPreSelect
              : ""
            : editStaff?.staffData.staff_type,
      }}
      enableReinitialize={true}
      onSubmit={(values, { resetForm, setFieldError }) => {

        let FormData = {
          name: values?.staff_name,
          email: values?.staff_email,
          mobile: values?.staff_mobile,
          country_dial_code: values?.country_dial_code,
          staff_type: values?.staff_type,
          designation_id: values.staff_designation
        };

        if (editStaff?.status == true) {
          FormData.id = editStaff?.staffData.id;
          if (editStaff?.staffData.staff_type != values?.staff_type) {
            FormData.type_changed = true;
          }
        }
        staffSubmit(FormData, resetForm, setFieldError, setStaffType);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
        resetForm,
        isValid,
        dirty,
      }) => {

        return (
          <>

            <CommonModal
              backdrop="static"
              keyboard={false}
              showModal={showAddStaffModal}
              setShowModal={setShowAddStaffModal}
              centered={true}
              className={`type-lg add-staff-modal`}
              title={<h4>Add staff</h4>}
              clearState={resetForm}
              content={
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <CommonInput
                        label="Name"
                        type="text"
                        variant="input"
                        name="staff_name"
                        id="input_name"
                        floatingLabel={true}
                        value={values?.staff_name}
                        hasError={errors?.staff_name && touched.staff_name}
                        errorMsg={errors?.staff_name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("staff_name", e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CommonInput
                        label="Mobile"
                        variant="phone-with-country"
                        floatingLabel={true}
                        id="input_phone"
                        name="staff_mobile"
                        default_country="IN"
                        value={values?.staff_mobile}
                        hasError={errors?.staff_mobile && touched.staff_mobile}
                        errorMsg={errors?.staff_mobile}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("staff_mobile", e.target.value);
                        }}
                        country_dial_code={
                          editStaff?.staffData.country_dial_code
                        }
                        callback={function (country) {
                          setFieldValue("country_dial_code", country.dial_code);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <CommonInput
                        label="Email"
                        type="email"
                        variant="input"
                        id="input_email"
                        floatingLabel={true}
                        name="staff_email"
                        value={values?.staff_email}
                        hasError={errors?.staff_email && touched.staff_email}
                        errorMsg={errors?.staff_email}
                        //onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("staff_email", e.target.value);
                        }}
                      />
                    </div>


                    <div className="col-md-6">
                      <CommonInput
                        selectedValue={getCurrentDestination(values.staff_designation)}
                        label="Designation"
                        variant="select-input"


                        floatingLabel={true}
                        options={designations}
                        onBlur={handleBlur}
                        callback={(value) => {
                          setFieldValue("staff_designation", value);
                        }}

                        hasError={errors?.staff_designation && touched.staff_designation}
                        errorMsg={errors?.staff_designation}
                      />
                    </div>
                    <div className={`col-md-12 ${Style.checkbox_container}`}>
                      <div className={Style.checkbox_wrap}>
                        <span
                          className={`form-check-box ${staffType === 2 || values?.staff_type === 2
                            ? "checked"
                            : ""
                            }`}
                          onClick={() => {
                            setStaffType(2);
                            setFieldValue("staff_type", 2);
                          }}
                        ></span>
                        <label
                          onClick={() => {
                            setStaffType(2);
                            setFieldValue("staff_type", 2);
                          }}
                        >
                          Permanent staff
                        </label>
                      </div>

                      <div className={Style.checkbox_wrap}>
                        <span
                          className={`form-check-box ${staffType === 1 || values?.staff_type === 1
                            ? "checked"
                            : ""
                            }`}
                          onClick={() => {
                            setStaffType(1);
                            setFieldValue("staff_type", 1);
                          }}
                        ></span>
                        <label
                          onClick={() => {
                            setStaffType(1);
                            setFieldValue("staff_type", 1);
                          }}
                        >
                          Contract staff
                        </label>
                      </div>

                      {/* {errors?.staff_type &&
                        Object.keys(errors).length === 1 && (
                          <span htmlFor="" className="error-message">
                            {errors?.staff_type}
                          </span>
                        )} */}
                    </div>
                  </div>
                </>
              }
              footer={
                <>
                  <button
                    className="btn btn-border-grey"
                    onClick={() => {
                      setStaffType("");
                      setShowAddStaffModal(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    //disabled={!isValid || !dirty}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {addStaffData?.status === "loading" ? (
                      <InfiniteDotLoader />
                    ) : (
                      "Save"
                    )}
                  </button>
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

export default AddStaff;
