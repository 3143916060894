import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../../Logic/Dimensions";
import Icon from "../../../Layouts/Icons";
import LiveProjectsCard from "../LiveProjectsCard";
import Style from "./LiveProjects.module.scss";

const LiveProjects = ({ LiveProjectsViewData, className }) => {
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const windowDimensions = useDimensions();
  const [isAssigned, setIsAssigned] = useState(true);
  const [isActiveDropdown, setIsActiveDropdown] = useState("Assigned");

  return (
    <>
      <div className={`${Style.LiveProjects__container} ${className}`}>
        <div className={Style.LiveProjects__titlebar}>
          <div className={`row mb-3`}>
            <div className={`col-12 col-sm-9 my-auto`}>
              <h4 className={`mb-0 ${Style.LiveProjects__title}`}>
                {LiveProjectsViewData?.title}
              </h4>
            </div>
          </div>
        </div>
        <div className={Style.LiveProjects__wrap} ref={imgRootRef}>
          {LiveProjectsViewData?.data?.length == 0 ? (
            <span>No more staff</span>
          ) : (
            <>
              <Table className={Style.LiveProjects}>
                <thead className={Style.LiveProjects__header}>
                  <tr>
                    <th className={Style.LiveProjects__headerItem}>
                      Project Name
                    </th>
                    <th className={Style.LiveProjects__headerItem}>
                      Client Name
                    </th>
                    <th className={Style.LiveProjects__headerItem}>Location</th>
                    <th className={Style.LiveProjects__headerItem}>
                      Mobile number
                    </th>
                    <th className={Style.LiveProjects__headerItem}>Events</th>
                    <th className={Style.LiveProjects__headerItem}></th>
                  </tr>
                </thead>
              </Table>
              <div className={Style.LiveProjects__table_scroll}>
                <Table className={Style.LiveProjects}>
                  <tbody className={Style.LiveProjects__contents}>
                    {LiveProjectsViewData?.data?.map((data, index) => {
                      const lastIndex = LiveProjectsViewData?.data?.length - 1;
                      return (
                        <React.Fragment key={index}>
                          <LiveProjectsCard
                            projectData={data}
                            project={data?.name}
                            client={data?.get_clients?.[0]?.["name"]}
                            location={data?.location_name}
                            mobile={data?.get_clients?.[0]?.["mobile"]}
                            events={data?.current_albums}
                            info={data?.info}
                          />
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </div>
        {/* <div className="w-100">
        <Link className={`btn btn-link px-md-2 mx-auto ${Style.LiveProjects_viewall_btn}`} to={LiveProjectsViewData?.view_all}>View All <Icon color={'currentColor'} size={16} icon={'arrow-right'} /></Link>
       </div> */}
      </div>
    </>
  );
};

export default LiveProjects;
