import React,{ useRef } from "react";
import { Container } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useForgotPassword } from "../../../Logic/useForgotPassword";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm";
import Style from "./ForgotPassword.module.scss";
import FormFooter from "./FormFooter";
import FormHeader from "./FormHeader";
import OtpScreen from "./OtpScreen";
import ResetPasswordForm from "./ResetPasswordForm";

const ForgotPassword = () => {

  const {
    FPFisEditMode,
    editEmail,
    FPFsubmittedEmail,
    emailCheckPassed,
    setEmailCheckPassed,
    forgotPasswordHandler,
    initialInput,
    FPFisLoading,
    otp,
    setOTP,
    forgotPasswordFormError,
    isOTPCompleted,
    SetOTPCompleted,
    FPFOTPerror,

    /**************************
     * RESET PASSWORD FORM *
     **************************/

    resetPasswordInitialInput,
    setResetPasswordInitialInput,
    resetPassword,
    passwordCheck,
    hasPasswordUpdated,
    FPFOTPisLoading,
  } = useForgotPassword();

  const emailInput = useRef(null);

  return (
    <div className={Style.ForgotPassword}>
      <Container className="auth-container">
        <FormHeader />

        {emailCheckPassed === false && (
          <ForgotPasswordForm
            emailCheckPassed={emailCheckPassed} //
            emailInput={emailInput}
            setIsOtpOpen={setEmailCheckPassed}
            forgotPasswordHandler={forgotPasswordHandler}
            initialInput={initialInput}
            isFormLoading={FPFisLoading}
            error={forgotPasswordFormError}
          />
        )}

        {emailCheckPassed === true && isOTPCompleted === false && (
          <>
            <OtpScreen
              isOtpOpen={emailCheckPassed}
              OtpInput={OtpInput}
              otp={otp}
              setOTP={setOTP}
              SetOTPCompleted={SetOTPCompleted}
              emailInput={emailInput}
              setEmailCheckPassed={setEmailCheckPassed}
              editEmail={editEmail}
              userEmail={FPFsubmittedEmail}
              otpInvalid={FPFOTPerror}
            />
          </>
        )}

        {isOTPCompleted === true && (
          <ResetPasswordForm 
          initData={resetPasswordInitialInput} 
          resetPassword={resetPassword}
          passwordCheck={passwordCheck}
          hasPasswordUpdated={hasPasswordUpdated}
          isLoading={FPFOTPisLoading}
          />
        )}
        <FormFooter />
      </Container>
    </div>
  );
};

export default ForgotPassword;
