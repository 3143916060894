import { forwardRef, useState } from "react";
import Icon from "../../Layouts/Icons";
import Style from "./PhotoFolderCard.module.scss";
import { useDimensions } from "../../../Logic/Dimensions";
import { useDispatch, useSelector } from "react-redux";
import { addImageHandler } from "../../../store/slices/UserFlow/userFlowSlice";

const PhotoFolderCard = ({
  image,
  setIsImagePreview,
  setIsSlideKey,
  keyId,
  id,
  lastIndex,
}) => {
  const windowDimensions = useDimensions();
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);
  const { sections, currentTab } = useSelector((state) => state.userFlow);
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`load-animaiton ${Style.photo_folder_card} ${
          isSelectionOpen ? "" : "img-loading img-loading-2"
        }`}
      >
        <figure>
          <img src={image} alt="" onLoad={() => setIsSelectionOpen(true)} />
        </figure>
        {windowDimensions.width >= 576 ? (
          <>
            {!sections[currentTab]?.currentAction?.preSelect && (
              <button
                className="btn btn-border-grey"
                onClick={() => {
                  setIsImagePreview(true);
                  setIsSlideKey(keyId);
                }}
              >
                View
              </button>
            )}
          </>
        ) : (
          <>
            <span
              className={Style.photo_folder_card_full_view}
              onClick={() => {
                setIsImagePreview(true);
                setIsSlideKey(keyId);
              }}
            ></span>
          </>
        )}

        {sections[currentTab]?.currentAction?.preSelect && (
          <span
            className={`form-check-box ${
              sections[currentTab]?.preSelectedImage?.includes(id)
                ? "checked"
                : ""
            }`}
            onClick={() => {
              dispatch(addImageHandler(id));
            }}
          >
            <Icon
              color={"#fff"}
              size={13}
              icon={
                sections[currentTab]?.preSelectedImage?.includes(id)
                  ? "icon-tick"
                  : ""
              }
            />
          </span>
        )}
      </div>
    </>
  );
};

export default PhotoFolderCard;
