import React from 'react';

const FAQ = () => {

  return (
    <>
      <div className='faq-page inner-page'>
        <div className='container'>
          <h1 className='policies-title'>FAQ</h1>
          <div className="policies-date">Last updated on: 29 October 2022</div>
          <div className='admin-content-area'>
            <dl className='default'>
              <dt><span className='counter-span'></span>What are the services offered by Photofind?</dt>
              <dd>
                Photofind is a friendly digital photo album. Best of your photos can be uploaded to Photofind through a simple drag and drop process. The photos can be re-arranged into albums and slideshows, based on your preference.
              </dd>
              <dt><span className='counter-span'></span>Does Photofind seek permanent access to the photo folder of my computer/mobile phone/portable devices?</dt>
              <dd>
                No. Photofind does not seek permanent access to your phone/computer and its storage folders, essentially because Photofind is not a mass storage solution. It gives you a personal on-line platform for convenient and organised display of your selected photographic memories.
              </dd>
              <dt><span className='counter-span'></span>Does Photofind use cookies?</dt>
              <dd>
                No. Photofind respects your total privacy. Photofind does not use any cookies.
              </dd>
              <dt><span className='counter-span'></span>Why is Photofind necessary? Why cannot we continue to store the photos in our computer/mobile phone/other personal devices?</dt>
              <dd>
                Photofind solutions and data base resides on fast cloud-based servers. This lets you to access your valuable photo-based memories from anywhere around the globe, while connected to the Internet. Even if you are away from your devices, you can access your photos and documents. Even if your mobile phone/laptop computer is lost or misplaced, you do not lose access to your valuable photos.
              </dd>
              <dt><span className='counter-span'></span>My photos are stored in iCloud and Google drives. So I do not lose them. So, what is so special about Photofind?</dt>
              <dd>
                Photofind is not a mass storage platform to dump your entire photo gallery. Rather, it is a premium selection of your photographic memories. Photofind’s strength is fast search/retrieval of your best cherished photographs along with a provision to share photographs to your loved ones, if you choose to. After logging in to PFA, a photo can be located at ease and the link can be shared with in a couple of seconds to your loved ones.
              </dd>
              <dt><span className='counter-span'></span>Is photo tagging possible on Photofind?</dt>
              <dd>
                Yes. You can add any text against a photo and can be later searched based on that tag.
              </dd>
              <dt><span className='counter-span'></span>Is location-based photo search possible on Photofind?</dt>
              <dd>
                If your location service was active while shooting the photograph, Photofind will capture the location details from the metadata of the photograph file and the photos can be searched out based on the location.
              </dd>
              <dt><span className='counter-span'></span>Can I make subject-wise albums to organise my photos?</dt>
              <dd>
                Yes, of course. You can create multiple number of albums and organise them, subject-wise. Albums can be viewed as grids or in expanded mode/view. These preferences can be set in the preferences page. Albums can also be re-arranged in your order of preference. The photos in every album can also be re-arranged in the desired order, just like you re-arrange the application icons in a mobile phone.
              </dd>
              <dt><span className='counter-span'></span>Can I hide an album along with its contents?</dt>
              <dd>
                Yes. In a family environment, if you want privacy of your photos, you can hide and un-hide an album at will. This feature will be particularly useful while making slide-shows which is another feature of Photofind.
              </dd>
              <dt><span className='counter-span'></span>How do I make a slide-show?</dt>
              <dd>
                There are two ways. If you want to make a quick slide-show, you can simply select a few photos from your general gallery or albums and click the slide show button. The Photofind takes a few seconds and the slideshow starts on a standard display mode. If you want to make an organised slide show, you can create a slide show for your friends or family by carefully selecting the photos and setting your preferences of duration, display styles and music tracks.
              </dd>
              <dt><span className='counter-span'></span>Does Photofind reduce the file size of my uploaded photo?</dt>
              <dd>
                Photofind tries to remove unwanted metadata and optimise the file sizes.  This is for improving the performance of your album. However, the viewing quality will not be affected in any manner.
              </dd>
              <dt><span className='counter-span'></span>After uploading the photos to Photofind, can I delete the original files from my local storage devices?</dt>
              <dd>
                As already mentioned, Photofind is not a mass storage solution. We request you to preserve your valuable originals. Photofind optimises the file sizes for web-based viewing. Hence, it cannot be considered as a platform to store your original photographs.
              </dd>
              <dt><span className='counter-span'></span>Can you Photofind’s value proposition more clear ?</dt>
              <dd>
                <p>
                  Read through the following scenarios:
                </p>
                <ol className="lower-alpha">
                  <li>
                    You met your friend who is a famous wild life photographer. You ask him to show you the 25 best photos from his collection. Generally, he will have to search through his hard drives/archives and spend a lot of time to make a selection. Even if he fetches some for you, he may not be fully satisfied that he has shown you all his best photographs. If he is a subscriber of Photofind or Photofind (Photofind’s studio/professional front end), then it takes only a few seconds to show you 25 or 50 of his best photographs.
                  </li>
                  <li>
                    In Photofind, from the general gallery of photos, you can mark your favourites. From the favourite folder, you can ‘star’ the best. Those starred favourites appear on your Profile Gallery and these can be re arranged based on your liking. This becomes the portfolio of a professional photographer. This portfolio can be shared among friends/family or for business purposes. So the combination of Photofind and Photofind gives a lot of professional business possibilities. Professionals like Architects and Creative Artists can showcase their work in a similar manner.
                  </li>
                  <li>
                    On a personal front, you can also create an album with the name “Documents” and store the .jpeg or .png soft copy images of your Passport, VISA, Security IDs and other important documents. Even if you misplace your mobile phone or Laptop, you can log in to Photofind and retrieve and share the documents within a few seconds.
                  </li>
                  <li>
                    Photofind gives you a feature to build your Family Tree. At first, upload the portraits or faces into the standard album called FACES/PORTRAITS.  Build the family tree and drag drop the photos from this folder to make the family tree. You can even place the photo and details of your pets. Sharing the family tree with your family and friends will be very interesting. The Tree is made as a pdf which can be expanded, viewed and printed in a variety of forms.
                  </li>
                  <li>
                    For corporates, Photofind becomes a very useful platform for storing the valuable photographs which can be easily recalled for reviews, board meetings  and when printing annual reports.
                  </li>
                  <li>
                    Wedding photographers and Photo Studios can register on Photofind.in and easily transact with their clients to make quick selections of the photos from the bulk clicks. They can record their project events, assign the staff by sharing the data so that the events are covered in time. Water-marked thumbnails of photographs can be shared with the clients and the Photofind link helps the client to make quick selections of the photos. This saves a lot of time and results in better customer satisfaction. The finally selected photos get uploaded on to Photofind and from there, the end client can start viewing the album. They can also continue to upload further photos from their Spouses side without disturbing the main wedding album.
                  </li>
                </ol>
              </dd>
              <dt><span className='counter-span'></span>Is photo editing possible on Photofind?</dt>
              <dd>
                Yes. A variety of photo editing tools are built-in to Photofind. Moreover, a variety of frames can be added in the photos placed under sub-albums.
              </dd>
              <dt><span className='counter-span'></span>Will photos get accidentally erased/deleted in Photofind?</dt>
              <dd>
                A confirmation is always sought for before deleting a photo file. So, accidental erasure can be prevented. If you delete an album, a confirmation is asked whether to delete the contents also. Even after deletion of the photos, they remain in a recoverable bin for 24 hours.
              </dd>
              <dt><span className='counter-span'></span>What happens if I forget to renew the subscription after the due date?</dt>
              <dd>
                Photofind will send you subtle and polite reminder e-mails for the renewal of the subscription. The services will continue during the grace period of 15 days after which the photos will be seen only as low-resolution thumbnails. You can re-activate your account within a period of 6 months if you make the payment. After 6 months if the subscription is not renewed, Photofind will send a message and inform you that the account and the contents are getting deleted from the system.
              </dd>
              <dt><span className='counter-span'></span>How many photos can be uploaded per Photofind account?</dt>
              <dd>
                A regular account will hold 1500 (one thousand five hundred) photographs. Again reminding you that Photofind is not a mass storage system. Photofind expects to hold your best photos so that you can proudly showcase your albums to your friends, relatives and business contacts. Premium Photofind accounts can hold 2000 photographs.
              </dd>
            </dl>
            <i>Photofind/FAQ/V 1.04 Dated 29th October 2022</i></div>
        </div>
      </div>
    </>
  )
}

export default FAQ;