import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/Auth/authSlice";

export const useLogout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    loggingOut();
  };

  const loggingOut = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };
  return { handleLogout };
};
