import { TextField } from "@mui/material";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Icon from "../Icons";
import countryCodes from "../../../utils/CountryCodes/countryCode.json";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CommonInput = ({
  label,
  placeholder,
  value,
  type,
  variant,
  name,
  className,
  id,
  floatingLabel,
  required,
  hasError,
  errorMsg,
  icon,
  onClick,
  default_country,
  callback,
  country_dial_code,
  options,
  defaultValue,
  selectClass,
  iconSize,
  onNameChange,
  datePickerCallback,
  getValue,
  dataPickerValue,
  selectedValue,
  countryName,

  ...props
}) => {
  const defaultCountry = countryCodes.filter((country) => {
    if (country_dial_code !== undefined) {
      return country.dial_code === country_dial_code;
    } else {
      return country.code === (default_country ? default_country : "IN");
    }
  });
  const [enteredInput, setEnteredInput] = useState(value);
  const [selectedInput, setSelectedInput] = useState("");
  const [preSelection, setPreSelection] = useState("");
  const [isPassVisible, setIsPassVisible] = useState(false);

  const [country, setCountry] = useState(defaultCountry[0]);

  const [dateValue, setDateValue] = useState("");
  const animatedComponents = makeAnimated();

  const handleInputChange = (e) => {
    console.log(e.target.value, "val/.///");
    setEnteredInput(e.target.value);
    onNameChange(e.target.value);
  };



  useEffect(() => {
    setEnteredInput(value);
    if (value == "") {
      setPreSelection("");
      setSelectedInput("");
    }
  }, [value]);

  useEffect(() => {
    if (dataPickerValue != undefined && dataPickerValue != "") {
      setDateValue(new Date(dataPickerValue));
    }
  }, [dataPickerValue]);

  useEffect(() => {
    if (
      selectedValue != undefined &&
      selectedValue != false &&
      preSelection == ""
    ) {
      setPreSelection(selectedValue);
    }
  }, [selectedValue]);


  console.log(
    hasError,
    errorMsg, "errr...")

  return (
    <>
      {variant === "input" ? (
        <div
          className={`form-group 
          ${floatingLabel ? "floating-label" : ""} 
          ${enteredInput?.length > 0 ? "filled" : ""} 
          ${hasError ? "field-error" : ""} ${className}`}
        >
          <label htmlFor={id}>
            {label}
            {required && "*"}
          </label>
          <div className="input-holder">
            <input
              type={type ? type : "text"}
              name={name ? name : id}
              id={id}
              value={value}
              className="form-control"
              placeholder={placeholder}
              onChange={(e) => handleInputChange(e)}
              {...props}
            />
          </div>
          {hasError && (
            <span htmlFor="" className="error-message">
              {errorMsg}
            </span>
          )}
        </div>
      ) : variant === "input-with-icon" ? (
        <div
          className={`form-group icon-prefix
          ${floatingLabel ? "floating-label" : ""} 
          ${enteredInput?.length > 0 ? "filled" : ""} 
          ${hasError ? "field-error" : ""} ${className}`}
        >
          {label && (
            <label htmlFor={id}>
              {label}
              {required && "*"}
            </label>
          )}
          <div className="input-holder">
            <div className="prefix-icon">
              <Icon color={""} size={iconSize} icon={icon} />
            </div>
            <input
              type={type ? type : "text"}
              name={name ? name : id}
              id={id}
              value={enteredInput}
              className="form-control"
              placeholder={placeholder}
              onChange={(e) => handleInputChange(e)}
              {...props}
            />
          </div>
          {hasError && (
            <span htmlFor="" className="error-message">
              {errorMsg}
            </span>
          )}
        </div>
      ) : variant === "password-visibility-toggle" ? (
        <div
          className={`form-group button-sibling 
          ${floatingLabel ? "floating-label" : ""} 
          ${enteredInput?.length > 0 ? "filled" : ""} 
          ${hasError ? "field-error" : ""} ${className}`}
        >
          <label htmlFor={id}>
            {label}
            {required && "*"}
          </label>
          <div className="input-holder">
            <input
              type={isPassVisible ? "text" : "password"}
              name={name ? name : id}
              id={id}
              value={enteredInput}
              className="form-control"
              placeholder={placeholder}
              onKeyDown={(event) => {
                if (event.code === 'Space') event.preventDefault()
              }}
              onChange={(e) => { handleInputChange(e) }}
              {...props}
            />
            <button onClick={() => setIsPassVisible(!isPassVisible)}>
              <Icon
                size={18}
                icon={!isPassVisible ? "eye-visible" : "eye-invisible"}
              />
            </button>
          </div>
          {hasError && (
            <span htmlFor="" className="error-message">
              {errorMsg}
            </span>
          )}
        </div>
      ) : variant === "phone-with-country" ? (
        <div
          className={`form-group country-code-phone 
          ${enteredInput?.length > 0 ? "filled" : ""} 
          ${floatingLabel ? "floating-label" : ""} 
          ${hasError ? "field-error" : ""} ${className}`}
        >
          <label htmlFor={id}>
            {label}
            {required && "*"}
          </label>
          <div className="country-code-phone-wrap">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {country?.dial_code}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="dropdown-menu-wrapper">
                  {countryCodes.map((country) => {
                    return (
                      <Dropdown.Item
                        key={country?.code}
                        onClick={(e) => {
                          setCountry(country);
                          callback(country);
                        }}
                      >
                        <span className="country">
                          {country?.flag && (
                            <figure className="flag">
                              <img src={country?.flag} alt="" />
                            </figure>
                          )}
                          <span className="name">{country?.name}</span>
                          <span className="code">({country?.code})</span>
                        </span>
                        <span className="dial-code">{country?.dial_code}</span>
                      </Dropdown.Item>
                    );
                  })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className="input-holder">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                min="0"
                name={name ? name : id}
                id={id}
                value={enteredInput}
                className="form-control"
                placeholder={placeholder}
                onChange={(e) => handleInputChange(e)}
                {...props}
              />
            </div>
            {hasError && (
              <span htmlFor="" className="error-message">
                {errorMsg}
              </span>
            )}
          </div>
        </div>
      ) : variant === "date-picker" ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            renderInput={(params) => (
              <TextField
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                {...params}
              />
            )}
            label={label}
            value={dateValue}
            className={`form-group date-picker ${dateValue != "" ? "filled" : ""
              }`}
            inputFormat="DD/MM/YYYY"
            components={{
              OpenPickerIcon: CalendarTodayIcon,
            }}
            onChange={(newValue) => {
              if (newValue != "Invalid Date" && newValue != null) {
                setDateValue(newValue);
                datePickerCallback(
                  newValue?.$d === undefined ? newValue : newValue?.$d
                );
              } else {
                setDateValue("");
                datePickerCallback("");
              }
            }}
          // renderInput={(params) => <TextField {...params} />}
          />
          {hasError && (
            <span htmlFor="" className="error-message">
              {errorMsg}
            </span>
          )}
        </LocalizationProvider>
      ) : variant === "location-input" ? (
        <div
          className={`form-group button-sibling 
          ${floatingLabel ? "floating-label" : ""} 
          ${enteredInput?.length > 0 ? "filled" : ""} 
          ${hasError ? "field-error" : ""} ${className}`}
        >
          <label htmlFor={id}>
            {label}
            {required && "*"}
          </label>
          <div className="input-holder">
            <input
              type="text"
              name={name ? name : id}
              id={id}
              value={enteredInput}
              className="form-control"
              placeholder={placeholder}
              onChange={(e) => handleInputChange(e)}
              {...props}
            />
            <button>
              <Icon size={18} icon={"icon-location"} />
            </button>
          </div>
          {hasError && (
            <span htmlFor="" className="error-message">
              {errorMsg}
            </span>
          )}
        </div>
      ) : variant === "select-input" ? (
        <>
          {console.log(selectedInput, preSelection, "input chanhe...")}
          <div
            className={`form-group select-input ${selectedInput || preSelection != "" ? "filled" : ""
              } ${floatingLabel ? "floating-label" : ""} ${className}`}
          >
            <label htmlFor={id}>
              {label}
              {required && "*"}
            </label>
            <Select
              value={preSelection}
              className={`${selectClass}`}
              classNamePrefix="select"
              //inputValue={defaultValue}
              isSearchable={true}
              name={name}
              options={options}
              placeholder={placeholder}
              isClearable={false}
              onChange={(e) => {
                console.log(e);
                setSelectedInput(e.value);
                callback(e.value);
                setPreSelection(e);
              }}
              {...props}
            />
            {hasError && (
              <span htmlFor="" className="error-message">
                {errorMsg}
              </span>
            )}
          </div>
        </>
      ) : variant === "multi-select-input" ? (
        <div
          className={`form-group multi-select-input ${selectedInput || defaultValue?.length > 0 ? "filled" : ""
            } ${floatingLabel ? "floating-label" : ""} ${className}`}
        >
          <label htmlFor={id}>
            {label}
            {required && "*"}
          </label>
          <Select
            noOptionsMessage={() => null}
            className={`${selectClass}`}
            classNamePrefix="select"
            isMulti={true}
            defaultValue={defaultValue}
            closeMenuOnSelect={false}
            components={animatedComponents}
            // inputValue={selectedInput}
            options={options}
            placeholder={placeholder}
            isClearable={false}
            onChange={(e) => {
              setSelectedInput(e[0]?.value);
              getValue(e);
            }}
            {...props}
          />
          {hasError && (
            <span htmlFor="" className="error-message">
              {errorMsg}
            </span>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CommonInput;
