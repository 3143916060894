import React, { useEffect, useState } from "react";
import { hdfcPayment } from "../../store/slices/Payment/checkoutSlice";
import { useDispatch, useSelector } from "react-redux";
import HdfcRedirect from "../HdfcRedirect";
import { useParams } from "react-router-dom";

const Payment = () => {
  const dispatch = useDispatch();
  const { plan_id,clientID,projectID } = useParams();

  const [responce, setResponce] = useState({});

  const { REACT_APP_APIURL } = process.env;
  


  useEffect(() => {

    const obj = {
      redirect_url: `${REACT_APP_APIURL}payment/verify-order`,
      cancel_url: `${REACT_APP_APIURL}payment/verify-order`,
      plan_id: plan_id,
    };



    if(clientID!==undefined){
      obj.client_id = clientID;
    }

    if(projectID!==undefined){
      obj.project_id = projectID;
    }

    dispatch(
      hdfcPayment(obj)
    )
      .unwrap()
      .then((res) => {
        setResponce(res.data.result);
      });
  }, []);

  if (responce?.order_id) {
    return <HdfcRedirect />;
  }

  //return <DashboardLoader />;
};

export default Payment;
