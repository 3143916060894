import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  authModalHandler,
  changeModal,
  deleteAccount,
  postStudioName,
} from "../../store/slices/Preference/studioDetailstSlice";
import { useLogout } from "../Header/useLogout";

export const useStudioDetails = () => {
  const dispatch = useDispatch();
  const [showChangeLogoModal, setShowChangeLogoModal] = useState({});
  const [stepsData, setStepsData] = useState([
    {
      id: 1,
      title: "Upload your logo",
      description:
        "Currently, you can only upload .JPEG and .PNG images with a maximum size of 2MB to your logo.",
      icon_name: "icon-upload",
      type: "logo",
      img: "",
    },
    {
      id: 1,
      title: "Upload your watermark here",
      description:
        "Upload your watermark. If not we will use a default whater mark. PNG images with a maximum size of 2MB",
      icon_name: "icon-watermark",
      type: "waterMark",
      img: "",
    },
  ]);

  const [showChangeProfilePictureModal, setShowChangeProfilePictureModal] =
    useState(false);
  const [showChangeWatermarkModal, setShowChangeWatermarkModal] =
    useState(false);
  const [openEditStudioModal, setOpenEditStudioModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { handleLogout } = useLogout();

  const nameValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const nameSubmit = async (FormData, resetForm) => {
    const resultAction = await dispatch(postStudioName(FormData));
    if (postStudioName.fulfilled.match(resultAction)) {
      resetForm();
      dispatch(authModalHandler(false));
      toast.success("Success", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleClick = (type) => {
    if (type == "name") {
      dispatch(changeModal("name"));
      dispatch(authModalHandler(true));
    } else if (type == "email") {
      dispatch(changeModal("email"));
      dispatch(authModalHandler(true));
    } else if (type == "mobile") {
      dispatch(changeModal("mobile"));
      dispatch(authModalHandler(true));
    } else if (type == "change logo") {
      dispatch(changeModal("change logo"));
      if (type == "change logo") {
        let data = {
          title: "Change logo",
          isOpen: true,
          type: "logo",
        };
        setShowChangeLogoModal(data);
      }
    } else if (type == "waterMark") {
      dispatch(changeModal("waterMark"));
      if (type == "waterMark") {
        let data = {
          title: "Change water mark",
          isOpen: true,
          type: "waterMark",
        };
        setShowChangeLogoModal(data);
      }
    } else if (type == "deletAccount") {
      dispatch(changeModal("deletAccount"));
      dispatch(authModalHandler(true));
    } else if (type == "socialMedia") {
      dispatch(changeModal("socialMedia"));
      dispatch(authModalHandler(true));
    } else if (type == "address") {
      dispatch(changeModal("address"));
      dispatch(authModalHandler(true));
    }
  };

  const deletAccount = async () => {
    const resultAction = await dispatch(deleteAccount());
    if (deleteAccount.fulfilled.match(resultAction)) {
      dispatch(authModalHandler(false));
      handleLogout();
    }
  };

  return {
    showChangeLogoModal,
    setShowChangeLogoModal,
    showChangeProfilePictureModal,
    setShowChangeProfilePictureModal,
    showChangeWatermarkModal,
    setShowChangeWatermarkModal,
    openEditStudioModal,
    setOpenEditStudioModal,
    openEmailModal,
    setOpenEmailModal,
    openMobileModal,
    setOpenMobileModal,
    openDeleteModal,
    setOpenDeleteModal,
    handleClick,
    nameValidationSchema,
    nameSubmit,
    deletAccount,
    stepsData,
    setStepsData,
  };
};
