import React, { useState } from "react";
import { Col, Container, Dropdown, ProgressBar, Row } from "react-bootstrap";
import Style from "./StyleGuide.module.scss";
import Icon from "../Layouts/Icons";
import Assets from "../Layouts/Assets";
import SearchBar from "../../utils/SearchBar";
import OtpInput from "react-otp-input";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonModal from "../Modals/CommonModal";
import MiniFileUploadModal from "../Modals/MiniFileUploadModals";
import CircularProgressBar from "../../utils/CircularProgressBar";
import CommonInput from "../Layouts/CommonInput";
import ProjectCard from "../Dashboard/ProjectCard";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import BlankDataPlaceholder from "../Dashboard/BlankDataPlaceholder";
import ToggleSwitch from "../../utils/ToggleSwitch";

const StyleGuide = () => {
  const [code, setCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showChangeLogoModal, setShowChangeLogoModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openProjectInfoModal, setOpenProjectInfoModal] = useState(false);
  const [openEditFolderModal, setOpenEditFolderModal] = useState(false);

  const [allCount, selectedCount] = [234];

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    showToast();
  };

  const showToast = () => {
    toast.success("Copied to clipboard", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  return (
    <div className={Style.StyleGuide}>
      <Container>
        {/* Buttons */}
        <div className="row">
          <section className="col-12">
            <h2 className={Style.section_title}>Buttons</h2>
            <div
              className={`${Style.section_content} p-4 m-0 bg-dark rounded align-items-center`}
            >
              <div>
                <button
                  className="btn btn-primary ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-primary")}
                >
                  Button Primary
                </button>
              </div>
              <div>
                <button
                  className="btn btn-secondary ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-secondary")}
                >
                  Button Secondary
                </button>
              </div>
              <div>
                <button
                  className="btn btn-white ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-white")}
                >
                  Button White
                </button>
              </div>
              <div>
                <button
                  className="btn btn-green ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-green")}
                >
                  Button Green
                </button>
              </div>
              <div>
                <button
                  className="btn btn-red ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-green")}
                >
                  Button Red
                </button>
              </div>
              <div>
                <button
                  className="btn btn-border-primary ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-border-primary")}
                >
                  Button Border Primary
                </button>
              </div>
              <div>
                <button
                  className="btn btn-border-white ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-border-white")}
                >
                  Button Border White
                </button>
              </div>
              <div>
                <button
                  className="btn btn-border-grey ms-2 mb-2"
                  onClick={() => handleCopy("btn btn-border-grey")}
                >
                  Button Border Grey
                </button>
              </div>
              <div>
                <button
                  className="btn-link btn-icon-link link-white ms-2 mb-2"
                  onClick={() =>
                    handleCopy("btn-link btn-icon-link link-white")
                  }
                >
                  <Icon size={30} icon={"btn-play-white"} />
                  <span>Icon Link Button</span>
                </button>
              </div>
            </div>
          </section>

          {/* Icons */}
          <section className="col-12">
            <h2 className={Style.section_title}>Icons</h2>
            <pre>
              <h5 className={Style.section_subtitle}>How to use</h5>
              <code>
                &lt;Icon color=&#123;color&#125; size=&#123;size&#125;
                icon=&#123;icon&#125; /&gt;
              </code>
            </pre>
            <div className={Style.section_content}>
              {IconData.map((data) => {
                return (
                  <div className={`${Style.ItemCardWrap} col-2`} key={data.id}>
                    <div className={Style.ItemCard}>
                      <Icon
                        color={data.fill}
                        size={data.size}
                        icon={data.name}
                      />
                      <h4 className={Style.ItemCard__icon}>{data.name}</h4>
                      <button
                        className={Style.ItemCard__copy}
                        onClick={() =>
                          handleCopy(
                            `<Icon color={'${data.fill}'} size={16} icon={'${data.name}'} />`
                          )
                        }
                      >
                        <Icon
                          color={"#cacaca"}
                          size={16}
                          icon={"content_copy"}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          {/* Font Weights */}
          <section className="col-12">
            <h2 className={Style.section_title}>Font weights</h2>
            <div className={Style.section_content}>
              {weightData.map((data) => {
                return (
                  <div className={`${Style.ItemCardWrap} col-2`} key={data.id}>
                    <div className={Style.ItemCard}>
                      <span className={`font-${data.name.toLowerCase()} fz-32`}>
                        Aa
                      </span>
                      <h4 className={Style.ItemCard__icon}>{data.name}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>

          {/* Headings */}
          <section className="col-lg-3">
            <h2 className={Style.section_title}>Headings</h2>
            <div className={`${Style.section_content} m-0`}>
              <div>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
              </div>
            </div>
          </section>

          {/* Color Palette */}
          <section className="col-lg-4">
            <h2 className={Style.section_title}>Color Palette</h2>
            <div className={Style.section_content}>
              {colorData.map((data) => {
                return (
                  <div
                    className={Style.color_box}
                    style={{ backgroundColor: `${data.hex}` }}
                    onClick={() => handleCopy(data.hex)}
                    key={data.id}
                  >
                    <span>{data.hex}</span>
                  </div>
                );
              })}
            </div>
          </section>

          {/* Form Compopnents */}
          <section className="col-12">
            <h2 className={Style.section_title}>Form Components</h2>
            <div className={`${Style.section_content} m-0 d-block`}>
              <div className="row align-items-end">
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Name"
                    placeholder=""
                    variant="input"
                    id="input_name"
                    floatingLabel={true}
                    className="regular single-border"
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Email"
                    placeholder="Enter your email id"
                    variant="input"
                    id="input_email"
                    required
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Email"
                    placeholder="Enter your email id"
                    type="email"
                    variant="input"
                    id="input_email_error"
                    errorMsg={"Invalid Email"}
                    hasError={true}
                    required
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Password"
                    variant="password-visibility-toggle"
                    floatingLabel={true}
                    id="input_password"
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Mobile"
                    variant="phone-with-country"
                    floatingLabel={true}
                    id="input_phone"
                    default_country="IN"
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Date"
                    variant="date-picker"
                    floatingLabel={true}
                    id="input_date"
                    value=""
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Location"
                    variant="location-input"
                    id="input_location"
                    floatingLabel={true}
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Event Category"
                    variant="select-input"
                    id="input_event_category"
                    placeholder=""
                    floatingLabel={true}
                    defaultValue={[]}
                    options={[
                      {
                        value: "Wedding",
                        label: "Wedding",
                      },
                      {
                        value: "Reception",
                        label: "Reception",
                      },
                      {
                        value: "Mehendi",
                        label: "Mehendi",
                      },
                      {
                        value: "Pre-Wedding Shoot",
                        label: "Pre-Wedding Shoot",
                      },
                      {
                        value: "Post-Wedding Shoot",
                        label: "Post-Wedding Shoot",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <CommonInput
                    label="Add Staff"
                    variant="multi-select-input"
                    id="input_event_category"
                    placeholder=""
                    floatingLabel={true}
                    defaultValue={[]}
                    options={[
                      {
                        value: "Adarsh",
                        label: "Adarsh",
                      },
                      {
                        value: "Anu",
                        label: "Anu",
                      },
                      {
                        value: "Sandeep",
                        label: "Sandeep",
                      },
                      {
                        value: "Nithin",
                        label: "Nithin",
                      },
                      {
                        value: "Jerin",
                        label: "Jerin",
                      },
                    ]}
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <div className="form-group otp-inputs">
                    <div className="input-holder">
                      <OtpInput
                        value={code}
                        numInputs={4}
                        name="otp"
                        onChange={(e) => {
                          setCode(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Dark Theme Toggler */}
          <section className="col-3">
            <h2 className={Style.section_title}>Toggle Switch</h2>
            <div className={`${Style.section_content} m-0 d-block`}>
              <ToggleSwitch
                className={`${Style.photo_select_toggle_btn}`}
                firstBtn={`All Photos ${allCount}`}
                secondBtn={`Selected ${selectedCount ? selectedCount : ""}`}
                size={"md"}
              />
            </div>
          </section>

          {/* Dropdown */}
          <section className="col-2">
            <h2 className={Style.section_title}>Dropdown</h2>
            <div className={`${Style.section_content} m-0 d-block`}>
              <Dropdown>
                <Dropdown.Toggle className={Style.HeaderProfileDropdown}>
                  Dropdown
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Item 1</Dropdown.Item>
                  <Dropdown.Item>Item 2</Dropdown.Item>
                  <Dropdown.Item>Item 3</Dropdown.Item>
                  <Dropdown.Item>Item 4</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </section>

          {/* Logos */}
          <section className="col-4">
            <h2 className={Style.section_title}>Logos</h2>
            <div
              className={`${Style.section_content} m-0 d-flex align-items-center`}
            >
              <figure
                className="mb-0 mx-2 d-flex justify-content-center align-items-center mb-3"
                style={{
                  background: "#ededed",
                  padding: "30px",
                  borderRadius: "12px",
                  minWidth: "200px",
                  minHeight: "100px",
                }}
              >
                <img
                  src={Assets.main_logo_dark}
                  alt=""
                  style={{ height: "40px" }}
                />
              </figure>
              <figure
                className="mb-0 mx-2 d-flex justify-content-center align-items-center mb-3"
                style={{
                  background: "#000",
                  padding: "30px",
                  borderRadius: "12px",
                  minWidth: "200px",
                  minHeight: "100px",
                }}
              >
                <img
                  src={Assets.main_logo_light}
                  alt=""
                  style={{ height: "40px" }}
                />
              </figure>
              <figure
                className="mb-0 mx-2 d-flex justify-content-center align-items-center mb-3"
                style={{
                  background: "#ededed",
                  padding: "30px",
                  borderRadius: "12px",
                  minWidth: "200px",
                  minHeight: "100px",
                }}
              >
                <img
                  src={Assets.photofind_logo_light}
                  alt=""
                  style={{ height: "40px" }}
                />
              </figure>
              <figure
                className="mb-0 mx-2 d-flex justify-content-center align-items-center mb-3"
                style={{
                  background: "#000",
                  padding: "30px",
                  borderRadius: "12px",
                  minWidth: "200px",
                  minHeight: "100px",
                }}
              >
                <img
                  src={Assets.photofind_logo_dark}
                  alt=""
                  style={{ height: "40px" }}
                />
              </figure>
            </div>
          </section>

          {/* Favicon */}
          <section className="col-2">
            <h2 className={Style.section_title}>Favicon</h2>
            <div
              className={`${Style.section_content} m-0 d-flex align-items-center`}
            >
              <figure
                className="mb-0 mx-2 d-flex justify-content-center align-items-center"
                style={{
                  background: "#ededed",
                  padding: "30px",
                  borderRadius: "12px",
                  minHeight: "100px",
                }}
              >
                <img src={"/favicon.ico"} alt="" style={{ height: "32px" }} />
              </figure>
            </div>
          </section>

          {/* Title Block */}
          <section className="col-5">
            <h2 className={Style.section_title}>Title Blocks</h2>
            <h6>
              <b>Classes</b>
            </h6>
            <ul className="mb-4">
              <li>
                <b>center: </b> Center alignment of title block
              </li>
              <li>
                <b>title-block-white: </b> White color for title block text
              </li>
            </ul>
            <div
              className={`${Style.section_content} m-0 d-flex align-items-center`}
            >
              <div className={`section-title-block`}>
                <h2>Block Title</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt dolore.
                </p>
              </div>
            </div>
          </section>

          {/* Search Bar */}
          <section className="col-4">
            <h2 className={Style.section_title}>Search Bar</h2>
            <div
              className={`${Style.section_content} m-0 d-flex align-items-center`}
            >
              <SearchBar />
            </div>
          </section>

          {/* Custom Checkbox */}
          <section className="col-2">
            <h2 className={Style.section_title}>Checkbox</h2>
            <div
              className={`${Style.section_content} m-0 d-flex align-items-center justify-content-center bg-dark p-3 rounded`}
            >
              <span
                className={`form-check-box ${isSelected ? "checked" : ""}`}
                onClick={() => setIsSelected(!isSelected)}
              >
                <Icon
                  color={"#fff"}
                  size={13}
                  icon={isSelected ? "icon-tick" : ""}
                />
              </span>
            </div>
          </section>

          {/* Progress */}
          <section className="col-3">
            <h2 className={Style.section_title}>Progress & Loaders</h2>
            <div
              className={`${Style.section_content} m-0 d-flex flex-column align-items-center justify-content-center p-3 rounded`}
              style={{ background: "#A09EA6" }}
            >
              <Row className="mb-3">
                <Col>
                  <CircularProgressBar
                    strokeWidth="4"
                    sqSize="30"
                    percentage={0}
                  />
                </Col>
                <Col>
                  <CircularProgressBar
                    strokeWidth="4"
                    sqSize="30"
                    percentage={25}
                  />
                </Col>
                <Col>
                  <CircularProgressBar
                    strokeWidth="4"
                    sqSize="30"
                    percentage={50}
                  />
                </Col>
                <Col>
                  <CircularProgressBar
                    strokeWidth="4"
                    sqSize="30"
                    percentage={75}
                  />
                </Col>
                <Col>
                  <CircularProgressBar
                    strokeWidth="4"
                    sqSize="30"
                    percentage={100}
                  />
                </Col>
              </Row>
              <Row className="mb-3 w-100">
                <Col>
                  <ProgressBar now={60} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InfiniteDotLoader />
                </Col>
              </Row>
            </div>
          </section>

          {/* Modals */}
          <section className="col-9">
            <h2 className={Style.section_title}>Modals</h2>
            <div
              className={`${Style.section_content} m-0 d-flex align-items-center`}
            >
              <button
                className="btn btn-primary mx-2 mb-2"
                onClick={() => setShowModal(true)}
              >
                Modal lg
              </button>
              <button
                className="btn btn-primary mx-2 mb-2"
                onClick={() => setShowModal2(true)}
              >
                Modal sm
              </button>
              <button
                className="btn btn-primary mx-2 mb-2"
                onClick={() => setShowChangeLogoModal(true)}
              >
                Mini file upload
              </button>
              <button
                className="btn btn-primary mx-2 mb-2"
                onClick={() => setOpenShareModal(true)}
              >
                Share Modal
              </button>
              <button
                className="btn btn-primary mx-2 mb-2"
                onClick={() => setOpenProjectInfoModal(true)}
              >
                Info Modal
              </button>
              <button
                className="btn btn-primary mx-2 mb-2"
                onClick={() => setOpenEditFolderModal(true)}
              >
                Edit Folder Modal
              </button>
            </div>
          </section>

          {/* Project Card */}
          <section className="col-5">
            <h2 className={Style.section_title}>Project Card</h2>
            <div
              className={`${Style.section_content} m-0 d-flex bg-dark py-4 px-2 rounded justify-content-center align-items-center`}
            >
              <div className="row w-100">
                <div className="col-6">
                  <ProjectCard
                    data={{
                      link: "",
                      image: Assets.photo14,
                      title: "Card Title",
                      date: "12/09/2022",
                    }}
                  />
                </div>
                <div className="col-6">
                  <ProjectCard
                    data={{
                      link: "",
                      image: "",
                      title: "Card Title",
                      date: "12/09/2022",
                    }}
                  />
                </div>
              </div>
            </div>
          </section>

          {/* Blank Data Placeholder */}
          <section className="col-7">
            <h2 className={Style.section_title}>Blank Data Placeholder</h2>
            <div
              className={`${Style.section_content} m-0 d-flex py-4 px-2 rounded justify-content-center align-items-center`}
            >
              <Row>
                <Col>
                  <BlankDataPlaceholder
                    title="Dummy title"
                    description="Nostrud amet aute reprehenderit eiusmod quis ad anim excepteur ut."
                    buttonText="Back"
                    template="image"
                  />
                </Col>
                <Col>
                  <BlankDataPlaceholder
                    title="Dummy title"
                    description="Nostrud amet aute reprehenderit eiusmod quis ad anim excepteur ut."
                    buttonText="Back"
                    template="folder"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </Container>

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={showModal}
        setShowModal={setShowModal}
        centered={true}
        className={"type-lg"}
        title={<h4>Modal Title</h4>}
        content={
          <>
            <div className="row">
              <div className="col-md-12">
                <CommonInput
                  label="Input Field"
                  type="text"
                  variant="input"
                  name="input_name"
                  id="input_name"
                  floatingLabel={true}
                />
              </div>
              <div className="col-md-6">
                <CommonInput
                  label="Input Field"
                  type="text"
                  variant="input"
                  name="input_name"
                  id="input_name"
                  floatingLabel={true}
                />
              </div>
              <div className="col-md-6">
                <CommonInput
                  label="Input Field"
                  type="text"
                  variant="input"
                  name="input_name"
                  id="input_name"
                  floatingLabel={true}
                />
              </div>
              <div className="col-md-12">
                <CommonInput
                  label="Input Field"
                  type="text"
                  variant="input"
                  name="input_name"
                  id="input_name"
                  floatingLabel={true}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary">Confirm</button>
            </div>
          </>
        }
      />

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={showModal2}
        setShowModal={setShowModal2}
        centered={true}
        className={"type-sm"}
        title={<h4>Modal Title</h4>}
        content={
          <>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => setShowModal2(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary">Confirm</button>
            </div>
          </>
        }
      />

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={openProjectInfoModal}
        setShowModal={setOpenProjectInfoModal}
        centered={true}
        className={"ProjectInfo__modal"}
        title={<h4>Project Info</h4>}
        content={
          <>
            <ul className={`${"ProjectInfo"} row`}>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Project Name</h5>
                <span className={"ProjectInfo__content"}>
                  Ansa & Ajay Wedding
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Client Name</h5>
                <span className={"ProjectInfo__content"}>Ajay A</span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Email Address</h5>
                <span className={"ProjectInfo__content"}>ajay29@gmail.com</span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Mobile Number</h5>
                <span className={"ProjectInfo__content"}>+91 9797975656</span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Location</h5>
                <span className={"ProjectInfo__content"}>
                  Golden-land bolgatty island, Kochi, Kerala
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Date</h5>
                <span className={"ProjectInfo__content"}>14-03-2023</span>
              </li>
            </ul>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setOpenShareModal(true);
                  setOpenProjectInfoModal(false);
                }}
              >
                Share
              </button>
            </div>
          </>
        }
      />

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={openShareModal}
        setShowModal={setOpenShareModal}
        centered={true}
        className={"Share__modal"}
        title={<h4>Share to Client</h4>}
        content={
          <>
            <ul className={`${"Share__list"}`}>
              <li className={`${"Share__item"}`}>
                <Icon size={17} icon={"icon-whatsapp"} />
                <span className={"Share__content"}>Share to Whatsapp</span>
              </li>
              <li className={`${"Share__item"}`}>
                <Icon size={17} icon={"icon-mail"} />
                <span className={"Share__content"}>Share Via Email</span>
              </li>
              <li className={`${"Share__item"}`}>
                <Icon size={17} icon={"icon-link"} />
                <span className={"Share__content"}>Copy Link</span>
              </li>
            </ul>
          </>
        }
      />

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={openEditFolderModal}
        setShowModal={setOpenEditFolderModal}
        centered={true}
        className={"EditFolder__modal"}
        title={<h4>Edit Folder</h4>}
        content={
          <>
            <div className="row">
              <div className="col-12">
                <CommonInput
                  label="Email"
                  variant="input"
                  id="input_email"
                  floatingLabel={true}
                />
              </div>
              <div className="col-md-6">
                <CommonInput
                  label="Location"
                  variant="location-input"
                  id="input_location"
                  floatingLabel={true}
                />
              </div>
              <div className="col-md-6">
                <CommonInput
                  label="Date"
                  variant="date-picker"
                  floatingLabel={true}
                  id="input_date"
                  value=""
                />
              </div>
              <div className="col-12">
                <CommonInput
                  label="Event Category"
                  variant="select-input"
                  id="input_event_category"
                  floatingLabel={true}
                  defaultValue={[]}
                  options={[
                    {
                      value: "Wedding",
                      label: "Wedding",
                    },
                    {
                      value: "Reception",
                      label: "Reception",
                    },
                    {
                      value: "Mehendi",
                      label: "Mehendi",
                    },
                    {
                      value: "Pre-Wedding Shoot",
                      label: "Pre-Wedding Shoot",
                    },
                    {
                      value: "Post-Wedding Shoot",
                      label: "Post-Wedding Shoot",
                    },
                  ]}
                />
              </div>
              <div className="col-12">
                <CommonInput
                  label="Assign Staff"
                  variant="multi-select-input"
                  id="input_assign_staff"
                  floatingLabel={true}
                  defaultValue={[]}
                  options={[
                    {
                      value: "Adarsh",
                      label: "Adarsh",
                    },
                    {
                      value: "Anu",
                      label: "Anu",
                    },
                    {
                      value: "Sandeep",
                      label: "Sandeep",
                    },
                    {
                      value: "Nithin",
                      label: "Nithin",
                    },
                    {
                      value: "Jerin",
                      label: "Jerin",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => setOpenEditFolderModal(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary">Next</button>
            </div>
          </>
        }
      />
    </div>
  );
};

export default StyleGuide;
const IconData = [
  
{
  id: 1,
  name: "info-icon",
  fill: "#000",
  size: 30
},
{
  id: 2,
  name: "arrow-right",
  fill: "#000",
  size: 30
},
{
  id: 3,
  name: "dashboard-icon",
  fill: "#000",
  size: 30
},
{
  id: 4,
  name: "staff-icon",
  fill: "#000",
  size: 30
},
{
  id: 5,
  name: "heart-line-icon",
  fill: "#000",
  size: 30
},
{
  id: 6,
  name: "bank-cards",
  fill: "#000",
  size: 30
},
{
  id: 7,
  name: "staff",
  fill: "#000",
  size: 30
},
{
  id: 8,
  name: "home",
  fill: "#000",
  size: 30
},
{
  id: 9,
  name: "filled-youtube",
  fill: "#000",
  size: 30
},
{
  id: 10,
  name: "icon-phone",
  fill: "#000",
  size: 30
},
{
  id: 11,
  name: "instagram",
  fill: "#000",
  size: 30
},
{
  id: 12,
  name: "facebook-line",
  fill: "#000",
  size: 30
},
{
  id: 13,
  name: "pinterest",
  fill: "#000",
  size: 30
},
{
  id: 14,
  name: "website-line",
  fill: "#000",
  size: 30
},
{
  id: 15,
  name: "youtube",
  fill: "#000",
  size: 30
},
{
  id: 16,
  name: "icon-list",
  fill: "#000",
  size: 30
},
{
  id: 17,
  name: "upload2",
  fill: "#000",
  size: 30
},
{
  id: 18,
  name: "icon-download",
  fill: "#000",
  size: 30
},
{
  id: 19,
  name: "icon-plus",
  fill: "#000",
  size: 30
},
{
  id: 20,
  name: "dropdown-arrow-down",
  fill: "#000",
  size: 30
},
{
  id: 21,
  name: "folder",
  fill: "#000",
  size: 30
},
{
  id: 22,
  name: "icon-bell",
  fill: "#000",
  size: 30
},
{
  id: 23,
  name: "icon-checked",
  fill: "#000",
  size: 30
},
{
  id: 24,
  name: "icon-edit",
  fill: "#000",
  size: 30
},
{
  id: 25,
  name: "icon-grid",
  fill: "#000",
  size: 30
},
{
  id: 26,
  name: "icon-link",
  fill: "#000",
  size: 30
},
{
  id: 27,
  name: "icon-location",
  fill: "#000",
  size: 30
},
{
  id: 28,
  name: "icon-mail2",
  fill: "#000",
  size: 30
},
{
  id: 29,
  name: "icon-projects",
  fill: "#000",
  size: 30
},
{
  id: 30,
  name: "icon-rearrange",
  fill: "#000",
  size: 30
},
{
  id: 31,
  name: "icon-search",
  fill: "#000",
  size: 30
},
{
  id: 32,
  name: "icon-settings",
  fill: "#000",
  size: 30
},
{
  id: 33,
  name: "icon-three-dots",
  fill: "#000",
  size: 30
},
{
  id: 34,
  name: "icon-tick",
  fill: "#000",
  size: 30
},
{
  id: 35,
  name: "icon-trash",
  fill: "#000",
  size: 30
},
{
  id: 36,
  name: "icon-unchecked",
  fill: "#000",
  size: 30
},
{
  id: 37,
  name: "icon-upload",
  fill: "#000",
  size: 30
},
{
  id: 38,
  name: "icon-watermark",
  fill: "#000",
  size: 30
},
{
  id: 39,
  name: "icon-whatsapp",
  fill: "#000",
  size: 30
},
{
  id: 40,
  name: "icon-zoom",
  fill: "#000",
  size: 30
},
{
  id: 41,
  name: "eye-invisible",
  fill: "#000",
  size: 30
},
{
  id: 42,
  name: "eye-visible",
  fill: "#000",
  size: 30
},
{
  id: 43,
  name: "facebook-alt",
  fill: "#000",
  size: 30
},
{
  id: 44,
  name: "google",
  fill: "#000",
  size: 30
},
{
  id: 45,
  name: "icon-close",
  fill: "#000",
  size: 30
},
{
  id: 46,
  name: "btn-play-primary",
  fill: "#000",
  size: 30
},
{
  id: 47,
  name: "btn-play-white",
  fill: "#000",
  size: 30
},
{
  id: 48,
  name: "easy-access",
  fill: "#000",
  size: 30
},
{
  id: 49,
  name: "icon-double-quote",
  fill: "#000",
  size: 30
},
{
  id: 50,
  name: "icon-fb",
  fill: "#000",
  size: 30
},
{
  id: 51,
  name: "icon-half-moon",
  fill: "#000",
  size: 30
},
{
  id: 52,
  name: "icon-linkedin",
  fill: "#000",
  size: 30
},
{
  id: 53,
  name: "icon-sun",
  fill: "#000",
  size: 30
},
{
  id: 54,
  name: "icon-twitter",
  fill: "#000",
  size: 30
},
{
  id: 55,
  name: "quick-share",
  fill: "#000",
  size: 30
},
{
  id: 56,
  name: "slider-nav-left",
  fill: "#000",
  size: 30
},
{
  id: 57,
  name: "slider-nav-right",
  fill: "#000",
  size: 30
},
{
  id: 58,
  name: "stay-secure",
  fill: "#000",
  size: 30
},
{
  id: 59,
  name: "storage",
  fill: "#000",
  size: 30
},
{
  id: 60,
  name: "icon-mail",
  fill: "#000",
  size: 30
},
{
  id: 61,
  name: "content_copy",
  fill: "#000",
  size: 30
},
{
  id: 62,
  name: "share2",
  fill: "#000",
  size: 30
},

]
// const IconData = [
//   {
//     id: 1,
//     name: "icon-sun",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 2,
//     name: "icon-half-moon",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 3,
//     name: "icon-fb",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 4,
//     name: "icon-twitter",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 5,
//     name: "icon-linkedin",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 6,
//     name: "slider-nav-left",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 7,
//     name: "slider-nav-right",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 8,
//     name: "storage",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 9,
//     name: "easy-access",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 10,
//     name: "quick-share",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 11,
//     name: "stay-secure",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 12,
//     name: "btn-play-primary",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 13,
//     name: "btn-play-white",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 14,
//     name: "icon-double-quote",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 15,
//     name: "facebook-alt",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 16,
//     name: "google",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 17,
//     name: "eye-visible",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 18,
//     name: "eye-invisible",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 19,
//     name: "icon-close",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 20,
//     name: "content_copy",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 21,
//     name: "dropdown-arrow-down",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 22,
//     name: "icon-tick",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 23,
//     name: "icon-upload",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 24,
//     name: "icon-watermark",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 25,
//     name: "icon-location",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 26,
//     name: "icon-projects",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 27,
//     name: "icon-settings",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 28,
//     name: "folder",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 29,
//     name: "icon-bell",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 30,
//     name: "icon-unchecked",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 31,
//     name: "icon-search",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 32,
//     name: "icon-three-dots",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 33,
//     name: "icon-rearrange",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 34,
//     name: "icon-zoom",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 35,
//     name: "icon-trash",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 36,
//     name: "icon-grid",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 37,
//     name: "icon-checked",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 38,
//     name: "icon-whatsapp",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 39,
//     name: "icon-mail",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 40,
//     name: "icon-link",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 41,
//     name: "icon-edit",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 42,
//     name: "upload2",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 43,
//     name: "share2",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 44,
//     name: "icon-plus",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 45,
//     name: "icon-download",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 46,
//     name: "icon-list",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 47,
//     name: "instagram",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 48,
//     name: "facebook-line",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 49,
//     name: "pinterest",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 50,
//     name: "website-line",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 51,
//     name: "youtube",
//     size: 30,
//     fill: "",
//   },
//   {
//     id: 52,
//     name: "icon-phone",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 53,
//     name: "icon-mail2",
//     size: 30,
//     fill: "#000",
//   },
//   {
//     id: 54,
//     name: "filled-youtube",
//     size: 30,
//     fill: "",
//   },
// ];

const weightData = [
  {
    id: 1,
    name: "Light",
  },
  {
    id: 2,
    name: "Regular",
  },
  {
    id: 3,
    name: "Medium",
  },
  {
    id: 4,
    name: "Semibold",
  },
  {
    id: 5,
    name: "Bold",
  },
];

const colorData = [
  {
    id: 1,
    hex: "#6720ff",
  },
  {
    id: 2,
    hex: "#0079B9",
  },
  {
    id: 3,
    hex: "#00b1ff",
  },
  {
    id: 4,
    hex: "#008D58",
  },
  {
    id: 5,
    hex: "#23cc2b",
  },
  {
    id: 6,
    hex: "#02A875",
  },
  {
    id: 7,
    hex: "#E9F8E9",
  },
  {
    id: 8,
    hex: "#A09EA6",
  },
  {
    id: 9,
    hex: "#EDEDEF",
  },
  {
    id: 10,
    hex: "#F6F6F6",
  },
  {
    id: 11,
    hex: "#F6F8F9",
  },
  {
    id: 12,
    hex: "#F8F9FA",
  },
  {
    id: 13,
    hex: "#FF0707",
  },
];
