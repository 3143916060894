import { useState } from "react";

export const useChangeLogo = (showChangeLogoModal, setShowChangeLogoModal) => {
  const handleOpenLogoModal = (type) => {
    if (type == "logo") {
      let data = {
        title: "Change logo",
        isOpen: true,
        type: "logo",
      };
      setShowChangeLogoModal(data);
    } else {
      let data = {
        title: "Change water mark",
        isOpen: true,
        type: "watermark",
      };
      setShowChangeLogoModal(data);
    }
  };

  return {
    // showChangeLogoModal,
    // setShowChangeLogoModal,
    handleOpenLogoModal,
  };
};
