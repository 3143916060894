import React, { Suspense } from "react";
import "../styles/common.scss";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import MainRoutes from "../utils/RoutesDetails";
import { useEffect } from "react";

const ScrollToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <>{props.children}</>;
};

const App = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            {MainRoutes?.map((route, index) => {
              return (
                <Route path={route?.path} key={index} element={route?.element}>
                  {route?.Children?.map((child, index) => {
                    return (
                      <Route
                        path={child?.path}
                        key={index}
                        element={child?.element}
                      >
                        {child.grandChildren?.map((grandChild, index) => {
                          return (
                            <Route
                              path={grandChild?.path}
                              key={index}
                              element={grandChild?.element}
                            >
                              {grandChild?.greatGrandChildrens?.map(
                                (greatGrandChild, k) => {
                                  return (
                                    <Route
                                      path={greatGrandChild?.path}
                                      key={k}
                                      element={greatGrandChild?.element}
                                    />
                                  );
                                }
                              )}
                            </Route>
                          );
                        })}
                      </Route>
                    );
                  })}
                </Route>
              );
            })}
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
