import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  logoData: {},
  waterMarkData: {},
};

export const uploadLogo = createAsyncThunk(
  "getStarted/uploadLogo",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("change-logo", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadWaterMarkLogo = createAsyncThunk(
  "getStarted/uploadWaterMarkLogo",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("change-watermark", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getStartedSlice = createSlice({
  name: "getStarted",
  initialState,
  reducers: {},
  extraReducers: {
    [uploadLogo.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [uploadLogo.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.logoData = action.payload.data.result;
    },
    [uploadLogo.rejected]: (state, action) => {
      state.status = "error";
    },
    [uploadWaterMarkLogo.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [uploadWaterMarkLogo.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.waterMarkData = action.payload.data.result;
    },
    [uploadWaterMarkLogo.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export default getStartedSlice.reducer;
