import React, { useEffect } from "react";
// import { Title } from "../Title";
import Style from "./Tabs.module.scss";
export const Tabs = ({
  activeTab,
  activeTabLeft,
  activeTabWidth,
  handleClickHandler,
  currentTab,
  tabdata,
  tabTitle
}) => {

  return (
    <>
      <div className={Style.ContentHeader__left}>
        {tabTitle}
        <ul className={Style.ContentHeader__navList}>
          {tabdata.map((item, index) => {
            return (
              <li
                className={currentTab === item?.type ? "tabActive" : ""}
                onClick={(e) => handleClickHandler(e, item?.type)}
                key={index}
              >
                {item?.name}
              </li>
            );
          })}

          <li
            className={Style.activeTab__indicator}
            style={{
              transform: `translateX(${activeTabLeft}px)`,
              width: `${activeTabWidth}px`,
            }}
          ></li>
        </ul>
      </div>
    </>
  );
};

// let data = [
//   {
//     name: "Active Projects",
//     type: "activeTab",
//   },
//   {
//     name: "Completed Projects",
//     type: "completedTab",
//   },
// ];
