import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { changePassword } from "../../store/slices/Preference/preferenceListSlice";
import { toast } from "react-toastify";
import { authModalHandler } from "../../store/slices/Preference/studioDetailstSlice";

export const useChangePassword = () => {
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [samePasswordError, setSamePasswordError] = useState(false);

  const dispatch = useDispatch();

  const passwordValidationSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required"),
    new_password: Yup.string()
      .required("New Password is required")
      .min(6, "New password must be at least 6 characters"),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password"), null], "Please make sure your passwords match."),
  });

  //   const { errors } = formState()

  const changePasswordSubmit = async (FormData, resetForm, setFieldError) => {
    const resultAction = await dispatch(changePassword(FormData));
    if (changePassword.fulfilled.match(resultAction)) {
      if (resultAction?.payload?.data?.status_code == "200") {
        resetForm();
        dispatch(authModalHandler(false));
        setOpenChangePasswordModal(false);
        toast.success("Change Password Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (resultAction?.payload?.data?.status_code == "400") {
        setFieldError("current_password", "Current Password does not match");
      } else if (
        resultAction?.payload?.data?.message == "Try different password"
      ) {
        setSamePasswordError(true);
        setTimeout(() => {
          setSamePasswordError(false);
        }, 1500);
        resetForm();
      }
    }
  };

  return {
    openChangePasswordModal,
    setOpenChangePasswordModal,
    passwordValidationSchema,
    changePasswordSubmit,
    samePasswordError,
  };
};
