import React, { useEffect } from "react";
// import SearchBar from "../../../../utils/SearchBar";
import Style from "./Search.module.scss";
import Icon from "../../../Layouts/Icons";
import useProjects from "../../../../Logic/useProjects";
export const Search = ({
  windowDimensions,
  setIsMobileSearch,
  isMobileSearch,
  handleChange,
  text,
  setResetVisible,
}) => {
  const {
    isFocused,
    setIsFocused,
    resetVisible,
    handleClear,
    handleResetVisible,
  } = useProjects();

  useEffect(() => {}, [isFocused]);

  return (
    <>
      {windowDimensions.width < 1200 && (
        <>
          <button
            className={`btn btn-link ${Style.ContentHeader_mob_Search_btn}`}
            onClick={() => setIsMobileSearch(true)}
          >
            <Icon size={18} icon={"icon-search"} />
          </button>
        </>
      )}
      {(isMobileSearch && windowDimensions.width < 1200) ||
      windowDimensions.width >= 1200 ? (
        <>
          <div className={Style.ContentHeader_searchbar_wrap}>
            {/* <SearchBar handleChange={handleChangeInput} handleClear={{}} /> */}
            <form
              action={"javascript:void(0)"}
              className={`${Style.SearchBar} ${
                isFocused ? Style.focused : ""
              } ${resetVisible ? Style.hasValue : ""}`}
            >
              <div className={Style.SearchBar__searchIcon}>
                <Icon size={18} icon={"icon-search"} />
              </div>
              <input
                value={text ? text : ""}
                type="text"
                name="search"
                placeholder="Search here"
                className={Style.SearchBar__searchInput}
                autoComplete="new-password"
                onFocus={() => {
                  if (typeof setIsFocused === "function") {
                    setIsFocused(true);
                  }
                }}
                onBlur={() => {
                  if (typeof setIsFocused === "function") {
                    setIsFocused(true);
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  if (typeof handleResetVisible === "function") {
                    handleResetVisible(e);
                  }
                }}
                
              />
              <div
                className={Style.SearchBar__searchReset}
                onClick={handleClear}
              >
                <Icon size={12} icon={"icon-close"} />
              </div>
            </form>
            {windowDimensions.width < 1200 && (
              <>
                <button
                  className="btn btn-link"
                  onClick={() => setIsMobileSearch(false)}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
