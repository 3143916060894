import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useAnimations } from "../../../Logic/Animations";
import { useDimensions } from "../../../Logic/Dimensions";
import { useHome } from "../../../Logic/useHome";
import Assets from "../../Layouts/Assets";
import Style from "./HomeAboutFPAStudio.module.scss";

const HomeAboutFPAStudio = () => {
  const { whatIsFPA, whatIsFPAData } = useHome();
  const windowDimensions = useDimensions();
  const { slideTopRef, slideRightRef } = useAnimations();

  useEffect(() => {
    // if (whatIsFPAData == null) {
    whatIsFPA();
    // }
  }, []);

  return (
    <section className={Style.HomeAboutFPAStudio}>
      <Container>
        <div className="section-title-block center">
          <h2 ref={slideTopRef}>{whatIsFPAData?.title}</h2>
          <p ref={slideTopRef}>{whatIsFPAData?.description}</p>
        </div>
        <Row>
          <Col>
            <div className={Style.HomeAboutFPAStudio__accordion}>
              <Accordion defaultActiveKey={[0]}>
                {whatIsFPAData?.features?.map((data, index) => {
                  return (
                    <>
                      <HomeAboutAccordionItem 
                        index={index}
                        title={data.title}
                        description={data.description}
                      />
                    </>
                  );
                })}
              </Accordion>
            </div>
          </Col>
          <Col>
            <figure className={Style.HomeAboutFPAStudio__image}>
              <img
                src={Assets.about_fpa_image}
                alt=""
                ref={
                  windowDimensions.width > 1200 ? slideRightRef : slideTopRef
                }
              />
            </figure>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const HomeAboutAccordionItem = ({index,title,description}) => {
  const windowDimensions = useDimensions();
  const { slideTopRef, slideRightRef } = useAnimations();
  return(
    <Accordion.Item
        key={index}
        eventKey={index}
        ref={
          windowDimensions.width > 1200
            ? slideRightRef
            : slideTopRef
        }
      >
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>{description}</Accordion.Body>
    </Accordion.Item>
  )
}

export default HomeAboutFPAStudio;


