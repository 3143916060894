import React from 'react'
import CommonLayout from '../../components/Layouts/CommonLayout'
import FolderView from '../../components/UserFlow/FolderView'

const UserFlowFolderViewPage = () => {
  return (
    <CommonLayout>
        <FolderView />
    </CommonLayout>
  )
}

export default UserFlowFolderViewPage