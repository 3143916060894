import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  allProjects: "",
  currentPage: 0,
  totalPages: "",
  order: "desc",
  currentTab: "activeTab",
  sections: {},
  editProject: { status: "idle", data: "" },
  viewProjectDetails: { data: "" },
  setStatusComplete: { data: "" },
  changeProjectStatus: { data: "" },
  setStatusActive: { data: "" },
  deleteProject: { all: {} },
  addProjects: {
    isLoading: false,
    popupAddProject: false,
    allPlans: "",
    stepOneFormData: "",
    stepTwoFormData: "",
    showClientSucessPopup: false,
    addProjectStepOne: false,
    addProjectStepTwo: false,
    orderDetails: "",
    selectedPlanID: "",
  },
};

/******************************
 * PROJECT - GET ALL PROJECTS *
 ******************************/

export const getAllProjectsAPI = createAsyncThunk(
  "project/getAllProjectsAPI",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const asString = new URLSearchParams(params).toString();
      /* const response = await api.get(
        `project/${params.url}?page=${params.page}&&sort=${params.slug}&&per_page=20&&search=${params.search}`
      );*/

      const response = await api.get(
        `project/${params.url}?${new URLSearchParams(params).toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customLoadMore = createAsyncThunk(
  "project/customLoadMore",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `project/${params.url}?${new URLSearchParams(params).toString()}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * PROJECT - GET ALL CLIENT PLANS *
 ***************************/

export const getAllPlans = createAsyncThunk(
  "project/getAllPlans",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("plan/choose-plan-for-client");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


/***************************
 * PROJECT - GET ALL STUDIO PLANS *
 ***************************/

export const getAllStudiosPlans = createAsyncThunk(
  "project/getAllStudiosPlans",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("plan/list-plan");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * NEW PROJECT  - SAVE NEW PROJECT AFTER COMPLETING PAYMENT *
 ***************************/

export const saveProjectDetails = createAsyncThunk(
  "project/saveProjectDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("project/save-project", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * EDIT PROJECT  - EDIT PROJECT  *
 ***************************/

export const EditProjectDetails = createAsyncThunk(
  "project/EditProjectDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("project/save-project", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * NEW PROJECT - SAVE CREATE CLIENT AFTER ADDING PROJECT *
 ***************************/

export const saveClientDetails = createAsyncThunk(
  "project/saveClientDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("client/save-client", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * NEW PROJECT -  GET ORDER ID FROM SERVER SIDE *
 ***************************/

export const getOrderID = createAsyncThunk(
  "project/getOrderID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("payment/save-order", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * NEW PROJECT -  SAVE ORDER *
 ***************************/

export const saveOrder = createAsyncThunk(
  "project/saveOrder",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("payment/save-order", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * PROJECT -  DELETE PROJECTS FROM BACKEND *
 ***************************/

export const deleteProjectCard = createAsyncThunk(
  "project/deleteProjectCard",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("project/delete-project", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * PROJECT -  DELETE SINGLE PROJECT FROM BACKEND *
 ***************************/

export const deleteSingleProject = createAsyncThunk(
  "project/deleteSingleProject",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("project/delete-project", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * PROJECT -  VIEW PROJECT DETAILS *
 ***************************/

export const viewProjectDetails = createAsyncThunk(
  "project/viewProjectDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `project/edit-project?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/***************************
 * PROJECT -  SET PROJECT STATUS INTO COMPLETE *
 ***************************/

export const updateProjectStatus = createAsyncThunk(
  "project/updateProjectStatus",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("project/completion-status", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    currentTabHandler: (state, action) => {
      state.currentTab = action.payload.tab;
      action.payload.callback();
    },
    storeNewProjectData: (state, action) => {
      state.addProjects.stepOneFormData = action.payload;
    },
    storeNewClientData: (state, action) => {
      state.addProjects.stepTwoFormData = action.payload;
    },
    storePlanID: (state, action) => {
      state.addProjects.selectedPlanID = action.payload;
    },
    projectOrderChange: (state, action) => {
      state.sections[state.currentTab]["sort"] = action.payload;
    },
    openPreSelectionhandler: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentAction: {
          preSelect: true,
        },
      };
    },

    selectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    unSelectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [
        ...new Array(0).keys(),
      ];
    },

    closePreSelect: (state, action) => {
      state.sections[state.currentTab]["currentAction"] = {
        ...state.sections[state.currentTab]["currentAction"],
        preSelect: false,
      };
    },

    closeFilterActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["currentAction"] = {};
    },

    singleSelection: (state, action) => {
      if (state.sections[state.currentTab]["selectedImages"] === undefined) {
        state.sections[state.currentTab]["selectedImages"] = [];
      }

      if (
        state.sections[state.currentTab]["selectedImages"].includes(
          action.payload
        )
      ) {
        state.sections[state.currentTab]["selectedImages"] = state.sections[
          state.currentTab
        ]["selectedImages"].filter(function (item) {
          return item !== action.payload;
        });
      } else {
        state.sections[state.currentTab]["selectedImages"] = [
          ...state.sections[state.currentTab]["selectedImages"],
          ...[action.payload],
        ];
      }
    },

    handleCustomSearchText: (state, action) => {
      state.sections[state.currentTab]["searchText"] = action.payload;
    },

    setSearchResultPlaceHolderValue: (state, action) => {
      state.sections[state.currentTab]["searchPlaceHolder"] = action.payload;
    },

    toggleProjectAddPopup: (state, action) => {
      state.addProjects.popupAddProject = action.payload;
    },

    storeProjectEditDetails: (state, action) => {
      state.editProject.data = action.payload;
    },

    storeViewProjectDetails: (state, action) => {
      state.viewProjectDetails = action.payload;
    },
    updateActiveTabData: (state, action) => {
      state.sections[state.currentTab]["data"] = action.payload;
    },
    storeDeleteProjectID: (state, action) => {
      state.deleteProject.projectID = action.payload;
    },

    projectStatusID: (state, action) => {
      state.changeProjectStatus.projectID = action.payload;
    },

    deleteProjectsPopupStatus: (state, action) => {
      state.deleteProject.all.status = action.payload;
    },

    clearCreateProjectData: (state, action) => {
      state.addProjects.stepOneFormData = {};
      state.addProjects.stepTwoFormData = {};
      state.addProjects.popupAddProject = false;
    },
    insertProjectDynamically: (state, action) => {
      state.sections[action.payload.tab]["data"] = action.payload.data;
    },
    clearTabData: (state, action) => {
      delete state.sections[action.payload]["data"];
    },
    clearTabProjectsData: (state, action) => {
      delete state.sections[action.payload]["data"];
    },
    saveClientComepleted: (state, action) => {
      state.addProjects.isLoading = action.payload;
    },
    insertProjectCard: (state, action) => {
      state.addProjects.isLoading = action.payload;
    },
    setAddProjectStepOne: (state, action) => {
      state.addProjects.addProjectStepOne = action.payload;
    },
    setAddProjectStepTwo: (state, action) => {
      state.addProjects.addProjectStepTwo = action.payload;
    },
    setStepOneFormData: (state, action) => {
      state.addProjects.stepOneFormData = { result: { id: action.payload } };
    },
    clearStepOneFormData: (state, action) => {
      state.addProjects.stepOneFormData = "";
    },
    toggleClientSucessPopup: (state, action) => {
      state.addProjects.showClientSucessPopup = action.payload;
    },
    updateClientDetailsInList: (state, action) => {
      state.sections[state.currentTab].data = action.payload;
    },
  },
  extraReducers: {
    /***************************
     * HOME LOADING PROJECT DATA *
     ***************************/

    [getAllProjectsAPI.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllProjectsAPI.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        ...action.payload.result,
      };

      if (action?.meta?.arg?.search != undefined) {
        if (action.payload.result.data.length === 0) {
          state.sections[state.currentTab]["searchResult"] = "empty";
        } else {
          state.sections[state.currentTab]["searchResult"] = "";
        }
      } else {
        state.sections[state.currentTab]["searchResult"] = "";
      }

      state.sections[state.currentTab]["sort"] = action?.meta?.arg?.sort;
    },
    [getAllProjectsAPI.rejected]: (state, action) => {
      state.status = "error";
    },

    [customLoadMore.pending]: (state, action) => {
      state.status = "loading";
    },
    [customLoadMore.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.sections[state.currentTab].data = [
        ...state.sections[state.currentTab].data,
        ...action?.payload?.result.data,
      ];
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.result?.current_page,
      };
    },
    [customLoadMore.rejected]: (state, action) => {
      state.status = "error";
    },

    [getAllPlans.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllPlans.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.addProjects.allPlans = action.payload.result;
    },
    [getAllPlans.rejected]: (state, action) => {
      state.status = "error";
    },


    [getAllStudiosPlans.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllStudiosPlans.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.addProjects.allPlans = action.payload.result;
    },
    [getAllStudiosPlans.rejected]: (state, action) => {
      state.status = "error";
    },

    /***************************
     * PROJECTDETAILSFORM SAVE *
     ***************************/

    [saveProjectDetails.pending]: (state, action) => {
      state.status = "loading";
    },
    [saveProjectDetails.fulfilled]: (state, action) => {
      state.status = "succeed";

      if (action.payload.data.success === true) {
        state.addProjects.stepOneFormData = action.payload.data;
      } else {
        state.addProjects.stepOneFormData = "";
      }

      // let data = current(state.sections[state.currentTab]?.["data"]);

      // state.sections[state.currentTab]["data"] = [
      //   ...data,
      //   ...[
      //     {
      //       created_at: action.payload.data.result.created_at,
      //       created_at_formatted_date: action.payload.data.result.created_at_formatted_date,
      //       id: action.payload.data.result.id,
      //       location_name: action.payload.data.result.location_name,
      //       name:  action.payload.data.result.name,
      //     },
      //   ],
      // ];
    },

    [saveProjectDetails.rejected]: (state, action) => {
      state.status = "error";
    },

    /***************************
     * PROJECTDETAILSFORM SAVE *
     ***************************/

    [EditProjectDetails.pending]: (state, action) => {
      state.editProject.status = "loading";
    },
    [EditProjectDetails.fulfilled]: (state, action) => {
      state.editProject.status = "succeed";
    },
    [EditProjectDetails.rejected]: (state, action) => {
      state.editProject.status = "error";
    },

    /***************************
     * CLIENTDETAILSFORM SAVE *
     ***************************/

    [saveClientDetails.pending]: (state, action) => {
      state.addProjects.isLoading = true;
    },
    [saveClientDetails.fulfilled]: (state, action) => {
      state.addProjects.isLoading = false;
      if (action.payload.data.success === true) {
        state.addProjects.stepTwoFormData = action.payload.data;
      } else {
        state.addProjects.stepTwoFormData = "";
      }
    },
    [saveClientDetails.rejected]: (state, action) => {
      state.addProjects.isLoading = false;
    },

    /****************
     * GET ORDER ID *
     ****************/

    [getOrderID.pending]: (state, action) => {
      state.status = "loading";
    },
    [getOrderID.fulfilled]: (state, action) => {
      state.status = "succeed";
      if (action.payload.data.success === true) {
        state.addProjects.orderDetails = action.payload.data;
      } else {
        state.addProjects.orderDetails = "";
      }
    },
    [getOrderID.rejected]: (state, action) => {
      state.status = "error";
    },

    /*******************************************
     * PROJECT -  DELETE PROJECTS FROM BACKEND *
     *******************************************/

    [deleteProjectCard.pending]: (state, action) => {
      state.deleteProject.status = "deleting";
    },
    [deleteProjectCard.fulfilled]: (state, action) => {
      state.deleteProject.status = "deleted";

      if (action.payload.data.success === true) {
        /************************************************************
         * REMOVEING DELETED ITEM FROM REDUX DATA HAS BEEN EXISTING *
         ************************************************************/
        state.sections[state.currentTab]["data"] = state.sections[
          state.currentTab
        ]["data"]?.filter(function (item) {
          return !state.sections[state.currentTab]["selectedImages"].includes(
            item.id
          );
        });

        /****************************************
         * RESETTING PRESELECT STATE SETS FALSE *
         ****************************************/

        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          currentAction: {
            preSelect: false,
          },
        };

        /****************************************
         * RESETTING SELECTED PROJECTS IDS TO EMPTY ARRAY [] *
         ****************************************/

        state.sections[state.currentTab]["selectedImages"] = [];
      }
    },
    [deleteProjectCard.rejected]: (state, action) => {
      state.deleteProject.status = "error";
    },

    /*******************************************
     * PROJECT -  DELETE SINGLE PROJECT FROM BACKEND *
     *******************************************/

    [deleteSingleProject.pending]: (state, action) => {
      state.deleteProject.status = "deleting";
    },
    [deleteSingleProject.fulfilled]: (state, action) => {
      state.deleteProject.status = "deleted";

      if (action.payload.data.success === true) {
        /************************************************************
         * REMOVEING DELETED ITEM FROM REDUX DATA HAS BEEN EXISTING *
         ************************************************************/
        state.sections[state.currentTab]["data"] = state.sections[
          state.currentTab
        ]["data"]?.filter(function (item) {
          return !action.meta?.arg?.id?.includes(item.id);
        });
      }
    },
    [deleteSingleProject.rejected]: (state, action) => {
      state.deleteProject.status = "error";
    },

    /****************
     * VIEW PROJECT DETAILS*
     ****************/

    [viewProjectDetails.pending]: (state, action) => {
      state.viewProjectDetails.status = "loading";
    },
    [viewProjectDetails.fulfilled]: (state, action) => {
      state.viewProjectDetails.status = "succeed";
      state.viewProjectDetails.data = action.payload.data.result;
    },
    [viewProjectDetails.rejected]: (state, action) => {
      state.viewProjectDetails.status = "error";
    },

    /****************
     * SETS PROJECT STATUS COMPLETE *
     ****************/

    [updateProjectStatus.pending]: (state, action) => {
      state.changeProjectStatus.status = "setting";
    },
    [updateProjectStatus.fulfilled]: (state, action) => {
      state.changeProjectStatus.status = "succeed";

      if (action.payload.data.success === true) {
        /************************************************************
         * REMOVEING MARKED COMPLETED ITEM FROM REDUX DATA *
         ************************************************************/

        state.sections[state.currentTab]["data"] = state.sections[
          state.currentTab
        ]["data"]?.filter(
          (item) => state.changeProjectStatus.projectID !== item.id
        );

        state.changeProjectStatus.projectID = "";
      }
    },
    [updateProjectStatus.rejected]: (state, action) => {
      state.changeProjectStatus.status = "error";
    },
  },
});

export const {
  storeNewProjectData,
  storeNewClientData,
  storePlanID,
  projectOrderChange,
  currentTabHandler,
  openPreSelectionhandler,
  selectAllImages,
  unSelectAllImages,
  closePreSelect,
  closeFilterActions,
  singleSelection,
  setSerachQuery,
  handleCustomSearchText,
  setSearchResultPlaceHolderValue,
  toggleProjectAddPopup,
  storeProjectEditDetails,
  storeViewProjectDetails,
  updateActiveTabData,
  storeDeleteProjectID,
  projectStatusID,
  deleteProjectsPopupStatus,
  clearCreateProjectData,
  insertProjectDynamically,
  clearTabData,
  clearTabProjectsData,
  saveClientComepleted,
  insertProjectCard,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  setStepOneFormData,
  clearStepOneFormData,
  toggleClientSucessPopup,
  updateClientDetailsInList,
} = projectSlice.actions;

export default projectSlice.reducer;
