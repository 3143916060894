import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Icon from "../../Layouts/Icons";
import SidebarCollapse from "./SidebarCollapse";
// import PlanUpgradeCard from '../PlanUpgradeCard'
import Style from "./SideMenuBar.module.scss";

const SideMenuBar = ({ isCollapsed, setIsCollapsed }) => {
  const location = useLocation();
  let { projectID,clientID } = useParams();

  return (
    <>
      {location.pathname === `/dashboard/user-plans/${projectID}/${clientID}/` ? (
        <></>
      ) : (
        <>
          <div
            className={`${Style.DashboardSidebar} ${
              isCollapsed ? Style.collapsed : ""
            }`}
          >
            <SidebarCollapse
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              Style={Style}
            />
            <div className={Style.DashboardSidebar__menu}>
              <h5 className={Style.DashboardSidebar__menuTitle}>Main Menu</h5>
              <ul className={Style.DashboardSidebar__menuLinks}>
                {menuList.map((item, index) => {
                  return (
                    <li
                      className={`${
                        location.pathname === item.path ||
                        location.pathname === `${item.path}/` ||
                        location.pathname === `${item.path}/folders` ||
                        location.pathname === `${item.path}/folders/` ||
                        location.pathname === `${item.path}/photos` ||
                        location.pathname === `${item.path}/photos/` ||
                        location.pathname === `${item.path}/studio` ||
                        location.pathname === `${item.path}/studio/`
                          ? Style.menu_active
                          : ""
                      }
                    ${
                      item?.subPages &&
                      item?.subPages?.filter(
                        (element) => element === location.pathname
                      ).length !== 0 &&
                      Style.menu_active
                    }
                      
                  `}
                      key={index}
                    >
                      <Link to={item.path}>
                        <Icon icon={item?.iconName} size={22} />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <PlanUpgradeCard /> */}
          </div>
        </>
      )}
    </>
  );
};

export default SideMenuBar;

let menuList = [
  {
    name: "Dashboard",
    iconName: "dashboard-icon",
    path: "/dashboard",
  },
  {
    name: "Projects",
    iconName: "icon-projects",
    path: "/dashboard/project",
  },
  {
    name: "Staff",
    iconName: "staff-icon",
    path: "/dashboard/staffs/permanant",
    subPages: ["/dashboard/staffs/contract"],
  },
  {
    name: "Preference",
    iconName: "icon-settings",
    path: "/dashboard/preference",
  },
];
