import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrderID, saveOrder } from "../store/slices/Project/ProjectSlice";
import { hdfcPayment } from "../store/slices/Payment/checkoutSlice";

const useStudioPayment = () => {
  const { allPlans, stepOneFormData, stepTwoFormData } = useSelector(
    (state) => state.project.addProjects
  );
  const { REACT_APP_APIURL } = process.env;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /****************************************************************
   * THIS FUNCTION CHECKS REQUEST TO SELECT PLAN IS VALID NOT NOT *
   ****************************************************************/

  const isItValidRequest = () => {
    if (stepOneFormData == "" || stepTwoFormData == "") {
      navigate("/dashboard");
    }
  };

  const handleInitPayment = (data) => {
    dispatch(
      hdfcPayment({
        redirect_url: `${REACT_APP_APIURL}ccavenue/verify-order`,
        cancel_url: `${REACT_APP_APIURL}ccavenue/verify-order`,
        plan_id: data.id,
      })
    )
      .unwrap()
      .then((res) => {
    
      });
  };

  const paymentCompleted = (Data) => {};

  /**********************************************************
   * THIS FUNCTION CALLS WHEN THE USER COMPLETE THE PAYMENT.
   * AFTER THE PAYMENT COMPLETION FINALL CALL WILL BE MADE WITH PAYMENT ID WHICH CAN BE COLLETED FROM PAYMENT GATEWAY AND ORDER ID *
   **********************************************************/

  const handleSaveOrder = (paymentID, order_id) => {
    let formData = {
      id: paymentID,
      order_id: order_id,
    };

    dispatch(saveOrder(formData))
      .unwrap()
      .then((result) => {
        const { success, message } = result.data;
        if (success === true) {
          navigate("/dashboard");
        } else {
          alert(message);
        }
      });
  };

  return { isItValidRequest, handleInitPayment };
};

export default useStudioPayment;
