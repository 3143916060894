import {
  useUploady,
  useBatchAddListener,
  useItemStartListener,
  useItemFinishListener,
  useItemFinalizeListener,
  useAbortAll,
} from "@rpldy/uploady";

import loadImage from "blueimp-load-image";
import imageCompression from "browser-image-compression";
import { useState, useEffect, useCallback } from "react";
import { getAxiosInstance } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  addToThumbs,
  clearThumbs,
  updateActiveUploadPercentage,
  updateUploadedImage,
  updateActiveIndex,
  callFunction,
  uploadModalAction,
  openMinimizedBox,
  updateImageCountGallery,
  // updateAllUploadedImages,
} from "../../store/slices/Gallery/gallerySlice";
import { useParams } from "react-router-dom";
import { updateAllUploadedImages, getAllUploadedImages } from "../../store/slices/Gallery/fileUploadSlice";

const BASE_URL = process.env.REACT_APP_APIURL;

// let images = [];
let uploadedImages = [];
let i = 0;
var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.avif|\.heic)$/i;

export const useUploadModal = () => {
  //old
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  let { folderID } = useParams();

  const { activeUploadPercent, openUploadModal, openMiniBox } = useSelector(
    (state) => state.gallery
  );

  //new
  const fileUploadState = useSelector((state) => state.fileUpload);

  const FPAupload = useSelector((state) => state.FPAupload);

  const [duplicateImages, setDuplicateImages] = useState([]);
  const [invalidImages, setInvalidImages] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const uploady = useUploady();
  const [fileIndex, setFileIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [isUploadingProgress, setIsUploadingprogress] = useState(false);



  useEffect(() => {
    if (fileUploadState.uploadMode == "photoFind") {
      dispatch(getAllUploadedImages(folderID))
    }
  }, [openUploadModal]);

  useEffect(() => {
    if (!isUploading) {
      compressedUpload(0);
    }
  }, [files]);

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let imgArry = [...e.dataTransfer.files];
    handleFiles(imgArry);
  };

  const handleImageChange = (e) => {
    handleFiles(e.target.files);
  };

  useItemStartListener((item) => {
    setIsUploadingprogress(true);
  });

  useItemFinalizeListener((item) => {
    setIsUploadingprogress(false);
  });

  useEffect(() => {
    if (!openUploadModal && isUploadingProgress) {
      dispatch(openMinimizedBox(true));
    }
  }, [openUploadModal]);

  const handleFiles = (upldFiles) => {
    let tempArr = [...upldFiles];
    tempArr.forEach((item) => {
      if (
        fileUploadState?.allUploadedImages?.some(
          (obj) => obj.original_name === item.name
        )
      ) {
        setDuplicateImages((state) => [
          ...state,
          { name: item?.name, duplicate: true },
        ]);
      } else if (!allowedExtensions.test(item?.name)) {
        setInvalidImages((state) => [
          ...state,
          { name: item?.name, invalid: true },
        ]);
      } else {
        setFiles((state) => [...state, item]);
        setAllFiles((state) => [...state, item]);
      }
    });
  };

  const compressedUpload = async (index) => {

    if (files.length > 0) {
      if (isUploading === false) {
        setIsUploading(true);
      }
      if (index < files.length) {
        handleImageUpload(files[index]).then((r) => {
          compressedUpload(index + 1);
          setFileIndex((prevState) => prevState + 1);
        });
      } else {
        setFiles([]);
        setIsUploading(false);
      }
    }
  };

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      let compressedFile = await imageCompression(imageFile, {
        ...options,
        alwaysKeepResolution: true,
      });
      loadImage.parseMetaData(imageFile, (data) => {
        uploady.upload(compressedFile, {
          destination: {
            params: { album_id: folderID },
            filesParamName: "image",
          },
        });
      });

      return true;
    } catch (error) {
      uploady.upload(imageFile, {
        destination: {
          params: { album_id: folderID },
          filesParamName: "image",
        },
      });

      return true;
    }
  };

  useItemFinishListener((item) => {
    if (fileUploadState.uploadMode == "photoFind") {
      if (item.uploadResponse.data.success === true) {
        addCompletedImages(item);
      } else {
        // addDuplicate(item?.file?.name);
      }
    }
  });

  const addCompletedImages = (item) => {
    images.forEach((img, i) => {
      if (img?.file?.name === item?.file?.name) {
        images[i] = {
          ...images[i],
          thumbnail:
            item?.uploadResponse?.data?.result?.image_info?.src
              ?.thumbnail_small,
        };
      }
    });
    setUploadIndex((prevState) => prevState + 1);
    setImages([...images]);

    dispatch(updateAllUploadedImages(item));
    // dispatch(updateImageCountGallery( ))

  };

  const addDuplicate = (item) => {
    //item is file's name
    let tempArr = JSON.parse(JSON.stringify(images));
    let index = tempArr.findIndex((image) => image.file.name === item);
    tempArr.splice(index, 1);
    setImages([...tempArr]);
    setDuplicateImages((state) => [...state, { name: item, duplicate: true }]);
  };

  useBatchAddListener((batch) => {
    if (fileUploadState.uploadMode == "photoFind") {
      setImages((state) => [...state, ...batch?.items]);
    }
  });



  const closeUploadModalWhenDone = () => {
    setUploadIndex(0);
    setFileIndex(0);
    setImages([]);
    setFiles([]);
    setAllFiles([]);
    setDuplicateImages([]);
    setInvalidImages([]);

    setIsUploading(false);
    dispatch(uploadModalAction(false));
    dispatch(updateActiveIndex(0));
    dispatch(clearThumbs());
  };


  const closeUploadModal = () => {
    if (uploadIndex == images?.length) {
      closeUploadModalWhenDone();
    } else {
      dispatch(uploadModalAction(false));
    }
  };


  return {
    activeUploadPercent,
    handleDragOver,
    handleDragOut,
    handleDrop,
    handleImageChange,
    closeUploadModal,
    closeUploadModalWhenDone,
    openMiniBox,
    openUploadModal,

    duplicateImages,
    invalidImages,
    allFiles,
    files,
    images,
    newFiles,
    uploadIndex,
    isUploading,
  };
};
