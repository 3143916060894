import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLiveProject } from "../../../store/slices/Dashboard/getDashboardSlice";

const useDashboardLiveProjects = () => {
  const dispatch = useDispatch();

  const { allLiveProjects } = useSelector((state) => state.dashborad);

  let liveProjectsData = {
    title: "Live Projects",
    type: "dashboard-table",
    view_all: "",
    data:allLiveProjects,
  };

  useEffect(() => {
    if (Object.keys(allLiveProjects)?.length === 0) {
      handleGetLiveProject();
    }
  }, []);

  const handleGetLiveProject = () => {
    dispatch(getLiveProject());
  };
  return {
    handleGetLiveProject,
    liveProjectsData,
  };
};

export default useDashboardLiveProjects;
