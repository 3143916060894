import React from 'react'
import { useAnimations } from '../../../../Logic/Animations';
import { useDimensions } from '../../../../Logic/Dimensions';
import Style from './FeatureCard.module.scss'

const FeatureCard = ({ icon, title, description }) => {
    const windowDimensions = useDimensions();
    const { slideTopRef, slideRightRef } = useAnimations();

    return (
        <div
            className={`${Style.FeatureCard} feature-card`}
            ref={windowDimensions.width > 1200 ? slideRightRef : slideTopRef}
        >
            <img src={icon} alt="" className={Style.FeatureCard__icon} />
            <h4 className={Style.FeatureCard__title}>{title}</h4>
            <p className={Style.FeatureCard__description}>{description}</p>
        </div>
    );
};

export default FeatureCard
