import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Keyboard,
  Controller,
  Pagination,
  Navigation,
  Lazy,
  EffectCoverflow,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Style from "./ImagePreview.module.scss";
import { useState } from "react";
import { useRef } from "react";
import Icon from "../../Layouts/Icons";
import { useDimensions } from "../../../Logic/Dimensions";

const ImagePreview = ({
  slideData,
  setIsImagePreview,
  activeSlideIndex,
  type,
}) => {
  const windowDimensions = useDimensions();
  const updateSwiper = useRef();
  const updateSwiperThumbs = useRef();
  const [isSelected, setIsSelected] = useState(false);
  const [isStartSlider, setIsStartSlider] = useState(null);
  const [isFirstSlider, setIsFirstSlider] = useState(null);

  useEffect(() => {
    updateSwiper.current.swiper.slideTo(
      isFirstSlider === 0 ? 0 : activeSlideIndex === 0 ? 1 : activeSlideIndex,
      false,
      false
    );
    updateSwiperThumbs.current.swiper.slideTo(
      isFirstSlider === 0 ? 0 : activeSlideIndex === 0 ? 1 : activeSlideIndex,
      false,
      false
    );
  }, [updateSwiper, updateSwiperThumbs, isFirstSlider]);

  useEffect(() => {
  }, [slideData]);

  return ReactDOM.createPortal(
    <>
      <div className={Style.image_preview}>
        <div className={Style.image_preview_header}>
          {windowDimensions.width >= 576 ? (
            <>
              <button
                className="btn btn-border-white"
                onClick={() => setIsImagePreview(false)}
              >
                Close
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-link"
                onClick={() => setIsImagePreview(false)}
              >
                <Icon color={"white"} size={15} icon={"icon-close"} />
              </button>
            </>
          )}
        </div>
        <div className={Style.image_preview_body}>
          <Swiper
            modules={[
              Keyboard,
              Controller,
              Pagination,
              Navigation,
              Lazy,
              EffectFade,
            ]}
            loop={false}
            effect={"fade"}
            spaceBetween={30}
            ref={updateSwiper}
            controller={{ control: updateSwiperThumbs.current?.swiper }}
            keyboard={{
              enabled: true,
            }}
            className={Style.main_slider}
            onInit={() =>
              setIsFirstSlider(activeSlideIndex === 0 ? 0 : activeSlideIndex)
            }
          >
            {slideData?.map((data, index) => {
              return (
                <SwiperSlide key={`main-${index}`} >
                  <figure className={Style.HomeClients__clientLogo}>
                    {isStartSlider - 2 <= index &&
                    isStartSlider + 2 >= index ? (
                      <>
                        <img
                          src={data?.src?.thumbnail_large}
                          alt="client-logo"
                        />
                        {/* {type == "normal-lightbox" ? (
                          <></>
                        ) : (
                          <>
                            <span
                              className={`form-check-box ${
                                isSelected ? "checked" : ""
                              }`}
                              onClick={() => setIsSelected(!isSelected)}
                            >
                              <Icon
                                color={"#fff"}
                                size={13}
                                icon={isSelected ? "icon-tick" : ""}
                              />
                            </span>
                          </>
                        )} */}
                      </>
                    ) : (
                      <></>
                    )}
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={Style.image_preview_footer}>
          <div className={Style.image_preview_footer_container}>
            <div className={Style.image_preview_footer_wrap}>
              <Swiper
                modules={[
                  Controller,
                  Pagination,
                  Navigation,
                  Lazy,
                  EffectCoverflow,
                ]}
                spaceBetween={0}
                slideToClickedSlide={true}
                slidesPerView={"auto"}
                ref={updateSwiperThumbs}
                centeredSlides={false}
                controller={{ control: updateSwiper.current?.swiper }}
                onSlideChange={(swiper) => setIsStartSlider(swiper.activeIndex)}
              >
                {slideData?.map((data, index) => {
                  return (
                    <SwiperSlide
                      key={`thumb-${index}`}
                      data-slide={`${
                        isStartSlider - 8 <= index && isStartSlider + 8 >= index
                          ? isStartSlider - index
                          : " "
                      }`}
                      className={Style.image_preview_body_slide}
                    >
                      <figure
                        className={`${
                          type == "normal-lightbox"
                            ? ""
                            : data.selected == true
                            ? "img_selectd"
                            : ""
                        } `}
                      >
                        {isStartSlider - 8 <= index &&
                        isStartSlider + 8 >= index ? (
                          <img
                            src={data?.src?.thumbnail_large}
                            alt="client-logo"
                          />
                        ) : (
                          <></>
                        )}
                      </figure>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("overlay-root")
  );
};

export default ImagePreview;
