import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REGISTER } from "redux-persist";
import {
  authActions,
  getRegisterData,
  createRegisterOtp,
  verifyOtp,
  storeCurrentAuthData,
  changeScreen,
  editMode,
  storeCurrentRegisterData,
  resendRegOTP,
  otpButtonEnable,
} from "../store/slices/Auth/authSlice";

export const useRegister = () => {
  const {
    registerOtpData,
    registerData,
    registerStatus,
    loginStatus,
    loginData,
    currentAuthData,
    isEditMode,
    loginIsOtpVerifying,
  } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [otp, setOTP] = useState("");
  const [isOtpError, setOtpError] = useState(false);
  const [otpCounter, setOtpCounter] = useState(30);

  const [isOTPverifying, setOTPverifying] = useState(false);
  const [showErrors, setShowErrors] = useState("");
  const [RegFormErrorObject, setRegFormErrorObject] = useState("");
  const [otpErrorMessage, setOtpErrorMessage] = useState(false);

  const [otpDetails, setOtpDetails] = useState("");

  const [initialInput, setInitialInputs] = useState({
    studio_name: isEditMode ? registerData.name : "",
    email: isEditMode ? registerData.email : "",
    phone_number: isEditMode ? registerData.mobile : "",
    password: isEditMode ? registerData.password : "",
    country_dial_code: isEditMode ? registerData.country_dial_code : "+91",
    location: isEditMode ? registerData.location : "",
  });


 

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /************************************************************************************
   * THIS FUNCTION GENERATES THE TOKEN WHICH WE SENDS TO THE SEVER WHILE NEW REGISTER *
   ************************************************************************************/

  const getUniqueID = () => {
    return (
      performance.now().toString(36) + Math.random().toString(36)
    ).replace(/\./g, "");
  };

  /********************************************************************
   * THIS FUNCTION TRIGGER WHEN THE USER SUBMMITING THE REGISTER FORM *
   ********************************************************************/

  const registerHandler = (data, { resetForm }) => {
    const token = getUniqueID();

    /***************************************
     * DATA FOR FINISHING REGISTER PROCESS *
     ***************************************/

    let newdata = {
      token: token,
      country_dial_code: data.country_dial_code,
      email: data.email,
      name: data.studio_name,
      password: data.password,
      mobile: data.phone_number,
      location: data.location,
    };

    /***************************************
     * DATA FOR INIT REGISTER PROCESS *
     ***************************************/

    let packetForReg = {
      token: token,
      email: data.email,
      mobile: data.phone_number,
      country_dial_code: data.country_dial_code,
    };

    /*****************************************************
     * DISPATCHING A CALL FOR TO STORE THE DATA IN REDUX *
     *****************************************************/

    dispatch(storeCurrentRegisterData({ formData: newdata }));

    /****
     * Only required data sends to the server side to send OTP before storeing the user data in the server side.
     * TOKEN : Random ID
     * EMAIL : USER EMAIL
     * PHONE : USER PHONE NUMBER
     * DIAL CODE : COUNTRY CODE OF THE USER
     ****/

    dispatch(getRegisterData(packetForReg))
      .unwrap()
      .then((res) => {
        const { success, status_code, result, message, validation } = res.data;
        setOtpDetails(result?.otp);
        if (success === true) {
          dispatch(
            storeCurrentAuthData({
              auth: result,
            })
          );

          dispatch(changeScreen("otp"));
          if (isEditMode === true) {
            dispatch(editMode(false));
          }

          resetForm();
        } else {
          if (validation) {
            setRegFormErrorObject(res);
          }
        }
      });
  };

  /************************************************************************************
   * WHEN CLICK ON EDIT BUTTON ON THE OTP SCREEN WHICH IS DONE BY FOLLOWING STEPS *
   ************************************************************************************/

  const editButton = () => {
    setOtpCounter(30);
    dispatch(changeScreen(""));
    dispatch(editMode(true));
    setOTP("");
    setOtpError(false);
  };

  /*******************************************************************************************************************
   * THIS USEEFFECT TRIGGER WHEN THE USER TYPING THE OTP ON THE FIELDS AND WHICH TRIGGER A API CALL WHEN OTP DIGIT COUNT HIT WITH COUNT 4 *
   *******************************************************************************************************************/

  useEffect(() => {
    setOtpError(false);
    dispatch(otpButtonEnable());

    if (otp.length === 4) {
      setOTPverifying(true);
      onVerifyOtp(otp);
    } else {
      setOTPverifying(false);
    }
  }, [otp]);

  /*****************************************************************************
   * THIS USEEFFECT CLEAR THE ERROR MESSAGE FROM REGISTER FORM AFTER 5 SECONDS *
   *****************************************************************************/

  useEffect(() => {
    setTimeout(() => {
      setRegFormErrorObject("");
    }, 5000);
  }, [RegFormErrorObject]);

  /*******************************************************************************************************************
   * THIS FUNCTION SENDS THE OTP WHICH HAS BEEN ENTERED BY THE USER AND SENDS TO THE SEVER SIDE WITH TOTAL FORM DATA TO COMPLETE THE USER REGISTERATION PROCESS *
   * AND WHICH HOLD THE TOKEN HAD BEEN GENERATED BY REACT THAT IS WILL BE CHECKED IN THE SERVER SIDE TO ENSURE THIS IS THE SAME USER WHICH REQUESTED FOR REGISTERATION
   *******************************************************************************************************************/

  const onVerifyOtp = async (otp) => {
    if (otp.length == 4) {
      const params = {
        otp: otp,
        ...registerData,
      };

      dispatch(verifyOtp(params))
        .unwrap()
        .then((res) => {
          const { data } = res;

          if (data.success === true) {
            dispatch(storeCurrentAuthData(data.result));
            dispatch(changeScreen(""));
            navigate("/get-started");
            setOTPverifying(false);
          } else {
            setOTPverifying(false);
            setOtpError(true);
            setOtpErrorMessage(true);

            setTimeout(() => {
              setOtpErrorMessage(false);
            }, 2000);
          }
        });
    } else {
    }
  };

  /***************************************************************
   * THIS FUNCTION TRIGGER THE RE-SEND OTP CALL FROM CLIENT SIDE *
   ***************************************************************/

  const triggerResendCall = (callback) => {
    let packetForResendOTP = {
      token: registerData.token,
      email: registerData.email,
      country_dial_code:registerData.country_dial_code,
      mobile:registerData.mobile,
    };

    dispatch(resendRegOTP(packetForResendOTP))
      .unwrap()
      .then((data) => {
        if (data.data.result.status === true) {
          callback();
          setOTP("");
          setOtpError(false);
          setOtpCounter(30);
        }
      });
  };

  const otpSubmitButton = () => {
    if (otp.length != 4) {
      setOtpError(true);
    } else if (otp.length == 4) {
      const params = {
        otp: otp,
        ...registerData,
      };

      dispatch(verifyOtp(params))
        .unwrap()
        .then((res) => {
          const { data } = res;

          if (data.success === true) {
            dispatch(storeCurrentAuthData(data.result));
            dispatch(changeScreen(""));
            navigate("/get-started");
            setOTPverifying(false);
          } else {
            setOTPverifying(false);
            setOtpError(true);
          }
        });
    }
  };

  return {
    registerHandler,
    onVerifyOtp,
    registerStatus,
    loginStatus,
    registerOtpData,
    registerData,
    showModal,
    setShowModal,
    currentAuthData,
    showErrors,
    initialInput,
    setInitialInputs,
    editButton,
    otp,
    setOTP,
    isOTPverifying,
    setOTPverifying,
    isOtpError,
    RegFormErrorObject,
    setRegFormErrorObject,
    triggerResendCall,
    otpSubmitButton,
    loginIsOtpVerifying,
    otpDetails,
    otpCounter,
    setOtpCounter,
    otpErrorMessage,
  };
};
