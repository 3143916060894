import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import AddStaffCard from "../AddStaffCard";
import Style from "./StaffListContract.module.scss";
import { useSelector } from "react-redux";
import { useStaff } from "../../../Logic/Preference/useStaff";
import PreferenceContainer from "../PreferenceContainer";
import Search from "antd/es/transfer/search";
import { Tabs } from "../ContentHeader/Tabs";
import Icon from "../../Layouts/Icons";
import { useStaffsTab } from "../../../Logic/Staffs/useStaffsTab";
import PermanantStaff from "../PermanantStaff";
import ContractStaff from "../ContractStaff";
import { useDimensions } from "../../../Logic/Dimensions";
import AddStaff from "../PreferenceList/Components/AddStaff";
import { useContactStaffs } from "./useContactStaffs";
import { useSearch } from "../../../Logic/Staffs/useSearch";

const StaffListContract = () => {
  const {
    staffValidationSchema,
    staffSubmit,
    handleToggleStatusAddStaffPopup,
    handleUpdateStaffPreSelect,
  } = useStaff();
  const staffItemRef = useRef(null);
  const observer = useRef();

  const { handleChangeInput, isMobileSearch, setIsMobileSearch } = useSearch();

  const { currentTab, sections, statusAddStaffPopup, designations
  } = useSelector(
    (state) => state.staffs
  );

  const { handleClickTab } = useStaffsTab();

  const windowDimensions = useDimensions();

  const [isMobSearch, setIsMobSearch] = useState(false);


  const {
    fetchStaffs,
    loadMore,
    setCurrentTab,
    handleStaffFilter,
    handletoggleStaffEdit,
    handleSetStaffEditData,
    handletoggleAssignedProjects,
    handlesetAssignedProjects,
    handleCustomSearchTextReset,
  } = useContactStaffs();

  const [activeTab, setActiveTab] = useState("contractStaffTab");
  const [activeTabLeft, setActiveTabLeft] = useState("");
  const [activeTabWidth, setActiveTabWidth] = useState("");

  useEffect(() => {
    let activeTabSelect = document.querySelectorAll(".tabActive");
    setActiveTabLeft(activeTabSelect[0]?.offsetLeft);
    setActiveTabWidth(activeTabSelect[0]?.offsetWidth);
  }, []);


  useEffect(() => {
    if (sections["contractStaffTab"] === undefined) {
      fetchStaffs("contractStaffTab");
    }
  }, []);

  useEffect(() => {
    if (staffItemRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore();
        }
      });
      observer.current.observe(staffItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [sections[currentTab]?.data]);


  return (
    <>
      <div className={Style.AddStaffList_container}>
        <div className="row">
          {windowDimensions.width < 1200 && (
            <>
              <div className="col-lg-12">
                <div
                  className={`${Style.AddStaffList_mob_top_header} ${isMobSearch ? Style.AddStaffList_mob_search_opened : ""
                    }`}
                >
                  <button onClick={() => {
                    handleUpdateStaffPreSelect(1);
                    handleToggleStatusAddStaffPopup(true);
                  }} className="btn btn-border-grey btn-sm btn-theme mob-btn">
                    <Icon color={"currentColor"} size={16} icon={"icon-plus"} />
                  </button>
                  <div className={Style.AddStaffList_mob_header_searchwrap}>
                    {isMobSearch && (
                      <>
                        <div
                          className={`${Style.AddStaffList_searchItem} ${Style.AddStaffList_searchItem_mob}`}
                        >
                          <Search
                            windowDimensions={windowDimensions}
                            setIsMobileSearch={setIsMobileSearch}
                            isMobileSearch={isMobileSearch}
                            onChange={handleChangeInput}
                            value={sections[currentTab]?.["searchText"]}
                          />
                          <button
                            className="btn btn-link"
                            onClick={() => setIsMobSearch(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    )}
                    <button
                      className="btn btn-border-grey btn-sm btn-theme mob-btn"
                      onClick={() => setIsMobSearch(true)}
                    >
                      <Icon
                        color={"currentColor"}
                        size={18}
                        icon={"icon-search"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-lg-6">
            <div className={Style.Title__backButton}>
              <h1 className={Style.AddStaffList__title}>Staff Information</h1>
            </div>
          </div>
          {windowDimensions.width >= 1200 && (
            <>
              <div className="col-lg-6 d-flex">
                <div className={Style.AddStaffList__search}>
                  <Search
                    windowDimensions={windowDimensions}
                    setIsMobileSearch={setIsMobileSearch}
                    isMobileSearch={isMobileSearch}
                    onChange={handleChangeInput}
                    value={sections[currentTab]?.["searchText"]}
                  />
                </div>
                <button
                  className={`btn btn-border-primary ms-2 ${Style.AddStaffList__add_btn}`}
                  onClick={() => {
                    handleUpdateStaffPreSelect(1);
                    handleToggleStatusAddStaffPopup(true);
                  }}
                >
                  <Icon color={"currentColor"} size={13} icon={"icon-plus"} />{" "}
                  Add Staff
                </button>
              </div>
            </>
          )}
        </div>

        <div className={Style.tabList_wrap}>
          <Tabs
            activeTab={activeTab}
            activeTabLeft={activeTabLeft}
            activeTabWidth={activeTabWidth}
            handleClickHandler={handleClickTab}
            setActiveTabLeft={setActiveTabLeft}
            setActiveTabWidth={setActiveTabLeft}
            currentTab={"contractStaffTab"}
            tabdata={tabdata}
          />
        </div>

        <PreferenceContainer
          className={Style.AddStaffList__PreferenceContainer}
        >
          <ContractStaff
            ref={staffItemRef}
            staffViewData={sections[currentTab]}
            handleStaffFilter={handleStaffFilter}
            handletoggleStaffEdit={handletoggleStaffEdit}
            handleSetStaffEditData={handleSetStaffEditData}
            handletoggleAssignedProjects={handletoggleAssignedProjects}
            handlesetAssignedProjects={handlesetAssignedProjects}
            designations={designations}
          />
        </PreferenceContainer>
      </div>

      <AddStaff
        showAddStaffModal={statusAddStaffPopup}
        setShowAddStaffModal={handleToggleStatusAddStaffPopup}
        staffValidationSchema={staffValidationSchema}
        staffSubmit={staffSubmit}
        designations={designations}
      />
    </>
  );
};

let tabdata = [
  {
    name: "Permanant Staff",
    type: "activeTab",
  },
  {
    name: "Contract Staff",
    type: "contractStaffTab",
  },
];

export default StaffListContract;
