import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSelection } from "../../../Logic/FilterHeaders/ProjectHeader/useSelection";
import useProjects from "../../../Logic/useProjects";
import CommonInput from "../../Layouts/CommonInput";
import Icon from "../../Layouts/Icons";
import CommonModal from "../../Modals/CommonModal";
import BlankDataPlaceholder from "../BlankDataPlaceholder";
import ProjectCard from "../ProjectCard";
import ProjectInfoPopup from "../ProjectCard/ProjectInfoPopup";
import ProjectEventInfoPopup from "../ProjectCard/ProjectEventInfoPopup";
import ShareInfoPopup from "../ProjectCard/ShareInfoPopup";

import Style from "./ProjectCardGallery.module.scss";
import DeleteProjectPopup from "../ProjectCard/DeleteProjectPopup";
import EditProjectpopup from "../ProjectCard/EditProjectpopup";
import EditFolderPopup from "../ProjectCard/EditFolderPopup";
import CompleteProjectStatus from "../ProjectCard/CompleteProjectStatus";
import { useProjectSingle } from "../../../Logic/useProjectSingle";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import PaymentAlert from "../ProjectCard/PaymentAlert";

const ProjectCardGallery = ({ isCollapsed }) => {
  const listRootRef = useRef(null);
  const listItemRef = useRef(null);
  const observer = useRef();
  const dispatch = useDispatch();
  const {
    fetchAllProjects,
    loadMore,
    handleShowAddProject,
    openProjectInfoModal,
    setOpenProjectInfoModal,
    openEventInfoModal,
    setOpenEventInfoModal,
    openEditProjectModal,
    setOpenEditProjectModal,
    openShareModal,
    setOpenShareModal,
    openDeleteModal,
    setOpenDeleteModal,
    handleEditProject,
    handleEditProjectName,
    handleViewProjectDetails,
    handleDeleteSingleProject,
    handleDeleteProjectID,

    /***************************
     * PROJECT STATUS CHANGE   *
     ***************************/

    handleProjectStatusID,
    handleUpdateProjectStatus,
    changeProjectStatusModal,
    setChangeProjectStatusModal,
    isDataLoading,
    getTabPlaceholderText,
    getThumbnails,
    handleSetAddProjectStepOne,
    handleSetAddProjectStepTwo,
    handleSetCurrentTabHandler,
  } = useProjects();

  const {
    handleProjectSinglePage,
    openEditFolderModal,
    setOpenEditFolderModal,
    handlePaymentPage,
  } = useProjectSingle();

  const {
    handleDeleteAllSelectedProjects,
    deleteProject,
    handleDeleteSelectedProjects,
  } = useSelection();

  const { handleSingleSelection } = useSelection();
  const { sections, currentTab, viewProjectDetails, status } = useSelector(
    (state) => state.project
  );
  const [isOneTimeLoad, setIsOneTimeLoad] = useState(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState();
  useEffect(() => {
    if (sections[currentTab]?.data === undefined) {
      fetchAllProjects(currentTab);
    }
  }, [currentTab]);


  useEffect(() => {
    if(currentTab === undefined){
      handleSetCurrentTabHandler("activeTab")
    }
  }, [currentTab]);

  useEffect(() => {
    if (listItemRef.current) {
      const interceptConfig = {
        root: listRootRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore(currentTab);
        }
      });
      observer.current.observe(listItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [sections[currentTab]]);

  return (
    <>
      <div className={Style.ProjectCardGallery} ref={listRootRef}>
        <Row>
          {sections[currentTab]?.data?.length > 0 ? (
            <>
              {sections[currentTab]?.data?.map((data, index) => {
                const lastIndex = sections[currentTab]?.data?.length - 1;
                return (
                  <Col
                    key={data?.id}
                    ref={lastIndex == index ? listItemRef : null}
                    className="img-loading-2-item"
                  >
                    <div className="selection" />
                    <ProjectCard
                      openProjectInfoModal={openProjectInfoModal}
                      setOpenProjectInfoModal={setOpenProjectInfoModal}
                      openEventInfoModal={openEventInfoModal}
                      setOpenEventInfoModal={setOpenEventInfoModal}
                      openEditProjectModal={openEditProjectModal}
                      setOpenEditProjectModal={setOpenEditProjectModal}
                      openEditFolderModal={openEditFolderModal}
                      setOpenEditFolderModal={setOpenEditFolderModal}
                      openShareModal={openShareModal}
                      setOpenShareModal={setOpenShareModal}
                      openDeleteModal={openDeleteModal}
                      setOpenDeleteModal={setOpenDeleteModal}
                      data={data}
                      isCollapsed={isCollapsed}
                      preSelect={sections[currentTab]?.currentAction?.preSelect}
                      handleSingleSelection={handleSingleSelection}
                      isSelectionActive={sections[currentTab][
                        "selectedImages"
                      ]?.includes(data.id)}
                      handleEditProject={handleEditProject}
                      handleViewProjectDetails={handleViewProjectDetails}
                      handleDeleteProjectID={handleDeleteProjectID}
                      handleProjectStatusID={handleProjectStatusID}
                      setChangeProjectStatusModal={setChangeProjectStatusModal}
                      openCard={handleProjectSinglePage}
                      getThumbnails={getThumbnails}
                      itemKey={index}
                      setIsDropdownOpened={setIsDropdownOpened}
                      isDropdownOpened={isDropdownOpened}
                    />
                  </Col>
                );
              })}
              
              {/* <div className="w-100 pt-3 pb-5 mb-3 d-flex">
                <InfiniteDotLoader type={"project"} className="mx-auto" />
              </div> */}
              
            </>
          ) : isDataLoading() ? (
            <>
            <div
              className={`${Style.infinite_dot_loader_wrap} ${
                isOneTimeLoad == false ? Style.loader_onetimeload : ""
              }`}
              onLoad={() => setIsOneTimeLoad(true)}
            >
              <InfiniteDotLoader type={"project"} />
            </div>
            
            </>
          ) : (
            <>
              <BlankDataPlaceholder
                title={getTabPlaceholderText(currentTab)?.title}
                description={getTabPlaceholderText(currentTab)?.description}
                buttonText={getTabPlaceholderText(currentTab)?.buttonText}
                // template={getTabPlaceholderText(currentTab)?.template}
                template={"folder"}
                onClick={() => {
                  handleSetAddProjectStepOne(true);
                  handleSetAddProjectStepTwo(false);
                  handleShowAddProject(true);
                }}
              />
              
            </>
          )}
        </Row>
      </div>

      {/* ProjectInfoPopup */}

      <ProjectInfoPopup
        openProjectInfoModal={openProjectInfoModal}
        setOpenShareModal={setOpenShareModal}
        setOpenProjectInfoModal={setOpenProjectInfoModal}
        data={viewProjectDetails}
      />
      {/* ProjectEventInfoPopup */}

      <ProjectEventInfoPopup
        openEventInfoModal={openEventInfoModal}
        setOpenEventInfoModal={setOpenEventInfoModal}
        setOpenShareModal={setOpenShareModal}
        setOpenProjectInfoModal={setOpenProjectInfoModal}
      />

      {/* Share info Popup */}

      <ShareInfoPopup
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
      />

      {/* Delete Popup */}

      <DeleteProjectPopup
        openDeleteModal={openDeleteModal}
        modalControl={setOpenDeleteModal}
        handleDeleteAction={handleDeleteSingleProject}
        isItMulitple={false}
      />

      {/* Multiple Delete Popup */}

      <DeleteProjectPopup
        openDeleteModal={deleteProject.all.status === true ? true : false}
        modalControl={handleDeleteSelectedProjects}
        handleDeleteAction={handleDeleteAllSelectedProjects}
        isItMulitple={true}
      />

      {/* CHANGE PROJECT STATUS */}

      <CompleteProjectStatus
        changeProjectStatusModal={changeProjectStatusModal}
        setChangeProjectStatusModal={setChangeProjectStatusModal}
        handleUpdateProjectStatus={handleUpdateProjectStatus}
      />

      {/* edit project Popup */}

      <EditProjectpopup
        openEditProjectModal={openEditProjectModal}
        setOpenEditProjectModal={setOpenEditProjectModal}
        handleEditProjectName={handleEditProjectName}
      />
    </>
  );
};

export default ProjectCardGallery;
