import { useDispatch, useSelector } from "react-redux";
import {
  openPreSelectionhandler,
  selectAllImages,
  unSelectAllImages,
  closePreSelect,
  closeFilterActions,
  singleSelection,
  deleteProjectCard,
  deleteProjectsPopupStatus,
  handleCustomSearchText,
} from "../../../store/slices/Project/ProjectSlice";

export const useSelection = () => {
  const dispatch = useDispatch();
  const { sections, currentTab, deleteProject } = useSelector(
    (state) => state.project
  );

  const handlePreSelectionhandler = () => {
    dispatch(openPreSelectionhandler());

    // if (sections[currentTab]?.currentAction?.preSelect === true) {
    //   dispatch(unSelectAllImages());
    // }
  };

  const handleSelectAllProjects = () => {
    if (sections[currentTab]?.currentAction?.preSelect) {
      let imgIds = sections[currentTab].data.map((item, index) => item.id);
      dispatch(selectAllImages(imgIds));
    }
  };

  const handledeSelectAllProjects = () => {
    if (sections[currentTab]?.selectedImages?.length > 0) {
      dispatch(unSelectAllImages());
    }
  };

  const handleDeleteSelectedProjects = (status = false) => {
    dispatch(deleteProjectsPopupStatus(status));
  };

  const handleDeleteAllSelectedProjects = () => {
    if (sections[currentTab]?.selectedImages?.length > 0) {
      let deleteObj = {
        id: sections[currentTab]?.selectedImages,
      };
      dispatch(deleteProjectCard(deleteObj))
        .unwrap()
        .then((res) => {
          if (res.data.success === true) {
            dispatch(handleCustomSearchText(""));
            dispatch(deleteProjectsPopupStatus(false));
          }
        });
    }
  };

  const handleCancelSelections = () => {
    if (sections[currentTab]?.currentAction?.preSelect) {
      dispatch(closeFilterActions());
      dispatch(closePreSelect());
    }
  };

  const handleSingleSelection = (id) => {
    if (sections[currentTab]?.currentAction?.preSelect) {
      dispatch(singleSelection(id));
    }
  };

  return {
    handlePreSelectionhandler,
    handleSelectAllProjects,
    handledeSelectAllProjects,
    handleDeleteSelectedProjects,
    handleCancelSelections,
    handleSingleSelection,
    handleDeleteAllSelectedProjects,
    deleteProject,
  };
};
