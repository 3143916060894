import React from "react";
import Style from "./InfiniteDotLoader.module.scss";

const InfiniteDotLoader = ({ type, typeSocial, typeStatus, className }) => {
  return (
    <>
      {type == "project" ||
      typeSocial == "soical" ||
      typeStatus == "status_check" ? (
        <>
          <div className={`${Style.InfiniteDotLoader_wrap} ${className}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      ) : (
        <>
          <div className={`${Style.InfiniteDotLoader} ${className} infinite-dot-loader`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </>
      )}
    </>
  );
};

export default InfiniteDotLoader;
