import React, { useRef, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Style from "../ContractStaff.module.scss";

const FilterDropDown = ({ isActiveDropdown, setIsActiveDropdown,handleStaffFilter }) => {
  return (
    <>
      <Dropdown className={`${Style.ContentHeader__filter_dropdown}`}>
        <Dropdown.Toggle
          className="btn btn-border-grey btn-sm"
          variant="custom-link"
        >
          {isActiveDropdown}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setIsActiveDropdown("All");
              handleStaffFilter("")
            }}
          >
            All
          </Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setIsActiveDropdown("Assigned");
              handleStaffFilter(1)
            }}
          >
            Assigned
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setIsActiveDropdown("Unassigned");
              handleStaffFilter(2)
            }}
          >
            Unassigned
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default FilterDropDown;
