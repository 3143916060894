import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import ChooseThePhotos from "../../components/UserFlow/ChooseThePhotos";

const UserFlowPage = () => {
  return (
    <CommonLayout noFooter>
      <ChooseThePhotos />
    </CommonLayout>
  );
};

export default UserFlowPage;
