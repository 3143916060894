import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { usePageTypeChecker } from "../../../../Logic/usePageTypeChecker";
import Assets from "../../Assets";
import Style from "./HeaderLogo.module.scss";

const HeaderLogo = ({ userProfileData }) => {
  const location = useLocation();
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // useEffect(() => {
  //   document.body.getAttribute("data-theme") === "black"
  //     ? setIsDarkTheme(true)
  //     : setIsDarkTheme(false);
  // }, [document.body.getAttribute("data-theme")] );

  useEffect(() => {
    userProfileData.theme_class_name === "black"
      ? setIsDarkTheme(true)
      : setIsDarkTheme(false);
  }, [userProfileData][document.body.getAttribute("data-theme")]);

  const pageTypes = usePageTypeChecker();

  return (
    <div className={Style.main_logos_wrap}>
      <div className={Style.main_logos_item}>
        <Link
          to={"/"}
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        >
          <figure className={Style.MainHeader__logo}>
            <img
              src={
                !isDarkTheme || pageTypes.commonPages
                  ? Assets.photofind_logo_light
                  : Assets.photofind_logo_dark
              }
              alt="photofind-logo"
            />
          </figure>
        </Link>
      </div>
      {location.pathname === "/userflow" ||
      location.pathname === "/userflow-folder-view" ? (
        <>
          <div className={Style.main_logos_item}>
            <figure className={Style.MainHeader__logo}>
              <img src={Assets.SarithaLogoDark} alt="photofind-logo" />
            </figure>
          </div>
        </>
      ) : (
        <>
          {userProfileData?.logo_url && !pageTypes.commonPages ? (
            <div className={Style.main_logos_item}>
              <figure className={Style.MainHeader__logo}>
                <img src={userProfileData?.logo_url} alt="photofind-logo" />
              </figure>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderLogo;
