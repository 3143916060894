import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  isOpen: false,
  paymentCancelStatus: "idle",
  selectedPlan: {},
  data: {
    isOpen: false,
    details: {},
  },
  hdfc_payment: {},
  hdfc_status: "idel",
};

export const hdfcPayment = createAsyncThunk(
  "payment/hdfcPayment",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("payment/save-order", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const HDFCPaymentStudio = createAsyncThunk(
  "payment/HDFCPaymentStudio",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("payment/save-order", params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const checkoutSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    closeModal: (state, action) => {
      state.data = {};
    },
    paymentModalAction: (state, action) => {
      state.isOpen = action.payload?.isOpen;
      state.selectedPlan = action.payload?.item;
    },
  },

  extraReducers: {
    [hdfcPayment.pending]: (state) => {
      state.hdfc_status = "loading";
    },

    [hdfcPayment.fulfilled]: (state, action) => {
      state.hdfc_payment = action.payload.data.result;
      state.hdfc_status = "success";
    },
    [hdfcPayment.rejected]: (state, action) => {
      state.hdfc_status = "error";
    },
  },
});

export const { closeModal, paymentModalAction } = checkoutSlice.actions;

export default checkoutSlice.reducer;
