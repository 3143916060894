import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import Style from "./VerifyOTPForm.module.scss";
import InfiniteDotLoader from "../InfiniteDotLoader";

var time = 60;

const VerifyOTPForm = ({
  phone,
  dialCode,
  email,
  editButton,
  otp,
  setOTP,
  isOTPverifying,
  isOtpError,
  otpSubmitButton,
  resendCallback,
  statusOTPcall,
  otpDetails,
  otpErrorMessage,
  otpCounter,
  setOtpCounter,
}) => {
  const [isOtpVerification, setIsOtpVerification] = useState(false);
  const [showResendOTPButton, setShowResendOTPButton] = useState(true);
  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  // useEffect(() => {
  //   timer > 0 && setTimeout(timeOutCallback, 1000);
  // }, [timer, timeOutCallback]);

  // const resetTimer = function () {
  //   if (!timer) {
  //     setTimer(60);
  //   }
  // };

  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [otpCounter]);

  return (
    <>
      <h1 className={Style.Login__title}>OTP Verification</h1>
      <p className={Style.Login__description}>
        {phone != "" && dialCode != ""
          ? `OTP  has been sent on your registered mobile number ${dialCode} ${phone}`
          : ""}

        {email != ""
          ? `OTP  has been sent on your registered email address ${email}`
          : ""}

        <Link to="" className="btn btn-link" onClick={() => editButton()}>
          Edit
        </Link>
      </p>
      <div className="form-group otp-inputs center">
        <div className="input-holder">
          <OtpInput
            value={otp}
            numInputs={4}
            name="otp"
            isDisabled={isOTPverifying}
            inputStyle={`${isOtpError == true ? Style.input_Style_error : ""}`}
            /*****************************************
             * CHECKING INPUT VALUE IS NUMBER OR NOT *
             *****************************************/
            onChange={(value) => {
              if (/^-?\d+$/.test(value) || value === "") {
                setOTP(value);
              }
            }}
            isInputNum={true}
          />
        </div>
      </div>

      {otpErrorMessage && <p className="form-error-note">Invalid OTP</p>}

      <button
        type="submit"
        className={`${Style.Login__button} btn btn-primary w-100 mb-4`}
        onClick={otpSubmitButton}
        disabled={statusOTPcall}
      >
        {isOTPverifying === true ? <InfiniteDotLoader /> : "Submit"}
      </button>
      <div className={Style.Auth__option}>
        <span>Didn't Receive the OTP? </span>
        {otpCounter > 0 ? <span> 00:{otpCounter}s </span> : ""}
        {otpCounter == 0 ? (
          <button
            className="btn btn-link link-primary"
            onClick={() => {
              resendCallback();
              setOtpCounter(30);
            }}
          >
            Resend OTP
          </button>
        ) : (
          ""
        )}

        {/* {otpCounter == 0 &&
        <button
          className="btn btn-link link-primary"
          onClick={() => {
            // if (timer === 0) {
              resendCallback();
            // }
          }}
        >
          {" "}
          {timer === 0 ? "Resend OTP" : `Resend OTP (${timer})`}{" "}
        </button>} */}
      </div>
    </>
  );
};

export default VerifyOTPForm;
