import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Assets from "../../Assets";
import Icon from "../../Icons";
import Style from "./LoggedInMenu.module.scss";

const LoggedInMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  menuList,
  userProfileData,
}) => {
  const location = useLocation();
  const [isLightTheme, setIsLightTheme] = useState(false);
  const [activeLogo, setActiveLogo] = useState("");

  const logosLight = [Assets.photofind_logo_light, userProfileData?.logo_url];
  const logosDark = [Assets.photofind_logo_dark, userProfileData?.logo_url];
  let i = 0;
  const shuffle = useCallback(() => {
    if (isLightTheme) {
      setActiveLogo(logosLight[i]);
      i += 1;
      if (i > logosLight.length - 1) {
        i = 0;
      } else {
        return i;
      }
    } else {
      setActiveLogo(logosDark[i]);
      i += 1;
      if (i > logosDark.length - 1) {
        i = 0;
      } else {
        return i;
      }
    }
  }, [userProfileData, isLightTheme]);

  const handleMenuLogoClick = () => {
    setIsMenuOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const intervalID = setInterval(shuffle, 3000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  useEffect(() => {
    document.body.getAttribute("data-theme") === "default"
      ? setIsLightTheme(true)
      : setIsLightTheme(false);
  }, [document.body.getAttribute("data-theme")]);

  return (
    <nav
      className={`${Style.LoggedInMenu__mobileMenu} ${
        isMenuOpen ? Style.menuOpen : ""
      }`}
    >
      <div className={Style.LoggedInMenu__mobileMenuHead}>
        <button
          className={Style.ham_close}
          onClick={() => setIsMenuOpen(false)}
        >
          <Icon size={22} icon={"icon-close"} />
        </button>
        <Link
          to={
            activeLogo && activeLogo === userProfileData?.logo_url
              ? "/dashboard"
              : activeLogo && activeLogo !== userProfileData?.logo_url
              ? "/"
              : "/dashboard"
          }
          onClick={() => handleMenuLogoClick()}
        >
          <figure className={Style.LoggedInMenu__mobileLogo}>
            <img
              src={activeLogo ? activeLogo : userProfileData?.logo_url}
              alt="photofind-logo"
            />
          </figure>
        </Link>
      </div>
      <ul className={Style.LoggedInMenu__mobileNavigation}>
        {menuList.map((item, index) => {
          return (
            <li
              className={
                location.pathname === item.path ||
                location.pathname === `${item.path}/`
                  ? Style.menu_active
                  : ""
              }
              key={index}
            >
              <Link to={item.path} onClick={() => setIsMenuOpen(false)}>
                <Icon icon={item?.iconName} size={22} />
                <span>{item.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default LoggedInMenu;
