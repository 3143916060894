import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import Icon from "../../../Layouts/Icons";
import CommonInput from "../../../Layouts/CommonInput";
import EditForm from "./EditForm";

const EditProjectpopup = ({openEditProjectModal,setOpenEditProjectModal,handleEditProjectName}) => {

  return (
    <CommonModal
    backdrop="static"
    keyboard={false}
    showModal={openEditProjectModal}
    setShowModal={setOpenEditProjectModal}
    centered={true}
    className={"edit-project-name-modal"}
    title={<h4>Edit Project Name</h4>}
    content={
      <>
       <EditForm setOpenEditProjectModal={setOpenEditProjectModal} handleEditProjectName={handleEditProjectName} />
      </>
    }
  />
  );
};

export default EditProjectpopup;
