import React, { useRef, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import Style from "../PermanantStaff.module.scss";
import FilterDropDown from "../FilterDropDown";

const TableHeader = ({ isActiveDropdown, setIsActiveDropdown,handleStaffFilter }) => {
    return (
      <>
        <thead className={Style.AddStaffList__header}>
          <tr>
            <th className={Style.AddStaffList__headerItem}><span>Staff Name</span></th>
            <th className={Style.AddStaffList__headerItem}><span>Mobile Number</span></th>
            <th className={Style.AddStaffList__headerItem}><span>Email id</span></th>
            <th className={Style.AddStaffList__headerItem}><span>Designation</span></th>
            <th className={Style.AddStaffList__headerItem}>
              <FilterDropDown
                isActiveDropdown={isActiveDropdown}
                setIsActiveDropdown={setIsActiveDropdown}
                handleStaffFilter={handleStaffFilter}
              />
            </th>
            <th className={Style.AddStaffList__headerItem}></th>
          </tr>
        </thead>
      </>
    );
}

export default TableHeader;
