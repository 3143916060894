import { Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";
import CommonInput from "../../../../Layouts/CommonInput";
import CommonModal from "../../../../Modals/CommonModal";

const ChangePassword = ({
  openChangePasswordModal,
  setOpenChangePasswordModal,
  passwordValidationSchema,
  changePasswordSubmit,
  samePasswordError,
}) => {
  const changePasswordData = useSelector((state) => state.preferenceList);
  return (
    <Formik
      validationSchema={passwordValidationSchema}
      initialValues={{
        current_password: "",
        new_password: "",
        confirm_password: "",
      }}
      onSubmit={(values, { resetForm, setFieldError }) => {
        let FormData = {
          old_password: values?.current_password,
          new_password: values?.new_password,
        };

        changePasswordSubmit(FormData, resetForm, setFieldError);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => {
        return (
          <>
            <CommonModal
              backdrop="static"
              keyboard={false}
              showModal={openChangePasswordModal}
              setShowModal={setOpenChangePasswordModal}
              centered={true}
              className={"edit-change-password-modal"}
              title={<h4>Change Password</h4>}
              clearState={resetForm}
              content={
                <>
                  <CommonInput
                    label="Current Password"
                    variant="password-visibility-toggle"
                    name="current_password"
                    id="input_current_password"
                    floatingLabel={true}
                    type="password"
                    value={values?.current_password}
                    hasError={
                      errors?.current_password && touched.current_password
                    }
                    errorMsg={errors?.current_password}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("current_password", e.target.value);
                    }}
                  />
                  <CommonInput
                    label="New Password"
                    variant="password-visibility-toggle"
                    name="new_password"
                    id="input_new_password"
                    floatingLabel={true}
                    value={values?.new_password}
                    hasError={errors?.new_password && touched.new_password}
                    errorMsg={errors?.new_password}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("new_password", e.target.value);
                    }}
                    type="password"
                  />
                  <CommonInput
                    label="Confirm Password"
                    variant="password-visibility-toggle"
                    name="confirm_password"
                    id="input_confirm_password"
                    floatingLabel={true}
                    value={values?.confirm_password}
                    hasError={
                      errors?.confirm_password && touched.confirm_password
                    }
                    errorMsg={errors?.confirm_password}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("confirm_password", e.target.value);
                    }}
                    type="password"
                  />
                  {samePasswordError == true && (
                    <p className="form-error-note">Try different password</p>
                  )}
                </>
              }
              footer={
                <>
                  <button
                    className="btn btn-border-grey"
                    onClick={() => {
                      setOpenChangePasswordModal(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => handleSubmit()}
                  >
                    {changePasswordData?.status === "loading" ? (
                      <InfiniteDotLoader />
                    ) : (
                      "Save"
                    )}
                  </button>
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
