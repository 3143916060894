import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDimensions } from "../../../../Logic/Dimensions";
import CircularProgressBar from "../../../../utils/CircularProgressBar";
import Assets from "../../../Layouts/Assets";
import Icon from "../../../Layouts/Icons";
import Style from "./ImageUploadItem.module.scss";
import { useItemProgressListener } from "@rpldy/uploady";

const ImageUploadItem = ({ item, index, uploadIndex, percentage }) => {
  const windowDimensions = useDimensions();
  const [charLength, setCharLength] = useState("");
  const nameRef = useRef();


  console.log("item",item);

  const { completed } = useItemProgressListener(item?.id) || {
    completed: item?.thumbnail ? 100 : 0,
  };

  useEffect(() => {
    setCharLength(nameRef?.current?.offsetWidth / 9);
  }, [windowDimensions.width]);

  return (
    <li key={index} className={Style.ImageUploadItem}>
      <div className={Style.ImageUploadItem__imageData}>
        <figure
          className={`${Style.ImageUploadItem__thumbnail} ${
            item?.image ? Style.hasImage : ""
          }`}
        >
          <img
            src={
              item?.thumbnail
                ? item?.thumbnail
                : Assets.placeholder_single_image
            }
            alt="img-thumb"
          />
        </figure>
        <span className={Style.ImageUploadItem__name} ref={nameRef}>
          {item?.file?.name?.substring(0, charLength)}
          {`${item?.file?.name?.length > charLength ? "..." : ""}`}
        </span>
      </div>
      <div
        className={`${Style.ImageUploadItem__progress} ${
          item.isUploaded ? Style.imageUploaded : ""
        }`}
      >
        {index === uploadIndex ? (
          <CircularProgressBar
            strokeWidth="4"
            sqSize="22"
            percentage={completed}
          />
        ) : index > uploadIndex ? (
          <CircularProgressBar strokeWidth="4" sqSize="22" percentage={0} />
        ) : (
          <span className={`form-check-box checked`}>
            <Icon color={"#fff"} size={13} icon={"icon-tick"} />
          </span>
        )}
      </div>
    </li>
  );
};

export default ImageUploadItem;
