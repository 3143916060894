import CommonModal from "../../../Modals/CommonModal";
import React, { forwardRef, useEffect, useState } from "react";
import Style from "./../AddStaffCard.module.scss";
import Icon from "../../../Layouts/Icons";
import { useDispatch, useSelector } from "react-redux";

function AssignedProjectDetails({
  setIsAssignedEventInfo,
  handleToggleEditFolder,
  setProjectID,
}) {

  const { assignedProjects } = useSelector((state) => state.staffs);

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={assignedProjects.status}
      setShowModal={setIsAssignedEventInfo}
      centered={true}
      className={Style.AssignedEventInfo_modal}
      title={<h4>Assigned event info</h4>}
      content={
        <>
          {assignedProjects?.staffData?.staff_assigned?.map((project, index) => { 
            return (
              <>
                <div className={Style.AssignedEventInfo_event_item_wrap}>
                  <div className={Style.AssignedEventInfo_event_id}>
                    {index+1}
                  </div>
                  <div className={Style.AssignedEventInfo_wrap}>
                    <div className={Style.AssignedEventInfo_event_wrap}>
                      <span className={Style.AssignedEventInfo_label}>
                        Event name
                      </span>
                      <span className={Style.AssignedEventInfo_event}>
                        {project?.album_details.name}
                      </span>
                    </div>
                    <div className={Style.AssignedEventInfo_event_client_wrap}>
                      <span className={Style.AssignedEventInfo_label}>
                        Client name
                      </span>
                      <span className={Style.AssignedEventInfo_client}>
                        {project?.album_details?.client?.name}
                      </span>
                    </div>
                  </div>
                  <div className={Style.AssignedEventInfo_action}>
                    <button
                      className={`btn btn-link`}
                      onClick={() =>{
                        setProjectID(project.album_details.project_id);
                        setIsAssignedEventInfo(false);
                        handleToggleEditFolder({status:true,id:project?.album_id});
                      }}
                    >
                      <Icon
                        color={"currentColor"}
                        size={19}
                        icon={"icon-edit"}
                      />
                    </button>
                  </div>
                </div>
              </>
            );
          })}
        </>
      }
      footer={<></>}
    />
  );
}

export default AssignedProjectDetails;
