import Icon from "../../Icons";
import React from "react";
import Style from "./HeaderAddress.module.scss";
import { useSelector } from "react-redux";

const HeaderAddress = () => {
  const { userData } = useSelector((state) => state.userFlow);

  return (
    <>
      <ul className={Style.header_address_list}>
        {userData?.email && (
          <li>
            <a
              href="https://sarithastudio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon color={""} size={16} icon={"website-line"} />{" "}
              {userData?.email}
            </a>
          </li>
        )}
        {userData?.preference?.address && (
          <li>
            <Icon color={""} size={17} icon={"icon-location"} />
            <span>{userData?.preference?.address}</span>
          </li>
        )}
        {userData?.mobile && (
          <li>
            <a href="tel:04802556222" rel="noopener noreferrer">
              <Icon color={""} size={16} icon={"icon-phone"} />{" "}
              {userData?.mobile}
            </a>
          </li>
        )}
        {userData?.email && (
          <li>
            <a href="mailto:sarithastudio@gmail.com" rel="noopener noreferrer">
              <Icon color={""} size={14} icon={"icon-mail2"} />{" "}
              {userData?.email}
            </a>
          </li>
        )}
      </ul>
    </>
  );
};

export default HeaderAddress;
