import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useThemes } from "../../../Logic/GetStarted/useThemes";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import SelectImageCard from "../../Cards/SelectImageCard";
import Assets from "../../Layouts/Assets";
import Style from "./SelectYourTheme.module.scss";

const SelectImageCardShimmer = () => {
  return (
    <Col lg="4" sm="4" xs={6} className={Style.Get_started_theme_item_shimmer}>
      <div className={`img-loading img-loading-2`}></div>
      <span
        className={`img-loading img-loading-2 ${Style.Get_started_theme_item_shimmer_text}`}
      ></span>
    </Col>
  );
};

const SelectYourTheme = () => {
  const { themeData, selectTheme, radioActive, setRadioActive, themeLoader } =
    useThemes();

  let shimmer = [];
  for (let i = 0; i < 6; i++) {
    shimmer.push(<SelectImageCardShimmer key={i} />);
  }
  const [isContentLoad, setIsContentLoad] = useState(false);
  const [isImageCardLoad, setIsImageCardLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsImageCardLoad(true);
    }, 2000000);
  }, [setIsContentLoad]);

  return (
    <div className={Style.GetStarted}>
      <Container>
        <div className={Style.Get_started_wrap}>
          <div className={`section-title-block ${Style.Get_started_header}`}>
            <h2 className="h1">Select Your Theme</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </p>
          </div>
          <div className={Style.Get_started_body}>
            <div className={Style.Get_started_container}>
              {!isImageCardLoad ? (
                <>
                  <Row
                    className={`${Style.Get_started_theme_item_shimmer_row} ${
                      isContentLoad
                        ? Style.Get_started_theme_item_shimmer_hideing
                        : ""
                    }`}
                  >
                    {shimmer}
                  </Row>
                </>
              ) : (
                <></>
              )}
              <Row
                className={`${
                  isContentLoad
                    ? Style.Get_started_content_load
                    : Style.Get_started_content_loading
                }`}
              >
                {themeData?.map((data, index) => {
                  return (
                    <Col
                      lg="4"
                      sm="4"
                      xs={6}
                      className={Style.Get_started_theme_item}
                      key={index}
                    >
                      <SelectImageCard
                        index={index}
                        id={data?.id}
                        title={data?.name}
                        themeImage={data?.file_url}
                        radioActive={radioActive}
                        setRadioActive={setRadioActive}
                        setIsContentLoad={setIsContentLoad}
                        isContentLoad={isContentLoad}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
          <div className={Style.Get_started_footer}>
            <Row>
              <Col sm="6" className="text-center text-sm-start">
                <p>Steps 2/2</p>
              </Col>
              <Col sm="6" className="text-center text-sm-end">
                <button
                  className="btn btn-primary d-inline-block"
                  onClick={() => selectTheme(radioActive)}
                >
                  {themeLoader ? <InfiniteDotLoader /> : "Continue"}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SelectYourTheme;
