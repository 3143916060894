import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import Icon from "../../../Layouts/Icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useShareInfoPopup } from "../../../../Logic/useShareInfoPopup";
import Assets from "../../../Layouts/Assets";
import Style from "./PaymentAlert.module.scss";

const PaymentAlert = ({ isPaymentAlertIsOpen, setIsPaymentAlertIsOpen }) => {
  return (
    <CommonModal
      backdrop="static"
      keyboard={true}
      showModal={isPaymentAlertIsOpen}
      setShowModal={setIsPaymentAlertIsOpen}
      centered={true}
      className={`Share__modal ${Style.PaymentAlert_modal}`}
      
      content={
        <>
          <div className={Style.PaymentAlert_wrap}>
          <img className={Style.PaymentAlert_img} src={Assets.box_important} alt="Payment Alert" />
          </div>
          <h3>Payment Alert!</h3>
          <h7>Please complete the payment to enable it.</h7>
        </>
      }
    />
  );
};

export default PaymentAlert;
