import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CommonInput from "../../../../Layouts/CommonInput";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";

let hasErrors = false;

const EditForm = ({ setOpenEditProjectModal,handleEditProjectName }) => {

  const { editProject } = useSelector((state) => state.project);

  const SignupSchema = Yup.object().shape({
    projectName: Yup.string().required("Project name is required"),
  });

  return (
    <Formik
      initialValues={{ projectName: editProject?.data?.name!="" ? editProject?.data?.name : "" }}
      onSubmit={(values, { resetForm }) => {
        handleEditProjectName(values, { resetForm });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;

        return (
          <>
            {/* Project Name */}
            <CommonInput
              type="text"
              placeholder="Project Name"
              variant="input"
              name="input_project_name"
              id="input_project_name"
              value={values.projectName}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("projectName", e.target.value);
              }}
              hasError={errors?.projectName && touched.projectName}
              errorMsg={errors?.projectName}
            />
            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => {
                  setOpenEditProjectModal(false);
                }}
              >
                Cancel
              </button>
              <button  disabled={!isValid || !dirty} onClick={() => {
                  handleSubmit();
                }} className="btn btn-primary"> {editProject.status ==="loading" ? <InfiniteDotLoader /> :  "Save" } </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default EditForm;
