import React from "react";
import CommonInput from "../../../Layouts/CommonInput";
import Style from "./PhoneLogin.module.scss";
import { Link } from "react-router-dom";
import { Icon } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLogin } from "../../../../Logic/useLogin";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";

let hasErrors = false;

const PhoneLogin = ({ setCurrentTab, currentTab, setIsOtpVerification }) => {
  const {
    handleLoginWithPhone,
    loginError,
    loginErrorData,
    LoginWithPhoneInit,
    setLoginWithPhoneInit,
    otpCreateError,
    otpCreateErrorData,
    otpCreateIsLoading,
  } = useLogin();

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const SignupSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required")
      .min(7, "Min 7 digits is required")
      .max(14, "Max 14 digits is required"),
  });

  return (
    <Formik
      initialValues={LoginWithPhoneInit}
      onSubmit={(values, { resetForm }) => {
        handleLoginWithPhone(values, { resetForm });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        // hasErrors = Object.keys(errors).length == 0;

        return (
          <>
            <div className={Style.Login__form}>
              <CommonInput
                label="Phone Number"
                variant="phone-with-country"
                id="input_phone"
                name="phone_number"
                placeholder="Enter your phone number"
                default_country="IN"
                value={values?.phone_number}
                country_dial_code={values.country_dial_code}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("phone_number", e.target.value);
                }}
                callback={function (country) {
                  setFieldValue("country_dial_code", country.dial_code);
                }}
                hasError={errors?.phone_number && touched?.phone_number}
                errorMsg={errors?.phone_number}
              />
              {otpCreateError === true && (
                <p className="form-error-note">{"Sorry, user not found!"}</p>
              )}
            </div>
            <button
              type="submit"
              disabled={!isValid || !dirty}
              className={`${Style.Login__button} btn btn-primary w-100`}
              onClick={() => handleSubmit()}
            >
              {otpCreateIsLoading === "loading" ? (
                <InfiniteDotLoader />
              ) : (
                "Sign In"
              )}
            </button>
          </>
        );
      }}
    </Formik>
  );
};

export default PhoneLogin;
