import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
// import DarkThemeToggler from '../../../utils/DarkThemeToggler'
import Style from "./HomeVideo.module.scss";

import { useAnimations } from "../../../Logic/Animations";
import VideoPlayer from "../../../utils/VideoPlayer";
import Assets from "../../Layouts/Assets";

const HomeVideo = () => {
  const { slideTopRef } = useAnimations();
  const [play, setPlay] = useState(false);
  const [paused, setPaused] = useState(false);
  const observer = useRef();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          setPlay(paused ? false : true);
        } else {
          setPlay(false);
        }
      });
      observer.current.observe(videoRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  });

  return (
    <section className={`${Style.HomeVideo} homeVideoPlayer`}>
      <Container>
        <div className={`section-title-block center`}>
          <h2 ref={slideTopRef}>Powerful Virtual Photo Sharing Platform</h2>
          <p ref={slideTopRef}>
            Lorem ipsum dolor sit amet, consetetur off the sadipscing elitr the
            lorem amen.
          </p>
        </div>
        <div className={`${Style.HomeVideo__player}`} ref={slideTopRef}>
          <div ref={videoRef}>
            <VideoPlayer
              play={play}
              setPlay={setPlay}
              paused={paused}
              setPaused={setPaused}
              vidUrl="https://youtu.be/K4TOrB7at0Y"
              autoplay={true}
              loop={true}
            />
          </div>
          <figure className={`${Style.vector} ${Style.vector_spring}`}>
            <img src={Assets.vector_spring_blue} alt="" ref={slideTopRef} />
          </figure>
          <figure className={`${Style.vector} ${Style.vector_semicircle}`}>
            <img src={Assets.vector_sc_blue} alt="" ref={slideTopRef} />
          </figure>
        </div>
        {/* <div className={Style.HomeVideo__themeToggler}>
                    <DarkThemeToggler />
                </div> */}
      </Container>
    </section>
  );
};

export default HomeVideo;
