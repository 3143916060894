import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/Auth/authSlice";
import getStartedSlice from "./slices/GetStarted/getStartedSlice";
import userBasicDataSlice from "./slices/GetStarted/userBasicDataSlice";
import HomeSlice from "./slices/Home/HomeSlice";
import preferenceListSlice from "./slices/Preference/preferenceListSlice";
import studioDetailstSlice from "./slices/Preference/studioDetailstSlice";
import ProjectSlice from "./slices/Project/ProjectSlice";
import postThemeSlice from "./slices/Theme/postThemeSlice";
import selectThemeSlice from "./slices/Theme/selectThemeSlice";
import userFlowSlice from "./slices/UserFlow/userFlowSlice";
import projectSingleSlice from "./slices/Project/ProjectSingle";
import gallerySlice from "./slices/Gallery/gallerySlice";
import galleryFPAupoadSlice from "./slices/Gallery/galleryFPAupload";
import staffsSlice from "./slices/Staffs/staffsSlice";
import getDashboardSlice from "./slices/Dashboard/getDashboardSlice";
import checkoutSlice from "./slices/Payment/checkoutSlice";
import fileUploadSlice from "./slices/Gallery/fileUploadSlice";



const persistConfig = {
  key: "root",
  storage,
  whitelist: ["registerData"],
};

const authSliceSection = persistReducer(persistConfig, authSlice);

const appReducer = combineReducers({
  auth: authSliceSection,
  home: HomeSlice,
  project: ProjectSlice,
  projectSinglePage: projectSingleSlice,
  getStarted: getStartedSlice,
  userData: userBasicDataSlice,
  theme: selectThemeSlice,
  selectedTheme: postThemeSlice,
  preference: studioDetailstSlice,
  preferenceList: preferenceListSlice,
  userFlow: userFlowSlice,
  gallery: gallerySlice,
  FPAupload: galleryFPAupoadSlice,
  staffs: staffsSlice,
  dashborad: getDashboardSlice,
  payment: checkoutSlice,
  fileUpload: fileUploadSlice
});

export default appReducer;
