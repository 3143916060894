import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import CommonInput from "../../../../Layouts/CommonInput";
import CommonModal from "../../../../Modals/CommonModal";
import Style from ".././AddNewFolderModal.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Icon from "../../../../Layouts/Icons";
import { useAddNewFolder } from "../../../../../Logic/useAddNewFolder";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";
export const FormFields = ({ handleCancel, handleSubmitCreateNewFolder }) => {
  const {
    initialInput,
    customFieldName,
    setCustomFieldName,
    getSelectedEventCategory,
    getSelectedStaffsArray,
    createNewFolder,
    allFolders,
  } = useAddNewFolder();

  const SignupSchema = Yup.object().shape({
    eventName: Yup.string().required("Event name is required"),
    customName: Yup.string().when("eventName", (eventName) => {
      if (eventName === "custom")
        return Yup.string().required("Event name is required");
    }),
    location: Yup.string().required("Location is required"),
    date: Yup.string().required("Date is required"),
    eventCategory: Yup.string().required("Event category is required"),
    staff: Yup.string().required("Staff is required."),
  });


  return (
    <Formik
      initialValues={initialInput}
      onSubmit={(values, { resetForm }) => {
        handleSubmitCreateNewFolder(values, resetForm);
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        return (
          <>
            <Row>
              <Col sm={12} xs={12}>
                {!customFieldName && (
                  <CommonInput
                    label="Event Name"
                    variant="select-input"
                    id="input_event_name"
                    floatingLabel={true}
                    options={createNewFolder.events}
                    selectedValue={
                      Number.isInteger(
                        allFolders?.editFolder?.details?.album_name_id
                      )
                        ? {
                            value:
                              allFolders?.editFolder?.details?.album_name_id,
                            label: allFolders?.editFolder?.details?.name,
                          }
                        : false
                    }
                    callback={(value) => {
                      setFieldValue("eventName", value);

                      if (value === "custom") {
                        setCustomFieldName(true);
                      } else {
                        setCustomFieldName(false);
                      }
                    }}
                    hasError={errors?.eventName && touched.input_event_name}
                    errorMsg={errors?.eventName}
                  />
                )}
                <div className={Style.custom_event_input_wrap}>
                  <CommonInput
                    type="text"
                    className={customFieldName === true ? "" : "d-none"}
                    label="Event Name"
                    variant="input"
                    name="customName"
                    id="customName"
                    floatingLabel={true}
                    value={values.customName}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("customName", e.target.value);
                    }}
                    hasError={errors?.customName && touched.customName}
                    errorMsg={errors?.customName}
                  />

                  <button
                    className={`${Style.custom_event_close_btn} ${
                      customFieldName === true ? "" : "d-none"
                    }`}
                    onClick={() => {
                      setFieldValue("eventName", "");
                      setFieldValue("customName", "");
                      setCustomFieldName(false);
                    }}
                  >
                    <Icon color={""} size={14} icon={"icon-close"} />
                  </button>
                </div>
              </Col>
              <Col lg={6}>
                <CommonInput
                  type="text"
                  label="Location"
                  variant="location-input"
                  name="location"
                  id="location"
                  floatingLabel={true}
                  value={values.location}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("location", e.target.value);
                  }}
                  hasError={errors?.location && touched.location}
                  errorMsg={errors?.location}
                />
              </Col>
              <Col lg={6}>
                <CommonInput
                  label="Date"
                  variant="date-picker"
                  id="input_date"
                  dataPickerValue={
                    allFolders?.editFolder?.status === true
                      ? allFolders?.editFolder?.details?.date
                      : ""
                  }
                  floatingLabel={true}
                  datePickerCallback={(dateValue) => {
                    setFieldValue("date", dateValue);
                  }}
                  hasError={errors?.date && touched.input_date}
                  errorMsg={errors?.date}
                />
              </Col>
              <Col sm={12} xs={12}>
                <CommonInput
                  label="Event Category"
                  variant="select-input"
                  id="input_event_category"
                  placeholder=""
                  floatingLabel={true}
                  selectedValue={getSelectedEventCategory(createNewFolder)}
                  options={createNewFolder.eventCategories}
                  callback={(value) => {
                    setFieldValue("eventCategory", value);
                  }}
                  hasError={errors?.eventCategory && touched.input_event_category}
                  errorMsg={errors?.eventCategory}
                />
              </Col>
              <Col sm={12} xs={12}>
                {createNewFolder?.staffList?.length == 0 &&  createNewFolder?.staffListIsLoading !=="loading"   ? (
                  <p className="form-error-note">
                    Please add your staff to continue...
                  </p>
                ) : (
                  <CommonInput
                    label="Add Staff"
                    variant="multi-select-input"
                    id="input_add_staff"
                    placeholder=""
                    floatingLabel={true}
                    defaultValue={getSelectedStaffsArray()}
                    options={createNewFolder?.staffList}
                    getValue={(staffs) => {
                      const staffArray = [];
                      staffs?.forEach((staff) => {
                        staffArray?.push(staff?.value);
                      });
                      setFieldValue("staff", staffArray?.join(","));
                      //setFieldValue("staff", staffArray);
                    }}
                    hasError={errors?.staff && touched.input_add_staff}
                    errorMsg={errors?.staff}
                  />
                )}
              </Col>
            </Row>
            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => handleCancel(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleSubmit()}

                disabled={
                  (!createNewFolder?.staffList?.length == 0 ? false : true) || !(dirty && isValid)
                }
              >
                {createNewFolder.status === "loading" ? (
                  <InfiniteDotLoader />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
