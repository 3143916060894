import React from 'react'
import Contact from '../../components/Contact'
import CommonLayout from '../../components/Layouts/CommonLayout'

const ContactPage = () => {
  return (
    <CommonLayout>
        <Contact />
    </CommonLayout>
  )
}

export default ContactPage