import React from "react";

const InformationAndGuidelines = () => {
  return (
    <>
      <div className="inner-page">
        <div className="container">
          <h1 className="policies-title">Information & Guidelines</h1>
          <div className="policies-date">Last updated on: 29 October 2022</div>
          <div className="admin-content-area">
            <p>
              The following guidelines and terms of service are given to the
              users of{" "}
              <a href="https://photofind.in" className="a">
                Photofind.in
              </a>
              .
            </p>
            <ol>
              <li>
                Photofind is a web portal created for the use of general public
                who register with Photofind by payment of the subscription fee
                and submits their personal contact information.
              </li>
              <li>
                Members who have registered on Photofind can upload digital
                photographs and use their page as a personal digital album.
              </li>
              <li>
                Members can organise the photos by rearranging the positions of
                the photos in the gallery or re-group into different sub albums.
              </li>
              <li>
                The file size of the photos may be optimised by Photofind for
                better performance of your album page. However, the quality for
                display and viewing will not be affected. file size of the
                photos may be optimised by Photofind for better performance of
                your album page. However, the quality for display and viewing
                shall be retained.
              </li>
              <li>
                Apart from usage as a digital album, there are facilities to
                create and share your Family Tree to your friends and relatives.
                Building of your Family Tree can be interesting to the whole
                family since it can store and display the thumbnail photo of
                every family member along with their personal information.
              </li>
              <li>
                The information you put in the Family Tree are visible only to
                you and those who share the family tree with. The details are
                not available to the public.
              </li>

              <li>
                You can delete the photos or albums from your page whenever you
                feel like. Photofind will not have any right on the photos you
                upload.
              </li>
              <li>
                A prompt Log-in procedure has to be completed to access the
                Photo Album or Family Tree. If you forget the password, there is
                a provision to reset the password. The new password will be
                intimated to you through your registered E mail.
              </li>
              <li>
                We request you to keep your page in an organised and presentable
                form. Towards this, the facility for the creation and naming of
                sub albums has been provided. Avoid uploading of duplicates.
                Remove wrongly exposed and unwanted photos so that you keep a
                presentable, good quality album which can be proudly displayed
                to other family members or friends when a need arises.
              </li>
              <li>
                You can also post Embedded Video Links from YouTube, Vimeo, and
                Instagram on your page. Kindly restrict to short videos related
                directly to the subject. A maximum of 15 video links can be
                posted on your page. A facility for uploading MP3 audio files
                (such as audio recordings of your loved ones) is also provided.
              </li>
              <li>
                <a
                  href="https://familyphotosalbum.com"
                  target="_blank"
                  className="a"
                >
                  FamilyPhotosAlbum.com
                </a>{" "}
                and{" "}
                <a href="https://photofind.in" className="a">
                  Photofind.in
                </a>{" "}
                (its Studio front end) are not mass storage solutions. Both
                these linked portals do not store the original photo files of
                their clients.
              </li>
            </ol>

            <h2>Information on Photofind’s ownership</h2>
            <p>
              <a
                href="https://familyphotosalbum.com"
                target="_blank"
                rel="noopener noreferrer"
                className="a"
              >
                FamilyPhotosAlbum.com
              </a>{" "}
              and its Studio front-end{" "}
              <a href="https://photofind.in" className="a">
                Photofind.in
              </a>{" "}
              are owned by Hamphotos Private Limited, Kochi, India where K G
              Girish Babu is the Managing Director. The product development,
              upkeep, and maintenance are under a long-term contract with M/s
              Webandcrafts, a 300+ employee company located within a
              Government-owned IT Park Special Economic Zone in Kerala, India.
            </p>
            <p>
              You may go through Photofind’s Privacy Policy where our aim also
              is to comply with GDPR.
            </p>

            <h2>Conclusion</h2>
            <p>
              Photofind is created and offered based on a deep passion for
              Photography and Information Technology.
            </p>
            <p>
              Log in and enjoy{" "}
              <a href="https://photofind.in" className="a">
                Photofind.in
              </a>
            </p>
            <i>File: Photofind/I&G Version 1.04 Dated 29th October 2022</i>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationAndGuidelines;
