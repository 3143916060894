import React from 'react';

const CancellationAndRefundPolicy = () => {

  return (
    <>
      <div className='faq-page inner-page'>
        <div className='container'>
          <h1 className='policies-title'>Cancellation & Refund Policy</h1>
          <div className="policies-date">Last updated on: 29 October 2022</div>
          <div className='admin-content-area'>
            <p>
              The terms hereof shall constitute Hamphotos Private Limited’s (hereinafter referred as ‘Company’) cancellation and refund policy, related to the solutions rendered on the web portal <a href="https://photofind.in" className='a'>Photofind.in</a>.
            </p>
            <h2>A. Cancellation and Refunds by the Company</h2>
            <p>
              There may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason, without any claims or liability to pay finance charges or interest on the amount. Some situations that may result in your order being cancelled include but are not limited to inaccuracies or errors in Solutions or pricing information, technical or technological problems or problems identified in relation to credit / debit fraud. We may also require some additional verifications or information before accepting any order. We will contact you if your order is cancelled or if additional information is required to accept your order. If your order is cancelled by the Company after your credit / debit card has been charged, the said amount will be refunded to the respective credit / debit card account.
            </p>
            <h2>B. Cancellation by you</h2>
            <p>
              You agree and acknowledge that you are subscribing to the solutions offered through the web portal FamilyPhotosAlbum.com, after going through and accepting the Guidelines, Terms of Service and also watching the demonstration videos. Still, if you decide to cancel the subscription, this can be done within 7 (seven) calendar days from the date of making the subscription payment.  In a case where you have not uploaded any photograph or nor created any family tree, you will be refunded in the entire amount after deducting any bank charges and payment gateway commissions that may have been applicable. In a case where you have uploaded photographs/created or attempted to create a family tree, and if there is a cancellation request received within the above period of 7 days, we may at our discretion limit the refund to 50% of the amount, and also deduct any bank charges and payment gateway commissions that may have been applicable. Beyond 7 days, the request for cancelation with a refund shall not be entertained by the Company.
            </p>
            <i>FPA/CRF/V 1.04 Dated 29th October 2022</i>
          </div>
        </div>
      </div>
    </>
  )
}

export default CancellationAndRefundPolicy;