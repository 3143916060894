import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Style from "./HomeTestimonials.module.scss";
import Icon from "../../Layouts/Icons";
import { useHome } from "../../../Logic/useHome";
import { useAnimations } from "../../../Logic/Animations";

const TestimonialCard = ({ content, author, company }) => {
  return (
    <div className={Style.TestimonialCard}>
      <div className={Style.TestimonialCard__top}>
        <Icon
          size={28}
          icon={"icon-double-quote"}
          className={Style.TestimonialCard__quoteIcon}
        ></Icon>
        <blockquote className={Style.TestimonialCard__content}>
          “{content}”
        </blockquote>
      </div>
      <div className={Style.TestimonialCard__author}>
        <div className={Style.TestimonialCard__authorName}>{author}</div>
        <div className={Style.TestimonialCard__authorCompany}>{company}</div>
      </div>
    </div>
  );
};

const HomeTestimonials = () => {
  const { slideTopRef } = useAnimations();
  const { testimonials, testimonialData, capitalizeFirstLetter } = useHome();

  useEffect(() => {
    testimonials();
  }, []);

  return (
    <section className={Style.HomeTestimonials}>
      <Container>
        <div className="section-title-block center">
          <h2 className={Style.HomeTestimonials_title}>Testimonials of FPA Studio by Photographers</h2>
          <p>
            Lorem ipsum dolor sit amet, consetetur off the sadipscing elitr the
            lorem amen
          </p>
        </div>
        <div className={Style.HomeTestimonials__sliderWrap}>
          {testimonialData?.length > 0 ? (
            <Swiper
              slidesPerView={1.17}
              spaceBetween={20}
              navigation={{
                prevEl: ".nav-left",
                nextEl: ".nav-right",
                clickable: true,
              }}
              modules={[Navigation, Autoplay]}
              loop={true}
              autoplay={{
                delay: 2000,
              }}
              speed={800}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1340: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              className={Style.HomeTestimonials__slider}
            >
              {testimonialData?.map((data) => {
                return (
                  <SwiperSlide key={data.id}>
                    <TestimonialCard
                      content={capitalizeFirstLetter(data?.description)}
                      author={capitalizeFirstLetter(data?.name)}
                      company={capitalizeFirstLetter(data?.company_name)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            ""
          )}

          <div className={Style.HomeTestimonials__navigation}>
            <button className={`${Style.nav_left} nav-left`}>
              <Icon size={14} icon={"slider-nav-left"} />
            </button>
            <button className={`${Style.nav_right} nav-right`}>
              <Icon size={14} icon={"slider-nav-right"} />
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeTestimonials;

const testimonialData = [
  {
    id: 1,
    content:
      "A complete package for every photographer! FPA has been the one-stop solution that simplified my entire work process.",
    author: "Alex Samual",
    company: "Lumiere Wedding Company",
  },
  {
    id: 2,
    content:
      "For the album making process, my clients used to send back as image numbers & my team had to manually sort it out. Now using FPA, it takes less than a minute.",
    author: "John Mathew",
    company: "Weva",
  },
  {
    id: 3,
    content:
      "Our clients used to take several months to select the photos required for their print albums. FPA reduced the burden of my staff",
    author: "Jaimee Soto",
    company: "Jaimee Soto Photography ",
  },
  {
    id: 4,
    content:
      "A complete package for every photographer! FPA has been the one-stop solution that simplified my entire work process.",
    author: "Alex Samual",
    company: "Lumiere Wedding Company",
  },
  {
    id: 5,
    content:
      "For the album making process, my clients used to send back as image numbers & my team had to manually sort it out. Now using FPA, it takes less than a minute.",
    author: "John Mathew",
    company: "Weva",
  },
  {
    id: 6,
    content:
      "Our clients used to take several months to select the photos required for their print albums. FPA reduced the burden of my staff",
    author: "Jaimee Soto",
    company: "Jaimee Soto Photography ",
  },
];
