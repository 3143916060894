import React from "react";
import Style from "./OtpScreen.module.scss";

const OtpScreen = ({
  OtpInput,
  otp,
  setOTP,
  SetOTPCompleted,
  isOtpOpen,
  emailInput,
  setEmailCheckPassed,
  editEmail,
  userEmail,
  otpInvalid,
}) => {
  return (
    <>
      <div className={`${Style.ForgotPassword__form}`}>
        {/* Email */}
        <div className="form-group">
          <div className="d-flex justify-content-between pb-2">
          <label
            htmlFor="input_email"
            className={`${isOtpOpen ? Style.otp_open : ""}`}
          >
            Email address
          </label>
          {isOtpOpen && (
              <button
                className="btn btn-link"
                onClick={() => {
                  editEmail();
                  setEmailCheckPassed(false);
                }}
              >
                Change?
              </button>
            )}
          </div>
          <div className="input-holder">
            <input
              type="email"
              name="input_email"
              id="input_email"
              className="form-control"
              placeholder="Enter Your Email Id"
              autoComplete="new-email"
              disabled={true}
              ref={emailInput}
              value={userEmail}
            />
          </div>
          {/* <small>Please enter valid Email ID</small> */}
        </div>
      </div>

      <div className="form-group otp-inputs text-center">
        <label htmlFor="input_otp">OTP Send to Mobile</label>
        <div className={`input-holder pt-2 ${Style.otp_input_center_item}`}>
          <OtpInput
            value={otp}
            numInputs={4}
            name="otp"
            inputStyle={`${
              otpInvalid == true
                ? Style.input_Style_error
                : ""
            }`}
            onChange={(value) => {
              /*****************************************
               * CHECKING INPUT VALUE IS NUMBER OR NOT. IF INPUT IS A NUMBER THEN SAVES THE VALUE OTHERWISE DISCARD *
               *****************************************/
              if (/^-?\d+$/.test(value) || value==="") {
                setOTP(value);
              }
              
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OtpScreen;
