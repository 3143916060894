import { useEffect, useState } from "react";
import {
  getAllStaffsAPI,
  customLoadMore,
  currentTabHandler,
  setStaffFilter,
  toggleStaffEdit,
  setStaffEditData,
  resetStaffEdit,
  toggleAssignedProjects,
  setAssignedProjects,
  handleCustomSearchText,
} from "../../../store/slices/Staffs/staffsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useContactStaffs = () => {
  const dispatch = useDispatch();

  const { currentTab, sections, editStaff, statusAddStaffPopup } = useSelector(
    (state) => state.staffs
  );

  useEffect(() => {
    setCurrentTab();
  });

  useEffect(() => {
    if (sections[currentTab]?.filter != undefined) {
      fetchStaffs("contractStaffTab");
    }
  }, [sections[currentTab]?.filter]);

  useEffect(() => {
    if (statusAddStaffPopup === false) {
      if (editStaff?.status === true) {
        dispatch(resetStaffEdit());
      }
    }
  }, [statusAddStaffPopup]);

  const handleStaffFilter = (filter) => {
    dispatch(setStaffFilter(filter));
  };

  const setCurrentTab = () => {
    dispatch(currentTabHandler({ tab: "contractStaffTab" }));
  };

  const getTabUrl = (slug) => {
    switch (slug) {
      case "contractStaffTab":
        return "contract-staff-list";
        break;
    }
  };

  const fetchStaffs = (slug, query = false) => {
    let obj = {
      url: getTabUrl(slug),
      page: "1",
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    } else if (
      sections?.[currentTab]?.["searchText"] != "" &&
      sections?.[currentTab]?.["searchText"] != undefined
    ) {
      obj.search = sections?.[currentTab]?.["searchText"];
    }

    obj.sort =
      sections[currentTab]?.filter === "" ? "" : sections[currentTab]?.filter;

    return dispatch(getAllStaffsAPI(obj));
  };

  const loadMore = () => {
    if (sections[currentTab]?.current_page < sections[currentTab]?.last_page) {
      let obj = {
        url: getTabUrl(currentTab),
        page: parseInt(sections[currentTab]?.current_page) + 1,
        per_page: 20,
        sort:
          sections[currentTab]?.sort != ""
            ? sections[currentTab]?.sort
            : "desc",
      };

      if (
        sections?.[currentTab]?.["search"] != "" &&
        sections?.[currentTab]?.["search"] != undefined
      ) {
        obj.search = sections?.[currentTab]?.["search"];
      }

      obj.sort =
        sections[currentTab]?.filter === "" ? "" : sections[currentTab]?.filter;

      dispatch(customLoadMore(obj));
    }
  };

  const handletoggleStaffEdit = (status) => {
    dispatch(toggleStaffEdit(status));
  };

  const handleSetStaffEditData = (data) => {
    dispatch(setStaffEditData(data));
  };

  const handletoggleAssignedProjects = (status) => {
    dispatch(toggleAssignedProjects(status));
  };

  const handlesetAssignedProjects = (data) => {
    dispatch(setAssignedProjects(data));
  };

  const handleCustomSearchTextReset = () => {
    dispatch(handleCustomSearchText(""));
  };

  return {
    fetchStaffs,
    loadMore,
    setCurrentTab,
    handleStaffFilter,
    handletoggleStaffEdit,
    handleSetStaffEditData,
    handletoggleAssignedProjects,
    handlesetAssignedProjects,
    handleCustomSearchTextReset,
  };
};
