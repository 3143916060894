
import Icon from "../../../../Layouts/Icons";
import Style from "./ClientAddStatus.module.scss";


export const ClientAddStatus = ({status, setShowModal}) => {


  return (
    <>
      {status === "success" ? (
      <>
        <div className="text-center mt-3">
          <div className={Style.ClientStatus_icon_successfull}>
              <Icon size={40} icon={"icon-tick"} />
          </div>
          <p className="fz-20 mt-3 mb-4">Client added successfully.</p>
          <button className={"btn btn-primary"} onClick={()=> setShowModal()}>Done</button>
        </div>
      </>
      ) : (
      <>
        <div className="text-center mt-3">
          <div className={Style.ClientStatus_icon_faild}>
              <Icon size={40} icon={"icon-close"} />
          </div>
          <p className="fz-20 mt-3 mb-4">The client was not added</p>
          <button className={"btn btn-primary"} >Back</button>
        </div>
      </>
      )}
    </>
  );
};

export default ClientAddStatus;