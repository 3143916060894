import React,{useState,useEffect} from 'react'
import InfiniteDotLoader from '../../../utils/InfiniteDotLoader'
import Assets from '../../Layouts/Assets'
import Style from './BlankDataPlaceholderSearchResult.module.scss'

const BlankDataPlaceholderSearchResult = ({ blankDescription }) => {

 
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // "timer" will be undefined again after the next re-render

    setLoader(true)

    setTimeout(() => {
      setLoader(false)
    }, 800);

  }, []);

  return (
    <div className={`${Style.BlankDataPlaceholder} blank-data-placeholder`}>
      
             {loader ? (
                <InfiniteDotLoader />
              ) : (
                <h3 className={Style.BlankDataPlaceholder__title}>{blankDescription}</h3>
              )}
    </div>
  )
}

export default BlankDataPlaceholderSearchResult
