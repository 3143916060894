import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Style from "./HomeFeatures.module.scss";

import { useAnimations } from "../../../Logic/Animations";
import { useDimensions } from "../../../Logic/Dimensions";
import { useHome } from "../../../Logic/useHome";
import FeatureCard from "./FeatureCard";

const HomeFeatures = () => {
  const windowDimensions = useDimensions();
  const { featuresOfFTP, featuresOfFTPData } = useHome();
  const {
    slideTopRef,
    slideRightRef,
    // splitTextRef,
  } = useAnimations();

  useEffect(() => {
    // if (featuresOfFTPData == null) {
    featuresOfFTP();
    // }
  }, []);

  return (
    <section className={`${Style.HomeFeatures} home-features`}>
      <Container>
        <div className={Style.HomeFeatures__head}>
          <Row>
            <Col lg={5}>
              <h2
                className={Style.HomeFeatures__title}
                ref={
                  windowDimensions.width > 1200 ? slideRightRef : slideTopRef
                }
              >
                {featuresOfFTPData?.title}
              </h2>
            </Col>
            <Col lg={7}>
              <p
                className={Style.HomeFeatures__description}
                ref={
                  windowDimensions.width > 1200 ? slideRightRef : slideTopRef
                }
              >
                {featuresOfFTPData?.description}
              </p>
            </Col>
          </Row>
        </div>
        <div className={Style.HomeFeatures__contents}>
          <Row>
            <Col lg={5}>
              <figure className={Style.HomeFeatures__image}>
                <img
                  src={featuresOfFTPData?.icon_image_url}
                  alt=""
                  ref={
                    windowDimensions.width > 1200 ? slideRightRef : slideTopRef
                  }
                />
              </figure>
            </Col>
            <Col lg={7}>
              <div className={Style.HomeFeatures__features}>
                <Row>
                  {featuresOfFTPData?.features?.map((data, index) => {
                    return (
                      <Col sm={6} key={index}>
                        <FeatureCard
                          iconSize={35}
                          icon={data.icon_image_url}
                          title={data.title}
                          description={data.description}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default HomeFeatures;

const featuresData = [
  {
    id: 1,
    iconSize: 35,
    icon: "storage",
    title: "Storage",
    description: "FPA has a range of cloud storage solutions for your photos.",
  },
  {
    id: 2,
    iconSize: 35,
    icon: "easy-access",
    title: "Easy Access",
    description: "We gives you access to every photo you save to FPA.",
  },
  {
    id: 3,
    iconSize: 35,
    icon: "stay-secure",
    title: "Stay Secure",
    description: "Keep your files private with multiple layers of protection.",
  },
  {
    id: 4,
    iconSize: 35,
    icon: "quick-share",
    title: "Quick Share",
    description: "Quickly send your photos big or small to anyone with ease.",
  },
];
