import Style from "./StudioName.module.scss";
import React from "react";
import CommonInput from "../../../../Layouts/CommonInput";
import { Formik } from "formik";
import { authModalHandler } from "../../../../../store/slices/Preference/studioDetailstSlice";
import { useDispatch, useSelector } from "react-redux";
import { updatePreferenceList } from "../../../../../store/slices/GetStarted/userBasicDataSlice";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";

const StudioName = ({ validationSchema, nameSubmit }) => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.userData);
  const studioName = useSelector((state) => state.preference);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: userProfileData?.name,
      }}
      onSubmit={(values, { resetForm }) => {
        nameSubmit(values, resetForm);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
        dirty,
        isValid,
      }) => {
        return (
          <>
            <CommonInput
              type="text"
              label="Studio Name"
              variant="input"
              id="input_studio_name"
              floatingLabel={true}
              touched={touched}
              name="name"
              value={values?.name}
              hasError={errors?.name}
              errorMsg={errors?.name}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("name", e.target.value);
              }}
            />

            <div className={Style.actions}>
              <button
                className="btn btn-border-grey me-2"
                onClick={() => dispatch(authModalHandler(false))}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                disabled={!isValid || !dirty}
                onClick={() => {
                  handleSubmit();
                  dispatch(
                    updatePreferenceList({
                      type: "name",
                      data: values?.name,
                    })
                  );
                }}
                type="button"
              >
                {studioName?.status === "loading" ? (
                  <InfiniteDotLoader />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default StudioName;
