import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  themeData: [],
};

export const getTheme = createAsyncThunk(
  "theme/getTheme",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("preference/themes");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const selectThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {},
  extraReducers: {
    [getTheme.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [getTheme.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.themeData = action.payload.data?.result;
    },
    [getTheme.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});


export default selectThemeSlice.reducer;
