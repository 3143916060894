import Style from "./ImageCropr.module.scss";
import { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function ImageCropr({ src }) {
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    zoom: 1,
  });


  return (
    <>
      <div className={Style.ImageCropr_wrap}>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          className={Style.ImageCropr}
        >
          <img className={Style.ImageCropr_image} src={src} />
        </ReactCrop>
      </div>
    </>
  );
}

export default ImageCropr;
