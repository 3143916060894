import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  addStaff,
  deleteStaff,
  deleteStaffHandler,
  loadMoreStaff,
  updateStaffDetailsHandler,
  viewStaff,
} from "../../store/slices/Preference/preferenceListSlice";
import {
  getPermanentStaffList,
  getContractStaffList,
  updatePermanentStaffSort,
  updateContractStaffSort,
  insertNewPermanentStaffdata,
  insertNewContractStaffdata,
  updateContractStaffData,
  updatePermanentStaffData,
  updatePermanentStaffCount,
  updateContractStaffCount,
} from "../../store/slices/Dashboard/getDashboardSlice";

import { getStaffDetails } from "../../store/slices/Project/ProjectSingle";

import {
  callbackStaffDelete,
  toggleStatusAddStaffPopup,
  updateSwitchStaffData,
  updateStaffPreSelect,
  updateStaffData,
  getAllStaffsAPI,
  clearTabData,
  getStaffDesignation,
} from "../../store/slices/Staffs/staffsSlice";

import { toast } from "react-toastify";

export const useStaff = () => {
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [showEditStaffModal, setShowEditStaffModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
  const dispatch = useDispatch();

  const { staffViewData } = useSelector((state) => state.preferenceList);
  const { currentTab, sections, statusAddStaffPopup, designations
  } = useSelector(
    (state) => state.staffs
  );

  const { permanentStaffList, contractStaffList } = useSelector(
    (state) => state.dashborad
  );


  const handleinsertNewPermanentStaffdata = (obj) => {
    if (Object.keys(permanentStaffList).length > 0) {
      var newUpdatedArray = [
        ...[{ ...obj, ...{ staff_assigned: [] } }],
        ...permanentStaffList.data,
      ];
      return dispatch(insertNewPermanentStaffdata(newUpdatedArray));
    }
  };

  const handleInsertNewContractStaffdata = (obj) => {
    if (Object.keys(contractStaffList).length > 0) {
      var newUpdatedArray = [
        ...[{ ...obj, ...{ staff_assigned: [] } }],
        ...contractStaffList.data,
      ];
      return dispatch(insertNewContractStaffdata(newUpdatedArray));
    }
  };

  const handleToggleStatusAddStaffPopup = (status) => {
    dispatch(toggleStatusAddStaffPopup(status));
  };

  const handleUpdateStaffPreSelect = (status) => {
    dispatch(updateStaffPreSelect(status));
  };

  const getTabUrl = (slug) => {
    switch (slug) {
      case "activeTab":
        return "permanent-staff-list";
        break;
      case "contractStaffTab":
        return "contract-staff-list";
        break;
    }
  };

  const fetchStaffs = (slug, query = false) => {
    let obj = {
      url: getTabUrl(currentTab),
      page: "1",
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    } else if (
      sections?.[currentTab]?.["searchText"] != "" &&
      sections?.[currentTab]?.["searchText"] != undefined
    ) {
      obj.search = sections?.[currentTab]?.["searchText"];
    }

    obj.sort =
      sections[currentTab]?.filter === "" ? "" : sections[currentTab]?.filter;

    return dispatch(getAllStaffsAPI(obj));
  };

  const handleUpdatedstaffData = (sentData) => {
    if (sentData.id !== undefined) {
      var newUpdatedArray = [];
      sections[currentTab]?.data.map((element) => {
        if (element.id === sentData.id) {
          newUpdatedArray.push({ ...element, ...sentData });
        } else {
          newUpdatedArray.push(element);
        }
      });
      dispatch(updateStaffData(newUpdatedArray));
    }
  };

  const handleCallbackStaffDelete = (sentData) => {
    var temp = [];

    const removedItemArray = sections[currentTab]?.data?.filter((staffData) => {
      temp = staffData.id == sentData?.id && temp.length == 0 ? staffData : "";
      return staffData.id !== sentData?.id;
    });

    dispatch(callbackStaffDelete(removedItemArray));
  };

  const showMessage = (text) => {
    toast.success(text, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // const fetchStaffs = () => {
  //   dispatch(viewStaff());
  // };

  const loadMore = () => {
    if (staffViewData?.current_page < staffViewData?.last_page) {
      dispatch(loadMoreStaff(staffViewData?.current_page + 1));
    }
  };

  const getOppositeTab = (tab) => {
    if (tab === "contractStaffTab") {
      return "activeTab";
    } else if (tab === "activeTab") {
      return "contractStaffTab";
    }
  };

  const staffValidationSchema = Yup.object().shape({
    staff_email: Yup.string().nullable().email("Invalid email format"),
    //   .required("Email is required."),
    staff_mobile: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required")
      .min(6, "Min 6 digits is required")
      .max(14, "Max 14 digits is allowed"),
    staff_name: Yup.string().required("Staff Name is required"),
    staff_type: Yup.string().required("Staff Type is required"),
  });

  const staffEditValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format"),
    //   .required("Email is required."),
    mobile: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required")
      .min(6, "Min 6 digits is required")
      .max(14, "Max 14 digits is allowed"),
    name: Yup.string().required("Staff Name is required"),
    staff_type: Yup.string().required("Staff Type is required"),
  });

  const staffSubmit = async (data, resetForm, setFieldError, setStaffType) => {
    const resultAction = await dispatch(addStaff(data));

    if (addStaff.fulfilled.match(resultAction)) {
      if (resultAction?.payload?.data?.status_code == "200") {
        setStaffType(null);
        let sentData = JSON.parse(resultAction?.payload.config.data);

        if (sentData.staff_type === 1) {
          handleInsertNewContractStaffdata(resultAction?.payload?.data?.result);
        } else if (sentData.staff_type === 2) {
          handleinsertNewPermanentStaffdata(
            resultAction?.payload?.data?.result
          );
        }

        /********************************************************************************************************
         * IF ANY STAFF DATA IS UPDATED THEN THIS FUNCTION TRIGGERS AND STAFF DETAILS WILL BE UPDATED INSTANLLY *
         ********************************************************************************************************/

        handleUpdatedstaffData(sentData);

        /*****************************************************
         * IF STAFF TYPE IS UPDATED , THIS FUNCTION WORKS *
         *****************************************************/

        if (sentData.type_changed === true) {
          handleCallbackStaffDelete(sentData);
          dispatch(clearTabData(getOppositeTab(currentTab)));
        }

        resetForm();
        setShowAddStaffModal(false);
        handleToggleStatusAddStaffPopup(false);
        showMessage(
          sentData.id == undefined
            ? "Staff has been added Successfully"
            : "Details have been updated successfully"
        );

        if (sentData.id == undefined) {
          fetchStaffs();
        }
      } else if (
        resultAction?.payload?.data?.validation?.status_code == "422"
      ) {
        setFieldError(
          "staff_email",
          resultAction?.payload?.data?.validation?.errors?.email?.[0]
        );
        setFieldError(
          "staff_mobile",
          resultAction?.payload?.data?.validation?.errors?.mobile?.[0]
        );
      }
    }
  };

  const staffUpdateSubmit = async (data, resetForm, setFieldError) => {
    const resultAction = await dispatch(addStaff(data));

    if (addStaff.fulfilled.match(resultAction)) {
      if (resultAction?.payload?.data?.status_code == "200") {
        resetForm();
        setShowEditStaffModal(false);
        dispatch(updateStaffDetailsHandler(data));
        toast.success("Update Successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        resultAction?.payload?.data?.validation?.status_code == "422"
      ) {
        setFieldError(
          "staff_email",
          resultAction?.payload?.data?.validation?.errors?.email?.[0]
        );
        setFieldError(
          "staff_mobile",
          resultAction?.payload?.data?.validation?.errors?.mobile?.[0]
        );
      }
    }
  };

  const deleteStaffData = async (data) => {
    const resultAction = await dispatch(deleteStaff({ id: data?.id }));
    if (deleteStaff.fulfilled.match(resultAction)) {
      let getStaffData;

      const removedItemArray = sections[currentTab]?.data?.filter(
        (staffData) => {
          if (staffData.id == data?.id) {
            getStaffData = staffData;
          }

          return staffData.id !== data?.id;
        }
      );

      dispatch(callbackStaffDelete(removedItemArray));


      if (getStaffData?.staff_type === 2) {
        let updatedArray = permanentStaffList?.data?.filter(
          (staffData) => {
            return staffData.id !== data?.id;
          }
        );
        dispatch(updatePermanentStaffData(updatedArray));
      } else if (getStaffData?.staff_type === 1) {
        let updatedArray = contractStaffList?.data?.filter(
          (staffData) => {
            return staffData.id !== data?.id;
          }
        );
        dispatch(updateContractStaffData(updatedArray));
      }

      setOpenDeleteModal(false);
      dispatch(deleteStaffHandler(data?.id));
      dispatch(getStaffDetails());

      toast.success("Staff has been deleted Successfully.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });


    }
  };

  return {
    showAddStaffModal,
    setShowAddStaffModal,
    staffValidationSchema,
    staffSubmit,
    staffViewData,
    loadMore,
    fetchStaffs,
    showEditStaffModal,
    setShowEditStaffModal,
    staffEditValidationSchema,
    staffUpdateSubmit,
    deleteStaffData,
    openDeleteModal,
    setOpenDeleteModal,
    handleToggleStatusAddStaffPopup,
    statusAddStaffPopup,
    handleUpdateStaffPreSelect,
    designations
  };
};
