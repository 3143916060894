import Style from "./StudioEmail.module.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../../../Layouts/CommonInput";
import { authModalHandler } from "../../../../../store/slices/Preference/studioDetailstSlice";
import { Col, Row } from "react-bootstrap";
import { useSocialMedia } from "../../../../../Logic/Preference/useSocialMedia";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";

const StudioSocialMediaInput = ({ data, index, inputName, setInputValue }) => {
  return (
    <Col lg="6" className={Style.social_media_form} key={data.id}>
      <CommonInput
        variant="input-with-icon"
        type="text"
        id="input_address"
        icon={data?.icon_class}
        iconSize={16}
        label={data?.type}
        placeholder={data?.type}
        floatingLabel={true}
        name="social_media"
        hasError={data.error}
        errorMsg={data.message}
        onNameChange={(event) => {
          inputName(index, event);
          setInputValue(event);
        }}
        value={data.value}
      />
    </Col>
  );
};

const StudioSocialMedia = () => {
  const {
    socialArray,
    getSocialMediaData,
    setSocialArray,
    inputName,
    handleClickSubmit,
  } = useSocialMedia();

  const { socialMediaData, status } = useSelector((state) => state.preference);
  const { userProfileData } = useSelector((state) => state.userData);

  const dispatch = useDispatch();
  useEffect(() => {
    getSocialMediaData();
  }, []);

  const [inputValue, setInputValue] = useState({});

  useEffect(() => {
    if (socialMediaData) {
      const transformed = socialMediaData?.map(
        ({ id, type, slug, icon_class }) => ({
          id: id,
          type: type,
          slug: slug,
          icon_class: icon_class,
        })
      );
      setSocialArray(transformed);
    }
  }, [socialMediaData]);

  useEffect(() => {
    setInputValue({
      0: userProfileData.preference.social_media[0]?.link || "",
      1: userProfileData.preference.social_media[1]?.link || "",
      2: userProfileData.preference.social_media[2]?.link || "",
      3: userProfileData.preference.social_media[3]?.link || "",
      4: userProfileData.preference.social_media[4]?.link || "",
    });
  }, [userProfileData.preference.social_media]);

  return (
    <>
      {status == "succeed" ? (
        <div
          className={`create-project-modal ${Style.Studio_Social_Media_inputs}`}
        >
          <Row>
            {socialArray?.map((data, index) => {
              return (
                <>
                  <Col lg="6" className={Style.social_media_form} key={data.id}>
                    <CommonInput
                      variant="input-with-icon"
                      type="text"
                      id="input_address"
                      icon={data?.icon_class}
                      iconSize={16}
                      label={data?.type}
                      placeholder={data?.type}
                      floatingLabel={true}
                      name="social_media"
                      hasError={data.error}
                      errorMsg={data.message}
                      onNameChange={(event) => {
                        let obj = {};
                        obj[index] = event;
                        inputName(index, event);
                        setInputValue({ ...inputValue, ...obj });
                      }}
                      value={inputValue[index]}
                    />
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      ) : (
        <div className={Style.loader_wrap}>
          <InfiniteDotLoader typeSocial="soical" />
        </div>
      )}
      {status == "succeed" && (
        <div className="modal-footer">
          <button
            className="btn btn-border-grey"
            onClick={() => dispatch(authModalHandler(false))}
          >
            Back
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleClickSubmit(inputValue)}
          >
            {status === "loading" ? <InfiniteDotLoader /> : "Add"}
          </button>
        </div>
      )}
    </>
  );
};

export default StudioSocialMedia;
