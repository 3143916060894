import { Formik } from "formik";
import Style from "./StudioEmail.module.scss";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonInput from "../../../../Layouts/CommonInput";
import { authModalHandler } from "../../../../../store/slices/Preference/studioDetailstSlice";
import VerifyOtpForm from "../Auth/VerifyOtpForm";
import { useEmailVerify } from "../../../../../Logic/Preference/useEmailVerify";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";

const StudioEmail = () => {
  const [emailValue, setEmailValue] = useState("");
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.userData);
  const studioEmail = useSelector((state) => state.preference);

  const {
    code,
    showErrors,
    setShowErrors,
    showOtpErrors,
    emailValidationSchema,
    emailSubmit,
    onEmailVerifyOtp,
    isUpdatingEmail,
    resendOtp,
    otpCounter,
    setOtpCounter,
    setshowOtpErrors,
  } = useEmailVerify();

  return (
    <>
      {isUpdatingEmail === true ? (
        <VerifyOtpForm
          title={"OTP Verification"}
          emailValue={emailValue}
          code={code}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          showOtpErrors={showOtpErrors}
          onEmailVerifyOtp={onEmailVerifyOtp}
          resendOtp={resendOtp}
          otpCounter={otpCounter}
          setOtpCounter={setOtpCounter}
          setshowOtpErrors={setshowOtpErrors}
        />
      ) : (
        <Formik
          validationSchema={emailValidationSchema}
          initialValues={{
            email: userProfileData?.email,
          }}
          onSubmit={(values, { resetForm, setFieldError }) => {
            emailSubmit(values, resetForm, setFieldError);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            setFieldValue,
            handleBlur,
            dirty,
            isValid,
          }) => {
            return (
              <>
                {console.log("errors", errors)}
                <CommonInput
                  type="email"
                  label="Studio Email"
                  variant="input"
                  id="email"
                  floatingLabel={true}
                  touched={touched}
                  name="email"
                  hasError={errors?.email}
                  errorMsg={errors?.email}
                  value={values?.email}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    setEmailValue(e.target.value);
                  }}
                />

                <div className={Style.actions}>
                  <button
                    className="btn btn-border-grey me-2"
                    onClick={() => dispatch(authModalHandler(false))}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={!isValid || !dirty}
                    type="submit"
                  >
                    {studioEmail?.status === "loading" ? (
                      <InfiniteDotLoader />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default StudioEmail;
