import React from "react";
import Style from "./FormHeader.module.scss";

const FormHeader = ({
  isOtpOpen,
  setIsEmailEditable,
  isEmailEditable,
  emailInput,
}) => {
  return (
    <>
      {" "}
      <h1 className={Style.ForgotPassword__title}>Forgot Password</h1>
      <p className={Style.ForgotPassword__description}>
        Enter your email below to receive your password reset instructions.
      </p>
    </>
  );
};

export default FormHeader;
