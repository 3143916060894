import { Formik } from "formik";
import React, { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useStaff } from "../../../Logic/Preference/useStaff";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import CommonInput from "../../Layouts/CommonInput";
import Icon from "../../Layouts/Icons";
import CommonModal from "../../Modals/CommonModal";
import { AddNewFolderModal } from "../ContentHeader/AddNewFolderModal";
import AddStaff from "../PreferenceList/Components/AddStaff";
import Style from "./AddStaffCard.module.scss";
import DeleteStaffPopup from "./DeleteStaffPopup";
import AssignedProjectDetails from "./AssignedProjectDetails";

const AddStaffCard = forwardRef(
  (
    {
      name,
      email,
      phone,
      staff_assigned,
      lastIndex,
      itemIndex,
      type,
      staffID,
      staffData,
      handleSetStaffEditData,
      handletoggleStaffEdit,
      handletoggleAssignedProjects,
      handlesetAssignedProjects,
      designations
    },
    ref
  ) => {
    const { staffViewData } = useSelector((state) => state.preferenceList);
    const [initialValue, setInitialValue] = useState({});

    const {
      showEditStaffModal,
      setShowEditStaffModal,
      staffEditValidationSchema,
      staffUpdateSubmit,
      openDeleteModal,
      setOpenDeleteModal,
      deleteStaffData,
    } = useStaff();

    const handleEdit = () => {
      setShowEditStaffModal(true);
      setInitialValue(staffViewData?.data[itemIndex]);
    };

    const addStaffData = useSelector((state) => state.preferenceList);
    const { assignedProjects } = useSelector((state) => state.staffs);

    useEffect(() => {
      if (addStaffData?.status == "succeed") {
        setInitialValue(staffViewData?.data?.[itemIndex]);
      }
    }, [staffViewData]);

    const [isAssignedEventInfo, setIsAssignedEventInfo] = useState(false);
    const [isAddNewFolderModal, setIsAddNewFolderModal] = useState(false);

    useEffect(() => {
      if (isAddNewFolderModal) {
        setIsAssignedEventInfo(false);
      }
    }, [isAddNewFolderModal]);

    const getCurrentDestination = (id) => {
      let index = designations?.findIndex((obj) => obj.value == id);
      if (index > -1) {
        return designations[index]
      }
      return {}
    }
    return (
      <>
        <tr
          className={Style.AddStaffCard}
          ref={lastIndex == itemIndex ? ref : null}
          data-last={lastIndex == itemIndex ? "last" : "not"}
        >
          <td data-label="Staff Name" className={Style.AddStaffCard__name}>
            <span>{name}</span>
          </td>
          <td data-label="Mobile Number" className={Style.AddStaffCard__number}>
            <span>{phone}</span>
          </td>
          <td data-label="Email id" className={Style.AddStaffCard__email}>
            <span>{email}</span>
          </td>
          <td data-label="Destination" className={Style.AddStaffCard__email}>
            <span>{getCurrentDestination(staffData?.designation_id).label}</span>
          </td>
          <td data-label="" className={Style.AddStaffCard__assigned}>
            {staff_assigned?.length > 0 && (
              <button
                className={Style.AddStaffCard__onproject}
                onClick={() => {
                  handletoggleAssignedProjects(true);
                  handlesetAssignedProjects(staffData);
                }}
              >
                {" "}
                On project
              </button>
            )}

            {staff_assigned?.length === 0 && (
              <button className={Style.AddStaffCard__not_assigned}>
                Not Assigned
              </button>
            )}
          </td>
          {type === "dashboard-table" ? (
            <></>
          ) : (
            <>
              <td className={Style.AddStaffCard__actions} align="right">
                <button
                  onClick={() => {
                    handletoggleStaffEdit(true);
                    handleSetStaffEditData(staffData);
                  }}
                >
                  <Icon color="#000" size={19} icon={"icon-edit"} />
                </button>
                <button onClick={() => setOpenDeleteModal(true)}>
                  <Icon color="#000" size={19} icon={"icon-trash"} />
                </button>
              </td>
            </>
          )}
        </tr>

        <Formik
          enableReinitialize={true}
          validationSchema={staffEditValidationSchema}
          initialValues={initialValue}
          onSubmit={(values, { resetForm }) => {
            staffUpdateSubmit(values, resetForm);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isValid,
            dirty,
          }) => {
            return (
              <>
                <CommonModal
                  backdrop="static"
                  keyboard={false}
                  showModal={showEditStaffModal}
                  setShowModal={setShowEditStaffModal}
                  centered={true}
                  className={"type-lg"}
                  title={<h4>Edit staff</h4>}
                  content={
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <CommonInput
                            label="Name"
                            type="text"
                            variant="input"
                            name="name"
                            id="input_name"
                            floatingLabel={true}
                            value={values?.name}
                            hasError={errors?.name && touched.name}
                            errorMsg={errors?.name}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("name", e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <CommonInput
                            label="Mobile"
                            variant="phone-with-country"
                            floatingLabel={true}
                            id="input_phone"
                            default_country="IN"
                            name="mobile"
                            value={values?.mobile}
                            hasError={errors?.mobile && touched.mobile}
                            errorMsg={errors?.mobile}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("mobile", e.target.value);
                            }}
                            country_dial_code={values?.country_dial_code}
                            callback={function (country) {
                              setFieldValue(
                                "country_dial_code",
                                country.dial_code
                              );
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <CommonInput
                            label="Email"
                            type="email"
                            variant="input"
                            id="input_email"
                            floatingLabel={true}
                            name="email"
                            value={values?.email}
                            hasError={errors?.email && touched.email}
                            errorMsg={errors?.email}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                  footer={
                    <>
                      <button
                        className="btn btn-border-grey"
                        onClick={() => setShowEditStaffModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSubmit()}
                        type="submit"
                        disabled={!isValid || !dirty}
                      >
                        {addStaffData?.status === "loading" ? (
                          <InfiniteDotLoader />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </>
                  }
                />
              </>
            );
          }}
        </Formik>

        <DeleteStaffPopup
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          staffID={staffID}
          type={type}
          deleteStaffData={deleteStaffData}
        />

        <AddNewFolderModal
          addNewProjectPopup={isAddNewFolderModal}
          setAddNewProjectPopup={setIsAddNewFolderModal}
        />
      </>
    );
  }
);

export default AddStaffCard;
