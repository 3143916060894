
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const usePageTypeChecker = (props) => {
  const [pagesTypes, setPagesTypes] = useState({});
  const location = useLocation();
  let HeaderLogo = [
    "/",
    "/contact",
    "/contact/",
    "/plans",
    "/plans/",
    "/guidelines-and-terms",
    "/guidelines-and-terms/",
    "/privacy-policy",
    "/privacy-policy/",
    "/cancellation-and-refund-policy",
    "/cancellation-and-refund-policy/",
    "/faq",
    "/faq/",
    "/user-plans",
    "/project-plans/",
    "/payment-success",
    "/payment-success/"
  ];
  function checkPathname(pathname) {
    return pathname === location.pathname;
  }
  useEffect(() => {
    let pathResult = HeaderLogo.filter(checkPathname);
    setPagesTypes({commonPages : pathResult.length === 0 ? false : true });
  }, [location.pathname]);
  return pagesTypes;
}