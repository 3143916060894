import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Icon from "../../../Layouts/Icons";
import CommonModal from "../../../Modals/CommonModal";
import Style from "./LiveProjectsCard.module.scss";
import { useNavigate } from "react-router-dom";

const LiveProjectsCard = ({
  projectData,
  project,
  client,
  location,
  mobile,
  events,
  email,
  date,
}) => {
  const [eventsInfoModal, setEventsInfoModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <tr className={Style.LiveProjectsCard}>
        <td data-label="Project Name">
          <span>{project}</span>
        </td>
        <td data-label="Client Name">
          <span>{client}</span>
        </td>
        <td data-label="Location">
          <span>{location}</span>
        </td>
        <td data-label="Mobile number">
          <a href={`tel: ${mobile}`}>{mobile}</a>
        </td>
        <td data-label="Events">
          <Dropdown
            className={`${Style.LiveProjectsCard__filter_dropdown} custom-dropdown`}
          >
            <Dropdown.Toggle
              className="btn btn-border-grey btn-sm"
              variant="text"
            >
              {events?.length} Events
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {events?.map((data, index) => {
                return (
                  <>
                    <Dropdown.Item
                      onClick={() => {
                        navigate(
                          `/dashboard/project/${projectData.id}/folders/${data.id}`
                        );
                      }}
                    >
                      {data.name}
                      {index !== events?.length - 1 && ","}
                    </Dropdown.Item>
                  </>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>
          <button class="text-btn" onClick={() => setEventsInfoModal(true)}>
            <Icon color={"currentColor"} size={16} icon={"info-icon"} />
          </button>
        </td>
      </tr>

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={eventsInfoModal}
        setShowModal={() => {
          setEventsInfoModal(false);
        }}
        centered={true}
        className={"create-project-modal"}
        title={<h4>Project info</h4>}
        content={
          <>
            <div className={`row ${Style.LiveProjectsCard__modal__row}`}>
              <div className="col-sm-6">
                <h6 className={Style.LiveProjectsCard__modal_label}>
                  Project name
                </h6>
                <p className={Style.LiveProjectsCard__modal_val}>
                  {projectData.name}
                </p>
              </div>
              <div className="col-sm-6">
                <h6 className={Style.LiveProjectsCard__modal_label}>
                  Client name
                </h6>
                <p className={Style.LiveProjectsCard__modal_val}>
                  {projectData?.get_clients?.[0]?.["name"]}
                </p>
              </div>
              {/* {email && (<>
              <div className="col-sm-6">
                <h6 className={Style.LiveProjectsCard__modal_label}>Email address</h6>
                <p className={Style.LiveProjectsCard__modal_val}><a href={`mailto: ${email}`}>{projectData?.get_clients?.[0]?.["email"]}</a></p>
              </div>
              </>)} */}
              {projectData?.get_clients?.[0]?.["mobile"] && (
                <>
                  <div className="col-sm-6">
                    <h6 className={Style.LiveProjectsCard__modal_label}>
                      Mobile number
                    </h6>
                    <p className={Style.LiveProjectsCard__modal_val}>
                      <a
                        href={`tel: ${projectData?.get_clients?.[0]?.["mobile"]}`}
                      >
                        {projectData?.get_clients?.[0]?.["mobile"]}
                      </a>
                    </p>
                  </div>
                </>
              )}
              {projectData?.location_name && (
                <>
                  <div className="col-sm-6">
                    <h6 className={Style.LiveProjectsCard__modal_label}>
                      Location
                    </h6>
                    <p className={Style.LiveProjectsCard__modal_val}>
                      {projectData?.location_name}
                    </p>
                  </div>
                </>
              )}
              {projectData?.created_at_formatted_date && (
                <>
                  <div className="col-sm-6">
                    <h6 className={Style.LiveProjectsCard__modal_label}>
                      Date
                    </h6>
                    <p className={Style.LiveProjectsCard__modal_val}>
                      {projectData?.created_at_formatted_date}
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        }
        footer={<></>}
      />
    </>
  );
};

export default LiveProjectsCard;
