import React, { useState, useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import Assets from "../../Layouts/Assets";
import Style from "./ProjectGallery.module.scss";
import { clearFPAUploadToken } from "../../../store/slices/Gallery/galleryFPAupload";
import { useDispatch } from "react-redux";
import { clearGallery,currentTabHandler } from "../../../store/slices/Gallery/gallerySlice";
const ProjectGallery = () => {
  /*************************************************
   * CLEARING FPA UPLOAD TOKEN TO PREVENT CONFLICT *
   *************************************************/

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(currentTabHandler("all"));
      dispatch(clearGallery());
      dispatch(clearFPAUploadToken());
    };
  }, []);

  return (
    <div className={Style.ProjectGallery}>
      <Outlet />
    </div>
  );
};

export default ProjectGallery;
