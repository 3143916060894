import React from 'react'
import { Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CommonLayout from '../../components/Layouts/CommonLayout'
import MainRoutes from '../../utils/RoutesDetails'

const TOCPage = () => {
    return (
        <CommonLayout style={{ padding: '80px 0' }}>
            <Container>
                <div className={`section-title-block center mb-5`}>
                    <h2>TOC</h2>
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th style={{ width: '150px' }}>#</th>
                            <th>Page</th>
                            <th style={{ width: '200px' }}>Route</th>
                            <th style={{ width: '120px' }}>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            MainRoutes?.map((route, index) => {
                                return (
                                    <>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td className='fz-15 font-medium'>{route.name}</td>
                                            <td className='fz-15 font-medium'>{route.path}</td>
                                            <td>
                                                <Link to={`${route.path}`} target={'_blank'} className='btn btn-primary btn-sm fz-14'>
                                                    Click Here
                                                </Link>
                                            </td>
                                        </tr>
                                        {
                                            route?.Children?.map((childRoute, childIndex) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className='fz-14' style={{ paddingLeft: '20px' }}>{index + 1}.{childIndex + 1}</td>
                                                            <td className='fz-15 font-medium'>{childRoute.name}</td>
                                                            <td className='fz-15 font-medium'>{`${route.path}${childRoute.path ? '/' : ''}${childRoute.path}`}</td>
                                                            <td>
                                                                <Link to={`${route.path}${childRoute.path ? '/' : ''}${childRoute.path}`} target={'_blank'} className='btn btn-primary btn-sm fz-14'>
                                                                    Click Here
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                        {
                                                            childRoute?.grandChildren?.map((grandChildRoute, grandChildIndex) => {
                                                                return (
                                                                    <tr>
                                                                        <td className='fz-14' style={{ paddingLeft: '20px' }}>{index + 1}.{childIndex + 1}.{grandChildIndex + 1}</td>
                                                                        <td className='fz-15 font-medium'>{grandChildRoute.name}</td>
                                                                        <td className='fz-15 font-medium'>{`${route.path}${childRoute.path ? '/' : ''}${childRoute.path}${grandChildRoute.path ? '/' : ''}${grandChildRoute.path}`}</td>
                                                                        <td>
                                                                            <Link to={`${route.path}${childRoute.path ? '/' : ''}${childRoute.path}${grandChildRoute.path ? '/' : ''}${grandChildRoute.path}`} target={'_blank'} className='btn btn-primary btn-sm fz-14'>
                                                                                Click Here
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Container>
        </CommonLayout>
    )
}

export default TOCPage