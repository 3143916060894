import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDimensions } from "../../../Logic/Dimensions";
import CommonModal from "../../Modals/CommonModal";
import LiveProjects from "./LiveProjects";
import StaffInformation from "./StaffInformation";
import StatusCard from "./StatusCard";
import Style from "./StudioDashboard.module.scss";
import StudioDetailsCard from "./StudioDetailsCard";
import useDashboardContractStaffs from "./useDashboardContactStaffs";
import useDashboardCount from "./useDashboardCount";
import useDashboardLiveProjects from "./useDashboardLiveProjects";
import usePermanentStaffList from "./usePermanentStaffList";

const StudioDashboard = () => {
  const windowDimensions = useDimensions();
  const { liveProjectsData } = useDashboardLiveProjects();
  const { projectstatus, handleSetCurrentTabForProject } = useDashboardCount();
  const {
    contractStaffList,
    permanentStaffSort,
    contractStaffSort,
    countStatus,
    statusLiveProject,
    statusPermanentStaffList,
    statusContractStaffList,
  } = useSelector((state) => state.dashborad);

  const { userProfileData } = useSelector((state) => state.userData);

  const {
    permanentStaffData,
    viewAllButtonPermanant,
    handletoggleAssignedProjects,
    handlesetAssignedProjects,
    handleUpdatePermanentStaffSort,
  } = usePermanentStaffList();

  const {
    contractStaffData,
    viewAllButtonContract,
    handleUpdateContractStaffSort,
  } = useDashboardContractStaffs();

  return (
    <>
      <div className={Style.StudioDashboard_container}>
        <div className={Style.Title__backButton}>
          <h1 className={Style.StudioDashboard__title}>
            Welcome {userProfileData.name},
          </h1>
          <p className={Style.StudioDashboard__subtitle}>
            Lorem ipsum dolor sit amet, consetetur ipsum dolor sit amet.
          </p>
        </div>
        <Row className={"g-3"}>
          <Col xl={windowDimensions.width > 1500 ? "6" : "12"}>
            <StudioDetailsCard />
          </Col>
          <Col xl={windowDimensions.width > 1500 ? "6" : "12"}>
            <Row className={`g-3 ${Style.StudioDashboard_status_card_row}`}>
              {projectstatus?.map((data, index) => {
                return (
                  <>
                    <Col md="6">
                      <StatusCard
                        title={data?.title}
                        value={data?.value}
                        link={data?.link}
                        type={data?.type}
                        className={
                          countStatus &&
                          countStatus !== "succeed" &&
                          "shimmer-loader"
                        }
                        tab={data.tab}
                        customAction={handleSetCurrentTabForProject}
                      />
                    </Col>
                  </>
                );
              })}
            </Row>
          </Col>
          {liveProjectsData.data.length > 0 && (
            <Col lg="12">
              <LiveProjects
                LiveProjectsViewData={liveProjectsData}
                className={
                  statusLiveProject &&
                  statusLiveProject !== "succeed" &&
                  "shimmer-loader"
                }
              />
            </Col>
          )}

          <Col lg="12">
            <StaffInformation
              staffViewData={permanentStaffData}
              viewAllButton={viewAllButtonPermanant}
              handletoggleAssignedProjects={handletoggleAssignedProjects}
              handlesetAssignedProjects={handlesetAssignedProjects}
              handleSortUpdate={handleUpdatePermanentStaffSort}
              selectedSortOption={permanentStaffSort}
              className={
                statusPermanentStaffList &&
                statusPermanentStaffList !== "succeed" &&
                "shimmer-loader"
              }
              preSelectStaffType={2}
            />
          </Col>

          <Col lg="12">
            <StaffInformation
              staffViewData={contractStaffData}
              viewAllButton={viewAllButtonContract}
              handletoggleAssignedProjects={handletoggleAssignedProjects}
              handlesetAssignedProjects={handlesetAssignedProjects}
              handleSortUpdate={handleUpdateContractStaffSort}
              selectedSortOption={contractStaffSort}
              className={
                statusContractStaffList &&
                statusContractStaffList !== "succeed" &&
                "shimmer-loader"
              }
              preSelectStaffType={1}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StudioDashboard;
