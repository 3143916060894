import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadLogo,
  uploadWaterMarkLogo,
} from "../../store/slices/GetStarted/getStartedSlice";
import { updateHeaderState } from "../../store/slices/GetStarted/userBasicDataSlice";
import { authModalHandler } from "../../store/slices/Preference/studioDetailstSlice";
import UploadImageCrop from "../useUploadImageCrop";

var imageObj = "";

export const useMiniFileUploadModal = (
  setShowChangeLogoModal,
  setStepsData,
  stepsData,
  seterrorMessage,
  seterrorMessageLogo
) => {
  const dispatch = useDispatch();

  const [imageChange, setImageChange] = useState(null);
  const [uploadtype, setUploadtype] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [ImageSrc, SetImageSrc] = useState(null);
  const [loader, setLoader] = useState(false);
  const [currentValue, setCurrentValue] = useState(100);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [error, SetError] = useState("");

  const [cropselection, SetcropSelection] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [aspect, SetAspect] = useState(1 / 1);
  let [cordX, cordY] = [50, 50];

  const imgRef = useRef(null);

  const useDebounce = function (value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  };

  const debouncedOpacityValue = useDebounce(currentValue, 400);
  const debouncedCropValue = useDebounce(cropselection, 400);

  useEffect(() => {
    var callbackUrl = (url) => {
      SetImageSrc(url);
    };
    imageObj = new UploadImageCrop(selectedImage, callbackUrl);

    imageObj.thubnailObj = imgRef;
    window.imageObj = imageObj;

    // setCrop({});
  }, [selectedImage]);

  useEffect(() => {
    if (cropselection != false) {
      imageObj.setCropConfig(cropselection);
      imageObj.getCroppedImage().then(() => { });
    }
  }, [debouncedCropValue]);

  useEffect(() => {
    imageObj.createCanvas(0.01 * currentValue);
    imageObj.handleCallback();
  }, [debouncedOpacityValue]);

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      let fileSize = e.dataTransfer.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
        SetError(
          "This file is too large to upload. maximum supported file size is 2MB"
        );
        setTimeout(() => {
          SetError("");
        }, 4000);
      } else {
        setImageChange(e.dataTransfer.files[0]);
        setSelectedImage(e.dataTransfer.files[0]);
      }
    }
  };

  const handleImageChange = async (e) => {
    if (e.target.files.length > 0) {
      let fileSize = e.target.files[0].size / 1024 / 1024;
      if (fileSize > 2) {
        SetError(
          "This file is too large to upload. maximum supported file size is 2MB"
        );
        setTimeout(() => {
          SetError("");
        }, 4000);
      } else {
        setImageChange(e.target.files[0]);
        setSelectedImage(e.target.files[0]);
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onSubmitUploadHandler = async (type) => {
    imageObj.getCroppedImage().then((data) => { });

    const ConvertFilePromise = new Promise((resolve, reject) => {
      imageObj.getImageBlob().then((file) => {
        resolve(blobToFile(file, selectedImage.name));
      });
    });
    setLoader(true);

    if (type == "logo") {
      ConvertFilePromise.then((cropedFile) => {
        let data = new FormData();

        if (cropselection === false) {
          data.append("image", selectedImage);
        } else {
          data.append("image", cropedFile);
        }

        dispatch(uploadLogo(data))
          .unwrap()
          .then((res) => {
            let uploadlogo = res.data.result;
            setShowChangeLogoModal({});
            dispatch(updateHeaderState(uploadlogo?.logo_url));
            let newdata = [...stepsData];
            newdata[0].img = uploadlogo?.logo_url;
            setSelectedImage(null);
            setLoader(false);

            seterrorMessageLogo(
              res?.data?.validation?.errors?.image?.[1] ==
              "The image must be a file of type: jpeg, png, jpg, svg."
            );
            setTimeout(() => {
              seterrorMessageLogo(false);
            }, 2000);
          });
      });
    } else {
      ConvertFilePromise.then((cropedFile) => {
        let data = new FormData();

        // if (cropselection === false) {
        //   data.append("image", selectedImage);
        // } else {
        data.append("image", cropedFile);
        // }

        dispatch(uploadWaterMarkLogo(data))
          .unwrap()
          .then((res) => {
            let waterMark = res.data.result;
            setShowChangeLogoModal({});
            let newdata = [...stepsData];
            newdata[1].img = waterMark?.water_mark_url;
            setSelectedImage(null);
            setLoader(false);

            seterrorMessage(
              res.data?.validation?.errors?.image?.[1] ==
              "The image must be a file of type: jpeg, png, jpg."
            );

            setTimeout(() => {
              seterrorMessage(false);
            }, 2000);
          });
      });
    }
  };

  return {
    imageChange,
    selectedImage,
    setImageChange,
    handleImageChange,
    handleDragOver,
    handleDragOut,
    handleDrop,
    onSubmitUploadHandler,
    loader,
    setSelectedImage,
    crop,
    setCrop,
    cropselection,
    SetcropSelection,
    aspect,
    SetAspect,
    cordX,
    cordY,
    imgRef,
    ImageSrc,
    currentValue,
    setCurrentValue,
    setUploadtype,
    error,
  };
};
