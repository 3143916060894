import React from 'react'
import CommonLayout from '../../components/Layouts/CommonLayout'
import ProjectPlans from '../../components/ProjectPlans'

const ProjectPlansPage = () => {
    return (
        // <CommonLayout noFooter>
            <ProjectPlans />
        // </CommonLayout>
    )
}

export default ProjectPlansPage