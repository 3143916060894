import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../Logic/GetStarted/useThemes";
import { useStaff } from "../../../Logic/Preference/useStaff";
import { useChangePassword } from "../../../Logic/Preference/useChangePassword";
import { useThemeSelect } from "../../../Logic/Preference/useThemeSelect";
import PreferenceCard from "../PreferenceCard";
import AddStaff from "./Components/AddStaff";
import ChangePassword from "./Components/ChangePassword";
import Style from "./PreferenceList.module.scss";

const PreferenceList = () => {
  const navigate = useNavigate();

  const { themeData } = useThemes();

  const { selectTheme, dropdownThemes } = useThemeSelect();

  const {
    openChangePasswordModal,
    setOpenChangePasswordModal,
    passwordValidationSchema,
    changePasswordSubmit,
    samePasswordError
  } = useChangePassword();

  const {
    showAddStaffModal,
    setShowAddStaffModal,
    staffValidationSchema,
    staffSubmit,
  } = useStaff();

  return (
    <div className={Style.PreferenceList}>
      <PreferenceCard
        title="Studio account"
        description="Create your account here and add relevant information"
        actionType="blank"
        onClick={() => navigate("/dashboard/preference/studio")}
        hoverEffect={true}
      />
      <PreferenceCard
        title="Background theme"
        description="Change your background theme according to your preferences"
        actionType="dropdown"
        actions={themeData}
        dropdownCurrent={dropdownThemes}
        handleClick={selectTheme}
        defaultAction="Default"
      />
      <PreferenceCard
        title="Change password"
        description="Click here to change your password and enter your new password"
        actionType="link"
        actions="Change"
        onClick={() => setOpenChangePasswordModal(true)}
      />
      {/* <PreferenceCard
        title="Subscriptions"
        description="Current plan ₹250 per month, expires on 03 Mar 2023"
        actionType="button"
        actions="Upgrade"
      /> */}
      
      {/* <PreferenceCard
        title="Add staff"
        description="To add staff click here and view further information"
        actionType="custom"
        className={Style.add_staff_modal_content}
        actions={
          <ul>
            <li>
              <button
                className="btn btn-border-grey"
                onClick={() => setShowAddStaffModal(true)}
              >
                Add
              </button>
            </li>
            <li>
              <button
                className="btn btn-border-grey"
                onClick={() => navigate("/dashboard/preference/staffs")}
              >
                View
              </button>
            </li>
          </ul>
        }
      /> */}

      <ChangePassword
        openChangePasswordModal={openChangePasswordModal}
        setOpenChangePasswordModal={setOpenChangePasswordModal}
        passwordValidationSchema={passwordValidationSchema}
        changePasswordSubmit={changePasswordSubmit}
        samePasswordError={samePasswordError}
      />

      <AddStaff
        showAddStaffModal={showAddStaffModal}
        setShowAddStaffModal={setShowAddStaffModal}
        staffValidationSchema={staffValidationSchema}
        staffSubmit={staffSubmit}
      />
    </div>
  );
};

export default PreferenceList;
