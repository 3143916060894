import { useEffect, useState, useRef } from "react";
import {
  currentTabHandler,
  uploadModalAction,
} from "../store/slices/Gallery/gallerySlice";

import { uploadFPAModalAction } from "../store/slices/Gallery/galleryFPAupload";

import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getFolderDetails,
  openPreSelectionhandler,
  closePreSelect,
  selectAllImages,
  unSelectAllImages,
  selectSingleImage,
  deSelectSingleImage,
  deleteGalleryImages,
  closeFilterActions,
  openDeleteImagesPopup,
  deleteActionSuccess,
  orderChangeGallery,
  updateImageCountGallery,
} from "../store/slices/Gallery/gallerySlice";
import { getCurrentPageDetails } from "../store/slices/Project/ProjectSingle";

export const useGalleryTab = () => {
  const selectedElement = useRef();
  const suggestedElement = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { projectID, folderID } = useParams();
  const [activeTab, setActiveTab] = useState("all");
  const [isChecked, setIsChecked] = useState(false);
  const [activeTabLeft, setActiveTabLeft] = useState("");
  const [activeTabWidth, setActiveTabWidth] = useState("");

  const { currentFolderDetails, sections, currentTab, status } = useSelector(
    (state) => state.gallery
  );

  const { getCurrentProjectDetails } = useSelector(
    (state) => state.projectSinglePage
  );
  const { currentPageDetails } = getCurrentProjectDetails;

  const handleClickTab = (e, tab) => {
    setActiveTab(tab);
    setActiveTabLeft(e.target.offsetLeft);
    setActiveTabWidth(e.target.offsetWidth);
    dispatch(currentTabHandler(tab));
    if (tab === "all") {
      navigate(`${projectID}/folders/${folderID}`);
    } else if (tab === "selected") {
      navigate(`${projectID}/folders/${folderID}/selected`);
    } else if (tab === "suggested") {
      navigate(`${projectID}/folders/${folderID}/suggested`);
    }
  };

  useEffect(() => {
    handleGetFolderDetails();

    const data = {
      id: projectID,
    };
    dispatch(getCurrentPageDetails(data));
  }, []);

  const handleGetFolderDetails = () => {
    let obj = {
      id: folderID,
      url: "/album/get-album",
    };
    dispatch(getFolderDetails(obj));
  };

  useEffect(() => {
    if (status === "succeeded") {
      if (window.location.pathname.indexOf("selected") != -1) {
        setTimeout(() => {
          setActiveTab("selected");
          selectedElement.current.click();
        }, 400);
      }
      if (window.location.pathname.indexOf("suggested") != -1) {
        setTimeout(() => {
          setActiveTab("suggested");
          suggestedElement.current.click();
        }, 400);
      }
    }
  }, [status]);

  useEffect(() => {
    let activeTabSelect = document.querySelectorAll(".tabActive");
    setActiveTabLeft(activeTabSelect[0]?.offsetLeft);
    setActiveTabWidth(activeTabSelect[0]?.offsetWidth);
  }, []);

  const switchActiveTab = (e, tab) => {
    setActiveTab(tab);
    setActiveTabLeft(e.target.offsetLeft);
    setActiveTabWidth(e.target.offsetWidth);
  };

  const handlePreSelection = () => {
    dispatch(openPreSelectionhandler());
  };

  const handleclosePreSelect = () => {
    dispatch(closePreSelect());
    dispatch(unSelectAllImages());
  };

  const handleSelectAllImages = () => {
    let imagesIDs = sections[currentTab]?.data.map((item) => item.id);
    dispatch(selectAllImages(imagesIDs));
  };

  const handledeSelectAllImages = () => {
    dispatch(unSelectAllImages());
  };

  const uploadModal = (data) => {
    dispatch(uploadModalAction(data));
  };

  const uploadFPAModal = (data) => {
    dispatch(uploadFPAModalAction(data));
  };

  const handleSelectImage = (ImageID) => {
    if (
      sections[currentTab]?.selectedImages?.includes(ImageID) === false ||
      sections[currentTab]?.selectedImages == undefined
    ) {
      let previouslySelectedItems =
        sections[currentTab]?.selectedImages === undefined
          ? []
          : sections[currentTab]?.selectedImages;

      dispatch(selectSingleImage([...previouslySelectedItems, ...[ImageID]]));
    } else {
      /*************************************
       * REMOVEING DE-SELETED ITEM FROM ARRAY *
       *************************************/
      let newArrayIds = sections[currentTab]?.selectedImages?.filter((item) => {
        return item !== ImageID;
      });
      dispatch(deSelectSingleImage(newArrayIds));
    }
  };

  const handleDeleteSelectedImages = () => {
    let formdata = new FormData();
    let count = 0;
    if (sections[currentTab]?.selectedImages?.length > 0) {
      sections[currentTab]?.selectedImages?.forEach((ImageID) => {
        count++;
        formdata.append("image_id[]", ImageID);
      });
    }

    if (
      sections[currentTab]?.selectedImages?.length ===
      sections[currentTab]?.data?.length
    ) {
      formdata.append("action", "all");
    }

    formdata.append("album_id", folderID);

    let obj = {
      formdata: formdata,
      url: "/album/delete-image",
    };

    dispatch(deleteGalleryImages(obj))
      .unwrap()
      .then((res) => {
        if (res.data.success === true) {
          let updatedGallery = sections[currentTab]["data"]?.filter((items) => {
            return !sections[currentTab]?.selectedImages.includes(items.id);
          });
          dispatch(
            updateImageCountGallery(
              parseInt(currentFolderDetails.images_count) - sections[currentTab]?.selectedImages?.length
            )
          );
          dispatch(deleteActionSuccess(updatedGallery));
        }
      });
  };

  const openDeletePopup = (state) => {
    dispatch(openDeleteImagesPopup(state));
  };

  const handleOrderChange = (order) => {
    dispatch(orderChangeGallery(order));
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return false;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return {
    handleClickTab,
    activeTab,
    setActiveTab,
    isChecked,
    setIsChecked,
    activeTabLeft,
    setActiveTabLeft,
    activeTabWidth,
    setActiveTabWidth,
    switchActiveTab,
    selectedElement,
    suggestedElement,
    currentFolderDetails,
    handlePreSelection,
    handleclosePreSelect,
    handleSelectAllImages,
    handledeSelectAllImages,
    uploadModal,
    uploadFPAModal,
    handleSelectImage,
    handleDeleteSelectedImages,
    openDeletePopup,
    handleOrderChange,
    capitalizeFirstLetter,
  };
};
