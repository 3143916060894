import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAnimations } from "../../Logic/Animations";
import useAuthChecker from "../../Logic/Auth/useAuthChecker";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";
import Style from "./PlansCard.module.scss";

const PlansCard = ({ data, buttonText, type, buttonCallback }) => {
  const { slideTopRef } = useAnimations();
  const [loader, setLoader] = useState(false);
  const { clientID, projectID } = useParams();

  const { userProfileData } = useSelector((state) => state.userData);
  const navigate = useNavigate();
  const { isAuthCompleted } = useAuthChecker();

  const isPaymentCompleted = () => {
    if (isAuthCompleted === true) {
      if (userProfileData.plan_payment_status === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div
      className={`${Style.PlansCard} ${
        type === "main"
          ? Style.PlansCard__main
          : type === "client"
          ? Style.PlansCard__client
          : ""
      } plans-card`}
    >
      {type === "main" ? (
        <>
          <div className={Style.PlansCard__top}>
            <div className={`${Style.plan_duration} plan-duration`}>
              {data.interval.duration}
              {data?.recommended ? (
                <span className={Style.plan_recommended_label}>
                  Recommended
                </span>
              ) : (
                ""
              )}
            </div>
            <h4 className={`${Style.plan_amount} plan-amount`}>
              <span className={Style.plan_amount_value}>
                {data?.currency?.name === "INR" ? "₹" : "$"} {data?.price}
              </span>{" "}
              /{" "}
              {data?.duration == "Monthly"
                ? "Month"
                : data?.duration === "Quarterly"
                ? "Quarter"
                : "Year"}
            </h4>
            <p className={Style.plan_description}>{data?.description}</p>
            <ul className={`${Style.plan_features} plan-features`}>
              {data?.feature_plan?.map((data, index) => {
                return <li key={index}>{data.name}</li>;
              })}
            </ul>
          </div>
          <div className={`${Style.PlansCard__button} plans-card-button`}>
            {/* <Link
              className="btn btn-border-primary"
              // onClick={() => handleClick()
              to={"/get-started"}
            >
              {buttonText}
            </Link> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                setLoader(true);
                setTimeout(() => {
                  buttonCallback(data);
                  setLoader(false);
                }, 800);
              }}
            >
              {loader ? (
                <InfiniteDotLoader />
              ) : isPaymentCompleted() ? (
                "Done"
              ) : (
                buttonText
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={Style.PlansCard__head}>
            <h3 className={Style.PlansCard__headTitle}>{data.name}</h3>
            {/* <p className={Style.PlansCard__headDescription}>{data.description}</p> */}
          </div>
          <div className={Style.PlansCard__contents}>
            <div className={Style.PlansCard__top}>
              <h4 className={`${Style.plan_amount} plan-amount`}>
                <span className={Style.plan_amount_value}>
                  {data?.currency?.name === "INR" ? "₹" : "$"} {data?.price}
                </span>{" "}
                /{" "}
                {data?.duration == "Monthly"
                  ? "Month"
                  : data?.duration === "Quarterly"
                  ? "Quarter"
                  : "Year"}
              </h4>
              <p className={Style.plan_description}>{data?.description}</p>
              <div className={Style.plan_features_label}>{data.label}</div>
              <ul className={`${Style.plan_features} plan-features`}>
                {data?.feature_plan?.map((data, index) => {
                  return <li key={index}>{data.name}</li>;
                })}
              </ul>
            </div>
            <div className={Style.PlansCard__button}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setLoader(true);
                  setTimeout(() => {
                    buttonCallback({
                      ...data,
                      ...{ clientID: clientID, projectID: projectID },
                    });
                    setLoader(false);
                  }, 800);
                }}
              >
                {loader ? <InfiniteDotLoader /> : buttonText}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PlansCard;
