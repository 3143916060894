import { Link } from "react-router-dom";
import Assets from "../../../Layouts/Assets";
import Icon from "../../../Layouts/Icons";
import Style from "./StatusCard.module.scss";
import { useNavigate } from "react-router-dom";

const StatusCard = ({
  title,
  value,
  link,
  type,
  action,
  tab,
  customAction,
  className
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`${Style.StatusCard} ${className}`}>
        <div className={Style.StatusCard_image_wrap}>
          <figure
            className={`
            ${Style.StatusCard_figure} 
            ${
              type === "active_projects"
                ? Style.StatusCard_active_projects
                : type === "completed_projects"
                ? Style.StatusCard_completed_projects
                : type === "permanent_staff"
                ? Style.StatusCard_permanent_staff
                : type === "contract_staff"
                ? Style.StatusCard_contract_staff
                : ""
            } 
          `}
          >
            <img
              src={
                type === "active_projects"
                  ? Assets.active_projects
                  : type === "completed_projects"
                  ? Assets.completed_projects
                  : type === "permanent_staff"
                  ? Assets.permanent_staff
                  : type === "contract_staff"
                  ? Assets.contract_staff
                  : ""
              }
            />
          </figure>
        </div>
        <div className={Style.StatusCard_details}>
          <h6>{title}</h6>
          <div className={Style.StatusCard_status_wrap}>
            <span className={Style.StatusCard_status}>{value}</span>
          </div>
          <div className={Style.StatusCard_link}>
            <a
              href="javascript:void(0)"
              to={link}
              className="btn btn-link px-md-4 footer-link"
              onClick={() => {
                if (tab != "") {
                  customAction({
                    tab: tab,
                    callback: () => {
                      navigate(link);
                    },
                  });
                } else {
                  navigate(link);
                }
              }}
            >
              <span>
                View All{" "}
                <Icon color={"currentColor"} size={14} icon={"arrow-right"} />
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusCard;
