import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import {
  storeCurrentPageDetails,
  getCurrentPageDetails,
  getStaffDetails,
  getEventCategory,
  submitCreateNewFolder,
  getFoldersByProjectID,
  toggleEditFolder,
  getFolderDetailsByID,
  getEventNames,
  customLoadMore,
  togglePreSelect,
  selectAllFolders,
  unSelectAllFolders,
  singleSelectionFolder,
  selectionCancel,
  deleteFolders,
  deleteSingleFolder,
  selectItemToBeDelete,
  cancelDeleteAction,
  deleteMultipleStatus,
  changeFolderOrder,
  eventInfoPopup,
  getEventInfoPopupDetails,
  shareInfoPopup,
  getEventShareInfoPopupDetails,
  paymentAlert,
  clearSingleProjectData,
} from "../store/slices/Project/ProjectSingle";

import { statusChangeLinkShare } from "../store/slices/Gallery/gallerySlice";
import { ToastContainer, toast } from "react-toastify";
export const useProjectSingle = () => {
  const player = useRef();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { projectID } = useParams();
  const [openEditFolderModal, setOpenEditFolderModal] = useState(false);
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
  const [addNewProjectPopup, setAddNewProjectPopup] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEventInfoModal, setOpenEventInfoModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { getCurrentProjectDetails, allFolders, createNewFolder, status } =
    useSelector((state) => state.projectSinglePage);
  const { currentPageDetails } = getCurrentProjectDetails;

  useEffect(() => {
    if (currentPageDetails != undefined && projectID != undefined) {
      if (Object.keys(currentPageDetails).length === 0) {
        const data = {
          id: projectID,
        };
        dispatch(getCurrentPageDetails(data));
      }
    }
  }, [currentPageDetails]);

  useEffect(() => {
    if (openEditFolderModal === false) {
      if (allFolders?.editFolder?.status === true) {
        handleToggleEditFolder({ status: false, id: "" });
      }
    }
  }, [openEditFolderModal]);

  const handleProjectSinglePage = (data) => {
    dispatch(storeCurrentPageDetails(data));
    navigate("/dashboard/project/" + data.id);
  };

  const handleOpenGalleryPage = (data) => {
    //dispatch(storeCurrentPageDetails(data));
    navigate("folders/" + data.id);
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return "";
    } else {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    }
  };

  const getAllStaffs = () => {
    return dispatch(getStaffDetails());
  };

  const getAllEventCategories = () => {
    return dispatch(getEventCategory());
  };

  const convert = (date) => {
    const d = Date.parse(date);
    const date_obj = new Date(d);
    return `${date_obj.getFullYear()}-${date_obj.toLocaleString("default", {
      month: "2-digit",
    })}-${date_obj.toLocaleString("default", { day: "2-digit" })}`;
  };

  const handleSubmitCreateNewFolder = (data, resetForm) => {
    try {
      const formData = {
        project_id: projectID,
        album_name_id: "",
        event_category_id: data.eventCategory,
        staff_id: data?.staff?.split(","),
        name: data?.eventName,
        location_name: data?.location,
        date: convert(data?.date),
      };
      if (data.customName != "" && data.customName != undefined) {
        formData.album_name_id = "";
        formData.name = data.customName;
      } else if (Number.isInteger(data.eventName)) {
        formData.album_name_id = data.eventName;
        formData.name = "";
      }

      if (allFolders.editFolder.status) {
        formData.id = allFolders.editFolder.id;
      }

      dispatch(submitCreateNewFolder(formData))
        .unwrap()
        .then((res) => {
          if (res.data.success === true) {
            resetForm();
            setOpenEditFolderModal(false);
            setAddNewProjectPopup(false);
            if (res.data.result !== 1) {
              navigate(
                `/dashboard/project/${res.data.result.project_id}/folders/${res.data.result.id}`
              );
            }
          } else {
            alert("Something went wrong!");
          }
        });
    } catch (error) {}
  };

  const handleGetAllProjects = (order = false) => {
    const data = {
      page: "1",
      per_page: 20,
      sort: order == false ? allFolders.filter.order : order,
      project_id: projectID,
    };
    dispatch(getFoldersByProjectID(data));
  };

  const loadMore = () => {
    if (allFolders?.list?.last_page >= allFolders?.list?.current_page) {
      const data = {
        page: allFolders?.list?.current_page + 1,
        per_page: 20,
        sort: allFolders.filter.order,
        project_id: projectID,
      };

      dispatch(customLoadMore(data));
    }
  };

  const handleToggleEditFolder = (status) => {
    let promiseArray = [];

    if (createNewFolder?.staffList?.length === 0) {
      promiseArray.push(getAllStaffs());
    }

    if (createNewFolder?.eventCategories.length === 0) {
      promiseArray.push(getAllEventCategories());
    }

    if (createNewFolder?.events.length === 0) {
      promiseArray.push(getAllEvents());
    }

    Promise.all(promiseArray).then((res) => {
      dispatch(toggleEditFolder(status));
      dispatch(getFolderDetailsByID(status))
        .unwrap()
        .then((res) => {
          if (res.data.success === true) {
            setOpenEditFolderModal(true);
          }
        });
    });
  };

  const getAllEvents = () => {
    return dispatch(getEventNames());
  };

  const handleTogglePreSelect = (state) => {
    dispatch(togglePreSelect(state));
  };

  const handleSelectAllFolders = () => {
    if (allFolders?.list?.data?.length > 0) {
      const foldersIDs = [];
      allFolders?.list?.data?.forEach((folder) => {
        foldersIDs.push(folder.id);
      });
      dispatch(selectAllFolders(foldersIDs));
    }
  };

  const handleUnSelectAllFolders = () => {
    dispatch(unSelectAllFolders([]));
  };

  const handleSingleSelectionFolder = (folderID) => {
    dispatch(singleSelectionFolder(folderID));
  };

  const handleCancelSelectionsFolder = (folderID) => {
    dispatch(selectionCancel());
  };

  const handleDeleteFolders = () => {
    if (
      allFolders.filter.preSelect === true &&
      allFolders.filter.selectedItems.length > 0
    ) {
      dispatch(deleteFolders({ id: allFolders.filter.selectedItems }));
    }
  };

  const handleDeleteFoldersPopupToggle = (status) => {
    dispatch(deleteMultipleStatus(status));
  };

  const handleSingleDeleteFolder = () => {
    if (allFolders.deleteSingleFolder.selection) {
      dispatch(deleteSingleFolder({ id: [allFolders.deleteSingleFolder.id] }))
        .unwrap()
        .then((res) => {
          if (!res?.data?.success) {
            alert("Something went wrong!!");
          }
        });
    }
  };

  const handleSelectItemToDelete = (folderID) => {
    if (folderID != undefined && folderID != "") {
      dispatch(selectItemToBeDelete(folderID));
    }
  };

  const handleSelectItemToDeleteCloseButton = () => {
    dispatch(cancelDeleteAction());
  };

  const handleOrderChange = (order) => {
    if (allFolders.filter.order !== order) {
      dispatch(changeFolderOrder(order));
      handleGetAllProjects(order);
    }
  };

  const handleEventPopup = (status) => {
    dispatch(eventInfoPopup(status));
  };

  const handleGetEventPopupData = (folderID) => {
    dispatch(getEventInfoPopupDetails({ id: folderID })).then((res) => {
      if (res.payload.data.success === true) {
        handleEventPopup(true);
      }
    });
  };

  const handleEventShareInfoPopup = (status) => {
    dispatch(shareInfoPopup(status));
  };

  const handlepaymentAlert = (status) => {
    dispatch(paymentAlert(status));
  };

  const handlePaymentPage = (status) => {
    if (
      Number.isInteger(parseInt(currentPageDetails?.client_id)) &&
      Number.isInteger(parseInt(projectID))
    ) {
      navigate(
        `/dashboard/user-plans/${projectID}/${currentPageDetails?.client_id}/`
      );
    } else {
      toast.error("Please add client details to complete the payment!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleGetEventShareInfoPopup = (id = false) => {
    dispatch(
      getEventShareInfoPopupDetails({
        id: id === false ? allFolders.eventInfo.shareInfo.id : id,
      })
    ).then((res) => {
      if (res.payload.data.success === true) {
        handleEventShareInfoPopup(true);
        dispatch(statusChangeLinkShare(1));
      }
    });
  };

  const clearEventPopupData = () => {};

  const isProjectSingle = () => {
    const { href } = window.location;

    if (projectID !== undefined && href.indexOf("project") != -1) {
      return true;
    } else {
      return false;
    }
  };

  const getThumbnails = (data) => {
    if (
      data?.latest_album === undefined ||
      data?.latest_album?.latest_image?.length === 0
    ) {
      return [1, 2, 3, 4];
    } else {
      return data?.latest_album?.latest_image;
    }
  };

  const handleclearSingleProjectData = () => {
    dispatch(clearSingleProjectData());
  };

  return {
    projectID,
    capitalizeFirstLetter,
    handleProjectSinglePage,
    getCurrentProjectDetails,

    openEditFolderModal,
    setOpenEditFolderModal,

    openEditProjectModal,
    setOpenEditProjectModal,

    addNewProjectPopup,
    setAddNewProjectPopup,

    openDeleteModal,
    setOpenDeleteModal,

    getAllStaffs,
    getAllEvents,
    getAllEventCategories,
    handleSubmitCreateNewFolder,
    handleGetAllProjects,
    handleToggleEditFolder,
    openEventInfoModal,
    setOpenEventInfoModal,
    openShareModal,
    setOpenShareModal,

    loadMore,
    handleTogglePreSelect,
    handleSelectAllFolders,
    handleUnSelectAllFolders,
    handleSingleSelectionFolder,
    handleCancelSelectionsFolder,
    handleDeleteFolders,
    handleSingleDeleteFolder,
    handleSelectItemToDelete,
    handleSelectItemToDeleteCloseButton,
    handleSingleDeleteFolder,
    handleDeleteFoldersPopupToggle,
    handleOrderChange,

    handleEventPopup,
    handleGetEventPopupData,
    handleGetEventShareInfoPopup,
    handleEventShareInfoPopup,
    clearEventPopupData,

    handleOpenGalleryPage,
    isProjectSingle,

    handlepaymentAlert,
    handlePaymentPage,
    status,
    currentPageDetails,
    getThumbnails,
    handleclearSingleProjectData,
  };
};
