import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../Logic/Dimensions";
import Assets from "../../Layouts/Assets";
import Icon from "../../Layouts/Icons";
import MiniFileUploadModal from "../../Modals/MiniFileUploadModals";
import Style from "./GetStarted.module.scss";

export const StepCard = ({
  icon,
  title,
  description,
  imageSelect,
  handleClick,
  url,
  type
}) => {
  const windowDimensions = useDimensions();


  return (
    <div className={Style.Get_started_step}>
      {windowDimensions.width >= 768 ? (
        <>
          <div className={Style.Get_started_step_iconwrap}>
            <div className={Style.Get_started_step_icon}>
              <Icon size={50} icon={icon} />
            </div>
          </div>
        </>
      ) : windowDimensions.width < 400 ? (
        <>
          <div className={Style.Get_started_step_mob_title_wrap}>
            <div className={Style.Get_started_step_icon}>
              <Icon size={30} icon={icon} />
            </div>
            <h4 className={Style.Get_started_step_title}>{title}</h4>
          </div>
        </>
      ) : (
        ""
      )}

      <div className={Style.Get_started_step_contentwrap}>
        {windowDimensions.width >= 768 ? (
          <>
            <h4 className={Style.Get_started_step_title}>{title}</h4>
            <p className={Style.Get_started_step_description}>{description}</p>
          </>
        ) : (
          <>
            {windowDimensions.width >= 400 ? (
              <div className={Style.Get_started_step_mob_title_wrap}>
                <div className={Style.Get_started_step_icon}>
                  <Icon size={30} icon={icon} />
                </div>
                <h4 className={Style.Get_started_step_title}>{title}</h4>
              </div>
            ) : (
              ""
            )}
            <p className={Style.Get_started_step_description}>{description}</p>
          </>
        )}
      </div>
      <div className={Style.Get_started_step_inputwrap}>
        <div className={Style.Get_started_step_uploadimg}>
          <figure>
            <img
              src={url ? url : Assets.img_placeholder}
              alt=""
              style={{ maxWidth: `${url ? "100%" : "56px"}` }}
            />
          </figure>
        </div>
        <Form.Label>
          {url ? (
            <span className="btn btn-outline-dark">Change</span>
          ) : (
            <span className="btn btn-success">Upload</span>
          )}

          {!url ? (
            <Form.Control
              // type="file"
              className="d-none"
              onClick={handleClick}
            />
          ) : (
            <button onClick={handleClick}></button>
          )}
        </Form.Label>
      </div>
    </div>
  );
};
