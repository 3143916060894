import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import Icon from "../../../Layouts/Icons";

const ProjectInfoPopup = ({
  openProjectInfoModal,
  setOpenShareModal,
  setOpenProjectInfoModal,
  data,
}) => {

  const [isCopied, setIsCopied] = useState(false);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {

      });
  };

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={openProjectInfoModal}
      setShowModal={setOpenProjectInfoModal}
      centered={true}
      className={"ProjectInfo__modal"}
      title={<h4>Project Info</h4>}
      content={
        data.status !== "loading" ? (
          <>
            <ul className={`${"ProjectInfo"} row`}>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Project Name</h5>
                <span className={"ProjectInfo__content"}>
                  {data?.data?.name}
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Client Name</h5>
                <span className={"ProjectInfo__content"}>
                  {data?.data?.get_clients?.[0]?.name}
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Email Address</h5>
                <span className={"ProjectInfo__content"}>
                  {data?.data?.get_clients?.[0]?.email}
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Mobile Number</h5>
                <span className={"ProjectInfo__content"}>
                  {data?.data?.get_clients?.[0]?.country_code}{data?.data?.get_clients?.[0]?.mobile}
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Location</h5>
                <span className={"ProjectInfo__content"}>
                  {data?.data?.location_name}
                </span>
              </li>
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Date</h5>
                <span className={"ProjectInfo__content"}>
                  {" "}
                  {data?.data?.created_at_formatted_date}
                </span>
              </li>
              {data?.data?.get_clients?.[0]?.sec_email != null && (
                <li className={`${"ProjectInfo__item"} col-lg-6`}>
                  <h5 className={"ProjectInfo__title"}>Additional Email</h5>
                  <span className={"ProjectInfo__content"}>
                    {data?.data?.get_clients?.[0]?.sec_email}
                  </span>
                </li>
              )}

              {data?.data?.get_clients?.[0]?.sec_mobile != null && (
                <li className={`${"ProjectInfo__item"} col-lg-6`}>
                  <h5 className={"ProjectInfo__title"}>Additional Mobile</h5>
                  <span className={"ProjectInfo__content"}>
                    {data?.data?.get_clients?.[0]?.sec_country_code}{data?.data?.get_clients?.[0]?.sec_mobile}
                  </span>
                </li>
              )}

              {data?.data?.get_clients?.[0]?.password != null && (
                <li className={`${"ProjectInfo__item"} col-lg-6`}>
                  <h5 className={"ProjectInfo__title"}>Password</h5>
                  <span className={"ProjectInfo__content"}>
                    {data?.data?.get_clients?.[0]?.password.length > 50
                      ? data?.data?.get_clients?.[0]?.password.substring(
                        0,
                        25
                      ) + "...more"
                      : data?.data?.get_clients?.[0]?.password}
                    <div className="ProjectInfo__copyLink"
                      onClick={() => handleCopyClick(data?.data?.get_clients?.[0]?.password)}
                    >
                      <Icon icon={"icon-link"} size={15}></Icon>
                    </div>
                    {isCopied && <span className={"ProjectInfo__clipBoard"}>copied</span>}
                  </span>

                </li>
              )}
            </ul>
            <div className="modal-footer">
              {/* <button
            className="btn btn-primary"
            onClick={() => {
              setOpenShareModal(true);
              setOpenProjectInfoModal(false);
            }}
          >
            Share
          </button> */}
            </div>
          </>
        ) : (
          <ul>Loading...</ul>
        )
      }
    />
  );
};

export default ProjectInfoPopup;
