import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import StyleGuide from "../../components/StyleGuide";

const StyleGuidePage = () => {
  return (
    <CommonLayout>
      <StyleGuide />
    </CommonLayout>
  );
};

export default StyleGuidePage;
