import React, { useEffect } from "react";
import ChoosePhotoCard from "../../Cards/ChoosePhotoCard";
import Style from "./ChooseThePhotos.module.scss";
import Icon from "../../Layouts/Icons";
import { Container } from "react-bootstrap";
import { useUserFlow } from "../../../Logic/UserFlow/useUserFlow";
import { useSelector } from "react-redux";

const ChooseThePhotos = () => {
  const { getUserFlowData } = useUserFlow();

  const { currentTab, sections, userData } = useSelector(
    (state) => state.userFlow
  );

  useEffect(() => {
    getUserFlowData();
  }, []);

  let mainImage = sections[currentTab]?.data?.[0]?.src?.thumbnail_large;
  let logoUrl = userData?.logo_url;
  let eventName =
    sections[currentTab]?.data?.[0]?.album_image?.[0]?.album?.event_category
      ?.name;
  let eventDate =
    sections[currentTab]?.data?.[0]?.album_image?.[0]?.album?.date;
  let eventLocation =
    sections[currentTab]?.data?.[0]?.album_image?.[0]?.album?.location_name;

  let albumName =
    sections[currentTab]?.data?.[0]?.album_image?.[0]?.album?.name;


  return (
    <>
      <div className={Style.choose_the_photos_wrap}>
        <div
          className={`section-title-block center ${Style.choose_the_photos_header}`}
        >
          <h2 className="h2">Choose the photos</h2>
          <p>
            Choose the photos that you want to showcase in your album. Choose
            carefully, once it done you can’t change.
          </p>
        </div>
        <ChoosePhotoCard
          image={mainImage}
          logo={logoUrl}
          eventName={eventName}
          eventDate={eventDate}
          eventLocation={eventLocation}
          albumName={albumName}
        />
      </div>
      <div className={Style.user_flow_fr_addressbar}>
        <Container className={Style.user_flow_fr_container}>
          <div className={Style.user_flow_fr_website_wrap}>
            <a
              href="https://www.sarithastudio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.sarithastudio.com
            </a>
          </div>
          <div className={Style.user_flow_fr_socialmedia_wrap}>
            <ul className={Style.user_flow_fr_socialmedia_list}>
              <li>
                <a href="#">
                  <Icon color={"#000"} size={16} icon={"icon-fb"} />
                </a>
              </li>
              <li>
                <a href="#">
                  <Icon color={""} size={16} icon={"instagram"} />
                </a>
              </li>
              <li>
                <a href="#">
                  <Icon color={""} size={16} icon={"filled-youtube"} />
                </a>
              </li>
              <li>
                <a href="#">
                  <Icon color={""} size={20} icon={"pinterest"} />
                </a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ChooseThePhotos;
