import React, { useRef, useState } from "react";
import { Button, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../../Logic/Dimensions";
import Icon from "../../../Layouts/Icons";
import AddStaffCard from "../../AddStaffCard";
import AddStaff from "../../PreferenceList/Components/AddStaff";
import Style from "./StaffInformation.module.scss";
import AssignedProjectDetails from "../../../Dashboard/AddStaffCard/AssignedProjectDetails";
import { useStaff } from "../../../../Logic/Preference/useStaff";
import { AddNewFolderModal } from "../../ContentHeader/AddNewFolderModal";
import { useEditEventDetails } from "../../../../Logic/useEditEventDetails";

const StaffInformation = ({
  staffViewData,
  viewAllButton,
  handletoggleAssignedProjects,
  handlesetAssignedProjects,
  handleSortUpdate,
  selectedSortOption,
  className,
  preSelectStaffType,
}) => {
  const imgRootRef = useRef(null);
  const imgItemRef = useRef(null);
  const windowDimensions = useDimensions();
  const [isActiveDropdown, setIsActiveDropdown] = useState("Assigned");
  const {
    showAddStaffModal,
    setShowAddStaffModal,
    staffValidationSchema,
    staffSubmit,
    handleToggleStatusAddStaffPopup,
    statusAddStaffPopup,
    handleUpdateStaffPreSelect,
    designations
  } = useStaff();

  const {
    getAllStaffs,
    getAllEventCategories,
    getAllEvents,
    setOpenEditFolderModal,
    openEditFolderModal,
    handleSubmitCreateNewFolder,
    setAddNewProjectPopup,
    addNewProjectPopup,
    handleToggleEditFolder,
    setProjectID,
  } = useEditEventDetails();

  return (
    <>

      <div className={`${Style.StaffInformation__container} ${className}`}>

        <div className={Style.StaffInformation__titlebar}>
          <div className={`row mb-3`}>
            <div className={`col-10 col-sm-9 my-auto`}>
              <h4 className={`mb-0 ${Style.StaffInformation__title}`}>
                {staffViewData?.title}
              </h4>
            </div>
            <div className={`col-2 col-sm-3 text-end my-auto ps-0`}>
              <button
                className={`btn btn-border-primary ${Style.StaffInformation__addStaff}`}
                onClick={() => {
                  handleToggleStatusAddStaffPopup(true);
                  handleUpdateStaffPreSelect(preSelectStaffType);
                }}
              >
                <Icon
                  color={"currentColor"}
                  size={16}
                  icon={"icon-plus"}
                  className={`${windowDimensions.width >= 768 ? "me-2" : ""}`}
                />
                {windowDimensions.width >= 768 ? "Add staff" : ""}
              </button>
            </div>
          </div>
        </div>



        <div className={Style.StaffInformation__wrap} ref={imgRootRef}>
          <Table className={Style.StaffInformation}>
            <thead className={Style.StaffInformation__header}>
              <tr>
                <th className={Style.StaffInformation__headerItem}>
                  Staff Name
                </th>
                <th className={Style.StaffInformation__headerItem}>
                  Mobile Number
                </th>
                <th className={Style.StaffInformation__headerItem}>Email id</th>
                <th className={Style.StaffInformation__headerItem}>Designation</th>

                <th className={Style.StaffInformation__headerItem}>
                  <Dropdown
                    className={`${Style.StaffInformation__filter_dropdown}`}
                  >
                    <Dropdown.Toggle
                      className="btn btn-border-grey btn-sm"
                      variant="custom-link"
                    >
                      {isActiveDropdown}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={selectedSortOption === "" ? true : false}
                        onClick={() => {
                          handleSortUpdate("");
                          setIsActiveDropdown("All");
                        }}
                      >
                        All
                      </Dropdown.Item>

                      <Dropdown.Item
                        disabled={selectedSortOption === 1 ? true : false}
                        onClick={() => {
                          handleSortUpdate(1);
                          setIsActiveDropdown("Assigned");
                        }}
                      >
                        Assigned
                      </Dropdown.Item>

                      <Dropdown.Item
                        disabled={selectedSortOption === 2 ? true : false}
                        onClick={() => {
                          handleSortUpdate(2);
                          setIsActiveDropdown("Unassigned");
                        }}
                      >
                        Unassigned
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                {/* <th className={Style.StaffInformation__headerItem}></th> */}
              </tr>
            </thead>
            {staffViewData?.data?.length == 0 ? (
              <tbody className={Style.StaffInformation__contents_no_staff}>
                <tr>
                  <td colSpan={5}>
                    <span> No staff has not been added. </span>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                <tbody className={Style.StaffInformation__contents}>
                  {staffViewData?.data?.slice(0, 6).map((staff, index) => {
                    const lastIndex = staffViewData?.data?.length - 1;

                    return (
                      <React.Fragment key={index}>
                        <AddStaffCard
                          designations={designations}
                          staffData={staff}
                          name={staff?.name}
                          phone={staff?.mobile}
                          email={staff?.email}
                          staff_assigned={staff?.staff_assigned}
                          ref={imgItemRef}
                          lastIndex={lastIndex}
                          itemIndex={index}
                          type={staffViewData?.type}
                          handletoggleAssignedProjects={
                            handletoggleAssignedProjects
                          }
                          handlesetAssignedProjects={handlesetAssignedProjects}
                        />
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </>
            )}
          </Table>
        </div>
        <div className="w-100 mx-auto pt-2 d-flex ">
          {staffViewData?.data?.length >= 8 && (
            <button
              onClick={viewAllButton}
              className="btn btn-primary-link mx-auto py-0 my-2"
            >
              View All{" "}
              <Icon color={"currentColor"} size={16} icon={"arrow-right"} />
            </button>
          )}
        </div>
      </div>
      <AssignedProjectDetails
        setIsAssignedEventInfo={handletoggleAssignedProjects}
        setIsAddNewFolderModal={() => { }}
        handleToggleEditFolder={handleToggleEditFolder}
        setAddNewProjectPopup={setAddNewProjectPopup}
        setProjectID={setProjectID}
      />
      <AddStaff
        showAddStaffModal={statusAddStaffPopup}
        setShowAddStaffModal={handleToggleStatusAddStaffPopup}
        staffValidationSchema={staffValidationSchema}
        staffSubmit={staffSubmit}
        designations={designations}
      />

      <AddNewFolderModal
        handleSubmitCreateNewFolder={handleSubmitCreateNewFolder}
        addNewProjectPopup={openEditFolderModal}
        setAddNewProjectPopup={setOpenEditFolderModal}
        isEventEdit={true}
      />
    </>
  );
};

export default StaffInformation;
