import React from "react";
import { Dropdown } from "react-bootstrap";

const ProjectMenu = ({
  data,
  currentTab,
  handleEditProject,
  setOpenDeleteModal,
  setOpenEditProjectModal,
  handleViewProjectDetails,
  setChangeProjectStatusModal,
  handleDeleteProjectID,
  handleProjectStatusID,
  handleShowAddProject,
  addProjectStepTwo,
  handleSetAddProjectStepOne,
  handleSetAddProjectStepTwo,
  handleSetStepOneFormData,
}) => {
  return (
    <>
      <Dropdown.Item
        onClick={() => {
          handleEditProject(data);
          setOpenEditProjectModal(true);
        }}
        disabled={currentTab === "activeTab" ? false : true}
      >
        Edit
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          handleViewProjectDetails(data.id);
        }}
      >
        Project Info
      </Dropdown.Item>

      {data.client_id == undefined && (
        <Dropdown.Item
          onClick={() => {
            handleSetStepOneFormData(data.id);
            if (data.client_id == undefined) {
              handleShowAddProject(true);
              handleSetAddProjectStepOne(false);
              handleSetAddProjectStepTwo(true);
            }
          }}
          disabled={currentTab === "activeTab" ? false : true}
        >
          Add Client
        </Dropdown.Item>
      )}

      <Dropdown.Item
        onClick={() => {
          handleProjectStatusID(data.id);
          setChangeProjectStatusModal(true);
        }}
      >
        {currentTab === "activeTab" ? "Completed" : "Active"}
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => {
          handleDeleteProjectID(data.id);
          setOpenDeleteModal(true);
        }}
      >
        Delete
      </Dropdown.Item>
    </>
  );
};

export default ProjectMenu;
