import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../../Logic/Dimensions";
import Assets from "../../../Layouts/Assets";
import Icon from "../../../Layouts/Icons";
import Style from "./StudioDetailsCard.module.scss";
import { useNavigate } from "react-router-dom";
import {
  authModalHandler,
  changeModal,
} from "../../../../store/slices/Preference/studioDetailstSlice";

const StudioDetailsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowDimensions = useDimensions();
  const { userProfileData, status } = useSelector((state) => state.userData);
  const [isLogoImgLoad, setIsLogoImgLoad] = useState(false);

  const socialMediaIcons = {
    instagram: Assets.icon_full_insta,
    facebook: Assets.icon_full_facebook,
    pinterest: Assets.icon_full_pinterest,
    youTube: Assets.icon_full_youtube,
  };

  const social_medias = [
    {
      id: 656,
      preference_id: 69,
      type_id: 1,
      link: userProfileData?.preference?.social_media[0]?.link || "",
      status: 1,
      created_at: "2023-04-11T11:34:25.000000Z",
      updated_at: "2023-04-11T11:34:25.000000Z",
      social_media_type: {
        id: 1,
        type: "Website",
        slug: "website",
        icon_class: "website-line",
        created_at: "2023-03-24T10:12:59.000000Z",
        updated_at: "2023-03-24T10:12:59.000000Z",
      },
    },
    {
      id: 657,
      preference_id: 69,
      type_id: 2,
      link: userProfileData?.preference?.social_media[1]?.link || "",
      status: 1,
      created_at: "2023-04-11T11:34:25.000000Z",
      updated_at: "2023-04-11T11:34:25.000000Z",
      social_media_type: {
        id: 2,
        type: "Facebook",
        slug: "facebook",
        icon_class: "facebook-line",
        created_at: "2023-03-24T10:12:59.000000Z",
        updated_at: "2023-03-24T10:12:59.000000Z",
      },
    },
    {
      id: 658,
      preference_id: 69,
      type_id: 3,
      link: userProfileData?.preference?.social_media[2]?.link || "",
      status: 1,
      created_at: "2023-04-11T11:34:25.000000Z",
      updated_at: "2023-04-11T11:34:25.000000Z",
      social_media_type: {
        id: 3,
        type: "YouTube",
        slug: "youTube",
        icon_class: "youtube",
        created_at: "2023-03-24T10:12:59.000000Z",
        updated_at: "2023-03-24T10:12:59.000000Z",
      },
    },
    {
      id: 659,
      preference_id: 69,
      type_id: 4,
      link: userProfileData?.preference?.social_media[3]?.link || "",
      status: 1,
      created_at: "2023-04-11T11:34:25.000000Z",
      updated_at: "2023-04-11T11:34:25.000000Z",
      social_media_type: {
        id: 4,
        type: "Instagram",
        slug: "instagram",
        icon_class: "instagram",
        created_at: "2023-03-24T10:12:59.000000Z",
        updated_at: "2023-03-24T10:12:59.000000Z",
      },
    },
    {
      id: 660,
      preference_id: 69,
      type_id: 5,
      link: userProfileData?.preference?.social_media[4]?.link || "",
      status: 1,
      created_at: "2023-04-11T11:34:25.000000Z",
      updated_at: "2023-04-11T11:34:25.000000Z",
      social_media_type: {
        id: 5,
        type: "Pinterest",
        slug: "pinterest",
        icon_class: "pinterest",
        created_at: "2023-03-24T10:12:59.000000Z",
        updated_at: "2023-03-24T10:12:59.000000Z",
      },
    },
  ];

  return (
    <>
      <div
        className={`${Style.StudioDetailsCard_wrap} ${
          status && status !== "succeed" && "shimmer-loader" && "shimmer-loader"
        }`}
      >
        <div className={Style.StudioDetailsCard_imgwrap}>
          <figure className={Style.StudioDetailsCard_logo}>
            <img
              src={
                userProfileData?.logo_url
                  ? userProfileData?.logo_url
                  : Assets.im_placeholder
              }
              onLoad={() => setIsLogoImgLoad(true)}
              alt="Studio Logo"
            />
          </figure>
          {windowDimensions.width >= 768 ? (
            <>
              <ul className={Style.StudioDetailsCard_social_media}>
                {console.log(
                  "userProfileData?.preference?.social_media",
                  userProfileData?.preference?.social_media
                )}
                {userProfileData?.preference?.social_media?.map((media) => {
                  const { link } = media;
                  if (media?.social_media_type?.slug !== "website") {
                    return (
                      <li>
                        <a
                          href={"javascript:void(0)"}
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (link == null || link == "") {
                              navigate("/dashboard/preference/studio");
                              dispatch(authModalHandler(true));
                              dispatch(changeModal("socialMedia"));
                            } else {
                              window.open(link, "_blank", "noreferrer");
                            }
                          }}
                        >
                          <img
                            src={
                              socialMediaIcons?.[media?.social_media_type?.slug]
                            }
                            alt=""
                          />
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </>
          ) : (
            <>
              <Link
                to="/dashboard/preference/studio"
                className="btn btn-border-white"
              >
                <Icon color={"currentColor"} size={14} icon={"icon-edit"} />{" "}
                Edit
              </Link>
            </>
          )}
        </div>

        <div className={Style.StudioDetailsCard_address}>
          <div className={Style.StudioDetailsCard_address_header}>
            <h3 className={Style.StudioDetailsCard_studio_name}>
              {userProfileData?.name}
            </h3>
            <a href={"mailto:" + userProfileData?.email}>
              {userProfileData?.email}
            </a>
            {windowDimensions.width > 576 && (
              <>
                <Link
                  to="/dashboard/preference/studio"
                  className="btn btn-border-white"
                >
                  <Icon color={"currentColor"} size={14} icon={"icon-edit"} />
                  {windowDimensions.width > 768 && "Edit"}
                </Link>
              </>
            )}
          </div>
          <div className={Style.StudioDetailsCard_address_details}>
            <p className={Style.StudioDetailsCard_address_label}>Address</p>
            <p className={Style.StudioDetailsCard_currentAddress}>
              {userProfileData?.preference?.address || "------------"}
            </p>
          </div>
          <div className={Style.StudioDetailsCard_address_footer}>
            <div className={Style.StudioDetailsCard_address_contact}>
              <p className={Style.StudioDetailsCard_address_label}>Contact</p>

              {userProfileData?.preference?.telephone_number !== "" && (
                <a
                  href={"tel:" + userProfileData?.preference?.telephone_number}
                >
                  {userProfileData?.preference?.telephone_number}
                </a>
              )}

              {userProfileData?.preference?.telephone_number !== null &&
                userProfileData?.mobile !== null && <>,</>}

              {userProfileData?.mobile !== undefined &&
                userProfileData?.mobile !== "" && (
                  <a href={"tel:" + userProfileData?.mobile}>
                    {userProfileData?.mobile}
                  </a>
                )}
            </div>
            <div className={Style.StudioDetailsCard_address_website}>
              <p className={Style.StudioDetailsCard_address_label}>Website</p>

              {userProfileData?.preference?.social_media?.map((media) => {
                if (media?.social_media_type?.slug === "website") {
                  return (
                    <a
                      href={media.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {media?.link || "----------"}
                    </a>
                  );
                }
              })}
            </div>

            {windowDimensions.width < 768 ? (
              <>
                <ul className={Style.StudioDetailsCard_social_media}>
                  {social_medias.map((media) => {
                    if (media?.social_media_type?.slug !== "website") {
                      return (
                        <li>
                          <a
                            href={media.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={
                                socialMediaIcons[media.social_media_type.slug]
                              }
                              alt=""
                            />
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudioDetailsCard;
