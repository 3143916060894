import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAnimations } from "../../Logic/Animations";
import Assets from "../Layouts/Assets";
import Style from "./PlansCardImage.module.scss";

const PlansCardImage = ({ className }) => {
  const { slideTopRef } = useAnimations();
  const { userProfileData } = useSelector((state) => state.userData);
  const location = useLocation();

  return (
    <>
      <div
        className={`col-12 col-lg-6 col-xl-7 my-auto text-center text-lg-start ${Style.PlansCardImage} ${className}`}
      >
        <div className={Style.text_inner}>
          <div className={Style.image_wrapper}>
            <div className={`${Style.image_container} image_container`}>
              <img
                className="family-image-item"
                src={Assets.family_image}
                alt={``}
              />
            </div>
          </div>

          <div
            className={`section-title-block mx-auto ms-lg-0 ${Style.PlansCardImage_content}`}
          >
            <h2 ref={slideTopRef}>
              {location.pathname === "/plans" || location.pathname === "/"
                ? userProfileData.plan_payment_status === 1
                  ? "Thank You For Purchasing This Plan"
                  : ""
                : "Choose a Plan to Start"}
            </h2>
            <p ref={slideTopRef}>
              {location.pathname === "/plans" || location.pathname === "/"
                ? userProfileData.plan_payment_status === 1
                  ? "We appreciate your business and are committed to providing you with the best possible experience."
                  : ""
                : `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt dolore.`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansCardImage;
