import React from "react";
import CommonModal from "../../Modals/CommonModal";
import PreferenceCard from "../PreferenceCard";
import Style from "./StudioPreferenceList.module.scss";
import { useStudioDetails } from "../../../Logic/Preference/useStudioDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  authModalHandler,
  isUpdatingEmailStatus,
  isUpdatingPhoneStatus,
} from "../../../store/slices/Preference/studioDetailstSlice";
import StudioName from "./Components/StudioName";
import StudioEmail from "./Components/StudioEmail";
import StudioMobileNumber from "./Components/StudioMobileNumber";
import MiniFileUploadModal from "../../Modals/MiniFileUploadModals";
import { useChangeLogo } from "../../../Logic/Preference/useChangeLogo";
import StudioDeleteAccount from "./Components/StudioDeleteAccount";
import StudioSocialMedia from "./Components/StudioSocialMedia";
import StudioAddress from "./Components/StudioAddress";
import { useGetStarted } from "../../../Logic/GetStarted/useGetStarted";

const StudioPreferenceList = () => {
  const {
    handleClick,
    nameValidationSchema,
    nameSubmit,
    showChangeLogoModal,
    setShowChangeLogoModal,
    stepsData,
    setStepsData,
  } = useStudioDetails();

  const {
    errorMessage,
    seterrorMessage,
    errorMessageLogo,
    seterrorMessageLogo,
  } = useGetStarted();

  const dispatch = useDispatch();

  const { currentScreen, openAuthModal, isUpdatingEmail, isUpdatingPhone } =
    useSelector((state) => state.preference);

  const { handleOpenLogoModal } = useChangeLogo(
    showChangeLogoModal,
    setShowChangeLogoModal
  );

  const handleClose = () => {
    dispatch(authModalHandler(false));
    dispatch(isUpdatingEmailStatus(false));
    dispatch(isUpdatingPhoneStatus(false));
  };

  const renderTitle = (title) => {
    // eslint-disable-next-line default-case
    switch (title) {
      case "name":
        return "Enter Studio name";
      case "email":
        return "Enter Email";
      case "mobile":
        return "Enter Mobile Number";
      case "address":
        return "Enter Address";

        break;
    }
  };

  const renderScreen = (page) => {
    // eslint-disable-next-line default-case
    switch (page) {
      case "name":
        return (
          <StudioName
            validationSchema={nameValidationSchema}
            nameSubmit={nameSubmit}
          />
        );
      case "email":
        return <StudioEmail />;
      case "mobile":
        return <StudioMobileNumber />;
      case "deletAccount":
        return <StudioDeleteAccount />;
      case "socialMedia":
        return <StudioSocialMedia />;
      case "address":
        return <StudioAddress />;
    }
  };

  const { userProfileData } = useSelector((state) => state.userData);

  let addressPrefence = (data) => {
    let adddressData;
    if (data == "address") {
      adddressData = userProfileData?.preference?.address;
    } else {
      adddressData = userProfileData[data];
    }
    return adddressData;
  };

  return (
    <>
      <div className={Style.StudioPreferenceList}>
        {lists?.map((data, i) => {
          return (
            <PreferenceCard
              index={i}
              title={data?.title}
              description={addressPrefence(data?.type)}
              actionType={data?.actionType}
              actions={data?.actions}
              onClick={() => handleClick(data?.type)}
              type={data?.type}
            />
          );
        })}
        <div className={Style.errorMsg}>
          {errorMessage == true && (
            <p className="form-error-note">
              The water mark must be a file of type: jpeg, png, jpg.
            </p>
          )}

          {errorMessageLogo == true && (
            <p className="form-error-note">
              The logo must be a file of type: jpeg, png, jpg.
            </p>
          )}
        </div>
      </div>

      {/* Modals */}

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={openAuthModal}
        setShowModal={() => handleClose()}
        centered={true}
        className={"edit-project-name-modal"}
        title={renderTitle(currentScreen)}
        content={renderScreen(currentScreen)}
        isheader={isUpdatingPhone || isUpdatingEmail}
      />

      {currentScreen == "change logo" && (
        <MiniFileUploadModal
          showChangeLogoModal={showChangeLogoModal}
          setShowChangeLogoModal={setShowChangeLogoModal}
          setStepsData={setStepsData}
          stepsData={stepsData}
          seterrorMessageLogo={seterrorMessageLogo}
        />
      )}
      {currentScreen == "waterMark" && (
        <MiniFileUploadModal
          showChangeLogoModal={showChangeLogoModal}
          setShowChangeLogoModal={setShowChangeLogoModal}
          setStepsData={setStepsData}
          stepsData={stepsData}
          errorMessage={errorMessage}
          seterrorMessage={seterrorMessage}
        />
      )}
    </>
  );
};

export default StudioPreferenceList;

let lists = [
  {
    type: "name",
    title: "Studio name",
    description: "Saritha Studio",
    actionType: "link",
    actions: "Edit",
  },
  {
    type: "email",
    title: "Email",
    description: "sarithastudio@gmail.com",
    actionType: "link",
    actions: "Edit",
  },
  {
    type: "mobile",
    title: "Mobile number",
    description: "+91 9797975656",
    actionType: "link",
    actions: "Edit",
  },
  {
    type: "address",
    title: "Address",
    description: "Guruvayur, Kerala 680101",
    actionType: "link",
    actions: "Edit",
  },
  {
    type: "socialMedia",
    title: "Social media",
    description:
      "Deleting an account means you will lose all your information forever.",
    actionType: "link",
    actions: "Edit",
  },
  {
    type: "change logo",
    title: "Change logo",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
    actionType: "link",
    actions: "Update",
  },
  {
    type: "waterMark",
    title: "Change watermark",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
    actionType: "link",
    actions: "Update",
  },
  // {
  //   type: "profilePic",
  //   title: "Update profile picture",
  //   description:
  //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam",
  //   actionType: "link",
  //   actions: "Update",
  // },
  {
    type: "deletAccount",
    title: "Delete account",
    description:
      "Deleting an account means you will lose all your information forever.",
    actionType: "link",
    actions: "Delete account",
  },
];
