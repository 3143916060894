import React from "react";
import { Link } from "react-router-dom";
import Style from "./SignupBox.module.scss";

const SignupBox = ({}) => {
  return (
    <div className={Style.Auth__option}>
              <span>Don't have an account? </span>
              <Link to="/register" className="btn btn-link link-primary">
                Create Account
              </Link>
    </div>
  );
};

export default SignupBox;
