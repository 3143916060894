import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getContractStaffList,
  updateContractStaffSort,
} from "../../../store/slices/Dashboard/getDashboardSlice";
import {
  toggleAssignedProjects,
  setAssignedProjects,
} from "../../../store/slices/Staffs/staffsSlice";

const useDashboardContractStaffs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { contractStaffList, contractStaffSort } = useSelector(
    (state) => state.dashborad
  );

  let contractStaffData = {
    title: "Contract Staff information",
    type: "dashboard-table",
    view_all: "",
    data: contractStaffList.data,
  };

  useEffect(() => {
    if (Object.keys(contractStaffList).length === 0) {
      fetchStaffs();
    }
  }, []);

  useEffect(() => {
    fetchStaffs();
  }, [contractStaffSort]);

  const fetchStaffs = (slug, query = false) => {
    let obj = {
      url: "contract-staff-list",
      page: 1,
      per_page: 20,
    };

    if (contractStaffSort != "") {
      obj.sort = contractStaffSort;
    }

    return dispatch(getContractStaffList(obj));
  };

  const viewAllButtonContract = () => {
    navigate("/dashboard/staffs/contract");
  };

  const handletoggleAssignedProjects = (status) => {
    dispatch(toggleAssignedProjects(status));
  };

  const handlesetAssignedProjects = (data) => {
    dispatch(setAssignedProjects(data));
  };

  const handleUpdateContractStaffSort = (status) => {
    dispatch(updateContractStaffSort(status));
  };

  return {
    contractStaffData,
    viewAllButtonContract,
    handletoggleAssignedProjects,
    handlesetAssignedProjects,
    handleUpdateContractStaffSort,
  };
};

export default useDashboardContractStaffs;
