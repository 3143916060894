import React from "react";
import Style from "./HamburgerMenu.module.scss";

const HamburgerMenu = ({ isMenuOpen, setIsMenuOpen }) => {
  return (
    <>
      <div
        className={`${Style.mobileMenu_hamburger} ${
          isMenuOpen ? Style.menuOpen : ""
        }`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <span />
        <span />
        <span />
      </div>
      <div
        className={`${Style.mobileMenu_overlay} ${
          isMenuOpen ? Style.menuOpen : ""
        }`}
        onClick={() => setIsMenuOpen(false)}
      ></div>
    </>
  );
};

export default HamburgerMenu;
