import React from "react";
import SelectYourTheme from "../../components/GetStartSteps/SelectYourTheme";
import CommonLayout from "../../components/Layouts/CommonLayout";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import useAuthChecker from "../../Logic/Auth/useAuthChecker";

const SelectYourThemePage = () => {
  const { userProfileData } = useSelector((state) => state.userData);

  const { isAuthCompleted } = useAuthChecker();

  return (
    <CommonLayout pageClass="bg-light">
      {isAuthCompleted ? (
        <>
          {userProfileData?.theme_class_name ? (
            <Navigate to="/dashboard" />
          ) : (
            <SelectYourTheme />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </CommonLayout>
  );
};

export default SelectYourThemePage;
