import React, { useState, useEffect, useRef } from "react";
import Assets from "../../Layouts/Assets";
import GalleryListView from "../GalleryListVIew";
import ImagePreview from "../../UserFlow/ImagePreview";
import useGallery from "../../../Logic/useGallery";
import { useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import MasonryCard from "../GalleryMasonryAllImages/MasonryCard";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import BlankDataPlaceholder from "../BlankDataPlaceholder";
import useMasonry from "../../../Logic/useMasonry";

const ProjectGallerySelected = () => {
  const rootRef = useRef(null);

  const {
    selectedImage,
    setSelectedImage,
    isImagePreview,
    setIsImagePreview,
    isSelectionOpen,
    setIsSelectionOpen,
    imgRef,
    observer,
  } = useMasonry();

  const { sections, currentTab, selectedImageListView } = useSelector(
    (state) => state.gallery
  );

  const { getImagesForSection, loadMore, handleGalleryTab } = useGallery();

  useEffect(() => {

    getImagesForSection("selected");
    
  }, []);

  useEffect(() => {
    if (imgRef?.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries?.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore("selected");
        }
      });
      observer?.current?.observe(imgRef?.current);
      return () => {
        observer.current?.disconnect();
      };
    }
  }, [sections[currentTab]?.data, rootRef, selectedImageListView]);

  let images = sections[currentTab]?.data.map((item) => item.images);

  return (
    <>
      {!selectedImageListView ? (
        <div ref={rootRef}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 0: 1, 320: 2, 576: 3, 992: 4, 1600: 5 }}
          >
            <Masonry>
              {sections[currentTab]?.data?.map((data, i) => {
                return (
                  <div ref={imgRef}>
                    <MasonryCard
                      key={i}
                      data={data?.images}
                      isImagePreview={isImagePreview}
                      setIsImagePreview={setIsImagePreview}
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                      isSelectionOpen={isSelectionOpen}
                      dataIndex={i}
                    />
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      ) : (
        <GalleryListView
          imageData={sections[currentTab]?.data}
          setIsImagePreview={setIsImagePreview}
          setSelectedImage={setSelectedImage}
          isSelectionOpen={isSelectionOpen}
          blankTitle="You haven't added any photos"
          blankDescription="The photos you added are shown here"
          blankButton="Add Photos"
          blankTemplate="image"
          ref={imgRef}
        />
      )}
      {sections[currentTab]?.data.length === 0 ? (
        <BlankDataPlaceholder
          title={"No images has been selected by client"}
          description={"Selected images are shown here"}
          template={"image"}
          buttonText={""}
        />
      ) : (
        ""
      )}
      {!isSelectionOpen && isImagePreview ? (
        <ImagePreview
          slideData={images}
          setIsImagePreview={setIsImagePreview}
          activeSlideIndex={selectedImage}
          type="normal-lightbox"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProjectGallerySelected;

const GalleryData = [
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 1,
    image: Assets.photo1,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 2,
    image: Assets.photo2,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 3,
    image: Assets.photo3,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 4,
    image: Assets.photo4,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 5,
    image: Assets.photo5,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 6,
    image: Assets.photo6,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 7,
    image: Assets.photo7,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 8,
    image: Assets.photo8,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 9,
    image: Assets.photo9,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 10,
    image: Assets.photo10,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 11,
    image: Assets.photo11,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 12,
    image: Assets.photo12,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 13,
    image: Assets.photo13,
    title: "7R401734",
    date: "03 Oct 2023",
  },
  {
    id: 14,
    image: Assets.photo14,
    title: "7R401734",
    date: "03 Oct 2023",
  },
];
