import { useDispatch, useSelector } from "react-redux";
import { getAllPhotos } from "../../store/slices/UserFlow/userFlowSlice";
import { useParams } from "react-router-dom";

export const useUserFlow = () => {
  const dispatch = useDispatch();
  const { currentTab, sections } = useSelector((state) => state.userFlow);
  let { user_token } = useParams();

  const getUserFlowData = () => {
    if (sections?.["All"]?.data == undefined) {
      dispatch(getAllPhotos(user_token));
    }
  };
  return {
    getUserFlowData,
  };
};
