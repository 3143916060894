import { useDispatch, useSelector } from "react-redux";
import {
  authModalHandler,
  isUpdatingEmailStatus,
  postStudioEmail,
  verifyEmailOtp,
  postResendStudioEmail,
} from "../../store/slices/Preference/studioDetailstSlice";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { updatePreferenceList } from "../../store/slices/GetStarted/userBasicDataSlice";

export const useEmailVerify = () => {
  const [code, setCode] = useState("");
  const [showErrors, setShowErrors] = useState("");
  const [showOtpErrors, setshowOtpErrors] = useState(null);
  const [otpCounter, setOtpCounter] = useState(30);

  const dispatch = useDispatch();
  const { isUpdatingEmail } = useSelector((state) => state.preference);

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required."),
  });
  const emailSubmit = async (FormData, resetForm, setFieldError) => {
    dispatch(postStudioEmail(FormData))
      .unwrap()
      .then((result) => {
        if (result.data.success === false) {
          console.log(
            "result.data.validation.errors.email[0]",
            result.data.validation.errors.email[0]
          );
          setFieldError("email", result.data.validation.errors.email[0]);
        } else {
          resetForm();
        }
      });
  };

  const onEmailVerifyOtp = async (code, email, type) => {
    setshowOtpErrors("");
    setCode(code);
    if (code?.length == 4) {
      const params = {
        otp: code,
        email: email,
      };
      const resultAction = await dispatch(verifyEmailOtp(params));

      if (verifyEmailOtp.fulfilled.match(resultAction)) {
        if (resultAction?.payload?.data?.status_code == "200") {
          dispatch(
            updatePreferenceList({
              type: "email",
              data: email,
            })
          );

          toast.success("Email Changed Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(authModalHandler(false));
          dispatch(isUpdatingEmailStatus(false));
        } else if (resultAction?.payload?.data?.status_code == "422") {
          setshowOtpErrors(resultAction?.payload?.data?.message);
        }
      }
    }
  };

  const resendOtp = async (email) => {
    setCode("");
    const resultAction = await dispatch(
      postResendStudioEmail({ email: email })
    );
    if (postStudioEmail.fulfilled.match(resultAction)) {
      setOtpCounter(30);
    }
  };
  return {
    code,
    showErrors,
    setShowErrors,
    setshowOtpErrors,
    showOtpErrors,
    emailValidationSchema,
    emailSubmit,
    onEmailVerifyOtp,
    isUpdatingEmail,
    otpCounter,
    setOtpCounter,
    resendOtp,
  };
};
