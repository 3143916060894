import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProjectsAPI,
  projectOrderChange,
  customLoadMore,
  setSerachQuery,
  toggleProjectAddPopup,
  storeProjectEditDetails,
  EditProjectDetails,
  storeViewProjectDetails,
  viewProjectDetails,
  updateActiveTabData,
  deleteProject,
  deleteSingleProject,
  storeDeleteProjectID,
  updateProjectStatus,
  projectStatusID,
  currentTabHandler,
  clearTabData,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  setStepOneFormData,
  clearStepOneFormData,
} from "../store/slices/Project/ProjectSlice";
import { Link, useParams } from "react-router-dom";

const useProjects = () => {
  const { projectID, folderID } = useParams();

  /********************
   * ADD NEW PROJECTS *
   ********************/

  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);
  // const [addProjectStepOne, setAddProjectStepOne] = useState(true);
  // const [addProjectStepTwo, setAddProjectStepTwo] = useState(false);

  /*****************
   * EDIT PROJECTS *
   *****************/

  const [openProjectInfoModal, setOpenProjectInfoModal] = useState(false);
  const [openEventInfoModal, setOpenEventInfoModal] = useState(false);
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [changeProjectStatusModal, setChangeProjectStatusModal] =
    useState(false);

  useEffect(() => {
    /*********************************************
     * PROJECT EDIT DATA CLEARS FROM REDUX VALUE *
     *********************************************/

    if (openEditProjectModal === false) {
      handleEditProject("");
    }
  }, [openEditProjectModal]);

  useEffect(() => {
    /*********************************************
     * PROJECT DELETE DATA CLEARS FROM REDUX VALUE *
     *********************************************/

    if (openDeleteModal === false) {
      handleDeleteProjectID("");
    }
  }, [openDeleteModal]);

  useEffect(() => {
    if (openShareModal === true) {
      handleDeleteProjectID("");
    }
  }, [openShareModal]);


 

  const getTabPlaceholderText = (text) => {
    switch (text) {
      case "completedTab":
        return {
          title: "You haven't added anything to completed",
          description: "The projects is marked as completed is shown here.",
          buttonText: "",
          template: "",
        };
        break;

      case "activeTab":
        return {
          title: "You haven't created any projects",
          description: "The projects you created are shown here",
          buttonText: "Create Project",
          template: "folder",
        };
        break;

      default:
        break;
    }
  };

  const handleSetStepOneFormData = (projectID) => {
    dispatch(setStepOneFormData(projectID));
  };

  const handleClearStepOneFormData = () => {
    dispatch(clearStepOneFormData());
  };

  const {
    status,
    sections,
    currentTab,
    editProject,
    deleteProject,
    setStatusComplete,
    changeProjectStatus,
  } = useSelector((state) => state.project);
  const { popupAddProject } = useSelector((state) => state.project.addProjects);

  const dispatch = useDispatch();

  const projectOrder = sections[currentTab]?.sort;

  const getTabUrl = (slug) => {
    switch (slug) {
      case "activeTab":
        return "list-project";
        break;
      case "completedTab":
        return "list-completed-project";
        break;
    }
  };

  const fetchAllProjects = (slug, query = false) => {
    let obj = {
      url: getTabUrl(slug),
      page: "1",
      sort:
        sections[currentTab]?.sort != "" &&
        sections[currentTab]?.sort != undefined
          ? sections[currentTab]?.sort
          : "desc",
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    }

    return dispatch(getAllProjectsAPI(obj));
  };

  const loadMore = (slug) => {
    if (sections[currentTab]?.current_page < sections[currentTab]?.last_page) {
      let obj = {
        url: getTabUrl(slug),
        page: parseInt(sections[currentTab]?.current_page) + 1,
        per_page: 20,
        sort:
          sections[currentTab]?.sort != ""
            ? sections[currentTab]?.sort
            : "desc",
      };

      if (
        sections?.[currentTab]?.["search"] != "" &&
        sections?.[currentTab]?.["search"] != undefined
      ) {
        obj.search = sections?.[currentTab]?.["search"];
      }

      dispatch(customLoadMore(obj));
    }
  };

  const orderChangeFetch = (order) => {
    // if (status != "loading") {
    //   const packet = new FormData();
    //   packet.append("per_page", 20);
    //   packet.append("page", 1);
    //   packet.append("sort", order);
    //   dispatch(getAllProjectsAPI(packet));
    // }
  };

  const isDataLoading = () => {
    if (status === "loading") {
      return true;
    } else {
      return false;
    }
  };

  const handleEditProjectName = (formData) => {
    const formdata = {
      id: editProject.data.id,
      name: formData.projectName,
      location_name: editProject.data.location_name,
    };

    dispatch(EditProjectDetails(formdata))
      .unwrap()
      .then((data) => {
        if (data.data.success === true) {
          const data = [...sections?.[currentTab]?.["data"]];

          let newList = [];
          data.forEach(function (item) {
            if (item.id === formdata.id) {
              item = { ...item, name: formdata?.name };
            }
            newList.push(item);
          });

          dispatch(updateActiveTabData(newList));
          setOpenEditProjectModal(false);
        }
      });
  };

  const handleProjectOrder = (order) => {
    // dispatch(projectOrderChange(orderNiceWordToUgly(order)));
    // orderChangeFetch(orderNiceWordToUgly(order));
  };

  const handleSearchQuery = (query) => {
    dispatch(setSerachQuery(query));
  };

  const handleShowAddProject = (state) => {
    dispatch(toggleProjectAddPopup(state));
  };

  const handleEditProject = (details, index) => {
    dispatch(storeProjectEditDetails(details));
  };

  const handleViewProjectDetails = (projectID) => {
    const data = {
      id: projectID,
    };
    dispatch(viewProjectDetails(data)).then((data) => {
      if (data.payload.data.success === true) {
        setOpenProjectInfoModal(true);
      }
    });
  };

  const handleDeleteSingleProject = () => {
    if (deleteProject?.projectID != "") {
      const data = { id: [deleteProject?.projectID] };
      dispatch(deleteSingleProject(data)).then((res) => {
        if (res.payload.data.success) {
          setOpenDeleteModal(false);
        } else {
          alert("Something went wrong!");
          setOpenDeleteModal(false);
        }
      });
    }
  };

  const handleDeleteProjectID = (projectID) => {
    dispatch(storeDeleteProjectID(projectID));
  };

  const handleProjectStatusID = (projectID) => {
    dispatch(projectStatusID(projectID));
  };

  const handleUpdateProjectStatus = () => {
    if (
      changeProjectStatus?.projectID != "" &&
      changeProjectStatus.projectID != undefined
    ) {
      dispatch(
        updateProjectStatus({
          id: changeProjectStatus.projectID,
          status: currentTab === "activeTab" ? 1 : 0,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.data.success === true) {
            setChangeProjectStatusModal(false);
            dispatch(
              clearTabData(
                currentTab === "activeTab" ? "completedTab" : "activeTab"
              )
            );
          }
        });
    }
  };

  const isProjectListing = () => {
    const { href } = window.location;
    if (
      projectID === undefined &&
      folderID === undefined &&
      href.indexOf("dashboard") !== -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getThumbnails = (data) => {
    if (
      data?.latest_album === undefined ||
      data?.latest_album?.latest_image?.length === 0
    ) {
      return [1, 2, 3, 4];
    } else {
      return data?.latest_album;
    }
  };

  const handleSetAddProjectStepOne = (value) => {
    dispatch(setAddProjectStepOne(value));
  };

  const handleSetAddProjectStepTwo = (value) => {
    dispatch(setAddProjectStepTwo(value));
  };

  const handleSetCurrentTabHandler = (value) => {
    dispatch(currentTabHandler({"tab":value,"callback":()=>{}}));
  };


  

  return {
    sections,
    currentTab,
    handleSearchQuery,

    fetchAllProjects,
    handleProjectOrder,
    loadMore,

    /*****************
     * EDIT PROJECTS *
     *****************/

    openProjectInfoModal,
    setOpenProjectInfoModal,
    openEventInfoModal,
    setOpenEventInfoModal,
    openEditProjectModal,
    setOpenEditProjectModal,
    openShareModal,
    setOpenShareModal,
    openDeleteModal,
    setOpenDeleteModal,

    openCreateProjectModal,
    setOpenCreateProjectModal,
    setAddProjectStepOne,
    setAddProjectStepTwo,
    popupAddProject,
    handleShowAddProject,

    /****************
     * EDIT PROJECT *
     ****************/

    handleEditProject,
    handleEditProjectName,

    /************************
     * VIEW PROJECT DETAILS *
     ************************/

    handleViewProjectDetails,

    /*************************
     * PROJECT DELETE SINGLE *
     *************************/

    handleDeleteSingleProject,
    handleDeleteProjectID,

    /***************************
     * PROJECT STATUS CHANGE *
     ***************************/

    handleProjectStatusID,
    handleUpdateProjectStatus,
    changeProjectStatusModal,
    setChangeProjectStatusModal,
    isDataLoading,
    isProjectListing,
    projectOrder,
    getTabPlaceholderText,
    getThumbnails,
    handleSetAddProjectStepOne,
    handleSetAddProjectStepTwo,
    handleSetStepOneFormData,
    handleClearStepOneFormData,
    handleSetCurrentTabHandler,
  };
};

export default useProjects;
