import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Assets from "../../components/Layouts/Assets";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Icon from "../../components/Layouts/Icons";
import Style from "./PaymentSuccess.module.scss";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useSelector } from "react-redux";

const PaymentSuccess = () => {
  let { PayString } = useParams();
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    let decoded = base64_decode(PayString);
    try {
      setOrderDetails(JSON.parse(decoded));
    } catch (error) { }
  }, [PayString]);

  const { userProfileData } = useSelector((state) => state.userData);

  return (
    <CommonLayout>
      <div className={`${Style.NotFoundPage} inner-page`}>
        <Container>
          <div className="inner-page-wrap">
            <div className={Style.demo1}>
              <div className={Style.ui_success}>
                <svg
                  viewBox="0 0 87 87"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="Group-3" transform="translate(2.000000, 2.000000)">
                      <circle
                        id="Oval-2"
                        stroke="rgba(165, 220, 134, 0.2)"
                        stroke-width="4"
                        cx="41.5"
                        cy="41.5"
                        r="41.5"
                      ></circle>
                      <circle
                        className={Style.ui_success_circle}
                        id="Oval-2"
                        stroke="#A5DC86"
                        stroke-width="4"
                        cx="41.5"
                        cy="41.5"
                        r="41.5"
                      ></circle>
                      <polyline
                        className={Style.ui_success_path}
                        id="Path-2"
                        stroke="#A5DC86"
                        stroke-width="6"
                        points="19 38.8036813 31.1020744 54.8046875 63.299221 28"
                      ></polyline>
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <h7 >
              Order ID : {orderDetails.order_id}
            </h7><br></br>
            <h7 >Amount : {orderDetails.amount} </h7 >
            <h2 className={Style.error_name}>Payment success</h2>
            <div className="pt-4 text-center">
              <Link to={userProfileData?.theme_class_name === null ? "/get-started" : "/dashboard"} className="btn btn-primary">
                {userProfileData?.theme_class_name === null ? "Get Started" : "Back to Dashboard"}
                {" "}
                <Icon
                  color={"currentColor"}
                  className="me-1"
                  size={17}
                  icon={"arrow-right"}
                />
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </CommonLayout>
  );
};

export default PaymentSuccess;
