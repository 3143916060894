import Style from "./StudioName.module.scss";
import React from "react";
import CommonInput from "../../../../Layouts/CommonInput";
import { Formik } from "formik";
import { authModalHandler } from "../../../../../store/slices/Preference/studioDetailstSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAddress } from "../../../../../Logic/Preference/useAddress";
import { updatePreferenceList } from "../../../../../store/slices/GetStarted/userBasicDataSlice";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";

const StudioAddress = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.userData);
  const { addressValidationSchema, addressSubmit } = useAddress();
  const studioAddress = useSelector((state) => state.preference);

  return (
    <Formik
      validationSchema={addressValidationSchema}
      initialValues={{
        address: userProfileData?.preference?.address || "",
        telephone: userProfileData?.preference?.telephone_number || "",
      }}
      onSubmit={(values, { resetForm }) => {
        let FormData = {
          address: values?.address,
          telephone_number: values?.telephone,
        };
        addressSubmit(FormData, resetForm);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
        dirty,
        isValid,
      }) => {
        return (
          <>
            <CommonInput
              type="text"
              label="Address"
              variant="input"
              id="input_studio_address"
              floatingLabel={true}
              name="address"
              touched={touched}
              value={values?.address}
              hasError={errors?.address && touched.address}
              errorMsg={errors?.address}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("address", e.target.value);
              }}
            />
            <CommonInput
              type="text"
              label="Telephone(optional)"
              variant="input"
              id="input_studio_number"
              floatingLabel={true}
              name="telephone"
              value={values?.telephone}
              hasError={errors?.telephone && touched.telephone}
              errorMsg={errors?.telephone}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("telephone", e.target.value);
              }}
            />

            <div className={Style.actions}>
              <button
                className="btn btn-border-grey me-2"
                onClick={() => dispatch(authModalHandler(false))}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                disabled={!isValid || !dirty}
                onClick={() => {
                  handleSubmit();
                  dispatch(
                    updatePreferenceList({
                      type: "address",
                      data: values?.address,
                    })
                  );
                }}
                type="button"
              >
                {studioAddress?.status === "loading" ? (
                  <InfiniteDotLoader />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default StudioAddress;
