import React from 'react'
import ForgotPassword from '../../components/AuthPages/ForgotPassword'
import CommonLayout from '../../components/Layouts/CommonLayout'

const ForgotPasswordPage = () => {
  return (
    <CommonLayout>
        <ForgotPassword />
    </CommonLayout>
  )
}

export default ForgotPasswordPage