import React from "react";
import { Dropdown, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import Icon from "../../../Layouts/Icons";
import Style from "./ButtonGroups.module.scss";
import { Search } from "./../Search";
export const ButtonGroups = ({
  setOpenCreateProjectModal,
  handleShowAddProject,
  setIsChecked,
  isChecked,
  setProjectOrder,
  projectOrder,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  isLoading,
  handleProjectOrder,
  handlePreSelectionhandler,
  handleSelectAllProjects,
  handledeSelectAllProjects,
  handleDeleteSelectedProjects,
  handleCancelSelections,
  windowDimensions,
  setIsMobileSearch,
  isMobileSearch,
  handleChangeInput,
  searchText,
  handleChangeOrder,
  getOrderLabel,
}) => {
  const { sections, currentTab } = useSelector((state) => state.project);

  const IsPreSelectOn =
    sections[currentTab]?.currentAction?.preSelect === "" ||
    sections[currentTab]?.currentAction?.preSelect == undefined
      ? false
      : sections[currentTab]?.currentAction?.preSelect;
  const isSearchActive =
    sections[currentTab]?.["searchText"] === "" ||
    sections[currentTab]?.["searchText"] === undefined
      ? false
      : true;
  const dataLength =
    sections[currentTab]?.data?.length === undefined ||
    sections[currentTab]?.data?.length < 0
      ? false
      : true;
  return (
    <>
      {(!IsPreSelectOn && dataLength) || isSearchActive ? (
        <Search
          windowDimensions={windowDimensions}
          setIsMobileSearch={setIsMobileSearch}
          isMobileSearch={isMobileSearch}
          handleChange={handleChangeInput}
          text={searchText}
        />
      ) : (
        ""
      )}

      {/* ADD-PROJECT BUTTON */}

      <ul className={Style.ContentHeader__actionsBtn}>
        {/* CHECKING PRE-SELECTION STATE IS TRUE OR NOT BEFORE SHOWING THE ADD PROJECT BUTTON */}
        {!sections[currentTab]?.currentAction?.preSelect && (
          <li >
            <button
              className="btn btn-border-grey btn-sm btn-theme"
              onClick={() => {
                setAddProjectStepOne(true);
                setAddProjectStepTwo(false);
                handleShowAddProject(true);
              }}
              disabled={currentTab === "completedTab" ? true : false}
            >
              {windowDimensions.width >= 1280 ? (
                <>Create Project</>
              ) : (
                <Icon color={"currentColor"} size={18} icon={"icon-plus"} />
              )}
            </button>
          </li>
        )}

        {/* PRE-STATE ENABLE OR DISABLE BUTTON */}

        <li>
          {!sections[currentTab]?.currentAction?.preSelect && // CHECKING PRE-SELECT IS TRUE OT NOT
            sections[currentTab] != undefined && // CHECKING DATA IS NOT UNDEFINED
            sections[currentTab]["data"]?.length > 0 && ( // CHECKING DATA LENGTH > 0
              <button
                disabled={
                  sections[currentTab] != undefined &&
                  sections[currentTab]["data"]?.length > 0
                    ? false
                    : true
                }
                className="btn btn-border-grey btn-sm"
                onClick={() => {
                  handlePreSelectionhandler();
                }}
              >
                <Icon
                  size={18}
                  icon={
                    !sections[currentTab]?.currentAction?.preSelect
                      ? "icon-unchecked"
                      : "icon-checked"
                  }
                />
                {windowDimensions.width >= 1280 && <>Select</>}
              </button>
            )}

          {/* DE-SELECT AND SELECT ALL BUTTONS */}

          {sections[currentTab]?.currentAction?.preSelect && ( // CHECKING PRE-SELECT IS TRUE OT NOT
            <button
              className="btn btn-border-grey btn-sm"
              onClick={() => {
                if (
                  sections[currentTab]?.selectedImages?.length ===
                  sections[currentTab]?.data?.length
                ) {
                  handledeSelectAllProjects();
                } else {
                  handleSelectAllProjects();
                }
              }}
            >
              <Icon
                size={18}
                icon={
                  sections[currentTab]?.selectedImages?.length !==
                  sections[currentTab]?.data?.length
                    ? "icon-unchecked"
                    : "icon-checked"
                }
              />

              {windowDimensions.width >= 1280 && (
                <>
                  {" "}
                  {sections[currentTab]?.selectedImages?.length ===
                  sections[currentTab]?.data?.length
                    ? "Deselect all"
                    : "Select all"}
                </>
              )}
            </button>
          )}
        </li>

        {/* DELETE BUTTON */}

        {sections[currentTab]?.selectedImages?.length > 0 && ( // CHECKING SELECTED IMAGES LENGTH IS > 0
          <li>
            <button
              className="btn btn-border-grey btn-sm"
              onClick={() => {
                handleDeleteSelectedProjects(true);
              }}
            >
              <Icon size={16} icon={"icon-trash"} />
              {windowDimensions.width >= 1280 && <>Delete</>}
            </button>
          </li>
        )}

        {/* CLOSE BUTTON  */}

        {sections[currentTab]?.currentAction?.preSelect && ( // CHECKING PRE-SELECT IS TRUE OT NOT
          <li>
            <button
              className="btn btn-border-grey btn-sm btn-three-dots"
              onClick={() => {
                handleCancelSelections();
              }}
            >
              <Icon color={""} size={14} icon={"icon-close"} />
            </button>
          </li>
        )}

        {!sections[currentTab]?.currentAction?.preSelect &&
          sections[currentTab] != undefined &&
          sections[currentTab]["data"]?.length > 0 && (
            <li>
              <Dropdown className={`${Style.ContentHeader__filter_dropdown}`}>
                <Dropdown.Toggle className="btn btn-border-grey btn-sm">
                  {getOrderLabel(projectOrder)}
                </Dropdown.Toggle>
                {isLoading != "loading" && (
                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={projectOrder !== "asc"}
                      onClick={() => {
                        handleChangeOrder("desc");
                      }}
                    >
                      Latest
                    </Dropdown.Item>

                    <Dropdown.Item
                      disabled={projectOrder !== "desc"}
                      onClick={() => {
                        handleChangeOrder("asc");
                      }}
                    >
                      Oldest
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </li>
          )}
      </ul>
    </>
  );
};
