import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REGISTER } from "redux-persist";
import {
  authActions,
  forgotPassword,
  createRegisterOtp,
  FPFOTPverifyOTP,
  storeCurrentAuthData,
  changeScreen,
  editMode,
  storeCurrentRegisterData,
  resendRegOTP,
  otpButtonEnable,
  FPFupdateEditMode,
  FPFupdateEmail,
  FPFOTPerrorStatusUpdate,
  FPFOTPstoreTokenValue,
  verifyOtp,
  forgotPasswordFinal,
} from "../store/slices/Auth/authSlice";

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    FPFisEditMode,
    FPFsubmittedEmail,
    FPFisLoading,
    FPFOTPerror,
    FPFOTPresponceToken,
    FPFOTPisLoading,
  } = useSelector((state) => state.auth);

  const [emailCheckPassed, setEmailCheckPassed] = useState(false);
  const [isOTPCompleted, SetOTPCompleted] = useState(false);


  const [otp, setOTP] = useState("");

  const [isOTPverifying, setOTPverifying] = useState(false);
  const [showErrors, setShowErrors] = useState("");
  const [forgotPasswordFormError, setForgotPasswordFormError] = useState("");

  const [initialInput, setInitialInputs] = useState({
    email: "",
  });

  const [resetPasswordInitialInput, setResetPasswordInitialInput] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordCheck, setPasswordCheck] = useState("");
  const [hasPasswordUpdated, setHasPasswordUpdated] = useState(false);


  /*************************************************************************
   * THIS USEEFFECT TRIGGERS WHEN FORGOT PASSWORD EDIT MODE SETS TRUE/FALSE *
   * IF FPFisEditMode IS TRUE THEN FORGOT PASSWORD FORM FILLS WITH EXITING EMAIL VALUE ENTERED BY THE USER.
   *************************************************************************/
  useEffect(() => {
    if (FPFisEditMode === true) {
      setInitialInputs({
        email: FPFisEditMode === true ? FPFsubmittedEmail : "",
      });
    }
  }, [FPFisEditMode]);

  /********************************************************************
   * THIS FUNCTION TRIGGER WHEN THE USER SUBMMITING THE FORGOT PASSWORD FIRST LEVEL FORM *
   ********************************************************************/

  const forgotPasswordHandler = (data, { resetForm }) => {
    /***************************************
     * DATA FOR FORGOT PASSWORD FORM *
     ***************************************/

    let formData = {
      email: data.email,
    };

    if (data.email != "") {
      dispatch(forgotPassword(formData))
        .unwrap()
        .then((res) => {
          const { success, status_code, result, message, validation } =
            res.data;

          if (success === true) {
            setEmailCheckPassed(true);
            dispatch(FPFupdateEmail(data.email));

            if (FPFisEditMode === true) {
              dispatch(FPFupdateEditMode(false));
            }

            resetForm();
          } else {
            setForgotPasswordFormError(res);
            setTimeout(() => {
              setForgotPasswordFormError("");
            }, 4000);
          }
        });
    }
  };

  /*********************************************************************************************************************
   * THIS EDIT FUNCTION TRIGGERS WHEN THE USER CLICK ON THE EDIT BUTTON WHICH IS PLACED ON THE OTP SCREEN OF THE FORGOT PASSWORD *
   *********************************************************************************************************************/

  const editEmail = () => {
    dispatch(FPFupdateEditMode(true));
  };

  useEffect(() => {
    
    /*****************************************************
     * REMOVEING THE OTP FIELD PREVIOUS VALIDATION ERROR *
     *****************************************************/

    dispatch(FPFOTPerrorStatusUpdate(false));

    if (otp.length === 4) {
    /************************************************
     * MAKING THE API CALL WHEN OTP LENGTH HIT ON 4 *
     ************************************************/
      verifyOTPForgotPassword(otp);
    } 
  }, [otp]);

  /***********************************************************************
   * THIS FUNCTION TRIGGERS WHEN THE USER ENTER THE FOUR DIGIT OTP VALUE *
   ***********************************************************************/

  const verifyOTPForgotPassword = async (otp) => {
    if (otp.length == 4) {
      const params = {
        otp: otp,
        email: FPFsubmittedEmail,
      };

      dispatch(FPFOTPverifyOTP(params))
        .unwrap()
        .then((res) => {
          const { data } = res;

          if (data.success === true) {
            SetOTPCompleted(true);

            /***********************************************************************************************
             * AFTER SUCCESSFULLY VERIFYING OTP, THE TOKEN IS REVEIVED FROM SERVER SIDE IS STORED IN REDUX *
             ***********************************************************************************************/
            dispatch(FPFOTPstoreTokenValue(data.result.token));
          } else {
            /******************************************************************************
             * IF THE USER ENTERED OTP WAS INVALID THEN ERROR STATUS UPDATE INTO TRUE *
             ******************************************************************************/

            dispatch(FPFOTPerrorStatusUpdate(true));
          }
        });
    } else {
    }
  };

  /***********************************************************************
   * THIS FUNCTION TRIGGERS WHEN THE USER SUBMIT THE FORM OF RESET PASSWORD *
   ***********************************************************************/
  const resetPassword = async (data, { resetForm }) => {
    
    if(FPFOTPisLoading =="loading"){
      return false;
    }

    if (
      FPFOTPresponceToken != "" &&
      data.newPassword == data.confirmPassword
    ) {
      const params = {
        token: FPFOTPresponceToken,
        password: data.newPassword,
      };

      dispatch(forgotPasswordFinal(params))
        .unwrap()
        .then((res) => {
          const { data } = res;
          if (data.success === true) {

             
            /***************************************************************************
             * SETHASPASSWORDUPDATED SHOWS SUCCESS MESSAGE AFTER RESETING THE PASSWORD *
             ***************************************************************************/

              setHasPasswordUpdated(true);

            /************************
             * RESETS THE FORM DATA *
             ************************/
            
              resetForm();
             
             /*********************************************************************************
             * REDIRECTING TO LOGIN PAGE AFTER 4 SECONDS AFTER THE PASSWORD HAS BEEN UPDATED *
             *********************************************************************************/
              setTimeout(() => {

             /*********************************
             * TEMPERORY DATA HAS BEEN RESET *
             *********************************/

              SetOTPCompleted(false);
              setEmailCheckPassed(false);
              dispatch(FPFOTPstoreTokenValue(""));

              navigate("/login");

              }, 4000);

          } 
        });
    } else {
      setPasswordCheck("error");
      setTimeout(() => {
        setPasswordCheck("");
      }, 3000);
    }
  };

  return {
    initialInput,
    forgotPasswordHandler,
    setInitialInputs,
    otp,
    setOTP,
    setOTPverifying,
    forgotPasswordFormError,
    setForgotPasswordFormError,
    editEmail,
    FPFisLoading,
    FPFisEditMode,
    FPFsubmittedEmail,
    emailCheckPassed,
    setEmailCheckPassed,
    isOTPCompleted,
    SetOTPCompleted,
    FPFOTPerror,
    resetPasswordInitialInput,
    resetPassword,
    passwordCheck,
    hasPasswordUpdated,
    FPFOTPisLoading,
  };
};
