import React, { useState } from "react";
import Style from "./ForgotPasswordForm.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CommonInput from "../../../Layouts/CommonInput";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";

let hasErrors = false;

const ForgotPasswordForm = ({
  forgotPasswordHandler,
  initialInput,
  isFormLoading,
  error,
}) => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  return (
    <Formik
      initialValues={initialInput}
      onSubmit={(values, { resetForm }) => {
        forgotPasswordHandler(values, { resetForm });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
      validateOnBlur={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;

        return (
          <>
            <div className={Style.ForgotPassword__form}>
              {/* Email */}
              <div className="form-group">
                <div className="input-holder">
                  <CommonInput
                    type="text"
                    label="Email address"
                    placeholder="Enter your email"
                    variant="input"
                    name="email"
                    id="email"
                    value={values?.email}
                    autoComplete="new-email"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue(
                        "email",
                        e.target.value
                      );
                    }}
                    hasError={errors?.email && touched.email}
                    errorMsg={errors?.email}
                  />
                </div>
                {/* <small>Please enter valid Email ID</small> */}
              </div>
            </div>
            <p>{error !== "" ? error?.data?.message : ""}</p>
            <button
              type="button"
              disabled={!isValid || !dirty}
              className={`${Style.ForgotPassword__button} btn btn-primary w-100`}
              onClick={() => handleSubmit()}
            >
              {isFormLoading === "loading"
                ? <InfiniteDotLoader />
                : "Continue to OTP"}
            </button>
          </>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
