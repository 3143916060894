import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import GetStarted from "../../components/GetStartSteps/GetStarted";
import CommonLayout from "../../components/Layouts/CommonLayout";
import useAuthChecker from "../../Logic/Auth/useAuthChecker";
import InfiniteDotLoader from "../../utils/InfiniteDotLoader";

const GetStartedPage = () => {
  const { isAuthCompleted } = useAuthChecker();

  const { userProfileData, status } = useSelector((state) => state.userData);
  return (
    <CommonLayout pageClass="bg-light">
      {isAuthCompleted ? (
        <> 
          {userProfileData?.plan_payment_status === 0 ?(
            <Navigate to="/plans" />
          ): userProfileData?.plan_payment_status === 1 && userProfileData?.theme_class_name ? (
            <Navigate to="/dashboard" />
          ) : status == "loading" ? (
            <div className="status_check">
              <InfiniteDotLoader typeStatus="status_check" />
            </div>
          ) : (
            <GetStarted />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </CommonLayout>
  );
};

export default GetStartedPage;
