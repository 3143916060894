import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import Icon from "../../../Layouts/Icons";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";
import { useDispatch, useSelector } from "react-redux";

const DeleteProjectPopup = ({
  openDeleteModal,
  modalControl,
  handleDeleteAction,
  isItMulitple,
  isItFolder,
}) => {
  const { deleteProject } = useSelector((state) => state.project);

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={openDeleteModal}
      setShowModal={modalControl}
      centered={true}
      className={"type-sm info-modal"}
      title={
        <h4>
          Delete{" "}
          {isItFolder === true
            ? isItMulitple === true
              ? "Events"
              : "Event"
            : isItMulitple === true
            ? "Projects"
            : "Project"}
        </h4>
      }
      content={
        <>
          {isItMulitple !== true ? (
            <p>
              Are you sure you want to delete this{" "}
              {isItFolder === true
                ? isItMulitple === true
                  ? "events"
                  : "event"
                : isItMulitple === true
                ? "projects"
                : "project"}
              ?
            </p>
          ) : (
            <p>
              Are you sure you want to delete all these selected{" "}
              {isItFolder === true
                ? isItMulitple === true
                  ? "events"
                  : "event"
                : isItMulitple === true
                ? "projects"
                : "project"}
              ?
            </p>
          )}

          <div className="modal-footer">
            <button
              className="btn btn-red"
              onClick={() => {
                handleDeleteAction();
              }}
            >
              {deleteProject.status == "deleting" ? (
                <InfiniteDotLoader />
              ) : (
                "Delete" 
              )}
            </button>
            <button
              className="btn btn-border-grey"
              onClick={() => modalControl(false)}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
};

export default DeleteProjectPopup;
