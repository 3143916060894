import React, { useState } from "react";
import Style from "./ImageTray.module.scss";
import { useDimensions } from "../../../../Logic/Dimensions";
import Icon from "../../../Layouts/Icons";

const ImageTray = ({
  name,
  date,
  data,
  isSelectionOpen,
  isSelected,
  setIsSelected,
  isLoaded,
  preSelect,
  isCardSelected,
  handleSelectImage,
  setFavIsSelected,
  isFavSelected,
  handleSuggest,
}) => {
  const windowDimensions = useDimensions();
  
  const [isFavSelectedAni, setIsFavSelectedAni] = useState(false);
 
  
  return (
    <>
      {isLoaded ? (
        <div
          className={`${Style.ImageTray} ${
            isSelectionOpen ? Style.selectionOpen : ""
          } ${preSelect ? Style.selected : ""} image-tray bb`}
          onClick={(e) => {
            if (preSelect) {
              e.stopPropagation();
              handleSelectImage(data.id);
            }
          }}
        >
          {preSelect ? (
            <span
              className={`form-check-box ${isCardSelected ? "checked" : ""}`}
            >
              <Icon
                color={"#fff"}
                size={13}
                icon={isCardSelected ? "icon-tick" : ""}
              />
            </span>
          ) : (
            <>
              {windowDimensions.width >= 576 ? (
                <button className="btn btn-sm btn-border-white">View</button>
              ) : (
                ""
              )}
              <div className={`${Style.ImageTray__content_wrap} `}>
                <div className={`${Style.ImageTray__name} image-tray__name`}>
                  {name}
                </div>
                <div className={`${Style.ImageTray__date} image-tray__date`}>
                  {date}
                </div>
                {handleSuggest!==undefined &&
                <div
                className={`${Style.ImageTray__fav} image-tray__fav accc`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSuggest({
                    status: !isFavSelected,
                    image_id: data.id,
                    setFavIsSelected,setFavIsSelected
                  });
                }}
              >
                <Icon
                  color={`${
                    isFavSelected
                      ? "red"
                      : ""
                  }`}
                  stroke={`${
                    isFavSelected
                      ? " "
                      : "#000"
                  }`}
                  size={16}
                  icon={"heart-line-icon"}
                />
              </div>
                }
                

              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ImageTray;
