import { useEffect, useRef } from "react";

export function useSingleKey(key, callback) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handle(event) {
      if (event.code == key) {
        callbackRef.current(event);
      }
    }
    document.addEventListener("keydown", handle);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handle);
    };
  }, [key]);
}
