import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEditEventDetails } from "../../../Logic/useEditEventDetails";
import AddStaffCard from "../AddStaffCard";
import AssignedProjectDetails from "../AddStaffCard/AssignedProjectDetails";
import { AddNewFolderModal } from "../ContentHeader/AddNewFolderModal";
import Style from "./ContractStaff.module.scss";
import TableHeader from "./TableHeader";
import { useEffect } from "react";

const ContractStaff = React.forwardRef(
  (
    {
      staffViewData,
      handleStaffFilter,
      handletoggleStaffEdit,
      handleSetStaffEditData,
      handletoggleAssignedProjects,
      handlesetAssignedProjects,
      designations
    },
    ref
  ) => {
    const [isActiveDropdown, setIsActiveDropdown] = useState("Assigned");
    const { assignedProjects } = useSelector((state) => state.staffs);

    const {
      getAllStaffs,
      getAllEventCategories,
      getAllEvents,
      setOpenEditFolderModal,
      openEditFolderModal,
      handleSubmitCreateNewFolder,
      setAddNewProjectPopup,
      addNewProjectPopup,
      handleToggleEditFolder,
      setProjectID,
    } = useEditEventDetails();


    return (
      <>
        <div className={Style.AddStaffList__wrap}>
          <Table className={Style.AddStaffList}>
            {staffViewData?.data?.length == 0 ? (
              <>
                <TableHeader
                  isActiveDropdown={isActiveDropdown}
                  setIsActiveDropdown={setIsActiveDropdown}
                  handleStaffFilter={handleStaffFilter}
                />
                <tbody>
                  <tr>
                    <td
                      colSpan={6}
                      align="center"
                      className="py-5 border-bottom-0"
                    >
                      <span className="fz-18">No more staff</span>
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                <TableHeader
                  isActiveDropdown={isActiveDropdown}
                  setIsActiveDropdown={setIsActiveDropdown}
                  handleStaffFilter={handleStaffFilter}
                />

                <tbody className={Style.AddStaffList__contents}>
                  {staffViewData?.data?.map((staff, index) => {
                    const lastIndex = staffViewData?.data?.length - 1;

                    return (
                      <React.Fragment key={index}>
                        <AddStaffCard
                          designations={designations}
                          staffData={staff}
                          name={staff?.name}
                          phone={staff?.mobile}
                          email={staff?.email}
                          ref={ref}
                          staff_assigned={staff.staff_assigned}
                          lastIndex={lastIndex}
                          itemIndex={index}
                          staffID={staff.id}
                          handletoggleStaffEdit={handletoggleStaffEdit}
                          handleSetStaffEditData={handleSetStaffEditData}
                          handletoggleAssignedProjects={
                            handletoggleAssignedProjects
                          }
                          handlesetAssignedProjects={handlesetAssignedProjects}
                        />
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </>
            )}
          </Table>
        </div>

        <AssignedProjectDetails
          isAssignedEventInfo={assignedProjects.status}
          setIsAssignedEventInfo={handletoggleAssignedProjects}
          setIsAddNewFolderModal={() => { }}
          handleToggleEditFolder={handleToggleEditFolder}
          setAddNewProjectPopup={setAddNewProjectPopup}
          setProjectID={setProjectID}
        />

        <AddNewFolderModal
          handleSubmitCreateNewFolder={handleSubmitCreateNewFolder}
          addNewProjectPopup={openEditFolderModal}
          setAddNewProjectPopup={setOpenEditFolderModal}
          isEventEdit={true}
        />
      </>
    );
  }
);

export default ContractStaff;
