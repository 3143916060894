import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  activeUploadIndex: 0,
  activeUploadPercent: 0,
  isSingleImageUpload: false,
  openUploadFPAModal: false,
  openFPAMiniBox: false,
  thumbs: [],
  duplicateImages: [],
  tokenDetails: null,
  tokenDetailsIsLoaded: false,
  allUploadedImages: [],


  duplicateImagesFPA: [],
  invalidImagesFPA: [],
  allFilesFPA: [],
  filesFPA: [],
  imagesFPA: [],
  newFilesFPA: [],
  uploadIndexFPA: 0,
  isUploadingFPA: false,
};

/**********************************************************
 * GETTING FPA UPLOAD TOKEN FETTING FROM FAMILYPHOTOS API *
 **********************************************************/

export const getFPAtoken = createAsyncThunk(
  "gallery/getFPAtoken",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance(true);
    try {
      const response = await api.post("v1/pf-upload", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const getAllFPAUploadedImages = createAsyncThunk(
//   "gallery/getAllFPAUploadedImages",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance(true);
//     try {
//       const response = await api.post(`/v1/list-images`, params);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const galleryFPAupoadSlice = createSlice({
  name: "galleryFPAupload",
  initialState,
  reducers: {
    addToThumbs: (state, action) => {
      state.thumbs = [...state.thumbs, action.payload];
    },

    updateThumbsArray: (state, action) => {
      state.thumbs = action.payload;
    },

    clearFPAThumbs: (state, action) => {
      state.thumbs = [];
      state.activeUploadPercent = 0;
    },

    updateActiveUploadPercentage: (state, action) => {
      state.activeUploadPercent = action.payload;
    },

    updateActiveIndex: (state, action) => {
      state.activeUploadIndex = action.payload;
    },

    uploadFPAModalAction: (state, action) => {
      state.openUploadFPAModal = action.payload;
    },

    openFPAMinimizedBox: (state, action) => {
      state.openFPAMiniBox = action.payload;
    },

    callFunction: (state, action) => {
      action.payload(state, action);
    },

    openDeleteImagesPopup: (state, action) => {
      state.sections[state.currentTab]["currentAction"] = {
        preSelect: true,
        deleteWarningPopup: action.payload,
      };
    },
    clearThumbs: (state, action) => {
      state.thumbs = [];
      // state.activeUploadIndex = 0;
      state.activeUploadPercent = 0;
      state.duplicatedImageIndexs = [];
    },
    clearFPAUploadToken: (state, action) => {
      state.tokenDetails = null;
      state.tokenDetailsIsLoaded = false;
    },
    updateAllUploadedImages: (state, action) => {
      try {
        state.allUploadedImages = [
          ...state.allUploadedImages,
          {
            id: action.payload?.uploadResponse?.data?.result.image.id,
            original_name:
              action.payload?.uploadResponse?.data?.result.image.original_name,
            src: action.payload?.uploadResponse?.data?.result.image.src,
          },
        ];
      } catch (error) {

      }
    },

    updateDuplicateImages: (state, action) => {
      state.duplicateImagesFPA = action.payload;
    },

    updateInvalidImages: (state, action) => {
      state.invalidImagesFPA = action.payload;
    },

    updateAllFiles: (state, action) => {
      state.allFilesFPA = action.payload;
    },

    updateFiles: (state, action) => {
      state.filesFPA = action.payload;
    },

    updateImages: (state, action) => {
      state.imagesFPA = action.payload;
    },

    updateNewFiles: (state, action) => {
      state.newFilesFPA = action.payload;
    },

    updateUploadIndex: (state, action) => {
      state.uploadIndexFPA = action.payload;
    },

    updateIsUploading: (state, action) => {
      state.isUploadingFPA = action.payload;
    },
    
  },
  extraReducers: {
    [getFPAtoken.pending]: (state, action) => {
      state.tokenDetailsIsLoaded = "loading";
    },
    [getFPAtoken.fulfilled]: (state, action) => {
      state.tokenDetails = action.payload.data.result;
      state.tokenDetailsIsLoaded = "success";
    },
    [getFPAtoken.rejected]: (state, action) => {
      state.tokenDetailsIsLoaded = "error";
    },

    // [getAllFPAUploadedImages.pending]: (state, action) => {},
    // [getAllFPAUploadedImages.fulfilled]: (state, action) => {
    //   state.allUploadedImages = action.payload.data.result;
    // },
    // [getAllFPAUploadedImages.rejected]: (state, action) => {},
  },
});

export const {
  currentTabHandler,
  openPreSelectionhandler,
  closePreSelect,
  selectAllImages,
  unSelectAllImages,
  addToThumbs,
  clearFPAThumbs,
  updateActiveUploadPercentage,
  updateActiveIndex,
  callFunction,
  uploadFPAModalAction,
  openMinimizedBox,
  selectSingleImage,
  deSelectSingleImage,
  closeFilterActions,
  openDeleteImagesPopup,
  deleteActionSuccess,
  clearThumbs,
  clearFPAUploadToken,
  updateAllUploadedImages,
  updateThumbsArray,

  updateDuplicateImages,
  updateInvalidImages,
  updateAllFiles,
  updateFiles,
  updateImages,
  updateNewFiles,
  updateUploadIndex,
  updateIsUploading,
  openFPAMinimizedBox
} = galleryFPAupoadSlice.actions;

export default galleryFPAupoadSlice.reducer;
