import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFolderview } from "../../../../Logic/UserFlow/useFolderview";
import { useSingleKey } from "../../../../utils/KeyBoardShortCuts";
import PhotoFolderCard from "../../../Cards/PhotoFolderCard";
import ImagePreview from "../../ImagePreview";
import Style from "../FolderView.module.scss";

const SelectedPhotos = () => {
  const { currentTab, sections } = useSelector((state) => state.userFlow);
  const { setIsImagePreview, setIsSlideKey, isImagePreview, isSlideKey } =
    useFolderview();

  const handleEscape = () => {
    setIsImagePreview(false);
  };

  useSingleKey("Escape", handleEscape);

  return (
    <div className={Style.user_flow_folderview_body}>
      {sections[currentTab]?.data?.length > 0 ? (
        <Row>
          {sections[currentTab]?.data?.map((data, index) => {
            return (
              <Col
                sm="6"
                className={`img-loading-2-item ${Style.col_image_wrap}`}
                key={index}
              >
                <PhotoFolderCard
                  image={data?.src?.thumbnail_medium}
                  imagePreview={setIsImagePreview}
                  setIsSlideKey={setIsSlideKey}
                  keyId={index}
                  id={data?.id}
                  setIsImagePreview={setIsImagePreview}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <span>No Photos Selected</span>
      )}

      {isImagePreview === true ? (
        <ImagePreview
          slideData={sections[currentTab]?.data}
          setIsImagePreview={setIsImagePreview}
          activeSlideIndex={isSlideKey}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectedPhotos;
