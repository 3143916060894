import CommonModal from "../../../Modals/CommonModal";
import React, { forwardRef, useEffect, useState } from "react";

function DeleteStaffPopup({openDeleteModal,setOpenDeleteModal,staffID,type,deleteStaffData}) {
    return (
        <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        centered={true}
        className={"type-sm info-modal"}
        title={<h4>Remove Staff</h4>}
        content={
          <>
            <p>Are you sure you want to remove this staff?</p>
          </>
        }
        footer={
          <>
            <button
              className="btn btn-red"
              onClick={() => {
                deleteStaffData({id:staffID});
              }}
            >
              Remove
            </button>
            <button
              className="btn btn-border-grey"
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </button>
          </>
        }
      />
    );
  }

  export default DeleteStaffPopup;