import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Icon from "../../Layouts/Icons";
import PreferenceContainer from "../PreferenceContainer";
import Style from "./Preference.module.scss";

const Preference = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={Style.Preference}>
      <PreferenceContainer>
        {location.pathname === "/dashboard/preference" ||
        location.pathname === "/dashboard/preference/" ? (
          <h1 className={Style.Preference__title}>Preference</h1>
        ) : location.pathname === "/dashboard/preference/staffs" ||
          location.pathname === "/dashboard/preference/staffs/" ? (
          <div className={Style.Title__backButton}>
            <button onClick={() => navigate("/dashboard/preference")}>
              <Icon color={"#000"} size={14} icon={"slider-nav-left"} />
            </button>
            <h1 className={Style.Preference__title}>Staff</h1>
          </div>
        ) : location.pathname === "/dashboard/preference/studio" ||
          location.pathname === "/dashboard/preference/studio/" ? (
          <div className={Style.Title__backButton}>
            <button onClick={() => navigate("/dashboard/preference")}>
              <Icon color={"#000"} size={14} icon={"slider-nav-left"} />
            </button>
            <h1 className={Style.Preference__title}>Studio account</h1>
          </div>
        ) : (
          ""
        )}
        <Outlet />
      </PreferenceContainer>
    </div>
  );
};

export default Preference;
