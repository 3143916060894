import React from "react";
import CommonInput from "../../../Layouts/CommonInput";
import Style from "./EmailLogin.module.scss";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useLogin } from "../../../../Logic/useLogin";
import { useRegister } from "../../../../Logic/useRegister";
import { useDispatch, useSelector } from "react-redux";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";
import { useDimensions } from "../../../../Logic/Dimensions";
import { Col, Row } from "react-bootstrap";

let hasErrors = false;

const EmailLogin = ({ setCurrentTab, currentTab, setIsOtpVerification }) => {
  const {
    handleLoginWithEmail,
    initialInput,
    setInitialInputs,
    loginError,
    loginErrorData,
    isLoginProgress,
  } = useLogin();

  const { message } = loginErrorData;

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required."),
  });
  const windowDimensions = useDimensions();

  return (
    <Formik
      initialValues={initialInput}
      onSubmit={(values, { resetForm }) => {
        handleLoginWithEmail(values, { resetForm });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;

        return (
          <>
            <div className={Style.Login__form}>
              {/* Email */}
              <CommonInput
                type="text"
                label="Email address"
                placeholder="Enter Your Email Id"
                variant="input"
                name="input_email"
                id="input_email"
                autoComplete="new-email"
                value={values.email}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                }}
                hasError={errors?.email && touched.email}
                errorMsg={errors?.email}
              />
              {/* Password */}
              <CommonInput
                label="Password"
                placeholder="Enter Password"
                variant="password-visibility-toggle"
                name="input_password"
                id="input_password"
                autoComplete={"new-password"}
                value={values.password}
                onBlur={handleBlur}
                onChange={(e) => {
                  console.log(e.target.value,"hhhhhhhh")
                  setFieldValue("password", e.target.value);
                }}
                hasError={errors?.password && touched.password}
                errorMsg={errors?.password}
              />
              <Row className={Style.forgot_pswd_row}>
                <Col sm>
                  {loginError === true && (
                    <p className={`text-danger mb-0`}>
                      {"Sorry Invalid Details!"}
                    </p>
                  )}
                </Col>
                <Col sm>
                  <div className={Style.btn_forgot_password}>
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                </Col>
              </Row>

              {/* <div className={Style.btn_forgot_password}>
                <Link to="/forgot-password">Forgot Password?</Link>
              </div> */}
            </div>
            {/* {loginError === true && (
                <p className="form-error-note">Sorry Invalid Details!</p>
              )} */}
            <button
              type="submit"
              disabled={!isValid || !dirty}
              className={`${Style.Login__button} btn btn-primary w-100`}
              onClick={() => handleSubmit()}
            >
              {isLoginProgress === "loading" ? (
                <InfiniteDotLoader />
              ) : (
                "Sign In"
              )}
            </button>
          </>
        );
      }}
    </Formik>
  );
};

export default EmailLogin;
