import fr from "../assets/Translation/fr.json";
import en from "../assets/Translation/en.json";

const useTranslation = () => {
  const t = (key) => {
    if (typeof window !== "undefined") {
      const language = localStorage.getItem("currentLang");
      if (language === "fr") {
        return fr[key];
      } else {
        return en[key];
      }
    }
  };
  return { t };
};

export default useTranslation;
