import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import {
  storeCurrentPageDetails,
  getCurrentPageDetails,
  getStaffDetails,
  getEventCategory,
  submitCreateNewFolder,
  getFoldersByProjectID,
  toggleEditFolder,
  getFolderDetailsByID,
  getEventNames,
  customLoadMore,
  togglePreSelect,
  selectAllFolders,
  unSelectAllFolders,
  singleSelectionFolder,
  selectionCancel,
  deleteFolders,
  deleteSingleFolder,
  selectItemToBeDelete,
  cancelDeleteAction,
  deleteMultipleStatus,
  changeFolderOrder,
  eventInfoPopup,
  getEventInfoPopupDetails,
  shareInfoPopup,
  getEventShareInfoPopupDetails,
  paymentAlert,
  clearSingleProjectData,
} from "../store/slices/Project/ProjectSingle";

import { statusChangeLinkShare } from "../store/slices/Gallery/gallerySlice";

export const useEditEventDetails = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  //const projectID = 8000;
  const [projectID, setProjectID] = useState("");
  const [openEditFolderModal, setOpenEditFolderModal] = useState(false);
  const [openEditProjectModal, setOpenEditProjectModal] = useState(false);
  const [addNewProjectPopup, setAddNewProjectPopup] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEventInfoModal, setOpenEventInfoModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const { getCurrentProjectDetails, allFolders, createNewFolder, status } =
    useSelector((state) => state.projectSinglePage);
  const { currentPageDetails } = getCurrentProjectDetails;

  const handleProjectSinglePage = (data) => {
    dispatch(storeCurrentPageDetails(data));
    navigate("/dashboard/project/" + data.id);
  };

  const handleOpenGalleryPage = (data) => {
    //dispatch(storeCurrentPageDetails(data));
    navigate("folders/" + data.id);
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return "";
    } else {
      return string?.charAt(0).toUpperCase() + string?.slice(1);
    }
  };

  const getAllStaffs = () => {
    return dispatch(getStaffDetails());
  };

  const getAllEventCategories = () => {
    return dispatch(getEventCategory());
  };

  const convert = (date) => {
    const d = Date.parse(date);
    const date_obj = new Date(d);
    return `${date_obj.getFullYear()}-${date_obj.toLocaleString("default", {
      month: "2-digit",
    })}-${date_obj.toLocaleString("default", { day: "2-digit" })}`;
  };

  const handleSubmitCreateNewFolder = (data, resetForm) => {
    try {
      const formData = {
        project_id: projectID,
        album_name_id: "",
        event_category_id: data.eventCategory,
        staff_id: data?.staff?.split(","),
        name: data?.eventName,
        location_name: data?.location,
        date: convert(data?.date),
      };
      if (data.customName != "" && data.customName != undefined) {
        formData.album_name_id = "";
        formData.name = data.customName;
      } else if (Number.isInteger(data.eventName)) {
        formData.album_name_id = data.eventName;
        formData.name = "";
      }

      if (allFolders.editFolder.status) {
        formData.id = allFolders.editFolder.id;
      }

      dispatch(submitCreateNewFolder(formData))
        .unwrap()
        .then((res) => {
          if (res.data.success === true) {
            resetForm();
            setOpenEditFolderModal(false);
            setAddNewProjectPopup(false);
            if (res.data.result !== 1) {
              navigate(
                `/dashboard/project/${res.data.result.project_id}/folders/${res.data.result.id}`
              );
            }
          } else {
            alert("Something went wrong!");
          }
        });
    } catch (error) {}
  };

  const handleToggleEditFolder = (status) => {
    let promiseArray = [];

    if (createNewFolder?.staffList?.length === 0) {
      promiseArray.push(getAllStaffs());
    }

    if (createNewFolder?.eventCategories.length === 0) {
      promiseArray.push(getAllEventCategories());
    }

    if (createNewFolder?.events.length === 0) {
      promiseArray.push(getAllEvents());
    }

    Promise.all(promiseArray).then((res) => {
      dispatch(toggleEditFolder(status));
      dispatch(getFolderDetailsByID(status))
        .unwrap()
        .then((res) => {
          if (res.data.success === true) {
            setOpenEditFolderModal(true);
          }
        });
    });
  };

  const getAllEvents = () => {
    return dispatch(getEventNames());
  };

  const handleTogglePreSelect = (state) => {
    dispatch(togglePreSelect(state));
  };

  const handleSelectAllFolders = () => {
    if (allFolders?.list?.data?.length > 0) {
      const foldersIDs = [];
      allFolders?.list?.data?.forEach((folder) => {
        foldersIDs.push(folder.id);
      });
      dispatch(selectAllFolders(foldersIDs));
    }
  };

  const handleUnSelectAllFolders = () => {
    dispatch(unSelectAllFolders([]));
  };

  const handleSingleSelectionFolder = (folderID) => {
    dispatch(singleSelectionFolder(folderID));
  };

  const handleCancelSelectionsFolder = (folderID) => {
    dispatch(selectionCancel());
  };

  const handleDeleteFoldersPopupToggle = (status) => {
    dispatch(deleteMultipleStatus(status));
  };

  const handleSingleDeleteFolder = () => {
    if (allFolders.deleteSingleFolder.selection) {
      dispatch(deleteSingleFolder({ id: [allFolders.deleteSingleFolder.id] }))
        .unwrap()
        .then((res) => {
          if (!res?.data?.success) {
            alert("Something went wrong!!");
          }
        });
    }
  };

  return {
    projectID,
    capitalizeFirstLetter,
    handleProjectSinglePage,
    getCurrentProjectDetails,

    openEditFolderModal,
    setOpenEditFolderModal,

    openEditProjectModal,
    setOpenEditProjectModal,

    addNewProjectPopup,
    setAddNewProjectPopup,

    openDeleteModal,
    setOpenDeleteModal,

    getAllStaffs,
    getAllEvents,
    getAllEventCategories,
    handleSubmitCreateNewFolder,
    handleToggleEditFolder,
    openEventInfoModal,
    setOpenEventInfoModal,
    openShareModal,
    setOpenShareModal,

    handleTogglePreSelect,
    handleSelectAllFolders,
    handleUnSelectAllFolders,
    handleSingleSelectionFolder,
    handleCancelSelectionsFolder,
    handleSingleDeleteFolder,
    handleSingleDeleteFolder,
    handleDeleteFoldersPopupToggle,

    handleOpenGalleryPage,

    status,
    currentPageDetails,
    setProjectID,
  };
};
