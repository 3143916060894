import React, { useState } from "react";
import CommonInput from "../../../../Layouts/CommonInput";
import Style from "./ClientDetailsForm.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import useAddNewProjects from "../../../../../Logic/useAddNewProjects";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";
import { useDispatch, useSelector } from "react-redux";

let hasErrors = false;

export const ClientDetailsForm = ({
  setOpenCreateProjectModal,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  handleCancel,
}) => {
  const { storeClientData } = useAddNewProjects();

  const { isLoading } = useSelector((state) => state.project.addProjects);

  const [initialInput, setInitialInputs] = useState({
    clientName: "",
    email: "",
    mobile: "",
    dial_code: "+91",
    sec_email: "",
    sec_mobile: "",
    sec_dial_code: "+91",
  });

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const SignupSchema = Yup.object().shape({
    clientName: Yup.string().required("Client Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    sec_email: Yup.string().email("Invalid email format"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Invalid mobile number")
      .required("Mobile number is required")
      .min(7, "Min 7 digits is required")
      .max(14, "Max 14 digits is required"),
    sec_mobile: Yup.string()
      .matches(phoneRegExp, "Invalid mobile number")
      .min(7, "Min 7 digits is required")
      .max(14, "Max 14 digits is required"),
  });

  return (
    <Formik
      initialValues={initialInput}
      onSubmit={(values, { resetForm, setFieldError }) => {
        let formData = {
          project_id: "",
          name: values.clientName,
          email: values.email,
          mobile: values.mobile,
          country_code: values.dial_code,
          sec_email: values.sec_email,
          sec_mobile: values.sec_mobile,
          sec_country_code: values.sec_dial_code,
        };

        storeClientData({ setFieldError: setFieldError, formData: formData });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
      validateOnBlur={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;
        console.log("errors",errors);
        console.log("errors?.email && touched.input_email",touched?.email);
        return (
          <>
            <div className="row">
              <div className="col-lg-12">
                <CommonInput
                  label="Client Name*"
                  variant="input"
                  id="input_client_name"
                  floatingLabel={true}
                  value={values.clientName}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("clientName", e.target.value);
                  }}
                  hasError={errors?.clientName && touched.input_client_name}
                  errorMsg={errors?.clientName}
                />
              </div>
              <div className="col-lg-6">
                <CommonInput
                  label="Email*"
                  type="email"
                  variant="input"
                  id="input_email"
                  name="input_email"
                  floatingLabel={true}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                  }}
                  hasError={errors?.email && touched.email}
                  errorMsg={errors?.email}
                />
              </div>
              <div className="col-lg-6">
                <CommonInput
                  label="Mobile*"
                  variant="phone-with-country"
                  floatingLabel={true}
                  id="input_phone"
                  default_country="IN"
                  value={values.mobile}
                  // country_dial_code={""}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("mobile", e.target.value);
                  }}
                  callback={function (country) {
                    setFieldValue("dial_code", country.dial_code);
                  }}
                  hasError={errors?.mobile && touched.mobile}
                  errorMsg={errors?.mobile}
                />
              </div>

              <div className="col-lg-6">
                <CommonInput
                  label="Additional Email"
                  type="email"
                  variant="input"
                  id="input_email"
                  floatingLabel={true}
                  value={values.sec_email}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("sec_email", e.target.value);
                  }}
                  hasError={errors?.sec_email && touched.sec_email}
                  errorMsg={errors?.sec_email}
                />
              </div>
              <div className="col-lg-6">
                <CommonInput
                  label="Additional Mobile"
                  variant="phone-with-country"
                  floatingLabel={true}
                  id="input_phone"
                  default_country="IN"
                  value={values.sec_mobile}
                  // country_dial_code={""}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("sec_mobile", e.target.value);
                  }}
                  callback={function (country) {
                    setFieldValue("sec_dial_code", country.sec_dial_code);
                  }}
                  hasError={errors?.sec_mobile && touched.sec_mobile}
                  errorMsg={errors?.sec_mobile}
                />
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => {
                  handleCancel(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={!isValid || !dirty || isLoading}
                className="btn btn-primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {isLoading ? <InfiniteDotLoader /> : "Next"}
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
