import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  destinationStatus:"idle",
  allCount: {},
  allLiveProjects: {},
  permanentStaffList: {},
  contractStaffList: {},
  permanentStaffSort: "",
  contractStaffSort: "",
};

export const getCounts = createAsyncThunk(
  "getDashboard/getCounts",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("dashboard/all-count");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLiveProject = createAsyncThunk(
  "getDashboard/getLiveProject",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("dashboard/live-projects");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getPermanentStaffList = createAsyncThunk(
  "getDashboard/getPermanentStaffList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const asString = new URLSearchParams(params).toString();

      const response = await api.get(
        `dashboard/${params.url}?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getContractStaffList = createAsyncThunk(
  "getDashboard/getContractStaffList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const asString = new URLSearchParams(params).toString();

      const response = await api.get(
        `dashboard/${params.url}?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getDashboardSlice = createSlice({
  name: "getDashboard",
  initialState,
  reducers: {
    updatePermanentStaffSort: (state, action) => {

      state.permanentStaffSort = action.payload;
    },
    updateContractStaffSort: (state, action) => {
      state.allCount.contractStaffCount = action.payload.length;
      state.contractStaffSort = action.payload;
    },
    insertNewPermanentStaffdata: (state, action) => {
      state.allCount.permanentStaffCount = action.payload.length;
      state.permanentStaffList.data = action.payload;
    },
    insertNewContractStaffdata: (state, action) => {
      state.allCount.contractStaffCount = action.payload.length;
      state.contractStaffList.data = action.payload;
    },
    updateContractStaffData: (state, action) => {
      state.allCount.contractStaffCount = action.payload.length;
      state.contractStaffList.data = action.payload;
    },
    updatePermanentStaffData: (state, action) => {
      state.allCount.permanentStaffCount = action.payload.length;
      state.permanentStaffList.data = action.payload;
    },
  },
  extraReducers: {
    [getCounts.pending]: (state, action) => {
      state.countStatus = "loading";
      state.message = "";
    },
    [getCounts.fulfilled]: (state, action) => {
      state.countStatus = "succeed";
      state.allCount = action.payload.data.result;
    },
    [getCounts.rejected]: (state, action) => {
      state.countStatus = "error";
    },

    [getLiveProject.pending]: (state, action) => {
      state.statusLiveProject = "loading";
      state.message = "";
    },
    [getLiveProject.fulfilled]: (state, action) => {
      state.statusLiveProject = "succeed";
      state.allLiveProjects = action.payload.data.result;
    },
    [getLiveProject.rejected]: (state, action) => {
      state.statusLiveProject = "error";
    },

    [getPermanentStaffList.pending]: (state, action) => {
      state.statusPermanentStaffList = "loading";
      state.message = "";
    },
    [getPermanentStaffList.fulfilled]: (state, action) => {
      state.statusPermanentStaffList = "succeed";
      state.permanentStaffList = action.payload.data.result;
    },
    [getPermanentStaffList.rejected]: (state, action) => {
      state.statusPermanentStaffList = "error";
    },

    [getContractStaffList.pending]: (state, action) => {
      state.statusContractStaffList = "loading";
      state.message = "";
    },
    [getContractStaffList.fulfilled]: (state, action) => {
      state.statusContractStaffList = "succeed";
      state.contractStaffList = action.payload.data.result;
    },
    [getContractStaffList.rejected]: (state, action) => {
      state.statusContractStaffList = "error";
    },



  },
});

export const {
  updatePermanentStaffSort,
  updateContractStaffSort,
  insertNewPermanentStaffdata,
  insertNewContractStaffdata,
  updateContractStaffData,
  updatePermanentStaffData,
} = getDashboardSlice.actions;

export default getDashboardSlice.reducer;
