import React, { useState } from "react";
import CommonInput from "../../../../Layouts/CommonInput";
import Style from "./ProjectDetailsForm.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import useAddNewProjects from "../../../../../Logic/useAddNewProjects";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";

let hasErrors = false;

export const ProjectDetailsForm = ({
  setOpenCreateProjectModal,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  handleShowAddProject,
  handleCancel,
}) => {
  const { storeProjectData, storeClientData } = useAddNewProjects();

  const [initialInput, setInitialInputs] = useState({
    projectName: "",
    location: "",
  });

  const [loader, setLoader] = useState(false);

  const SignupSchema = Yup.object().shape({
    projectName: Yup.string().required("Project Name is required"),
    location: Yup.string().required("Location is required."),
  });

  return (
    <Formik
      initialValues={initialInput}
      onSubmit={(values, { resetForm }) => {
        let formData = {
          id: "",
          client_id: "",
          name: values.projectName,
          location_name: values.location,
        };
        storeProjectData({
          formData: formData,
          resetForm: resetForm,
          setAddProjectStepOne: setAddProjectStepOne,
          setAddProjectStepTwo: setAddProjectStepTwo,
        });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;
        
        return (
          
          <>
       
            <CommonInput
              label="Project Name*"
              variant="input"
              id="input_project_name"
              floatingLabel={true}
              value={values.projectName}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("projectName", e.target.value);
              }}
              hasError={errors?.projectName && touched.input_project_name}
              errorMsg={errors?.projectName}
            />
            <CommonInput
              label="Location*"
              variant="location-input"
              id="input_location"
              floatingLabel={true}
              value={values.location}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("location", e.target.value);
              }}
              hasError={errors?.location && touched.input_location}
              errorMsg={errors?.location}
            />

            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => {
                  handleCancel(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={!isValid || !dirty}
                className="btn btn-primary"
                onClick={() => {
                  setLoader(true);
                  setTimeout(() => {
                    handleSubmit();
                    setLoader(false);
                  }, 800);
                }}
              >
                {loader ? <InfiniteDotLoader /> : "Next"}
              </button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};
