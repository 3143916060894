import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBasicData,
  updateThemeState,
} from "../../store/slices/GetStarted/userBasicDataSlice";
import { postTheme } from "../../store/slices/Theme/postThemeSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/Auth/authSlice";
import useAuthChecker from "../Auth/useAuthChecker";

export const useThemeSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeData } = useSelector((state) => state.theme);
  const { preference } = useSelector((state) => state.userData.userProfileData);
  const { userProfileData } = useSelector((state) => state.userData);
  const { isAuthCompleted } = useAuthChecker();
  useEffect(() => {
   // if (Object.keys(userProfileData).length == 0) {
    if(isAuthCompleted){
      dispatch(getUserBasicData())
      .unwrap()
      .then((data) => {})
      .catch((err) => {
        if (err.message === "Unauthenticated.") {
          navigate("/");
          dispatch(logout());
        }
      });
    }
   // }
  }, []);

  const [dropdownThemes, setDropdownThemes] = useState("");

  useEffect(() => {
    let newData = themeData?.filter((item) => {
      return item?.id == preference?.theme?.id;
    });
    setDropdownThemes(newData[0]?.name);
  }, [themeData, preference]);

  const selectTheme = async (data) => {
    setDropdownThemes(data?.name);
    const resultAction = await dispatch(postTheme({ theme_id: data?.id }));
    dispatch(updateThemeState(data?.class_name));

    if (postTheme.fulfilled.match(resultAction)) {
    }
  };

  return { selectTheme, dropdownThemes };
};
