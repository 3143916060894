import React, { useState, useEffect, useRef } from "react";
import Assets from "../../Layouts/Assets";
import GalleryListView from "../GalleryListVIew";
import ImagePreview from "../../UserFlow/ImagePreview";
import useGallery from "../../../Logic/useGallery";
import { useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import MasonryCard from "../GalleryMasonryAllImages/MasonryCard";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import BlankDataPlaceholder from "../BlankDataPlaceholder";
import useMasonry from "../../../Logic/useMasonry";

const ProjectGallerySuggested = () => {
  const rootRef = useRef(null);

  const { sections, currentTab, selectedImageListView, status } = useSelector(
    (state) => state.gallery
  );

  const {
    selectedImage,
    setSelectedImage,
    isImagePreview,
    setIsImagePreview,
    isSelectionOpen,
    setIsSelectionOpen,
    imgRef,
    observer,
  } = useMasonry();

  const { getImagesForSection, loadMore, handleSuggest, handleGalleryTab } =
    useGallery();

  useEffect(() => {
    handleGalleryTab("suggested");

      getImagesForSection("suggested");
  
  }, []);

  useEffect(() => {
    if (imgRef?.current) {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries?.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore("suggested");
        }
      });
      observer?.current?.observe(imgRef?.current);
      return () => {
        observer.current?.disconnect();
      };
    }
  }, [sections[currentTab]?.data, rootRef, selectedImageListView]);

  return (
    <>
      <div ref={rootRef}>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 0: 1, 320: 2, 576: 3, 992: 4, 1600: 5 }}
        >
          <Masonry>
            {sections[currentTab]?.data?.map((data, i) => {
              return (
                <div ref={imgRef}>
                  <MasonryCard
                    key={i}
                    data={data?.images}
                    isLiked={data?.is_suggested}
                    isImagePreview={isImagePreview}
                    setIsImagePreview={setIsImagePreview}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    isSelectionOpen={isSelectionOpen}
                    dataIndex={i}
                    handleSuggest={handleSuggest}
                  />
                </div>
              );
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>

      {sections[currentTab]?.data.length === 0 ? (
        <BlankDataPlaceholder
          title={"You have not suggested any images."}
          description={"Suggested images are shown here"}
          template={"image"}
          buttonText={""}
        />
      ) : (
        ""
      )}
      {!isSelectionOpen && isImagePreview ? (
        <ImagePreview
          slideData={sections[currentTab]?.data.map((item) => item.images)}
          setIsImagePreview={setIsImagePreview}
          activeSlideIndex={selectedImage}
          type="normal-lightbox"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProjectGallerySuggested;
