import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Register from "../../components/AuthPages/Register";
import CommonLayout from "../../components/Layouts/CommonLayout";
import { changeScreen } from "../../store/slices/Auth/authSlice";

const RegisterPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(changeScreen(""));
    };
  }, []);

  return (
    <CommonLayout>
      <Register />
    </CommonLayout>
  );
};

export default RegisterPage;
