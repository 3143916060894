import React from "react";
import { useDimensions } from "../../../Logic/Dimensions";
import useGallery from "../../../Logic/useGallery";
import BlankDataPlaceholder from "../BlankDataPlaceholder";
import Style from "./GalleryListView.module.scss";

const GalleryListView = React.forwardRef(
  (
    {
      imageData,
      setIsImagePreview,
      setSelectedImage,
      blankTitle,
      blankDescription,
      blankButton,
      blankTemplate,
    },
    ref
  ) => {
    const windowDimensions = useDimensions();

    const handleOpenImageView = (imageIndex) => {
      setIsImagePreview(true);
      setSelectedImage(imageIndex);
    };

    const { isDataLoading } = useGallery();

    return (
      <div className={Style.GalleryListView}>
        {imageData?.length > 0 ? (
          <ul className={Style.GalleryListViewContainer}>
            {imageData?.map((data, dataIndex) => {
              return (
                <li
                  className={Style.GalleryListView__item}
                  key={dataIndex}
                  ref={ref}
                >
                  <div
                    className={Style.GalleryListView__left}
                    onClick={() => handleOpenImageView(dataIndex)}
                  >
                    <figure className={Style.GalleryListView__image}>
                      <img src={data?.images?.src?.thumbnail_small} alt="" />
                    </figure>
                    {windowDimensions.width >= 576 ? (
                      <span className={Style.GalleryListView__fileName}>
                        {data?.images?.original_name}
                      </span>
                    ) : (
                      <div className={Style.GalleryListView__mobRight}>
                        <span className={Style.GalleryListView__fileName}>
                          {data?.images?.original_name}
                        </span>
                        <div className={Style.GalleryListView__uploadDate}>
                          {/* Uploaded: {data?.images?.created_at?.split("T")[0]} */}
                        </div>
                      </div>
                    )}
                  </div>
                  {windowDimensions.width >= 576 ? (
                    <div className={Style.GalleryListView__right}>
                      <div className={Style.GalleryListView__uploadDate}>
                        {/* Uploaded: {data?.images?.created_at?.split("T")[0]} */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          !isDataLoading && (
            <BlankDataPlaceholder
              title={blankTitle}
              description={blankDescription}
              buttonText={blankButton}
              template={blankTemplate}
            />
          )
        )}
      </div>
    );
  }
);

export default GalleryListView;
