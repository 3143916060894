import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentTabAction } from "../../store/slices/UserFlow/userFlowSlice";
import Style from "./ToggleSwitch.module.scss";

const ToggleSwitch = ({
  firstBtn,
  secondBtn,
  className,
  isSelected,
  setIsSelected,
  handleUpdateShareLinkStatus,
  disabled,
}) => {
  const [toggleState, setToggleState] = useState(
    isSelected ? "state1" : "state2"
  );

  const [buttonLeft, setButtonLeft] = useState(
    toggleState === "state2" ? 0 : 28
  );

  const handleToggle = (e, theme) => {
    setToggleState(theme);
    setButtonLeft(e.target.offsetLeft);
  };

  useEffect(() => {
    setToggleState(isSelected ? "state1" : "state2");
    setButtonLeft(isSelected ? 28 : 0);
  }, [isSelected]);

  useEffect(() => {
    try {
      if (disabled === true) {
        setIsSelected(false);
      } else {
        setIsSelected(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [disabled]);

  return (
    <div
      className={`
      ${Style.ToggleSwitch} ${className}
      ${!isSelected ? "active-state1" : "active-state2"}
      `}
    >
      <div
        className={`
          ${Style.buttonActiveState} active-state-toggler
          ${!isSelected ? "state1-active" : "state2-active"}
        `}
        style={{ left: buttonLeft }}
      ></div>
      <button
        disabled={disabled}
        className={`
          ${Style.ToggleSwitch__toggleButton} first-button
        `}
        onClick={(e) => {
          handleToggle(e, "state1");
          handleUpdateShareLinkStatus(0, setIsSelected);
        }}
      >
        <span>{firstBtn}</span>
      </button>
      <button
        disabled={disabled}
        className={`${Style.ToggleSwitch__toggleButton} second-button`}
        onClick={(e) => {
          handleToggle(e, "state2");
          handleUpdateShareLinkStatus(1, setIsSelected);
        }}
      >
        <span>{secondBtn}</span>
      </button>
    </div>
  );
};

export default ToggleSwitch;
