import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPlansData,
  getTestimonials,
  getOurClients,
  getFeaturesOfFTP,
  getWhatIsFPA,
} from "../store/slices/Home/HomeSlice";
import useAuthChecker from "./Auth/useAuthChecker";
import useStudioPayment from "./useStudioPayment";

export const useHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthCompleted } = useAuthChecker();
  const { handleInitPayment } = useStudioPayment();

  const {
    testimonialData,
    ourClientsData,
    plansData,
    featuresOfFTPData,
    whatIsFPAData,
  } = useSelector((state) => state.home);

  const { userProfileData } = useSelector((state) => state.userData);

  const isPaymentCompleted = () => {
    if (userProfileData.plan_payment_status === 0) {
      return false;
    } else {
      return true;
    }
  };

  const capitalizeFirstLetter = (s) => s && s[0].toUpperCase() + s.slice(1);

  const plans = () => {
    // if (plansData == null) {
    dispatch(getPlansData());
    // }
  };

  const testimonials = () => {
    // if (testimonialData == null) {
    dispatch(getTestimonials());
    // }
  };

  const ourClients = () => {
    // if (ourClientsData == null) {
    dispatch(getOurClients());
    // }
  };

  const featuresOfFTP = () => {
    // if (featuresOfFTPData == null) {
    dispatch(getFeaturesOfFTP());
    // }
  };

  const whatIsFPA = () => {
    // if (whatIsFPAData == null) {
    dispatch(getWhatIsFPA());
    // }
  };

  const buttonCallback = (data) => {
    if (isAuthCompleted === false) {
      navigate("/register");
      /********************************************************************************************
       * IF THE USER IS LOGGED OUT IN AND CLICKED ON BUY BUTTON WILL BE REDIRECTED TO REGISTER PAGE *
       ******************************************************************************************/
    } else {
      if (isPaymentCompleted() === true) {
        /******************************************************************************************
         * IF THE USER IS LOGGED IN AND THE PAYMENT HAS BEEN DONE WILL BE REDIRECTED TO DASHBOARD *
         ******************************************************************************************/

        navigate("/dashboard");
      } else {
        /******************************************************************************************************
         * IF THE USER IS LOGGED IN AND THE PAYMENT HAS NOT BEEN DONE WILL BE REDIRECTED TO HDFC PAYMENT PAGE *
         ******************************************************************************************************/

        navigate(`/payment/${data.id}`);
        
       // handleInitPayment(data);
       // navigate("/plans");
      }
    }
  };

  return {
    plansData,
    testimonialData,
    ourClients,
    ourClientsData,
    plans,
    plansData,
    featuresOfFTP,
    featuresOfFTPData,
    whatIsFPA,
    whatIsFPAData,
    testimonials,
    capitalizeFirstLetter,
    buttonCallback,
    isPaymentCompleted,
  };
};
