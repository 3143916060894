import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetStarted } from "../../../Logic/GetStarted/useGetStarted";
import { useMiniFileUploadModal } from "../../../Logic/Uploaded/useMiniFileUploadModal";
import MiniFileUploadModal from "../../Modals/MiniFileUploadModals";
import Style from "./GetStarted.module.scss";
import { StepCard } from "./setpCard";

const GetStarted = () => {
  const {
    imageSelect,
    showChangeLogoModal,
    setImageSelect,
    handleOpenLogoModal,
    setShowChangeLogoModal,
    stepsData,
    setStepsData,
    errorMessage,
    errorMessageLogo,
    seterrorMessage,
    seterrorMessageLogo,
  } = useGetStarted();

  return (
    <div className={Style.GetStarted}>
      <Container>
        <div className={Style.Get_started_wrap}>
          <div className={`section-title-block ${Style.Get_started_header}`}>
            <h2 className="h1">Time to get started</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            </p>
            <h6 className={Style.Get_started_subtitle}>
              Upload your logo and watermark here
            </h6>
          </div>
          <div className={Style.Get_started_body}>
            {stepsData?.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <StepCard
                    url={data?.img}
                    title={data.title}
                    description={data.description}
                    icon={data.icon_name}
                    type={data.type}
                    setImageSelect={setImageSelect}
                    imageSelect={imageSelect}
                    handleClick={() => handleOpenLogoModal(data.type)}
                  />
                </React.Fragment>
              );
            })}
          </div>
          {errorMessage == true && (
            <p className="form-error-note">
              The water mark must be a file of type: jpeg, png, jpg.
            </p>
          )}

          {errorMessageLogo == true && (
            <p className="form-error-note">
              The logo must be a file of type: jpeg, png, jpg.
            </p>
          )}
          <div className={Style.Get_started_footer}>
            <Row>
              <Col sm="6" className="text-center text-sm-start">
                <p>Steps 1/2</p>
              </Col>
              <Col sm="6" className="text-center text-sm-end">
                <Link
                  to="/select-your-theme"
                  className="btn btn-link d-inline-block me-2"
                >
                  Skip
                </Link>
                <Link
                  // to={`${stepsData[0]?.img !== "" ? "/select-your-theme" : ""}`}
                  to={"/select-your-theme"}
                  className="btn btn-primary d-inline-block"
                  // disabled={
                  //   stepsData[0]?.img !== "" &&
                  //   stepsData[1]?.img !== "" &&
                  //   stepsData[0]?.img !== undefined
                  //     ? false
                  //     : true
                  // }
                >
                  Next
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <MiniFileUploadModal
        showChangeLogoModal={showChangeLogoModal}
        setShowChangeLogoModal={setShowChangeLogoModal}
        setStepsData={setStepsData}
        stepsData={stepsData}
        seterrorMessage={seterrorMessage}
        seterrorMessageLogo={seterrorMessageLogo}
      />
    </div>
  );
};

export default GetStarted;
