import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import Style from "./PreferenceCard.module.scss";

const PreferenceCard = ({
  index,
  type,
  title,
  description,
  actionType,
  actions,
  defaultAction,
  formik,
  setDropdownCurrent,
  selectItem,
  handleClick,
  dropdownCurrent,
  hoverEffect,
  ...props
}) => {
  return (
    <div className={`${Style.PreferenceCard} ${hoverEffect ? Style.PreferenceCard_hover : ''}`} key={index}>
      <div className={Style.PreferenceCard__details}>
        <h3 className={Style.PreferenceCard__title}>{title}</h3>
        {description && (
          <p className={`${Style.PreferenceCard__description} description-item`}><span>{description}</span></p>
        )}
      </div>
      <div className={Style.PreferenceCard__actions}>
        {actionType === "blank" ? (
          <div className={Style.btnOverlay} {...props}></div>
        ) : actionType === "dropdown" ? (
          <Dropdown>
            <Dropdown.Toggle variant="border-grey">
              <span>{dropdownCurrent}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions?.map((data, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      handleClick(data);
                    }}
                  >
                    <span {...props}>{data?.name}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        ) : actionType === "link" ? (
          <button className="btn-link link-underline" {...props}>
            {actions}
          </button>
        ) : actionType === "button" ? (
          <button className="btn btn-border-grey" {...props}>
            {actions}
          </button>
        ) : actionType === "custom" ? (
          actions
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PreferenceCard;
