import React from "react";
import { useLocation } from "react-router-dom";
import CommonLayout from "../../components/Layouts/CommonLayout";
import CancellationAndRefundPolicy from "../../components/UtilityPages/CancellationAndRefundPolicy";
import FAQ from "../../components/UtilityPages/FAQ";
import InformationAndGuidelines from "../../components/UtilityPages/InfomationAndGuidelines";
import PrivacyPolicy from "../../components/UtilityPages/PrivacyPolicy";
import NotFoundPage from "../NotFoundPage";

const LegalPages = () => {
    const location = useLocation();

    return (
        <CommonLayout>
            {
                location.pathname === '/guidelines-and-terms' || location.pathname === '/guidelines-and-terms/' ? (
                    <InformationAndGuidelines />
                ) : location.pathname === '/privacy-policy' || location.pathname === '/privacy-policy/' ? (
                    <PrivacyPolicy />
                ) : location.pathname === '/faq' || location.pathname === '/faq/' ? (
                    <FAQ />
                ) : location.pathname === '/cancellation-and-refund-policy' || location.pathname === '/cancellation-and-refund-policy/' ? (
                    <CancellationAndRefundPolicy />
                ) : (
                    <NotFoundPage />
                )
            }
        </CommonLayout>
    );
};

export default LegalPages;
