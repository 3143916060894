import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePhoneVerify } from "../../../../../Logic/Preference/usePhoneVerify";
import { authModalHandler } from "../../../../../store/slices/Preference/studioDetailstSlice";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";
import CommonInput from "../../../../Layouts/CommonInput";
import VerifyOtpForm from "../Auth/VerifyOtpForm";
import Style from "./StudioMobile.module.scss";

const StudioMobileNumber = () => {
  const [phoneValue, setPhoneValue] = useState("");
  const studioMobile = useSelector((state) => state.preference);
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.userData);

  const {
    code,
    showErrors,
    setShowErrors,
    setshowOtpErrors,
    showOtpErrors,
    phoneValidationSchema,
    mobileSubmit,
    otpCounter,
    resendOtp,
    isUpdatingPhone,
    onPhoneVerifyOtp,
    setOtpCounter,
    getCountryCode,
  } = usePhoneVerify();

  return (
    <>
      {isUpdatingPhone === true ? (
        <VerifyOtpForm
          title={"OTP Verification"}
          emailValue={phoneValue}
          getCountryCode={getCountryCode}
          code={code}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          showOtpErrors={showOtpErrors}
          onEmailVerifyOtp={onPhoneVerifyOtp}
          resendOtp={resendOtp}
          otpCounter={otpCounter}
          setOtpCounter={setOtpCounter}
          setshowOtpErrors={setshowOtpErrors}
        />
      ) : (
        <Formik
          validationSchema={phoneValidationSchema}
          initialValues={{
            mobile: userProfileData?.mobile,
            country_dial_code: userProfileData?.country_dial_code
              ? userProfileData?.country_dial_code
              : "+91",
          }}
          onSubmit={(values, { resetForm }) => {
            mobileSubmit(values, resetForm);
          }}
        >
          {({
            errors,
            values,
            touched,
            handleSubmit,
            setFieldValue,
            handleBlur,
            dirty,
            isValid,
          }) => {
            return (
              <>
                <CommonInput
                  label="Studio Mobile Number"
                  variant="phone-with-country"
                  floatingLabel={true}
                  id="input_phone"
                  name="mobile"
                  default_country="IN"
                  value={values?.mobile}
                  hasError={errors?.mobile && touched.mobile}
                  errorMsg={errors?.mobile}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("mobile", e.target.value);
                    setPhoneValue(e.target.value);
                  }}
                  country_dial_code={values?.country_dial_code}
                  callback={function (country) {
                    setFieldValue("country_dial_code", country.dial_code);
                  }}
                />
                <div className={Style.actions}>
                  <button
                    className="btn btn-border-grey me-2"
                    onClick={() => dispatch(authModalHandler(false))}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                    type="button"
                    disabled={!isValid || !dirty}
                  >
                    {studioMobile?.status === "loading" ? (
                      <InfiniteDotLoader />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default StudioMobileNumber;
