import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDimensions } from "../../../../Logic/Dimensions";
import Style from "./LoggedOutHeaderRight.module.scss";

const LoggedOutHeaderRight = () => {
  const location = useLocation();
  const windowDimensions = useDimensions();
  return (
    <nav className={Style.LoggedOutHeader__navigation}>
      {windowDimensions.width >= 1200 ? (
        <>
          <ul className={`${Style.LoggedOutHeader__navigation_links} default`}>
            <li className={`${location.pathname === "/" ? "active" : ""}`}>
              <Link
                to="/"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                Home
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/plans" ||
                location.pathname === "/plans/"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="/plans"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                Plans
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/contact" ||
                location.pathname === "/contact/"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="/contact"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                Contact
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/login" ||
                location.pathname === "/login/"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="/login"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                Login
              </Link>
            </li>
          </ul>
          <ul
            className={`${Style.LoggedOutHeader__navigation_buttons} default`}
          >
            <li>
              <Link
                to="/register"
                onClick={() =>
                  location.pathname.startsWith("/dashboard")
                    ? ""
                    : window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                }
              >
                <button className="btn btn-border-primary ">Register</button>
              </Link>
            </li>
            <li>
              <a
                className="btn btn-primary text-center d-block"
                href="https://corporate.photofind.webc.in/"
              >
              Corporate
              </a>
            </li>
          </ul>
          
        </>
      ) : (
        ""
      )}
    </nav>
  );
};

export default LoggedOutHeaderRight;
