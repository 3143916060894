import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

import { useDimensions } from "../../../../Logic/Dimensions";
import { useProjectSingle } from "../../../../Logic/useProjectSingle";
import CustomBreadcrumb from "../../../CustomBreadcrumb";
import Assets from "../../../Layouts/Assets";
import CommonInput from "../../../Layouts/CommonInput";
import Icon from "../../../Layouts/Icons";
import CommonModal from "../../../Modals/CommonModal";
import ImageUploadModal from "../../../Modals/ImageUploadModal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Style from "../ContentHeader.module.scss";
import { AddNewFolderModal } from "../AddNewFolderModal";
import FPAImageUploadModal from "../../../Modals/FPAImageUploadModal";

const ProjectFoldersListingHeader = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("Latest");
  const [isThanksModal, setIsThanksModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showFPAUploadModal, setShowFPAUploadModal] = useState(false);
  const windowDimensions = useDimensions();

  const {
    projectID,
    capitalizeFirstLetter,
    handleProjectSinglePage,
    setOpenEditFolderModal,
    setOpenEditProjectModal,
    openEditProjectModal,

    addNewProjectPopup,
    setAddNewProjectPopup,
    getAllStaffs,
    getAllEvents,
    getAllEventCategories,
    handleSubmitCreateNewFolder,
    handleTogglePreSelect,
    handleSelectAllFolders,
    handleUnSelectAllFolders,
    handleCancelSelectionsFolder,
    handleDeleteFolders,
    handleDeleteFoldersPopupToggle,
    handleOrderChange,
    handleEventPopup,
    handlePaymentPage,
  } = useProjectSingle();

  const { getCurrentProjectDetails, allFolders } = useSelector(
    (state) => state.projectSinglePage
  );

  const { currentTab } = useSelector((state) => state.project);

  const { currentPageDetails } = getCurrentProjectDetails;
  
  return (
    <>
      {windowDimensions.width > 992 ? (
        <>
          <CustomBreadcrumb
            levels={[
              { title: "Project", path: "/dashboard/project" },
              {
                title: capitalizeFirstLetter(currentPageDetails?.name),
                path: "/dashboard/folders",
              },
            ]}
          />
        </>
      ) : (
        <></>
      )}
      <div className={Style.ContentHeader__wrap}>
        <div className={Style.ContentHeader__left}>
          <h1 className={Style.ContentHeader__title}>
            {capitalizeFirstLetter(currentPageDetails?.name)}
          </h1>
        </div>
        <div className={Style.ContentHeader__right}>
          {currentTab !== "completedTab" && (
            <ul className={Style.ContentHeader__actionsBtn}>
              {
                allFolders.list.plan_payment_status == 0&& ( // CHECKING PRE-SELECT IS TRUE OT NOT
                  <li>
                    <button
                      className="btn btn-border-grey btn-sm btn-theme btn-bank-cards"
                      onClick={handlePaymentPage}
                    >
                      {windowDimensions.width >= 1280 ? (
                        <>Pay Now</>
                      ) : (
                        <Icon
                          color={"currentColor"}
                          size={21}
                          icon={"bank-cards"}
                        />
                      )}
                    </button>
                  </li>
                )}

              {allFolders?.filter?.preSelect === false && ( // CHECKING PRE-SELECT IS TRUE OT NOT
                <li>
                  <button
                    className="btn btn-border-grey btn-sm btn-theme"
                    onClick={() => setAddNewProjectPopup(true)}
                  >
                    {windowDimensions.width >= 1280 ? (
                      <>Create Event</>
                    ) : (
                      <Icon
                        color={"currentColor"}
                        size={18}
                        icon={"icon-plus"}
                      />
                    )}
                  </button>
                </li>
              )}

              {/* <li>
            <button
              className="btn btn-border-grey btn-sm"
              onClick={() => setShowUploadModal(true)}
            >
              {windowDimensions.width >= 1280 ? (
                <>Upload to FPA</>
              ) : (
                <Icon color={"#000"} size={18} icon={"share2"} />
              )}
            </button>
          </li> */}
              
                {!allFolders?.filter?.preSelect && // CHECKING PRE-SELECT IS TRUE OT NOT
                  allFolders?.list?.data != undefined && // CHECKING DATA IS NOT UNDEFINED
                  allFolders?.list?.data?.length > 0 && ( // CHECKING DATA LENGTH > 0
                  <li>
                    <button
                      disabled={
                        allFolders?.list?.data != undefined &&
                        allFolders?.list?.data?.length > 0
                          ? false
                          : true
                      }
                      className="btn btn-border-grey btn-sm"
                      onClick={() => {
                        handleTogglePreSelect(!allFolders?.filter?.preSelect);
                      }}
                    >
                      <Icon
                        size={18}
                        icon={
                          !allFolders?.filter?.preSelect
                            ? "icon-unchecked"
                            : "icon-checked"
                        }
                      />
                      {windowDimensions.width >= 1280 && <>Select</>}
                    </button>
                    </li>
                  )}
                {allFolders?.filter?.preSelect && (
                  <li>
                  <button
                    className="btn btn-border-grey btn-sm"
                    onClick={() => {
                      if (
                        allFolders?.list?.data?.length ===
                        allFolders?.filter?.selectedItems?.length
                      ) {
                        handleUnSelectAllFolders();
                      } else {
                        handleSelectAllFolders();
                      }
                    }}
                  >
                    <Icon
                      size={18}
                      icon={
                        allFolders?.list?.data?.length !==
                        allFolders?.filter?.selectedItems?.length
                          ? "icon-unchecked"
                          : "icon-checked"
                      }
                    />
                    {windowDimensions.width >= 1280 ? (
                      <>
                        {allFolders?.list?.data?.length ===
                        allFolders?.filter?.selectedItems?.length
                          ? "Deselect all"
                          : "Select all"}
                      </>
                    ) : (
                      ""
                    )}
                  </button>
                  </li>
                )}
              {/* DELETE BUTTON */}

              {allFolders?.filter?.selectedItems?.length > 0 && ( // CHECKING SELECTED IMAGES LENGTH IS > 0
                <li>
                  <button
                    className="btn btn-border-grey btn-sm"
                    onClick={() => {
                      handleDeleteFoldersPopupToggle(true);
                    }}
                  >
                    <Icon size={16} icon={"icon-trash"} />
                    {windowDimensions.width >= 1280 && <>Delete</>}
                  </button>
                </li>
              )}

              {/* CLOSE BUTTON  */}

              {allFolders?.filter?.preSelect && ( // CHECKING PRE-SELECT IS TRUE OT NOT
                <li>
                  <button
                    className="btn btn-border-grey btn-sm btn-three-dots"
                    onClick={() => {
                      handleCancelSelectionsFolder();
                    }}
                  >
                    <Icon color={""} size={14} icon={"icon-close"} />
                  </button>
                </li>
              )}

              {allFolders?.filter?.preSelect === false && (
                <li>
                  <Dropdown
                    className={`${Style.ContentHeader__filter_dropdown}`}
                  >
                    <Dropdown.Toggle className="btn btn-border-grey btn-sm">
                      {activeDropdown}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={
                          allFolders?.filter?.order === "desc" ? true : false
                        }
                        onClick={() => {
                          handleOrderChange("desc");
                          setActiveDropdown("Latest");
                        }}
                      >
                        Latest
                      </Dropdown.Item>

                      <Dropdown.Item
                        disabled={
                          allFolders?.filter?.order === "asc" ? true : false
                        }
                        onClick={() => {
                          handleOrderChange("asc");
                          setActiveDropdown("Oldest");
                        }}
                      >
                        Oldest
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>

      {/* <ImageUploadModal
        showModal={showUploadModal}
        setShowModal={setShowUploadModal}
        onUpload={() => {
          setShowUploadModal(false);
          setIsConfirmModal(true);
        }}
      />

      <FPAImageUploadModal
        showModal={showFPAUploadModal}
        setShowModal={setShowFPAUploadModal}
        onUpload={() => {
          setShowFPAUploadModal(false);
          setIsConfirmModal(true);
        }}
      /> */}

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={isConfirmModal}
        setShowModal={setIsConfirmModal}
        centered={true}
        className={`type-sm confirm_modal ${Style.confirm_modal}`}
        title={<h4>Confirm Upload to FPA</h4>}
        content={
          <>
            <p>
              You are about to upload images to FPA. Click Confirm to Proceed.
            </p>
            <div className="modal-footer">
              <button
                className={`btn btn-green`}
                onClick={() => {
                  setIsConfirmModal(false);
                  setIsThanksModal(true);
                }}
              >
                Confirm
              </button>
              <button
                className="btn btn-border-grey"
                onClick={() => setIsConfirmModal(false)}
              >
                Cancel
              </button>
            </div>
          </>
        }
      />

      <AddNewFolderModal
        getAllStaffs={getAllStaffs}
        getAllEventCategories={getAllEventCategories}
        getAllEvents={getAllEvents}
        handleSubmitCreateNewFolder={handleSubmitCreateNewFolder}
        addNewProjectPopup={addNewProjectPopup}
        setAddNewProjectPopup={setAddNewProjectPopup}
      />

      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={openCreateFolderModal}
        setShowModal={setOpenCreateFolderModal}
        centered={true}
        className={"edit-project-name-modal"}
        title={<h4>Create Event</h4>}
        content={
          <>
            <CommonInput
              label="Folder Name"
              variant="input"
              id="input_project_name"
              floatingLabel={true}
            />
            <div className="modal-footer">
              <button
                className="btn btn-border-grey"
                onClick={() => setOpenCreateFolderModal(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setOpenCreateFolderModal(false);
                }}
              >
                Create
              </button>
            </div>
          </>
        }
      />

      <CommonModal
        backdrop={windowDimensions.width >= 576 ? "static" : false}
        keyboard={false}
        showModal={isThanksModal}
        setShowModal={setIsThanksModal}
        centered={true}
        className={`type-lg ${Style.thanks_modal}`}
        content={
          <>
            <div className="row">
              <div className="col-12 text-center">
                <div className={Style.successfully_sent_img}>
                  <figure>
                    <img src={Assets.successfully_sent_gif} alt="wedding-img" />
                  </figure>
                </div>
                <h3 className={`mb-3 ${Style.thaks_you_modal_title}`}>
                  Thank you!
                </h3>
                <p className={`mb-4 ${Style.thaks_you_modal_content}`}>
                  You have successfully sent all photos you want to showcase on
                  your album.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div className="w-100 text-center">
                <Link to="/" className="btn btn-primary px-md-4 footer-link">
                  <span className="px-sm-2">Back Home</span>
                </Link>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default ProjectFoldersListingHeader;
