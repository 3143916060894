import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Assets from "../../Layouts/Assets";
import Icon from "../../Layouts/Icons";
import Style from "./ProjectCard.module.scss";
import FolderMenu from "./Dropdown/FolderMenu";
import ProjectMenu from "./Dropdown/ProjectMenu";
import CardSelection from "./CardSelection";
import { useProjectSingle } from "../../../Logic/useProjectSingle";
import useProjects from "../../../Logic/useProjects";
import { useProjectCard } from "../../../Logic/useProjectCard";
import { useParams } from "react-router-dom";

const ProjectCard = ({
  data,
  preSelect,
  handleSingleSelection,
  isSelectionActive,
  setOpenEventInfoModal,
  setOpenEditProjectModal,
  setOpenDeleteModal,
  handleEditProject,
  handleViewProjectDetails,
  handleDeleteProjectID,
  handleProjectStatusID,
  setChangeProjectStatusModal,
  openCard,
  handleToggleEditFolder,
  deleteCard,
  handleEventPopup,
  handleGetEventPopupData,
  handlepaymentAlert,
  handlePaymentPage,
  getThumbnails,
  itemKey,
  isDropdownOpened,
  setIsDropdownOpened,
}) => {
  const {
    nameRef,
    charLength,
    currentTab,
    isImageLoad,
    setIsImageLoad,
    capitalizeFirstLetter,
    stopPropagationProcess,
  } = useProjectCard();

  const { isProjectSingle } = useProjectSingle();
  const {
    isProjectListing,
    handleShowAddProject,
    addProjectStepTwo,
    handleSetAddProjectStepOne,
    handleSetAddProjectStepTwo,
    handleSetStepOneFormData,
  } = useProjects();
  const location = useLocation();

  const [isDropdownHoverActive, setIsDropdownHoverActive] = useState(false);

  const handleDropdownMouseOver = function (event) {
    setIsDropdownHoverActive(true);
  };
  const handleDropdownMouseLeave = function (event) {
    setTimeout(function () {
      setIsDropdownHoverActive(false);
    }, 500);
  };

  let { projectID } = useParams();

  let arrayCheck = (data) => {
    let newArray = Array(0);

    if (data?.length == 4) {
      newArray = [...data];
    } else if (data?.length == 3) {
      newArray = [...data, ...Array(1)];
    } else if (data?.length == 2) {
      newArray = [...data, ...Array(2)];
    } else if (data?.length == 1) {
      newArray = [...data, ...Array(0)];
    } else {
      newArray = [...Array(1)];
    }

    return newArray;
  };

  return (
    <div
      className={`${Style.ProjectCard} ${preSelect ? Style.preselectOpen : ""}`}
      onClick={() => {
       
        if (isDropdownHoverActive === false) {
          if (preSelect === true) {
            handleSingleSelection(data.id);
          } else {
            // OPEN CARD TO NEXT LEVEL
            openCard(data);
          }
        }
      }}
    >
      <Link to={preSelect ? "" : data?.link ? data?.link : ""} />
      <div className={`${Style.ProjectCard_image_thumb_wrap}`}>
        <div className={Style.ProjectCard_image_thumb}>
          {location.pathname == "/dashboard/project"
            ? arrayCheck(data?.latest_album?.latest_image)?.map((items) => {
                return (
                  <figure
                    className={`${Style.ProjectCard__image} ${
                      items?.src?.thumbnail_small ? "" : Style.placeholder
                    } ${
                      !isImageLoad ? "img-loading-2" : "img-loading-2-loaded"
                    }`}
                  >
                    <img
                      src={
                        items?.src?.thumbnail_small
                          ? items?.src?.thumbnail_small
                          : Assets.placeholder_image_stack
                      }
                      className={
                        items?.src?.thumbnail_small
                          ? ""
                          : Style.image_placeholder
                      }
                      alt=""
                      onLoad={() => setIsImageLoad(true)}
                    />
                  </figure>
                );
              })
            : location.pathname == `/dashboard/project/${projectID}`
            ? arrayCheck(data?.latest_image)?.map((items) => {
                return (
                  <figure
                    className={`${Style.ProjectCard__image} ${
                      data?.image ? "" : Style.placeholder
                    } ${
                      !isImageLoad ? "img-loading-2" : "img-loading-2-loaded"
                    }`}
                  >
                    <img
                      src={
                        items?.src?.thumbnail_small
                          ? items?.src?.thumbnail_small
                          : Assets.placeholder_image_stack
                      }
                      alt=""
                      onLoad={() => setIsImageLoad(true)}
                      className={
                        items?.src?.thumbnail_small
                          ? ""
                          : Style.image_placeholder
                      }
                    />
                  </figure>
                );
              })
            : ""}
        </div>
      </div>

      <div className={Style.ProjectCard__tray}>
        <div className={Style.ProjectCard__project}>
          <div className={Style.ProjectCard__projectName} ref={nameRef}>
            {capitalizeFirstLetter(data?.name?.substring(0, charLength))}

            {`${String(data?.name).length > charLength ? "..." : ""}`}
          </div>
          <div className={Style.ProjectCard__projectDate}>{data?.date}</div>
        </div>

        {!preSelect && (
          <div className={Style.ProjectCard__options}>
            <Dropdown
              onClick={(event)=>{
                event.stopPropagation()
              }}
              onMouseEnter={handleDropdownMouseOver}
              onMouseLeave={handleDropdownMouseLeave}
              className="options-dropdown"
              onTouchStart={handleDropdownMouseOver}
              onTouchEnd={handleDropdownMouseLeave}
            >
              <Dropdown.Toggle
                className={Style.option_dropdown}
                variant="custom"
              >
                <Icon size={16} icon={"icon-three-dots"} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {isProjectListing() ? (
                  <ProjectMenu
                    data={data}
                    currentTab={currentTab}
                    handleEditProject={handleEditProject}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenEditProjectModal={setOpenEditProjectModal}
                    handleViewProjectDetails={handleViewProjectDetails}
                    setChangeProjectStatusModal={setChangeProjectStatusModal}
                    handleDeleteProjectID={handleDeleteProjectID}
                    handleProjectStatusID={handleProjectStatusID}
                    handleShowAddProject={handleShowAddProject}
                    addProjectStepTwo={addProjectStepTwo}
                    handleSetAddProjectStepOne={handleSetAddProjectStepOne}
                    handleSetAddProjectStepTwo={handleSetAddProjectStepTwo}
                    handleSetStepOneFormData={handleSetStepOneFormData}
                  />
                ) : isProjectSingle() ? (
                  <FolderMenu
                    data={data}
                    deleteCard={deleteCard}
                    setOpenEventInfoModal={setOpenEventInfoModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    handleToggleEditFolder={handleToggleEditFolder}
                    handleEventPopup={handleEventPopup}
                    handleGetEventPopupData={handleGetEventPopupData}
                    handlepaymentAlert={handlepaymentAlert}
                  />
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>

      <CardSelection
        preSelect={preSelect}
        isSelectionActive={isSelectionActive}
      />
      {location.pathname == "/dashboard" &&
        data.client_id == null &&
        currentTab != "completedTab" && (
          <>
            <div className={Style.ProjectCard_noClient}>
              Please update client details!
            </div>
          </>
        )}
    </div>
  );
};

export default ProjectCard;
