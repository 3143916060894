import React from "react";
import Style from "./FormFooter.module.scss";
import { Link } from "react-router-dom";

const FormFooter = ({
  isOtpOpen,
  setIsEmailEditable,
  isEmailEditable,
  emailInput,
}) => {
  return (
    <>
      <div className={Style.Auth__option}>
          <span>Remember Password? </span>
          <Link to="/login" className="btn btn-link link-primary">
            Sign In
          </Link>
        </div>
    </>
  );
};

export default FormFooter;
