import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllPlans,
  storePlanID,
  getOrderID,
  saveOrder,
  toggleProjectAddPopup,
} from "../store/slices/Project/ProjectSlice";

const useMasonry = () => {
  const imgRef = useRef(null);
  const observer = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);

  let columnsCountBreakPoints = {
    0: 1,
    320: 2,
    576: 3,
    992: 4,
    1600: 5,
  };

  return {
    columnsCountBreakPoints,
    selectedImage,
    setSelectedImage,
    isImagePreview,
    setIsImagePreview,
    isSelectionOpen,
    setIsSelectionOpen,
    imgRef,
    observer,
  };
};

export default useMasonry;
