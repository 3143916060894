import React, { useState } from 'react'
import Icon from "../Icons";

const CustomPasswordInput = ({
    floatingLabel,
    hasError,
    className,
    label,
    required,
    value,
    name,
    placeholder,
    onChange,
    errorMsg,
    onBlur,
    ...props
}) => {

    console.log(hasError, errorMsg, 'err...');
    const [isPassVisible, setIsPassVisible] = useState(false);

    return (

        <div
            className={`form-group button-sibling 
    ${floatingLabel ? "floating-label" : ""} 
    ${value?.length > 0 ? "filled" : ""} 
    ${hasError ? "field-error" : ""} ${className}`}
        >
            <label >
                {label}
                {required && "*"}
            </label>
            <div className="input-holder">
                <input
                    type={isPassVisible ? "text" : "password"}
                    name={name}
                    value={value}
                    className="form-control"
                    placeholder={placeholder}
                    onKeyDown={(event) => {
                        if (event.code === 'Space') event.preventDefault()
                    }}
                    onChange={onChange}
                    onBlur={onBlur}

                    {...props}
                />
                <button onClick={() => setIsPassVisible(!isPassVisible)}>
                    <Icon
                        size={18}
                        icon={!isPassVisible ? "eye-visible" : "eye-invisible"}
                    />
                </button>
            </div>
            {hasError && (
                <span htmlFor="" className="error-message">
                    {errorMsg}
                </span>
            )}
        </div>
    )
}

export default CustomPasswordInput