import React from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../../../Logic/Header/useLogout";
import Style from "./HeaderProfileDropdown.module.scss";
import { useDispatch, useSelector } from "react-redux";

const HeaderProfileDropdown = () => {
  const { handleLogout } = useLogout();
  const navigate = useNavigate();
  const location = useLocation();

  const { userProfileData } = useSelector((state) => state.userData);

  return (
    <Dropdown className={Style.HeaderProfileDropdown}>
      <Dropdown.Toggle className={Style.HeaderProfileDropdown_toggle}>
        <span>
          {userProfileData?.name ? (
            userProfileData?.name
          ) : (
            <div className={Style.profile_shimmer}></div>
          )}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* <Dropdown.Item>Profile</Dropdown.Item> */}
        {location.pathname === "/" ? (
          <Dropdown.Item
            onClick={() => {
              navigate(
                userProfileData?.plan_payment_status === 0
                  ? "/plans"
                  : userProfileData && userProfileData?.theme_class_name == null
                  ? "/get-started"
                  : "/dashboard"
              );
            }}
          >
            Dashboard
          </Dropdown.Item>
        ) : location.pathname.startsWith("/dashboard") ? (
          <Dropdown.Item onClick={() => navigate("/")}>Home</Dropdown.Item>
        ) : (
          <>
            <Dropdown.Item onClick={() => navigate("/")}>Home</Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                navigate(
                  userProfileData?.plan_payment_status === 0
                    ? "/plans"
                    : userProfileData &&
                      userProfileData?.theme_class_name == null
                    ? "/get-started"
                    : "/dashboard"
                );
              }}
            >
              Dashboard 
            </Dropdown.Item>
          </>
        )}
        <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderProfileDropdown;
