import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import VerifyOTPForm from "../../../utils/VerifyOTPForm";
import CommonInput from "../../Layouts/CommonInput";
import Icon from "../../Layouts/Icons";
import EmailLogin from "./EmailLogin";
import Style from "./Login.module.scss";
import PhoneLogin from "./PhoneLogin";
import SignupBox from "./SignupBox";
import SocialMediaLogin from "./SocialMediaLogin";
import Tabs from "./Tabs";
import { useLogin } from "../../../Logic/useLogin";

const Login = () => {
  const [currentTab, setCurrentTab] = useState("email");

  const { otpCreateScreen, otpCreateRequestData } = useSelector(
    (state) => state.auth
  );

  const {
    editButton,
    otp,
    setOTP,
    isOTPverifying,
    isOtpError,
    LWPotpIsButtonEnable,
    triggerResendCall,
    otpErrorMessage,
    otpCounter,
    setOtpCounter
  } = useLogin();

  return (
    <div className={Style.Login}>
      <Container className="auth-container">
        {otpCreateScreen != "otp" ? (
          <>
            <Tabs setCurrentTab={setCurrentTab} currentTab={currentTab} />
            {currentTab === "email" ? <EmailLogin /> : <PhoneLogin />}
            {/* <SocialMediaLogin /> */}
            <SignupBox />
          </>
        ) : (
          <VerifyOTPForm
            phone={otpCreateRequestData.phone_number}
            dialCode={otpCreateRequestData.country_dial_code}
            email={""}
            editButton={editButton}
            otp={otp}
            setOTP={setOTP}
            isOTPverifying={isOTPverifying}
            isOtpError={isOtpError}
            otpSubmitButton={""}
            resendCallback={triggerResendCall}
            statusOTPcall={LWPotpIsButtonEnable}
            otpErrorMessage={otpErrorMessage}
            otpCounter={otpCounter}
            setOtpCounter={setOtpCounter}
          />
        )}
      </Container>
    </div>
  );
};

export default Login;
