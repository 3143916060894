import React, { useEffect, useRef, useState } from "react";

import Icon from "../../../Layouts/Icons";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../../../Modals/CommonModal";

const DeleteProjectGalleryItemsPopup = ({
  openDeleteModal,
  modalControl,
  handleDeleteAction,
  isItMulitple,
}) => {
  const { deleteProject } = useSelector((state) => state.project);

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={openDeleteModal}
      setShowModal={modalControl}
      centered={true}
      className={"type-sm info-modal"}
      title={<h4>Delete {isItMulitple === true ? "Images" : "Image"}</h4>}
      content={
        <>
          {isItMulitple !== true ? (
            <p>Are you sure you want to delete this {isItMulitple === true ? "Images" : "Image"}?</p>
          ) : (
            <p>Are you sure you want to delete all these selected {isItMulitple === true ? "Images" : "Image"}?</p>
          )}

          <div className="modal-footer">
            <button
              className="btn btn-red"
              onClick={() => {
                handleDeleteAction();
              }}
            >
              {deleteProject.status == "deleting" ? (
                <InfiniteDotLoader />
              ) : (
                "Delete"
              )}
            </button>
            <button
              className="btn btn-border-grey"
              onClick={() => modalControl(false)}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
};

export default DeleteProjectGalleryItemsPopup;
