import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import CommonLayout from '../../components/Layouts/CommonLayout';
import Icon from '../../components/Layouts/Icons';
import Style from "./PaymentCanceled.module.scss";
import { decode as base64_decode, encode as base64_encode } from "base-64";

const PaymentCanceled = () => {

  let { PayString } = useParams();
  const [orderDetails, setOrderDetails] = useState({});

  useEffect(() => {
    let decoded = base64_decode(PayString);
    try {
      setOrderDetails(JSON.parse(decoded));
    } catch (error) {
      
    }
  }, [PayString]);


  return (
    <CommonLayout>
      <div className={`${Style.NotFoundPage} inner-page`}>
        <Container>
          <div className="inner-page-wrap">

            <div className={Style.demo1}>
              <div className={Style.ui_error}>
                <svg  viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Group-2" transform="translate(2.000000, 2.000000)">
                        <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
                        <circle  className={Style.ui_error_circle} stroke="#F74444" stroke-width="4" cx="41.5" cy="41.5" r="41.5"></circle>
                          <path className={Style.ui_error_line1} d="M22.244224,22 L60.4279902,60.1837662" id="Line" stroke="#F74444" stroke-width="6" stroke-linecap="square"></path>
                          <path className={Style.ui_error_line2} d="M60.755776,21 L23.244224,59.8443492" id="Line" stroke="#F74444" stroke-width="6" stroke-linecap="square"></path>
                      </g>
                  </g>
                </svg>
              </div>
            </div>
            
            <h7 >
              Order ID : {orderDetails.order_id}
            </h7><br></br>
            <h7 >Amount : {orderDetails.amount} </h7 >
            <h2 className={Style.error_name}>Payment Canceled</h2>
            <div className='pt-4 text-center'>
              <Link to="/dashboard" className="btn btn-primary">
                <Icon color={"currentColor"} className="me-1" size={17} icon={"back-arrow"} /> Back to Dashboard
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </CommonLayout>
  )
}

export default PaymentCanceled;