import React from "react";
import { useLocation, useParams } from "react-router-dom";
import ProjectFoldersListingHeader from "./ProjectFoldersListingHeader";
import ProjectGalleryHeader from "./ProjectGalleryHeader";
import ProjectHeader from "./ProjectHeader";
import Style from "./ContentHeader.module.scss";

const ContentHeader = ({ isGrid, setIsGrid }) => {
  const location = useLocation();
  let { projectID, folderID } = useParams();

  return (
    <div className={Style.ContentHeader}>
      {projectID === undefined && folderID === undefined ? (
        <ProjectHeader />
      ) : projectID !== undefined && folderID === undefined ? (
        <ProjectFoldersListingHeader />
      ) : projectID !== undefined && folderID !== undefined ? (
        <ProjectGalleryHeader isGrid={isGrid} setIsGrid={setIsGrid} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ContentHeader;
