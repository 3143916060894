import React from "react";
import Icon from "../../../Layouts/Icons";

const CardSelection = ({
  preSelect,
  isSelectionActive
}) => {
  return (
    <>
     {preSelect ? (
        <span
          className={`form-check-box ${
            isSelectionActive ? "checked preselect" : ""
          }`}
        >
          <Icon
            color={"#fff"}
            size={13}
            icon={isSelectionActive ? "icon-tick" : ""}
          />
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default CardSelection;
