import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  // loginCreateOtp,
  loginUser,
  LWPverifyOtp,
  storeCurrentAuthData,
  showSignInState,
  changeScreen,
  invalidLoginDetails,
  UpdateErrorData,
  loginWithPhone,
  createOTPstatusUpdate,
  createOTPUpdateErrorData,
  createOTPinvalidPhoneNumber,
  createOTPUpdateScreen,
  createOTPSaveRequestData,
  createOTPEditModeSwitch,
  LWPotpIsLoading,
  LWPOTPupdateButtonStatus,
  LWPresendOtp,
} from "../store/slices/Auth/authSlice";

import { useRegister } from "./useRegister";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOTP] = useState("");
  const [otpCounter, setOtpCounter] = useState(30);

  const [isOtpError, setOtpError] = useState(false);
  const [isOTPverifying, setOTPverifying] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const {
    loginError,
    isLoginProgress,
    otpCreateIsLoading,
    loginErrorData,
    otpCreateError,
    otpCreateRequestData,
    otpCreateErrorData,
    otpCreateisEditMode,
    otpCreateProgress,
    LWPotpIsLoading,
    LWPotpIsButtonEnable,
  } = useSelector((state) => state.auth);

  /*************************************
   * INITAL DATA FOR FORMIK LOGIN WITH EMAIL FORM *
   *************************************/

  const [initialInput, setInitialInputs] = useState({
    email: "",
    password: "",
  });

  /*************************************
   * INITAL DATA FOR FORMIK LOGIN WITH PHONE FORM *
   *************************************/

  const [LoginWithPhoneInit, setLoginWithPhoneInit] = useState({
    country_dial_code:
      otpCreateisEditMode === true
        ? otpCreateRequestData.country_dial_code
        : "+91",
    phone_number:
      otpCreateisEditMode === true ? otpCreateRequestData.phone_number : "",
  });

  /****************************************************************
   * LOGIN WITH EMAIL FUNCTION WHICH TRIGGERS ON LOGIN FORM SUBMIT *
   ****************************************************************/

  const handleLoginWithEmail = (data, { resetForm }) => {
    if (isLoginProgress !== "loading") {
      dispatch(loginUser(data))
        .unwrap()
        .then((res) => {
          const { success } = res.data;
          if (success === true) {
            dispatch(storeCurrentAuthData(res.data));
            navigate("/get-started");
          } else {
            dispatch(invalidLoginDetails(true));
            dispatch(UpdateErrorData(res.data));

            setTimeout(() => {
              dispatch(invalidLoginDetails(false));
              dispatch(UpdateErrorData(""));
            }, 3000);
          }
        });
    }
  };

  /************************************************************************************
   * WHEN CLICK ON EDIT BUTTON ON THE OTP SCREEN WHICH IS DONE BY FOLLOWING STEPS *
   ************************************************************************************/

  const editButton = () => {
    dispatch(createOTPUpdateScreen(""));
    dispatch(createOTPEditModeSwitch(true));
    setOTP("");
    setOtpError(false);
  };

  const handleLoginWithPhone = (data) => {
    if (otpCreateIsLoading !== "loading") {
      dispatch(createOTPstatusUpdate(true));
      dispatch(createOTPSaveRequestData(data));

      const { phone_number, country_dial_code } = data;

      dispatch(
        loginWithPhone({
          mobile: phone_number,
          country_dial_code: country_dial_code,
        })
      )
        .unwrap()
        .then((res) => {
          const { success } = res.data;
          if (success === true) {
            dispatch(storeCurrentAuthData(res.data));
            dispatch(createOTPstatusUpdate(false));
            dispatch(createOTPUpdateScreen("otp"));
          } else {
            dispatch(createOTPinvalidPhoneNumber(true));
            dispatch(createOTPUpdateErrorData(res.data));
            dispatch(createOTPstatusUpdate(false));
            setTimeout(() => {
              dispatch(createOTPinvalidPhoneNumber(false));
              dispatch(createOTPUpdateErrorData(""));
            }, 3000);
          }
        });
    }
  };

  /*******************************************************************************************************************
   * THIS USEEFFECT TRIGGER WHEN THE USER TYPING THE OTP ON THE FIELDS AND WHICH TRIGGER A API CALL WHEN OTP DIGIT COUNT HIT WITH COUNT 4 *
   *******************************************************************************************************************/

  useEffect(() => {
    setOtpError(false);

    if (otp.length === 4) {
      dispatch(LWPOTPupdateButtonStatus(false));
      setOTPverifying(true);
      VerifyOtp(otp);
    } else {
      dispatch(LWPOTPupdateButtonStatus(true));
      setOTPverifying(false);
    }
  }, [otp]);

  /*******************************************************************************************************************
   * THIS FUNCTION SENDS THE OTP WHICH HAS BEEN ENTERED BY THE USER AND SENDS TO THE SEVER SIDE WITH TOTAL FORM DATA TO COMPLETE THE USER REGISTERATION PROCESS *
   * AND WHICH HOLD THE TOKEN HAD BEEN GENERATED BY REACT THAT IS WILL BE CHECKED IN THE SERVER SIDE TO ENSURE THIS IS THE SAME USER WHICH REQUESTED FOR REGISTERATION
   *******************************************************************************************************************/

  const VerifyOtp = async (otp) => {
    if (otp.length == 4) {
      /***************************************
       * DATA FOR VERIFY OTP WITH PHONE *
       ***************************************/

      let params = {
        otp: otp,
        mobile: otpCreateRequestData.phone_number,
        country_dial_code: otpCreateRequestData.country_dial_code,
      };

      dispatch(LWPverifyOtp(params))
        .unwrap()
        .then((res) => {
          const { data } = res;

          if (data.success === true) {
            dispatch(storeCurrentAuthData(data.result));
            dispatch(changeScreen(""));
            navigate("/get-started");
            setOTPverifying(false);
          } else {
            dispatch(LWPOTPupdateButtonStatus(true));
            setOTPverifying(false);
            setOtpError(true);
            setOtpErrorMessage(true);

            setTimeout(() => {
              setOtpErrorMessage(false);
            }, 2000);
          }
        });
    } else {
    }
  };

  /***************************************************************
   * THIS FUNCTION TRIGGER THE RE-SEND OTP CALL FROM CLIENT SIDE *
   ***************************************************************/

  const triggerResendCall = (callback) => {
    let packetForResendOTP = {
      mobile: otpCreateRequestData.phone_number,
      country_dial_code: otpCreateRequestData.country_dial_code,
    };

    dispatch(LWPresendOtp(packetForResendOTP))
      .unwrap()
      .then((data) => {
        if (data.data.success === true) {
          callback();
          setOTP("");
          setOtpError(false);
          setOtpCounter(30);
        }
      });
  };

  // const forgotPasswordHandler = async (data) => {
  //   dispatch(storeCurrentAuthData({ mail: data.mail }));
  //   const resultAction = await dispatch(forgotPassword({ email: data.mail }));
  //   if (forgotPassword.fulfilled.match(resultAction)) {
  //     setShowForgotPassword(true);
  //   } else {
  //     setLoginErrors("Please enter valid Email ID ");
  //     setTimeout(() => {
  //       setLoginErrors("");
  //     }, 1500);
  //   }
  // };

  return {
    isLoginProgress,
    otpCreateIsLoading,
    loginErrors,
    showForgotPassword,
    setShowForgotPassword,
    handleLoginWithEmail,
    handleLoginWithPhone,
    initialInput,
    setInitialInputs,
    loginError,
    loginErrorData,
    otpCreateError,
    otpCreateErrorData,
    LoginWithPhoneInit,
    setLoginWithPhoneInit,
    editButton,
    isOtpError,
    setOtpError,
    otp,
    setOTP,
    LWPotpIsLoading,
    isOTPverifying,
    isOtpError,
    LWPotpIsLoading,
    LWPotpIsButtonEnable,
    otpErrorMessage,
    triggerResendCall,
    otpCounter,
    setOtpCounter,
  };
};
