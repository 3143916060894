import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  designationsStatus: "idle",
  designations: [],
  currentPage: 0,
  totalPages: "",
  order: 1,
  currentTab: "activeTab",
  statusAddStaffPopup: false,
  sections: {},
  editStaff: { status: false, staffData: "" },
  assignedProjects: { status: false, staffData: "" },
  staffPreSelect: "",
};

/******************************
 * STAFF - GET ALL STAFFS *
 ******************************/

export const getAllStaffsAPI = createAsyncThunk(
  "staffs/getAllStaffsAPI",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const asString = new URLSearchParams(params).toString();

      const response = await api.get(
        `dashboard/${params.url}?${new URLSearchParams(params).toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customLoadMore = createAsyncThunk(
  "staffs/customLoadMore",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `dashboard/${params.url}?${new URLSearchParams(params).toString()}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getStaffDesignation = createAsyncThunk(
  "staffs/getStaffDesignation",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("staff/staff-designation");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)


const staffSlice = createSlice({
  name: "staffs",
  initialState,
  reducers: {
    currentTabHandler: (state, action) => {
      state.currentTab = action.payload.tab;
      if (typeof action.payload.callback === "function") {
        action.payload.callback();
      }
    },
    callbackStaffDelete: (state, action) => {
      state.sections[state.currentTab].data = action.payload;
    },

    handleCustomSearchText: (state, action) => {
      try {
        state.sections[state.currentTab]["searchText"] = action.payload;
      } catch (error) { }
    },

    setSearchResultPlaceHolderValue: (state, action) => {
      state.sections[state.currentTab]["searchPlaceHolder"] = action.payload;
    },
    toggleStatusAddStaffPopup: (state, action) => {
      state.statusAddStaffPopup = action.payload;
    },
    setStaffFilter: (state, action) => {
      state.sections[state.currentTab].filter = action.payload;
    },
    toggleStaffEdit: (state, action) => {
      state.statusAddStaffPopup = true;
      state.editStaff.status = action.payload;
    },
    setStaffEditData: (state, action) => {
      state.editStaff.staffData = action.payload;
    },
    resetStaffEdit: (state, action) => {
      state.editStaff.status = false;
      state.editStaff.staffData = "";
    },
    updateStaffData: (state, action) => {
      state.sections[state.currentTab].data = action.payload;
    },
    toggleAssignedProjects: (state, action) => {
      state.assignedProjects.status = action.payload;
    },
    setAssignedProjects: (state, action) => {
      state.assignedProjects.staffData = action.payload;
    },
    updateSwitchStaffData: (state, action) => {
      state.sections[action.payload.currentTab].data = action.payload.data;
    },
    updateStaffPreSelect: (state, action) => {
      state.staffPreSelect = action.payload;
    },
    clearTabData: (state, action) => {
      delete state.sections[action.payload];
    },
  },
  extraReducers: {
    [getAllStaffsAPI.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAllStaffsAPI.fulfilled]: (state, action) => {
      console.log("action", action.meta.arg.url);
      console.log("state.currentTab");

      if (
        (action.meta.arg.url === "contract-staff-list" &&
          state.currentTab === "contractStaffTab") ||
        (action.meta.arg.url === "permanent-staff-list" &&
          state.currentTab === "activeTab")
      ) {
        state.status = "succeed";
        state.sections[state.currentTab] = {
          ...state.sections[state.currentTab],
          ...action.payload.result,
        };

        if (action?.meta?.arg?.search != undefined) {
          if (action.payload?.result?.data?.length === 0) {
            state.sections[state.currentTab]["searchResult"] = "empty";
          } else {
            state.sections[state.currentTab]["searchResult"] = "";
          }
        } else {
          state.sections[state.currentTab]["searchResult"] = "";
        }

        state.sections[state.currentTab]["sort"] = action?.meta?.arg?.sort;
      }

    },
    [getAllStaffsAPI.rejected]: (state, action) => {
      state.status = "error";
    },

    [customLoadMore.pending]: (state, action) => {
      state.status = "loading";
    },
    [customLoadMore.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.sections[state.currentTab].data = [
        ...state.sections[state.currentTab].data,
        ...action?.payload?.result.data,
      ];
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.result?.current_page,
      };
    },
    [customLoadMore.rejected]: (state, action) => {
      state.status = "error";
    },



    [getStaffDesignation.pending]: (state, action) => {
      state.designationsStatus = "loading";
    },
    [getStaffDesignation.fulfilled]: (state, action) => {

      let data = action.payload.data.result?.map((item, index) => {
        return { value: item.id, label: item.type }
      })
      state.designationsStatus = "succeed";
      state.designations = data
    },
    [getStaffDesignation.rejected]: (state, action) => {
      state.designationsStatus = "error";
    },
  },
});

export const {
  currentTabHandler,
  callbackStaffDelete,
  handleCustomSearchText,
  setSearchResultPlaceHolderValue,
  toggleStatusAddStaffPopup,
  setStaffFilter,
  toggleStaffEdit,
  setStaffEditData,
  resetStaffEdit,
  updateStaffData,
  toggleAssignedProjects,
  setAssignedProjects,
  updateSwitchStaffData,
  updateStaffPreSelect,
  clearTabData,
} = staffSlice.actions;

export default staffSlice.reducer;
