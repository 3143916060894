import React from "react";
import { Link } from "react-router-dom";
import Style from "./Tabs.module.scss";

const Tabs = ({setCurrentTab,currentTab}) => {
  return (<>
        <h1 className={Style.Login__title}>Sign In to Your Account</h1>
        <div className={Style.Login__tabs}>
        <button
          className={Style.tabItem}
          onClick={() => setCurrentTab("email")}
        >
          <span>Email Id</span>
        </button>
        <button
          className={Style.tabItem}
          onClick={() => setCurrentTab("phone")}
        >
          <span>Phone</span>
        </button>
        <div
          className={Style.tabActive}
          style={{ left: currentTab === "email" ? "1.5%" : "51%" }}
        ></div>
      </div>
      </>
  );
};

export default Tabs;
