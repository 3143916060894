import { useEffect, useState } from "react";
import { currentTabHandler } from "../../../store/slices/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";

export const useTab = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("activeTab");
  const [isChecked, setIsChecked] = useState(false);
  const [activeTabLeft, setActiveTabLeft] = useState("");
  const [activeTabWidth, setActiveTabWidth] = useState("");

  const { currentTab } = useSelector((state) => state.project);

  const handleClickTab = (e, tab) => {
    const callback = () => {
      setActiveTab(tab);
      setActiveTabLeft(e.target.offsetLeft);
      setActiveTabWidth(e.target.offsetWidth);
    };
    dispatch(currentTabHandler({ tab: tab, callback: callback }));
  };

  useEffect(() => {
    let activeTabSelect = document.querySelectorAll(".tabActive");
    setActiveTabLeft(activeTabSelect[0]?.offsetLeft);
    setActiveTabWidth(activeTabSelect[0]?.offsetWidth);
  }, []);

  return {
    activeTab,
    isChecked,
    activeTabLeft,
    activeTabWidth,
    setActiveTab,
    setIsChecked,
    setActiveTabLeft,
    setActiveTabWidth,
    handleClickTab,
    currentTab,
  };
};
