import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useContactForm } from "../../../Logic/Contact/useContactForm";
import CommonInput from "../../Layouts/CommonInput";
import Style from "./ContactForm.module.scss";
import { Formik } from "formik";

const ContactForm = () => {
  const [messageValue, setMessageValue] = useState("");

  const { countryData } = useSelector((state) => state.home);

  const { getUserFlowData, countryValidationSchema, countrySubmit } =
    useContactForm();

  useEffect(() => {
    getUserFlowData();
  }, []);

  function isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}

  return (
    <Formik
      className={Style.ContactForm_main_wrap}
      validationSchema={countryValidationSchema}
      initialValues={{
        country: "",
        email: "",
        phone: "",
        name: "",
        message: "",
        country_dial_code: "+91",
      }}
      onSubmit={(values, { resetForm, setFieldError }) => {
        let FormData = {
          name: values?.name,
          message: values?.message,
          email: values?.email,
          mobile: values?.phone,
          state_id: values?.country,
          country_dial_code: values?.country_dial_code,
        };

        countrySubmit(FormData, resetForm, setFieldError);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        handleBlur,
        resetForm,
      }) => {
        return (
          <>
            <div className={Style.ContactForm}>
              <h2 className={Style.ContactForm__title}>We are here to help</h2>
              <Row>
                <Col lg={6}>
                  <CommonInput
                    label="Name"
                    placeholder=""
                    variant="input"
                    id="input_name"
                    floatingLabel={true}
                    className="regular single-border"
                    name="name"
                    value={values?.name}
                    hasError={errors?.name && touched.name}
                    errorMsg={errors?.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <CommonInput
                    label="Email"
                    placeholder=""
                    variant="input"
                    id="input_email"
                    floatingLabel={true}
                    className="regular single-border"
                    name="email"
                    value={values?.email}
                    hasError={errors?.email && touched.email}
                    errorMsg={errors?.email}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("email", e.target.value);
                    }}
                  />
                </Col>
                <Col lg={6}>
                  {/* <CommonInput
                    type="number"
                    label="Phone"
                    placeholder=""
                    variant="input"
                    id="input_phone"
                    floatingLabel={true}
                    className="regular single-border"
                    name="phone"
                    value={values?.phone}
                    hasError={errors?.phone && touched.phone}
                    errorMsg={errors?.phone}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value);
                    }}
                  /> */}
                  <CommonInput
                    label=" Mobile Number"
                    variant="phone-with-country"
                    floatingLabel={true}
                    className={`regular single-border ${Style.country_code_input}`}
                    id="input_phone"
                    
                    name="phone"
                    type="text"
                    default_country="IN"
                    value={values?.phone}
                    hasError={errors?.phone && touched.phone}
                    errorMsg={errors?.phone}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      
                  //e.nativeEvent

                      if(isNumberKey(e) && e.keyCode !== '38' && e.keyCode !== '40'){
                        setFieldValue("phone", e.target.value);
                      }

                    }}
                    country_dial_code={values?.country_dial_code}
                    callback={function (country) {
                      setFieldValue("country_dial_code", country.dial_code);
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <CommonInput
                    label="State"
                    variant="select-input"
                    type="text"
                    id="input_country_select"
                    placeholder=""
                    floatingLabel={true}
                    className="regular single-border"
                    // defaultValue={values?.country}
                    value={values?.country}
                    countryName={values?.country}
                    name="country"
                    options={countryData?.map((data, index) => {
                      return {
                        value: data?.id,
                        label: data?.name,
                      };
                    })}
                    hasError={errors?.country && touched.country}
                    errorMsg={errors?.country}
                    onBlur={handleBlur}
                    callback={(e) => {
                      setFieldValue("country", e);
                    }}
                  />
                </Col>
                <Col lg={12}>
                  <div
                    className={`form-group floating-label regular single-border textarea ${
                      messageValue !== "" ? "has-value" : ""
                    }`}
                  >
                    <div className="input-holder">
                      <textarea
                        name="message"
                        id="input_message"
                        className="form-control"
                        value={values?.message}
                        onChange={(e) => {
                          setFieldValue("message", e.target.value);
                          setMessageValue(e.target.value);
                        }}
                      />
                    </div>
                    <label htmlFor="input_message">Message (Optional)</label>
                  </div>
                </Col>
              </Row>
              <div className={Style.ContactForm__submit}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
