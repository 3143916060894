import React from 'react'
import CommonLayout from '../../components/Layouts/CommonLayout'
import Plans from '../../components/Plans'

const PlansPage = () => {
    return (
        <CommonLayout>
            <Plans />
        </CommonLayout>
    )
}

export default PlansPage