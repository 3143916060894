import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDimensions } from "../../../Logic/Dimensions";
import Icon from "../../Layouts/Icons";
import Style from "./HomeBanner.module.scss";

import { useAnimations } from "../../../Logic/Animations";
import Assets from "../../Layouts/Assets";
import useTranslation from "../../../Logic/useTranslation";
import useAuthChecker from "../../../Logic/Auth/useAuthChecker";

const HomeBanner = () => {
  const windowDimensions = useDimensions();
  const { slideTopRef, slideRightRef, splitTextRef } = useAnimations();
  const { t } = useTranslation();

  const handleClickToScroll = () => {
    const element = document.querySelector(".home-features");
    const offset = 63;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const handleScrollToVideo = () => {
    const videoSec = document.querySelector(".homeVideoPlayer");
    const offset = windowDimensions.width >= 1200 ? 0 : 63;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = videoSec.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const { isAuthCompleted } = useAuthChecker();

  return (
    <section className={Style.HomeBanner}>
      <Container>
        <Row>
          <Col lg={6}>
            <h1 className={Style.HomeBanner__title}>
              <div ref={splitTextRef} className="split-lines overflow-hidden">
                Store and
              </div>
              <div ref={splitTextRef} className="split-lines overflow-hidden">
                share your
              </div>
              <div ref={splitTextRef} className="split-lines overflow-hidden">
                photos safely
              </div>
            </h1>
            <p
              className={Style.HomeBanner__description}
              ref={windowDimensions.width > 1200 ? slideRightRef : slideTopRef}
            >
              We believe that choosing to store your memory in the cloud
              shouldn’t compromise the archival quality of your image.
            </p>
            <div className={Style.HomeBanner__buttons}>
              {!isAuthCompleted && (
                <Link to="login">
                  <button
                    className="btn btn-border-white"
                    ref={
                      windowDimensions.width > 1200
                        ? slideRightRef
                        : slideTopRef
                    }
                  >
                    Get Started
                  </button>
                </Link>
              )}
              <button
                className="btn-link btn-icon-link link-white"
                ref={
                  windowDimensions.width > 1200 ? slideRightRef : slideTopRef
                }
                onClick={() => handleScrollToVideo()}
              >
                <Icon size={30} icon={"btn-play-white"} />
                <span>Watch Video</span>
              </button>
            </div>
          </Col>
          
            <Col lg={6}>
              <div
                className={Style.HomeBanner__videoPlayer}
                ref={
                  windowDimensions.width > 1200 ? slideRightRef : slideTopRef
                }
                onClick={() => handleScrollToVideo()}
              >
                <figure className={Style.ipad_frame}>
                  <img src={windowDimensions.width >= 992 ? Assets.ipad_frame : Assets.ipad_frame_mob} alt="" />
                  <figure className={Style.videoThumb}>
                    <img src={Assets.bannerVideoThumbs} alt="" />
                  </figure>
                </figure>
                {windowDimensions.width >= 992 && (
                  <>
                  <figure className={`${Style.vector} ${Style.vector_spring}`}>
                    <img
                      src={Assets.vector_spring_white}
                      alt=""
                      ref={slideTopRef}
                    />
                  </figure>
                  <figure
                    className={`${Style.vector} ${Style.vector_semicircle}`}
                  >
                    <img src={Assets.vector_sc_green} alt="" ref={slideTopRef} />
                  </figure>
                  </>
                )}
              </div>
            </Col>
        </Row>
      </Container>
      {windowDimensions.width >= 768 ? (
        <button
          className={Style.HomeBanner__scrollDown}
          onClick={() => handleClickToScroll()}
        >
          <div className={Style.HomeBanner__scrollDown__anim}>
            <div className={Style.scroll_me} ref={slideRightRef}>
              <div className={Style.chevron}></div>
              <div className={Style.chevron}></div>
              <div className={Style.chevron}></div>
            </div>
          </div>
          <span ref={slideRightRef}>Scroll Down</span>
        </button>
      ) : (
        ""
      )}
    </section>
  );
};

export default HomeBanner;
