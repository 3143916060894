import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadLogo } from "../../store/slices/GetStarted/getStartedSlice";

export const useGetStarted = () => {
  const [hasUploadedFiles, setHasUploadedFiles] = useState(false);
  const [imageSelect, setImageSelect] = useState(null);
  const [showChangeLogoModal, setShowChangeLogoModal] = useState({});
  const [errorMessage, seterrorMessage] = useState(false);
  const [errorMessageLogo, seterrorMessageLogo] = useState(false);

  const [stepsData, setStepsData] = useState([
    {
      id: 1,
      title: "Upload your logo",
      description:
        "Currently, you can only upload .JPEG and .PNG images with a maximum size of 2MB to your logo.",
      icon_name: "icon-upload",
      type: "logo",
      img: "",
    },
    {
      id: 1,
      title: "Upload your watermark here",
      description:
        "Upload your watermark. If not we will use a default whater mark. PNG images with a maximum size of 2MB",
      icon_name: "icon-watermark",
      type: "waterMark",
      img: "",
    },
  ]);

  const handleOpenLogoModal = (type) => {
    if (type == "logo") {
      let data = {
        title: "Change logo",
        isOpen: true,
        type: "logo",
      };
      setShowChangeLogoModal(data);
    } else {
      let data = {
        title: "Change water mark",
        isOpen: true,
        type: "waterMark",
      };
      setShowChangeLogoModal(data);
    }
  };
  return {
    hasUploadedFiles,
    setHasUploadedFiles,
    imageSelect,
    setImageSelect,
    showChangeLogoModal,
    handleOpenLogoModal,
    setShowChangeLogoModal,
    stepsData,
    setStepsData,
    errorMessage,
    seterrorMessage,
    errorMessageLogo,
    seterrorMessageLogo,
  };
};
