import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Assets from "../../Layouts/Assets";
import Icons from "../../Layouts/Icons";
import CommonModal from "../CommonModal";
import Style from "./FPAImageUploadModal.module.scss";
import "swiper/css/free-mode";
import { FreeMode } from "swiper";
import { useFPAUploadModal } from "../../../Logic/FPA Uploader/useFPAUploadModal";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import Thumb from "../ImageUploadModal/Thumb";

const Progress = ({ strokeWidth, percentage, success }) => {
  const radius = 50 - strokeWidth / 2;
  const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "var(--theme-btn-primary)",
    strokeLinecap: "round",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
  };

  return (
    <>
      {success ? (
        <>
          <svg
            className={Style.success_button_svg}
            width={23}
            height={23}
            viewBox="0 0 1404 1024"
          >
            <path
              d={
                "M487.88 1024l-487.88-487.778 107.528-107.528 380.352 380.352 809.146-809.045 107.528 107.528z"
              }
              fill={"white"}
              strokeWidth={strokeWidth}
            />
          </svg>
        </>
      ) : (
        <>
          <svg
            className={Style.CircularProgressbar}
            viewBox="0 0 100 100"
            width={23}
            height={23}
          >
            <path
              className="CircularProgressbar-trail"
              d={pathDescription}
              strokeWidth={strokeWidth}
              fillOpacity={0}
              style={{
                stroke: "#D7DAE4",
              }}
            />

            <path
              className="CircularProgressbar-path"
              d={pathDescription}
              strokeWidth={strokeWidth}
              fillOpacity={0}
              style={progressStyle}
            />
          </svg>
        </>
      )}
    </>
  );
};

const FPAImageUploadModal = ({
  showModal,
  setShowModal,
  onUpload,
  handleDragOver,
  handleDragOut,
  handleDrop,
  handleImageChange,
  thumbs,
  activeUploadPercent,
  activeUploadIndex,
  closeUploadModal,
  cancelUpload,
  closeUploadModalWhenDone,

  allFiles,
  uploadIndex,
  duplicateImages,
  invalidImages,
  images,
}) => {
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    const arrayIndex = imageData.length < 6 ? 6 - imageData.length : 0;
    const previewImageData = [...imageData, ...Array(arrayIndex).keys()];
    setUploadedImages(previewImageData);
  }, []);

  const updateSwiper = useRef();

  const { all } = useSelector((state) => state.gallery.sections);

  useEffect(() => {
    updateSwiper?.current?.swiper?.slideTo(
      activeUploadIndex > 1 ? activeUploadIndex - 1 : 0
    );
  }, [activeUploadIndex]);


  let tempImg = images[uploadIndex]?.thumbnail
    ? []
    : images[uploadIndex]
      ? [images[uploadIndex]]
      : [];

  const ListItem = ({ data, index, style }) => {
    return (
      <div
        className={`${Style.file_upload_preview_wrap} file_upload_preview_wrap`}
        style={style}
      >
        {data[index]?.duplicate ? (
          <Thumb duplicate={data[index]?.name} />
        ) : data[index]?.invalid ? (
          <Thumb invalid={data[index]?.name} />
        ) : (
          <Thumb image={data[index]} />
        )}
      </div>
    );
  };

  return (
    <>
      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={showModal}
        setShowModal={setShowModal}
        centered={true}
        className={Style.ImageUploadModal}
        content={
          <>
            {/* {all?.data?.length === 0 && (
              <div className={Style.images_not_selected_wrap}>
                <p className={Style.images_not_selected}>
                   No images has been uploaded!
                </p>
              </div>
            )} */}

            <div
              className={Style.ImageUploadModal__uploadBox}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onDragLeave={handleDragOut}
            >
              <label htmlFor="uploadImg">
                <figure>
                  <img src={Assets.blank_data_placeholder} alt="" />
                </figure>
                <span>
                  Drag and drop an image, <span>or Browse</span>
                </span>
                <p>Photos will be uploaded to the client's FPA account.</p>

                <input
                  onChange={handleImageChange}
                  type="file"
                  className="d-none"
                  name=""
                  accept="image/gif, image/jpeg, image/png"
                  id="uploadImg"
                  multiple
                />
              </label>
            </div>
            <div className={Style.ImageUploadModal__preview}>
              <div className={`${Style.file_upload_status} me-3 ms-0`}>
                <div
                  className={`${Style.add_more_btn_contents}  ${uploadIndex !== allFiles?.length && "inprogress"
                    } `}
                >
                  <strong className="mb-sm-1">File Upload</strong>

                  <span>
                    {uploadIndex}/{allFiles?.length}
                  </span>

                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    variant="danger"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        className={Style.tooltip_bottom}
                      >
                        <span>
                          Total - <strong>{allFiles?.length}</strong>
                        </span>
                        <span>
                          Uploaded - <strong>{uploadIndex}</strong>
                        </span>
                        <span>
                          Duplicate - <strong>{duplicateImages?.length}</strong>
                        </span>
                        <span>
                          Invalid - <strong>{invalidImages?.length}</strong>
                        </span>
                      </Tooltip>
                    }
                  >
                    <button
                      className={`btn btn-link border px-2 ${Style.upload_image_info_btn}`}
                    >
                      {/* <Icons
                          className="d-inline-block pt-1"
                          icon={"info"}
                          color={"#ffad50"}
                          size={18}
                        /> */}
                      !
                    </button>
                  </OverlayTrigger>

                  {/* <span className="mb-sm-1">5/100</span>
                    <strong className="mb-0">File Upload</strong> */}

                  {/* <span className={Style.usage_limit_exeeded}>Usage Limit Exceeded</span> */}
                </div>
              </div>

              <div
                className={Style.file_upload_list_wrap_inneritem}
              //ref={sliderRef}
              >
                <FixedSizeList
                  itemData={[
                    ...tempImg,
                    ...images
                      .slice(
                        uploadIndex - 10 > 0 ? uploadIndex - 10 : 0,
                        uploadIndex
                      )
                      .reverse(),
                    ...duplicateImages,
                    ...invalidImages,
                  ]}
                  height={70}
                  itemCount={
                    [
                      ...images.slice(
                        uploadIndex - 10 > 0 ? uploadIndex - 10 : 0,
                        uploadIndex
                      ),
                      ...duplicateImages,
                      ...invalidImages,
                    ].length
                  }
                  itemSize={124}
                  width={400}
                  direction="horizontal"
                  style={{ flex: "1 0", width: "100%" }}
                  className={Style.file_upload_list_wrap}
                >
                  {ListItem}
                </FixedSizeList>
              </div>

              <label className={Style.add_more_btn} for="uploadImg">
                <Icons size={18} icon={"icon-plus"} />
                <br />
                <span>Add more</span>
              </label>
            </div>
            {/* <div className={Style.ImageUploadModal_files_noti}>
              <p>Files are being uploaded</p>
            </div> */}
            <div className={Style.ImageUploadModal__button}>
              <button
                className="btn btn-primary"
                disabled={allFiles?.length > 0 && uploadIndex == allFiles?.length
                  ? false
                  : true}
                onClick={() => closeUploadModal()}
              >
                Done
              </button>
            </div>
          </>
        }
      />
    </>
  );
};

export default FPAImageUploadModal;

const imageData = [
  {
    id: 1,
    image: Assets.photo1,
  },
  {
    id: 2,
    image: Assets.photo2,
  },
  {
    id: 3,
    image: Assets.photo3,
  },
  {
    id: 4,
    image: Assets.photo4,
  },
  {
    id: 5,
    image: Assets.photo5,
  },
  {
    id: 6,
    image: Assets.photo6,
  },
  {
    id: 7,
    image: Assets.photo7,
  },
];
