import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCustomSearchText,
  getAllProjectsAPI,
  projectOrderChange,
  customLoadMore,
  setSerachQuery,
  setSearchResultPlaceHolderValue,
  unSelectAllImages,
  closeFilterActions,
  closePreSelect,
} from "../../../store/slices/Project/ProjectSlice";

export const useSearch = () => {
  const dispatch = useDispatch();
  const { sections, currentTab } = useSelector((state) => state.project);

  /************************
   * SEARCH BAR VARIABLES *
   ************************/

  const [isFocused, setIsFocused] = useState(false);
  const [resetVisible, setResetVisible] = useState(false);

  const [searchResultPlaceHolderText, setSearchResultPlaceHolderText] =
    useState("You haven't created any projects1");

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    dispatch(handleCustomSearchText(value));
  };

  const useDebounce = function (value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  };

  const debouncedSearchTerm = useDebounce(
    sections[currentTab]?.searchText,
    700
  );

  useEffect(() => {
    if (debouncedSearchTerm !== undefined) {
      fetchAllProjects(currentTab, debouncedSearchTerm);
      handleCancelSelections();
    }
  }, [debouncedSearchTerm]);

 


  const handleCancelSelections = () => {

    // This function resets the pre-section and selected projects. 
    if (sections[currentTab]?.currentAction?.preSelect) {
      dispatch(closeFilterActions());
      dispatch(closePreSelect());
      dispatch(unSelectAllImages());
    }
  };

  const getTabUrl = (slug) => {
    switch (slug) {
      case "activeTab":
        return "list-project";
        break;
      case "completedTab":
        return "list-completed-project";
        break;
    }
  };

  const fetchAllProjects = (slug, query = false) => {
    let obj = {
      url: getTabUrl(slug),
      page: "1",
      sort: "desc",
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    }

    dispatch(getAllProjectsAPI(obj));
  };

  const loadMore = (slug) => {
    if (sections[currentTab]?.current_page < sections[currentTab]?.last_page) {
      let obj = {
        url: getTabUrl(slug),
        page: sections[currentTab]?.current_page + 1,
        slug: "",
      };

      if (
        sections?.[currentTab]?.["search"] != "" &&
        sections?.[currentTab]?.["search"] != undefined
      ) {
        obj.search = sections?.[currentTab]?.["search"];
      }

      dispatch(customLoadMore(obj));
    }
  };

  const handleClear = () => {
    // setText("");
    setResetVisible(false);
    fetchAllProjects(currentTab);
  };

  /************************
   * SEARCH BAR FUNCTIONS *
   ************************/

  const handleResetVisible = (e) => {
    // setText(e.target.value);
    e.target.value.length > 0 ? setResetVisible(true) : setResetVisible(false);
  };

  return {
    handleChangeInput,
    searchText: sections[currentTab]?.searchText,
    handleClear,
    isFocused,
    setIsFocused,
    resetVisible,
    setResetVisible,
    searchResultPlaceHolderText,
  };
};
