import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import ContentHeader from "../ContentHeader";
import Style from "./Projects.module.scss";
import { useFPAUploadModal } from "../../../Logic/FPA Uploader/useFPAUploadModal";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadModalAction,
  openMinimizedBox,
  clearThumbs,
} from "../../../store/slices/Gallery/gallerySlice";

import {
  uploadFPAModalAction,
  clearFPAThumbs,
  openFPAMinimizedBox
} from "../../../store/slices/Gallery/galleryFPAupload";



import ImageUploadMinimized from "../ImageUploadMinimized";
import ImageUploadModal from "../../Modals/ImageUploadModal";
import FPAImageUploadModal from "../../Modals/FPAImageUploadModal";
import { useUploadModal } from "../../../Logic/Uploader/useUploadModal";

const Projects = () => {
  const [isGrid, setIsGrid] = useState(true);
  const [isCollapsed] = useOutletContext();
  const [isShowImageUpload, setIsShowImageUpload] = useState(false);
  const dispatch = useDispatch();

  const [isFpa, setIsFpa] = useState(false);

  const { FPAupload } = useSelector((state) => state);
  let { folderID } = useParams();

  const {
    handleDragOver,
    handleDragOut,
    handleDrop,
    handleImageChange,
    thumbs,
    activeUploadPercent,
    activeUploadIndex,
    closeUploadModal,
    cancelUpload,
    closeUploadModalWhenDone,
    openMiniBox,
    openUploadModal,
    duplicateImages,
    invalidImages,
    allFiles,
    files,
    images,
    newFiles,
    uploadIndex,
    isUploading,
  } = useUploadModal();


  const {
    openUploadFPAModal,
    handleDragOver: handleDragOverFPA,
    handleDragOut: handleDragOutFPA,
    handleDrop: handleDropFPA,
    handleImageChange: handleImageChangeFPA,
    thumbs: thumbsFPA,
    activeUploadPercent: activeUploadPercentFPA,
    activeUploadIndex: activeUploadIndexFPA,
    closeUploadModal: handleCloseFPAModal,
    cancelUpload: cancelUploadFPA,
    closeUploadModalWhenDone: closeUploadModalWhenDoneFPA,
    handleCloseFPAMiniMizedModal,
    allFiles: allFilesFPA,
    uploadIndex: uploadIndexFPA,
    duplicateImages: duplicateImagesFPA,
    invalidImages: invalidImagesFPA,
    images: imagesFPA,
  } = useFPAUploadModal();



  const handleCloseMiniMizedModal = () => {
    dispatch(openMinimizedBox(false));
  };



  return (
    <div
      className={`${Style.Projects} ${isShowImageUpload ? Style.Projects_active_imageupload : ""
        }`}
    >
      <ContentHeader isGrid={isGrid} setIsGrid={setIsGrid} />
      <Outlet context={[isGrid, isCollapsed]} />

      <ImageUploadModal
        showModal={openUploadModal}
        setShowModal={closeUploadModal}
        handleDragOver={handleDragOver}
        handleDragOut={handleDragOut}
        handleDrop={handleDrop}
        handleImageChange={handleImageChange}
        thumbs={thumbs}
        activeUploadPercent={activeUploadPercent}
        activeUploadIndex={activeUploadIndex}
        closeUploadModal={closeUploadModal}
        cancelUpload={cancelUpload}
        closeUploadModalWhenDone={closeUploadModalWhenDone}
        openMiniBox={openMiniBox}
        openUploadModal={openUploadModal}
        duplicateImages={duplicateImages}
        invalidImages={invalidImages}
        allFiles={allFiles}
        files={files}
        images={images}
        newFiles={newFiles}
        uploadIndex={uploadIndex}
        isUploading={isUploading}
      />

      {openMiniBox && (
        <ImageUploadMinimized
          setIsShowImageUpload={handleCloseMiniMizedModal}
          uploadData={images}
          //uploadIndex={activeUploadIndex}
          percentage={activeUploadPercent}
          images={images}
          duplicateImages={duplicateImages}
          invalidImages={invalidImages}
          uploadIndex={uploadIndex}
          allFiles={allFiles}
        />
      )}

      <FPAImageUploadModal
        // showModal={true}
        showModal={openUploadFPAModal}
        setShowModal={handleCloseFPAModal}
        handleDragOver={handleDragOverFPA}
        handleDragOut={handleDragOutFPA}
        handleDrop={handleDropFPA}
        handleImageChange={handleImageChangeFPA}
        thumbs={thumbsFPA}
        activeUploadPercent={activeUploadPercentFPA}
        activeUploadIndex={activeUploadIndexFPA}
        closeUploadModal={handleCloseFPAModal}
        cancelUpload={cancelUploadFPA}
        closeUploadModalWhenDone={closeUploadModalWhenDoneFPA}
        allFiles={allFilesFPA}
        uploadIndex={uploadIndexFPA}
        duplicateImages={duplicateImagesFPA}
        invalidImages={invalidImagesFPA}
        images={imagesFPA}
      />

      {FPAupload.openFPAMiniBox && (
        <ImageUploadMinimized
          setIsShowImageUpload={handleCloseFPAMiniMizedModal}
          uploadData={imagesFPA}
          // uploadIndex={FPAupload.activeUploadIndex}
          percentage={FPAupload.activeUploadPercent}
          images={imagesFPA}
          duplicateImages={duplicateImagesFPA}
          invalidImages={invalidImagesFPA}
          uploadIndex={uploadIndexFPA}
          allFiles={allFilesFPA}
        />
      )}

    </div>
  );
};

export default Projects;
