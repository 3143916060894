import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import usePayment from "../../Logic/usePayment";
import PlansCardStack from "../PlansCardStack";
import Style from "./ProjectPlans.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ProjectPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectID,clientID } = useParams();

  const { getClientPlans,getStudioPlans, allPlans, selectPlan, isSuccess, isItValidRequest } =
    usePayment();

  useEffect(() => {
    /********************************************************************
     * isItValidRequest() CHECKS THE USER COMES THIS PAGE IS VALID OR NOT *
     *           IF IT NOT VALID USER WILL BE REDIRECT INTO DASHBOARD   *
     ********************************************************************/
    //isItValidRequest();

    if (projectID != undefined) {
      getClientPlans();
    }else{
      getStudioPlans();
    }

    if (
      location.pathname.startsWith("/dashboard") &&
      location.pathname !== "/user-plans"
    ) {
      document.body.setAttribute(
        "data-theme",
        localStorage.getItem("photofindtheme")
      );
    } else {
      document.body.setAttribute("data-theme", "default");
    }
    if (location.pathname === "/user-plans") {
    }
  }, []);

  return (
    <div className={Style.ProjectPlans}>
      <Container>
        <div className={`section-title-block center`}>
          <h2>Click to create an account for your client.</h2>
          <p>Choose basic plan for your client, and cancel at any time</p>
        </div>
        <PlansCardStack
          plansData={allPlans}
          buttonText={"Buy Now"}
          cardType={"client"}
          buttonCallback={selectPlan}
        />
      </Container>
    </div>
  );
};

export default ProjectPlans;
