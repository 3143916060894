import React, { useState } from "react";
import Assets from "../../Layouts/Assets";
import Icon from "../../Layouts/Icons";
import ImageUploadItem from "./ImageUploadItem";
import Style from "./ImageUploadMinimized.module.scss";
import ImageThumb from "./ImageThumb";

const ImageUploadMinimized = ({
  setIsShowImageUpload,
  uploadData,
  uploadIndex,
  percentage,
  duplicateImages,
  invalidImages,
  allFiles,
  images
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  function ListItem({ data, index, style }) {
    return (
      <div className={Style.fu_body_list} style={style}>
        {data[index]?.duplicate ? (
          <ImageThumb
            image={data?.[index]}
            name={data?.[index]?.name}
            isDupicate
          />
        ) : data[index]?.invalid ? (
          <ImageThumb name={data?.[index]?.name} />
        ) : (
          <ImageThumb
            image={data[index]}
            name={data?.[index]?.file?.name}
            isImageSuccess
          />
        )}
      </div>
    );
  }

  return (
    <div
      className={`${Style.ImageUploadMinimized} ${
        isCollapsed && Style.ImageUploadMinimized__collapsed
      }`}
    >
      <div className={Style.ImageUploadMinimized__head}>
        <h5 className={Style.ImageUploadMinimized__title}>
             {allFiles?.length > 0 && allFiles?.length == uploadIndex
            ? `Uploaded ${allFiles?.length} Photos`
            : `Uploading ${Math.abs( allFiles?.length - uploadIndex )} Photos`}
        </h5>
        <div className={Style.ImageUploadMinimized__actions}>
          <button
            className={Style.ImageUploadMinimized__collapseBtn}
            onClick={() =>
              isCollapsed ? setIsCollapsed(false) : setIsCollapsed(true)
            }
          >
            <Icon color={"#000"} size={12} icon={"dropdown-arrow-down"} />
          </button>
          <button
            className={Style.ImageUploadMinimized__close}
            onClick={() => setIsShowImageUpload(false)}
          >
            <Icon color={"#000"} size={12} icon={"icon-close"} />
          </button>
        </div>
      </div>
      <div className={Style.ImageUploadMinimized__body}>

      {/* <FixedSizeList
          itemData={[...images, ...duplicateImages, ...invalidImages]}
          height={isCollapsed ? 0 : 270}
          itemCount={[...images, ...duplicateImages, ...invalidImages].length}
          itemSize={70}
          width={400}
          direction="vertical"
          style={{ flex: "1 0", width: "100%" }}
        >
        
        {ListItem}
        </FixedSizeList> */}

        <ul className={Style.ImageUploadMinimized__list}>
          {uploadData.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ImageUploadItem
                  item={item}
                  index={index}
                  uploadIndex={uploadIndex}
                  percentage={percentage}
                />
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ImageUploadMinimized;

const imageData = [
  {
    id: 1,
    // image: Assets.photo1,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: true,
  },
  {
    id: 2,
    // image: Assets.photo2,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: true,
  },
  {
    id: 3,
    image: Assets.photo3,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: false,
  },
  {
    id: 4,
    image: Assets.photo4,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: false,
  },
  {
    id: 5,
    image: Assets.photo5,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: false,
  },
  {
    id: 6,
    image: Assets.photo6,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: false,
  },
  {
    id: 7,
    image: Assets.photo7,
    name: "pexels-jinto-mathew-11146331.jpg",
    isUploaded: false,
  },
];
