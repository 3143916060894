import React, { useEffect, useRef, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Assets from "../../Layouts/Assets";
import Icons from "../../Layouts/Icons";
import CommonModal from "../CommonModal";
import Style from "./ImageUploadModal.module.scss";
import "swiper/css/free-mode";
import { FreeMode } from "swiper";
import { useUploadModal } from "../../../Logic/Uploader/useUploadModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FixedSizeList } from "react-window";
import Thumb from "./Thumb";


const ImageUploadModal = ({
  showModal,
  setShowModal,
  onUpload,

  handleDragOver,
  handleDragOut,
  handleDrop,
  handleImageChange,

  activeUploadIndex,

  closeUploadModalWhenDone,

  allFiles,
  uploadIndex,
  duplicateImages,
  invalidImages,
  images,
}) => {


  const updateSwiper = useRef();




  useEffect(() => {
    updateSwiper?.current?.swiper?.slideTo(
      activeUploadIndex > 1 ? activeUploadIndex - 1 : 0
    );
  }, [activeUploadIndex]);

  const ListItem = ({ data, index, style }) => {
    return (
      <div
        className={`${Style.file_upload_preview_wrap} file_upload_preview_wrap`}
        style={style}
      >
        {data[index]?.duplicate ? (
          <Thumb duplicate={data[index]?.name} />
        ) : data[index]?.invalid ? (
          <Thumb invalid={data[index]?.name} />
        ) : (
          <Thumb image={data[index]} />
        )}
      </div>
    );
  };


  let tempImg = images[uploadIndex]?.thumbnail
    ? []
    : images[uploadIndex]
      ? [images[uploadIndex]]
      : [];



  return (
    <>
      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={showModal}
        setShowModal={setShowModal}
        centered={true}
        className={Style.ImageUploadModal}
        content={
          <>
            <div
              className={Style.ImageUploadModal__uploadBox}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onDragLeave={handleDragOut}
            >
              <label htmlFor="uploadImg">
                <figure>
                  <img src={Assets.blank_data_placeholder} alt="" />
                </figure>
                <span>
                  Drag and drop an image, <span>or Browse</span>
                </span>
                {/* <p>Photos will be uploaded to the client's FPA account.</p> */}

                <input
                  onChange={handleImageChange}
                  type="file"
                  className="d-none"
                  name=""
                  accept="image/gif, image/jpeg, image/png"
                  id="uploadImg"
                  multiple
                />
              </label>
            </div>
            <div className={Style.file_upload_preview}>


              <div className={`${Style.file_upload_status} me-3 ms-0`}>
                <div
                  className={`${Style.add_more_btn_contents}  ${uploadIndex !== allFiles?.length && "inprogress"
                    } `}
                >
                  <strong className="mb-sm-1">File Upload</strong>

                  <span>
                    {uploadIndex}/{allFiles?.length}
                  </span>

                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    variant="danger"
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        className={Style.tooltip_bottom}
                      >
                        <span>
                          Total - <strong>{allFiles?.length}</strong>
                        </span>
                        <span>
                          Uploaded - <strong>{uploadIndex}</strong>
                        </span>
                        <span>
                          Duplicate - <strong>{duplicateImages?.length}</strong>
                        </span>
                        <span>
                          Invalid - <strong>{invalidImages?.length}</strong>
                        </span>
                      </Tooltip>
                    }
                  >
                    <button
                      className={`btn btn-link border px-2 ${Style.upload_image_info_btn}`}
                    >

                      !
                    </button>
                  </OverlayTrigger>


                </div>
              </div>

              <div
                className={Style.file_upload_list_wrap_inneritem}
              //ref={sliderRef}
              >
                <FixedSizeList
                  itemData={[
                    ...tempImg,
                    ...images
                      .slice(
                        uploadIndex - 10 > 0 ? uploadIndex - 10 : 0,
                        uploadIndex
                      )
                      .reverse(),
                    ...duplicateImages,
                    ...invalidImages,
                  ]}
                  height={70}
                  itemCount={
                    [
                      ...images.slice(
                        uploadIndex - 10 > 0 ? uploadIndex - 10 : 0,
                        uploadIndex
                      ),
                      ...duplicateImages,
                      ...invalidImages,
                    ].length
                  }
                  itemSize={124}
                  width={400}
                  direction="horizontal"
                  style={{ flex: "1 0", width: "100%" }}
                  className={Style.file_upload_list_wrap}
                >
                  {ListItem}
                </FixedSizeList>
              </div>

              <label className={Style.add_more_btn} for="uploadImg">
                <Icons size={18} icon={"icon-plus"} />
                <br />
                <span>Add more</span>
              </label>
            </div>
            <div className={Style.ImageUploadModal__button}>
              <button
                disabled={allFiles?.length > 0 && uploadIndex == allFiles?.length
                  ? false
                  : true}
                className="btn btn-primary"
                onClick={() => closeUploadModalWhenDone()}
              >
                {"Done"}
              </button>
            </div>
          </>
        }
      />
    </>
  );
};

export default ImageUploadModal;


