import React from "react";
import HomeAboutFPAStudio from "./HomeAboutFPAStudio";
import HomeBanner from "./HomeBanner";
import HomeChoosePlans from "./HomeChoosePlans";
import HomeClients from "./HomeClients";
import HomeFeatures from "./HomeFeatures";
import HomeTestimonials from "./HomeTestimonials";
import HomeVideo from "./HomeVideo";

const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeFeatures />
      <HomeVideo />
      <HomeAboutFPAStudio />
      <HomeChoosePlans />
      <HomeTestimonials />
      <HomeClients />
    </>
  );
};

export default Home;
