import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Style from "../utils/VideoPlayer/VideoPlayer.module.scss";

export const useShareInfoPopup = ({ setOpenShareModal }) => {
  const { allFolders } = useSelector((state) => state.projectSinglePage);
  const [loading, setLoading] = useState(false);
  const [clientURL, setClientURL] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setClientURL(
      `https://photofind-userflow.webc.in/${allFolders.eventInfo.shareInfo.data.uuid}`
    );
  }, [allFolders.eventInfo.shareInfo.data.uuid]);

  const copyToClipboard = (textToCopy) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleWhatsAppShare = () => {
    setLoading("sharingw");
    setTimeout(() => {
      let phone =
        allFolders.eventInfo.data?.project?.get_clients?.[0]?.["mobile"];

      let whatsappLink = `https://api.whatsapp.com/send?text=${clientURL}&phone=${phone}`;
      window.open(whatsappLink, "_blank");
      setOpenShareModal(false);
      setLoading(false);
    }, 800);
  };

  const handleEmailShare = () => {
    setLoading("sharinge");

    setTimeout(() => {
      let clientEmail =
        allFolders.eventInfo.data?.project?.get_clients?.[0]?.["email"];
      let subject = "Client URL";
      let token = allFolders.eventInfo.shareInfo.data.uuid;
      let whatsappLink = `https://mail.google.com/mail/?view=cm&to=${allFolders.eventInfo.data?.project?.get_clients?.[0]?.["email"]}&su=${subject}&body=${clientURL}`;
      window.open(whatsappLink, "_blank");
      setOpenShareModal(false);
      setLoading(false);
    }, 800);
  };

  const handleCopyClick = (copyText) => {
    copyToClipboard(copyText)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setOpenShareModal(false);
          setLoading(false);
          setIsCopied(false);
        }, 1000);
      })
      .catch((err) => {
        setOpenShareModal(false);
        setLoading(false);
      });
  };

  return {
    copyToClipboard,
    clientURL,
    handleWhatsAppShare,
    handleEmailShare,
    // handleCopyClientURL,
    loading,
    setLoading,
    handleCopyClick,
    isCopied,
  };
};
