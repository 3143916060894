import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  storeNewProjectData,
  storeNewClientData,
  getAllPlans,
  saveProjectDetails,
  saveClientDetails,
  clearCreateProjectData,
  getOrderID,
  clearTabProjectsData,
  saveClientComepleted,
  insertProjectCard,
  getAllProjectsAPI,
  setStepOneFormData,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  toggleProjectAddPopup,
  toggleClientSucessPopup,
  updateClientDetailsInList,
  handleCustomSearchText
} from "../store/slices/Project/ProjectSlice";

import { storeCurrentPageDetails } from "../store/slices/Project/ProjectSingle";

const useAddNewProjects = () => {
  const { stepOneFormData, stepTwoFormData, selectedPlanID } = useSelector(
    (state) => state.project.addProjects
  );

  const {
    status,
    sections,
    currentTab,
    editProject,
    deleteProject,
    setStatusComplete,
    changeProjectStatus,
  } = useSelector((state) => state.project);

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const getTabUrl = (slug) => {
    switch (slug) {
      case "activeTab":
        return "list-project";
        break;
      case "completedTab":
        return "list-completed-project";
        break;
    }
  };

  const handleProjectSinglePage = (data) => {
    dispatch(storeCurrentPageDetails(data));
    navigate("/dashboard/project/" + data.id);
    dispatch(clearCreateProjectData(data));
    dispatch(clearTabProjectsData("activeTab"));
    dispatch(saveClientComepleted(false));
  };

  const handleRedirectionToPaymentPage = (data) => {
    dispatch(storeCurrentPageDetails(data));
    navigate(
      `/dashboard/user-plans/${data.id}/${stepTwoFormData?.result?.id}/`
    );
  };

  const storeProjectData = (data) => {
    const { formData, resetForm, setAddProjectStepOne, setAddProjectStepTwo } =
      data;
    dispatch(saveProjectDetails(formData))
      .unwrap()
      .then((result) => {
        if (result.data.success === true) {
          resetForm();
          setAddProjectStepOne(false);
          setAddProjectStepTwo(true);
        } else {
          alert("Something went wrong while creating new project!");
        }
      });
  };

  const insertProjectData = () => {
    fetchAllProjects("activeTab");
  };

  const handleClientSuccessPopup = (status) => {
    dispatch(toggleClientSucessPopup(status));
  };

  const updateProjectsClientID = (projectID, ClientID) => {
    const updatedArray = [];
    sections?.[currentTab]?.data.forEach((item) => {
      if (item.id === projectID) {
        updatedArray.push({ ...item, client_id: ClientID });
      } else {
        updatedArray.push(item);
      }
    });
    dispatch(updateClientDetailsInList(updatedArray));
  };

  useEffect(() => {
    //  updateProjectsClientID(547, 1000);
  }, [sections?.[currentTab]]);

  const storeClientData = (data) => {
    const { formData, setFieldError, resetForm } = data;

    formData.project_id = stepOneFormData?.result?.id;

    dispatch(saveClientDetails(formData))
      .unwrap()
      .then((result) => {
        console.log(
          "result.data.success",
          result.data.success === true && result.data.result.status == 1
        );

        if (result.data.success === true && result.data.result.status == 1) {
          if (stepOneFormData.success === true) {
            /******************************************************************************************
             * IF CLIENT DETAILS AND PROJECT DETAILS WAS ADDED AT THE SAME TIME THEN THE USER WILL BE REDIRECTED TO PAYMENT PAGE *
             ******************************************************************************************/

            // handleRedirectionToPaymentPage(stepOneFormData.result);

            dispatch(toggleProjectAddPopup(false)); 
            dispatch(storeCurrentPageDetails(stepOneFormData.result));
            navigate(
              `/dashboard/user-plans/${stepOneFormData.result.id}/${result.data.result.id}/`
            );
          } else if (
            stepOneFormData.success === undefined &&
            stepOneFormData.result.id !== ""
          ) {
            /******************************************************************************************
             * IF CLIENT DETAILS WAS ADDED SPERATELY THEN THE USER WILL BE REDIRECTED TO PAYMENT PAGE *
             ******************************************************************************************/
            dispatch(toggleProjectAddPopup(false)); 
            navigate(
              `/dashboard/user-plans/${stepOneFormData.result.id}/${result.data?.result?.id}/`
            );
          } /*else {
            // dispatch(setAddProjectStepOne(false));
            // dispatch(setAddProjectStepTwo(false));
            // handleClientSuccessPopup(true);
            // updateProjectsClientID(
            //   stepOneFormData?.result?.id,
            //   result.data.result.id
            // );
          }*/
        } else {
          if (result?.data?.result?.validation?.errors?.email?.[0] != "") {
            setFieldError(
              "email",
              result?.data?.result?.validation?.errors?.email?.[0]
            );
          }

          if (result?.data?.result?.validation?.errors?.mobile?.[0] != "") {
            setFieldError(
              "mobile",
              result?.data?.result?.validation?.errors?.mobile?.[0]
            );
          }
        }
      });
  };

  const fetchAllProjects = (slug, query = false) => {
    let obj = {
      url: getTabUrl(slug),
      page: "1",
      sort:
        sections[currentTab]?.sort != "" &&
        sections[currentTab]?.sort != undefined
          ? sections[currentTab]?.sort
          : "desc",
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    }

    return dispatch(getAllProjectsAPI(obj));
  };

  const allPlans = () => {
    dispatch(getAllPlans());
  };

  return {
    storeProjectData,
    storeClientData,
    allPlans,
    insertProjectData,
    handleClientSuccessPopup,
  };
};

export default useAddNewProjects;
