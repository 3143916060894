import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserBasicData } from "../../store/slices/GetStarted/userBasicDataSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/Auth/authSlice";
import useAuthChecker from "../Auth/useAuthChecker";
import { getStaffDesignation } from "../../store/slices/Staffs/staffsSlice";

export const useHeader = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.userData);
  const { registerData } = useSelector((state) => state.auth);
  const { destinations, designationsStatus } = useSelector((state) => state.staffs);

  const navigate = useNavigate();
  const { isAuthCompleted } = useAuthChecker();

  useEffect(() => {
    try {
      if (Object.keys(userProfileData).length == 0 && isAuthCompleted) {
        dispatch(getUserBasicData())
          .unwrap()
          .then((data) => { })
          .catch((err) => {
            if (err.message === "Unauthenticated.") {
              navigate("/");
              dispatch(logout());
            }
          });
      }
    } catch (error) {

    }
  }, []);

  useEffect(() => {
    if (
      window.location.href.indexOf("dashboard") != -1 &&
      isAuthCompleted === false
    ) {
      navigate("/");
      dispatch(logout());
    }
  }, [isAuthCompleted]);


  console.log(designationsStatus, destinations, "cvvvv");

  useEffect(() => {
    if (designationsStatus == "idle") {
      dispatch(getStaffDesignation())
    }
  }, [])


  return {
    userProfileData,
  };
};
