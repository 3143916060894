import {
  useUploady,
  useBatchAddListener,
  useItemStartListener,
  useItemFinishListener,
  useItemFinalizeListener,
  useAbortAll,
  useItemProgressListener,
} from "@rpldy/uploady";
import loadImage from "blueimp-load-image";
import imageCompression from "browser-image-compression";

import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToThumbs,
  clearThumbs,
  updateActiveUploadPercentage,
  updateActiveIndex,
  callFunction,
  uploadFPAModalAction,
  openMinimizedBox,
  updateImageCountGallery,
  getFPAtoken,
  // getAllFPAUploadedImages,
  updateAllUploadedImages,
  updateDuplicateImages,
  updateInvalidImages,
  updateAllFiles,
  updateFiles,
  updateImages,
  updateNewFiles,
  updateUploadIndex,
  updateIsUploading,
  openFPAMinimizedBox,
} from "../../store/slices/Gallery/galleryFPAupload";
import { useParams } from "react-router-dom";
import { updateThumbsArray } from "../../store/slices/Gallery/galleryFPAupload";
import { getAllFPAUploadedImages, updateAllFPAUploadedImages } from "../../store/slices/Gallery/fileUploadSlice";

var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.svg|\.avif|\.heic)$/i;

export const useFPAUploadModal = () => {
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  let { folderID } = useParams();

  const { getCurrentProjectDetails } = useSelector(
    (state) => state.projectSinglePage
  );

  const [progress, setProgess] = useState(0);
  const progressData = useItemProgressListener();

  useEffect(() => {
    if (progressData && progressData.completed > progress) {
      setProgess(() => progressData.completed);
      dispatch(updateActiveUploadPercentage(progressData.completed));
    }
  }, [progressData]);

  const {
    tokenDetails,
    thumbs,
    activeUploadIndex,
    activeUploadPercent,
    openUploadFPAModal,
    openFPAMiniBox,
    tokenDetailsIsLoaded,
  } = useSelector((state) => state.FPAupload);

  const fileUploadState = useSelector((state) => state.fileUpload);


  const currentFolderDetails = useSelector(
    (state) => state.gallery.currentFolderDetails
  );

  const [duplicateImages, setDuplicateImages] = useState([]);
  const [invalidImages, setInvalidImages] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const uploady = useUploady();
  const [fileIndex, setFileIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [isUploadingProgress, setIsUploadingprogress] = useState(false);


  /***************************************************
   * TRIGGER FETCH CALL FOR GETTING FPA UPLOAD TOKEN *
   ***************************************************/

  useEffect(() => {
    if (
      getCurrentProjectDetails?.currentPageDetails?.get_clients?.[0]
        ?.FPA_client_id !== undefined &&
      tokenDetails?.code == null &&
      tokenDetailsIsLoaded !== "loading"
    ) {
      let formdata = {
        user_id:
          getCurrentProjectDetails?.currentPageDetails?.get_clients?.[0]
            .FPA_client_id,
      };
      dispatch(getFPAtoken(formdata));
    } else {
    }
  }, [getCurrentProjectDetails?.currentPageDetails?.get_clients?.[0]]);

  /******************************************************************************
   * FETCHING ALL IMAGES FROM FPA WAS UPLOADED BEFORE TO CHECK DUPLICATE IMAGES *
   ******************************************************************************/

  useEffect(() => {
    if (getCurrentProjectDetails?.currentPageDetails?.get_clients?.length > 0) {
      let data = new FormData();
      data.append(
        "user_id",
        getCurrentProjectDetails?.currentPageDetails?.get_clients?.[0]
          .FPA_client_id
      );
      dispatch(getAllFPAUploadedImages(data));
    }
  }, [getCurrentProjectDetails?.currentPageDetails?.get_clients]);

  /*********************************************************************************************************************
   * WHEN THE USER SELECTS THE FILES AND THE ISUPLOADING STATUS IS FALSE THEN IF STARTS UPLOADING THE FILE FPA WITH INDEX NUMBER 0 *
   *********************************************************************************************************************/

  useEffect(() => {
    if (!isUploading) {
      compressedUpload(0);
    }
  }, [files]);

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let imgArry = [...e.dataTransfer.files];
    handleFiles(imgArry);
  };

  const handleImageChange = (e) => {
    handleFiles(e.target.files);
  };

  useItemStartListener((item) => {
    if (fileUploadState.uploadMode == "FPA") {
      setIsUploadingprogress(true);
    }
  });

  useItemFinalizeListener((item) => {
    if (fileUploadState.uploadMode == "FPA") {
      setIsUploadingprogress(false);
    }
  });


  useEffect(() => {
    if (!openUploadFPAModal && isUploadingProgress) {
      dispatch(openFPAMinimizedBox(true))

    }
  }, [openUploadFPAModal]);

  const handleFiles = (upldFiles) => {
    let tempArr = [...upldFiles];

    tempArr.forEach((item, index) => {
      if (
        fileUploadState.allFPAUploadedImages.some(
          (image) => image.original_name === item.name
        )
      ) {

        setDuplicateImages((state) => [
          ...state,
          { name: item?.name, duplicate: true },
        ]);


      } else if (!allowedExtensions.test(item?.name)) {
        setInvalidImages((state) => [
          ...state,
          { name: item?.name, invalid: true },
        ]);

      } else {
        setFiles((state) => [...state, item]);
        setAllFiles((state) => [...state, item]);
      }
    });
  };

  const compressedUpload = async (index) => {
    if (files.length > 0) {
      if (isUploading === false) {
        setIsUploading(true);
      }
      if (index < files.length) {
        handleImageUpload(files[index]).then((r) => {
          compressedUpload(index + 1);
          setFileIndex((prevState) => prevState + 1);
        });
      } else {
        setFiles([]);
        setIsUploading(false);
        dispatch(updateIsUploading(false));
      }
    }
  };

  const addCompletedImages = (item) => {
    images.forEach((img, i) => {
      if (img?.file?.name === item?.file?.name) {
        images[i] = {
          ...images[i],
          thumbnail: item?.uploadResponse?.data?.result?.image?.src?.thumbnail2,
        };
      }
    });

    setUploadIndex((prevState) => prevState + 1);
    setImages([...images]);
    dispatch(updateAllFPAUploadedImages(item));



    // if (allFiles?.length > 10) {
    //   if (newFiles?.length < 10) {
    //     setNewFiles([...newFiles, item]);
    //     dispatch(updateNewFiles([...newFilesFPA, item]));
    //   } else {
    //     let newFilesTmp = [...newFiles, item];
    //     newFilesTmp.forEach((item) => {
    //       dispatch(updateAllUploadedImages(item));
    //     });

    //     setNewFiles([]);
    //     dispatch(updateNewFiles([]));
    //   }
    // } else {
    //   dispatch(updateAllUploadedImages(item));
    // }
  };




  useItemFinishListener((item) => {

    if (fileUploadState.uploadMode == "FPA") {
      if (item.uploadResponse.data.status === true) {
        addCompletedImages(item);
      } else {
        // addDuplicate(item?.file?.name);
      }
    }
  });

  // const addDuplicate = (item) => {
  //   //item is file's name
  //   let tempArr = JSON.parse(JSON.stringify(images));
  //   let index = tempArr.findIndex((image) => image.file.name === item);
  //   tempArr.splice(index, 1);
  //  setImages([...tempArr]);
  //   dispatch(updateImages([...tempArr]));
  //   dispatch(
  //     updateDuplicateImages([
  //       ...duplicateImages,
  //       { name: item, duplicate: true },
  //     ])
  //   );
  //   setDuplicateImages((state) => [...state, { name: item, duplicate: true }]);
  //   dispatch(
  //     updateDuplicateImages([
  //       ...duplicateImagesFPA,
  //       { name: item?.name, duplicate: true },
  //     ])
  //   );
  // };

  useBatchAddListener((batch) => {
    if (fileUploadState.uploadMode == "FPA") {
      setImages((state) => [...state, ...batch?.items]);
    }
  });

  const handleImageUpload = async (imageFile) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      let compressedFile = await imageCompression(imageFile, {
        ...options,
        alwaysKeepResolution: true,
      });



      // let cmpFile = imageFile.size > 4199999 ? compressedFile : imageFile;

      loadImage.parseMetaData(imageFile, (data) => {
        uploady.upload(compressedFile, {
          destination: {
            params: {
              album_id: folderID,
              user_id:
                getCurrentProjectDetails?.currentPageDetails?.get_clients?.[0]
                  .FPA_client_id,
              token: tokenDetails.code,
              album_name: currentFolderDetails?.name,
            },
            filesParamName: "image",
          },
        });
      });

      return true;
    } catch (error) {
      uploady.upload(imageFile, {
        destination: {
          params: { album_id: folderID },
          filesParamName: "image",
        },
      });

      return true;
    }
  };

  const closeUploadModalWhenDone = () => {
    dispatch(uploadFPAModalAction(false));
    dispatch(updateActiveIndex(0));
    dispatch(clearThumbs());

    setUploadIndex(0);
    dispatch(updateUploadIndex(0));
    setFileIndex(0);
    setImages([]);
    setFiles([]);
    dispatch(updateFiles([]));
    setAllFiles([]);
    dispatch(updateAllFiles([]));
    setDuplicateImages([]);
    dispatch(updateDuplicateImages([]));
    setInvalidImages([]);
    setIsUploading(false);
  };

  const closeUploadModal = () => {
    if (uploadIndex == images?.length) {
      closeUploadModalWhenDone();
    } else {
      dispatch(uploadFPAModalAction(false));
    }
  };

  const cancelUpload = (fileData, index) => {
    images = images.filter((item, i) => {
      return i !== index;
    });
    removeThumbsFromState(index);
  };

  const removeThumbsFromState = (i) => {
    const removeThumbs = (state) => {
      state.thumbs = state.thumbs.filter((item, index) => index !== i);
    };
    dispatch(callFunction(removeThumbs));
  };

  const handleCloseFPAMiniMizedModal = () => {
    dispatch(openFPAMinimizedBox(false));
  };


  const handleCloseFPAModal = () => {
    dispatch(uploadFPAModalAction(false));
  };



  return {
    thumbs,
    activeUploadIndex,
    activeUploadPercent,
    handleDragOver,
    handleDragOut,
    handleDrop,
    handleImageChange,
    closeUploadModal,
    cancelUpload,
    handleCloseFPAMiniMizedModal,
    closeUploadModalWhenDone,
    openUploadFPAModal,

    allFiles,
    uploadIndex,
    duplicateImages,
    invalidImages,
    images,
    uploadIndex,
  };
};
