import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateThemeState } from "../../store/slices/GetStarted/userBasicDataSlice";
import { postTheme } from "../../store/slices/Theme/postThemeSlice";
import { getTheme } from "../../store/slices/Theme/selectThemeSlice";

export const useThemes = () => {
  const dispatch = useDispatch();
  const [radioActive, setRadioActive] = useState(0);
  const { themeData } = useSelector((state) => state.theme);
  const [themeLoader, setThemeLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTheme());
  }, []);

  const selectTheme = async (id) => {
    setThemeLoader(true);
    const resultAction = await dispatch(
      postTheme({ theme_id: themeData?.[id]?.id })
    );

    if (postTheme.fulfilled.match(resultAction)) {
      dispatch(updateThemeState(themeData?.[id]?.class_name));
      setThemeLoader(false);
      navigate("/dashboard");
    }
  };

  return {
    themeData,
    selectTheme,
    radioActive,
    setRadioActive,
    themeLoader,
  };
};
