import React, { useEffect } from "react";
import useAddNewProjects from "../../../../Logic/useAddNewProjects";
import CommonModal from "../../../Modals/CommonModal";
import Style from "./AddNewProjectModal.module.scss";
import { ClientDetailsForm } from "./ClientDetailsForm";
import { Footer } from "./Footer";
import { ProjectDetailsForm } from "./ProjectDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import ClientAddStatus from "./ClientAddStatus";
export const AddNewProjectModal = ({
  openCreateProjectModal,
  popupAddProject,
  handleShowAddProject,
  setOpenCreateProjectModal,
  CommonInput,
  setAddProjectStepOne,
  setAddProjectStepTwo,
  handleClearStepOneFormData,
}) => {
  const { insertProjectData, handleClientSuccessPopup } = useAddNewProjects();

  const {
    addProjectStepOne,
    addProjectStepTwo,
    stepOneFormData,
    showClientSucessPopup,
  } = useSelector((state) => state.project.addProjects);

  useEffect(() => {
    if (popupAddProject == false && addProjectStepTwo == true) {
      if (stepOneFormData?.success === true) {
        insertProjectData();
      } else {
        handleClearStepOneFormData();
        setAddProjectStepOne(false);
        setAddProjectStepTwo(false);
      }
    }
  }, [popupAddProject]);

  const callback = (data) => {
    return data();
  };

  return (
    <>
      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={popupAddProject}
        setShowModal={() => {
          handleShowAddProject(false);
          handleClientSuccessPopup(false);
        }}
        centered={true}
        className={"create-project-modal"}
        title={
          !showClientSucessPopup &&
          <h4>{addProjectStepOne ? "Create Project" : "Add Client Details"}</h4>
        }
        content={
          <>
            {addProjectStepOne && (
              <ProjectDetailsForm
                setOpenCreateProjectModal={setOpenCreateProjectModal}
                setAddProjectStepOne={setAddProjectStepOne}
                setAddProjectStepTwo={setAddProjectStepTwo}
                handleShowAddProject={handleShowAddProject}
                handleCancel={handleShowAddProject}
              />
            )}

            {addProjectStepTwo && (
              <ClientDetailsForm
                setOpenCreateProjectModal={setOpenCreateProjectModal}
                setAddProjectStepOne={setAddProjectStepOne}
                setAddProjectStepTwo={setAddProjectStepTwo}
                handleCancel={handleShowAddProject}
              />
            )}

            {showClientSucessPopup && (
              <ClientAddStatus
                status={"success"}
                setShowModal={() => {
                  handleShowAddProject(false);
                  handleClientSuccessPopup(false);
                }}
              />
            )}
          </>
        }
      />
    </>
  );
};
