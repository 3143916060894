import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CommonInput from "../../../Layouts/CommonInput";
import CommonModal from "../../../Modals/CommonModal";
import Style from "./AddNewFolderModal.module.scss";
import { FormFields } from "./FormFields";
export const AddNewFolderModal = ({
  setAddNewProjectPopup,
  addNewProjectPopup,
  handleSubmitCreateNewFolder,
  isEventEdit,
}) => {
  return (
    <>
      <CommonModal
        backdrop="static"
        keyboard={false}
        showModal={addNewProjectPopup}
        setShowModal={() => {
          setAddNewProjectPopup(false);
        }}
        centered={true}
        className={"create-project-modal"}
        title={
          isEventEdit === false && isEventEdit!==undefined  ? <h4>Create Event</h4> : <h4>Edit Event</h4>
        }
        content={
          <FormFields
            handleSubmitCreateNewFolder={handleSubmitCreateNewFolder}
            handleCancel={setAddNewProjectPopup}
          />
        }
      />
    </>
  );
};
