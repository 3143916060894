import React from "react";
import Assets from "../../Layouts/Assets";
import Style from "./BlankDataPlaceholder.module.scss";

const BlankDataPlaceholder = ({
  image,
  title,
  description,
  buttonText,
  template,
  onClick,
}) => {
  return (
    <div className={`${Style.BlankDataPlaceholder} blank-data-placeholder`}>
      <figure className={Style.BlankDataPlaceholder__image}>
        {template === "folder" ? (
          <img src={image ? image : Assets.blank_folder_placeholder} alt="" />
        ) : template === "image" ? (
          <img src={image ? image : Assets.blank_data_placeholder} alt="" />
        ) : (
          <img src={image} alt="" />
        )}
      </figure>
      <h3 className={Style.BlankDataPlaceholder__title}>{title}</h3>
      <p className={Style.BlankDataPlaceholder__description}>{description}</p>
      {buttonText !== "" && buttonText !== undefined && (
        <button className="btn btn-primary" onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default BlankDataPlaceholder;
