import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";
import StyleGuidePage from "../pages/StyleGuidePage";
import TOCPage from "../pages/TOCPage";

import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";

import GetStartedPage from "../pages/GetStartedPage";
import SelectYourThemePage from "../pages/SelectYourThemePage";

import UserFlowPage from "../pages/UserFlowPage";
import UserFlowFolderViewPage from "../pages/UserFlowFolderViewPage";

import PlansPage from "../pages/PlansPage";
import ProjectPlansPage from "../pages/ProjectPlansPage";

import DashboardPage from "../pages/DashboardPage";
import Projects from "../components/Dashboard/Projects";
import Preference from "../components/Dashboard/Preference";
import ContactPage from "../pages/ContactPage";
import LegalPages from "../pages/LegalPages";
import ProjectsList from "../components/Dashboard/ProjectsList";
import ProjectFolders from "../components/Dashboard/ProjectFolders";
import ProjectGallery from "../components/Dashboard/ProjectGallery";
import PreferenceList from "../components/Dashboard/PreferenceList";
import StudioPreferenceList from "../components/Dashboard/StudioPreferenceList";
import AllPhotosPage from "../pages/AllPhotosPage";
import SelectedPhotosPage from "../pages/SelectedPhotosPage";
import ProjectGallerySelected from "../components/Dashboard/ProjectGallerySelected";
import ProjectGalleryAll from "../components/Dashboard/ProjectGalleryAll";
import ProjectGallerySuggested from "../components/Dashboard/ProjectGallerySuggested";
import StaffListContract from "../components/Dashboard/StaffListContract";
import StaffListPermanant from "../components/Dashboard/StaffListPermanant";
import StudioDashboard from "../components/Dashboard/StudioDashboard";
import Payment from "../components/Payment";
import PaymentSuccess from "../pages/PaymentSuccess";
import PaymentCanceled from "../pages/PaymentCanceled";

const MainRoutes = [
  {
    name: "Home",
    path: "/",
    element: (
      <>
        <HomePage />
      </>
    ),
  },
  {
    name: "Sign In",
    path: "/login",
    element: (
      <>
        <LoginPage />
      </>
    ),
  },
  {
    name: "Register",
    path: "/register",
    element: (
      <>
        <RegisterPage />
      </>
    ),
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    element: (
      <>
        <ForgotPasswordPage />
      </>
    ),
  },
  {
    name: "Get Started",
    path: "/get-started",
    element: (
      <>
        <GetStartedPage />
      </>
    ),
  },
  {
    name: "Select Your Theme",
    path: "/select-your-theme",
    element: (
      <>
        <SelectYourThemePage />
      </>
    ),
  },
  {
    name: "UserFlow",
    path: "/userflow/:user_token",
    element: (
      <>
        <UserFlowPage />
      </>
    ),
  },
  {
    name: "UserFlow Folder View",
    path: "/userflow/:user_token/folder-view",
    element: (
      <>
        <UserFlowFolderViewPage />
      </>
    ),
    Children: [
      {
        name: "All Photos",
        path: "",
        element: <AllPhotosPage />,
      },
      {
        name: "Selected Photos",
        path: "selected",
        element: <SelectedPhotosPage />,
      },
    ],
  },

  {
    name: "Plans",
    path: "/plans",
    element: (
      <>
        <PlansPage />
      </>
    ),
  },
  {
    name: "Project Plans",
    path: "/user-plans",
    element: (
      <>
        <ProjectPlansPage />
      </>
    ),
  },
  {
    name: "payment",
    path: "payment/:plan_id",
    element: <Payment />,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    element: <DashboardPage />,
    Children: [
      {
        name: "studio-dashboard",
        path: "",
        element: <StudioDashboard />,
      },
      {
        name: "payment",
        path: "payment/:plan_id/:clientID/:projectID",
        element: <Payment />,
      },
      {
        name: "Projects",
        path: "project",
        element: <Projects />,
        grandChildren: [
          {
            name: "Projects List",
            path: "",
            element: <ProjectsList />,
          },
          {
            name: "Project Folders",
            path: ":projectID",
            element: <ProjectFolders />,
          },
          {
            name: "Project Gallery",
            path: ":projectID/folders/:folderID/*",
            element: <ProjectGallery />,
            greatGrandChildrens: [
              {
                name: "all",
                path: "",
                element: <ProjectGalleryAll />,
              },
              {
                name: "Suggested  Photos",
                path: "suggested",
                element: <ProjectGallerySuggested />,
              },
              {
                name: "selected",
                path: "selected",
                element: <ProjectGallerySelected />,
              },
            ],
          },
        ],
      },
      {
        name: "Project Plans",
        path: "user-plans/:projectID/:clientID/",
        element: (
          <>
            <ProjectPlansPage />
          </>
        ),
      },
      {
        name: "Preference",
        path: "preference",
        element: <Preference />,
        grandChildren: [
          {
            name: "Preference List",
            path: "",
            element: <PreferenceList />,
          },
          {
            name: "Studio Preference List",
            path: "studio",
            element: <StudioPreferenceList />,
          },
        ],
      },
      {
        name: "Staffs List Permanant",
        path: "staffs/permanant",
        element: <StaffListPermanant />,
      },
      {
        name: "Staffs Contract List",
        path: "staffs/contract",
        element: <StaffListContract />,
      },
      {
        name: "Dashboard",
        path: "/dashboard/studio-dashboard",
        element: <StudioDashboard />,
      },
    ],
  },
  {
    name: "Contact",
    path: "/contact",
    element: (
      <>
        <ContactPage />
      </>
    ),
  },
  {
    name: "privacy-policy",
    path: "privacy-policy",
    element: <LegalPages />,
  },
  {
    name: "Guidelines and Terms of Service",
    path: "guidelines-and-terms",
    element: <LegalPages />,
  },
  {
    name: "FAQ",
    path: "faq",
    element: <LegalPages />,
  },
  {
    name: "Cancellation and Refund Policy",
    path: "cancellation-and-refund-policy",
    element: <LegalPages />,
  },
  {
    name: "Style Guide",
    path: "/style-guide",
    element: (
      <>
        <StyleGuidePage />
      </>
    ),
  },
  {
    name: "TOC",
    path: "/toc",
    element: (
      <>
        <TOCPage />
      </>
    ),
  },
  {
    name: "404",
    path: "*",
    element: (
      <>
        <NotFoundPage />
      </>
    ),
  },
  {
    name: "payment-success/",
    path: "/payment-success/:PayString",
    element: (
      <>
        <PaymentSuccess />
      </>
    ),
  },
  {
    name: "payment-failed",
    path: "/payment-failed/:PayString",
    element: (
      <>
        <PaymentCanceled />
      </>
    ),
  },
];

export default MainRoutes;
