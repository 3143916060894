import React from 'react'
import Icon from '../../../Layouts/Icons'

const SidebarCollapse = ({ Style, isCollapsed, setIsCollapsed }) => {
    return (
        <figure className={Style.DashboardSidebar__collapseHolder}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="95.318"
                height="138.926"
                viewBox="0 0 95.318 138.926"
            >
                <path
                    id="Path_76279"
                    data-name="Path 76279"
                    d="M0,137.926H64.73v-12.3a28.256,28.256,0,0,1,10.625-22.08l3.8-3.033a40.34,40.34,0,0,0,.09-62.971l-3.949-3.171A28.257,28.257,0,0,1,64.73,12.345V0H0Z"
                    transform="translate(0.5 0.5)"
                    fill="#fff"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
            </svg>
            <button
                className={Style.DashboardSidebar__collapseButton}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <Icon color={"#fff"} size={16} icon={"slider-nav-left"} />
            </button>
        </figure>
    )
}

export default SidebarCollapse