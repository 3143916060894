import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Icons from "../components/Layouts/Icons";
import { Link, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";

import {
  currentTabHandler,
  getGalleryImages,
  customFetchImages,
  selectSingleImage,
  deSelectSingleImage,
  selectedImageListViewToggle,
  resetGalleryData,
  downloadUserSelectedFiles,
  clearGallery,
  updateShareLinkStatus,
  addImagesToSuggestion,
  removeImageFromSuggestion,
  removeImageFromSuggested,
  updateSuggestedImageCount,
  updateImageSuggestionStatus,
  clearImageSuggestion,
} from "../store/slices/Gallery/gallerySlice";

import {
  postShareNotification,
  shareInfoPopup,
  getFoldersByProjectID,
} from "../store/slices/Project/ProjectSingle";

const useGallery = () => {
  const dispatch = useDispatch();
  const { folderID, projectID } = useParams();

  const location = useLocation();

  const { sections, currentTab, status, currentFolderDetails } = useSelector(
    (state) => state.gallery
  );

  const { allFolders } = useSelector((state) => state.projectSinglePage);

  const [isShowNotifyModal, setIsShowNotifyModal] = useState(false);

  useEffect(() => {
    if (allFolders.list.plan_payment_status === undefined) {
      handleGetAllProjects();
    }
  }, []);

  const getUrls = (slug) => {
    switch (slug) {
      case "all":
        return "/album/album-images";
        break;
      case "selected":
        return "/album/selected-images";
        break;
      case "suggested":
        return "/album/suggested-images";
        break;
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (string === "" || string === undefined) {
      return "";
    } else {
      return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    }
  };

  const handleGetAllProjects = (order = false) => {
    const data = {
      page: "1",
      per_page: 20,
      sort: order == false ? allFolders.filter.order : order,
      project_id: projectID,
    };
    dispatch(getFoldersByProjectID(data));
  };

  const handleSuggest = (obj) => {
    const { status, image_id, setFavIsSelected } = obj;
    const formData = {
      album: [
        {
          image_id: image_id,
          album_id: folderID,
        },
      ],
    };
    if (status === true) {
      dispatch(addImagesToSuggestion(formData))
        .unwrap()
        .then((res) => {
          if (res.data.success) {
            setFavIsSelected(status);

            dispatch(
              updateSuggestedImageCount(res.data.result.suggested_images_count)
            );

            const newSSArray = [];
            sections?.["all"]["data"]?.forEach((items) => {
              if (items.id == image_id) {
                let album_image =
                  items?.album_image === undefined
                    ? { is_suggested: 0 }
                    : items?.album_image;
                newSSArray.push({
                  ...items,
                  ...{
                    album_image: [
                      {
                        ...album_image[0],
                        ...{ is_suggested: 1 },
                      },
                    ],
                  },
                });
              } else {
                newSSArray.push(items);
              }
            });
            dispatch(clearImageSuggestion());
            dispatch(updateImageSuggestionStatus(newSSArray));
          } else {
            setFavIsSelected(!status);
          }
        });
    } else if (status === false) {
      dispatch(removeImageFromSuggestion(formData))
        .unwrap()
        .then((res) => {
          if (res.data.success) {
            if (currentTab !== "suggested") {
              setFavIsSelected(status);
            }
            console.log(res.data.result, "hey delete data..");
            dispatch(
              updateSuggestedImageCount(res.data.result.suggested_images_count)
            );

            try {
              let updatedSuggested = sections?.["suggested"]?.["data"]?.filter(
                (items) => {
                  return items.id !== image_id;
                }
              );

              dispatch(removeImageFromSuggested(updatedSuggested));
            } catch (error) { }

            const newSSArray = [];
            sections?.["all"]["data"]?.forEach((items) => {
              if (items.id == image_id) {
                let album_image =
                  items?.album_image === undefined
                    ? { is_suggested: 0 }
                    : items?.album_image;
                newSSArray.push({
                  ...items,
                  ...{
                    album_image: [
                      {
                        ...album_image[0],
                        ...{ is_suggested: 0 },
                      },
                    ],
                  },
                });
              } else {
                newSSArray.push(items);
              }
            });

            dispatch(updateImageSuggestionStatus(newSSArray));
          } else {
            setFavIsSelected(!status);
          }
        });
    }
  };

  const handleGalleryTab = (tabName) => {
    dispatch(currentTabHandler(tabName));
  };

  const isDataLoading = () => {
    if (status === "loading") {
      return true;
    } else {
      return false;
    }
  };

  const handleEventShareInfoPopup = (status) => {
    dispatch(shareInfoPopup(status));
  };

  const getImagesForSection = (slug) => {

    let obj = {
      url: getUrls(slug),
      id: folderID,
      page: 1,
      sort: "desc",
      per_page: 20,
    };

    if (sections[currentTab]?.["order"] != undefined) {
      obj.sort = sections[currentTab]?.["order"];
    }

    if (
      sections?.all?.data == undefined ||
      sections?.selected?.data == undefined ||
      currentFolderDetails?.id !== folderID
    ) {
      dispatch(getGalleryImages(obj))
        .unwrap()
        .then((res) => {

          console.log("hey selected!!!", res);
        });
    }
  };

  const loadMore = (slug, type) => {
    if (type == "others") {
      if (sections["all"]?.current_page < sections["all"]?.last_page) {
        let obj = {
          id: folderID,
          url: getUrls(slug),
          page: sections["all"]?.current_page + 1,
          sort: "desc",
        };

        if (sections[currentTab]?.["order"] != undefined) {
          obj.sort = sections[currentTab]?.["order"];
        }

        dispatch(customFetchImages(obj));
      }
    } else {
      if (
        sections[currentTab]?.current_page < sections[currentTab]?.last_page
      ) {
        let obj = {
          id: folderID,
          url: getUrls(slug),
          page: sections[currentTab]?.current_page + 1,
          sort: "desc",
        };

        if (sections[currentTab]?.["order"] != undefined) {
          obj.sort = sections[currentTab]?.["order"];
        }

        dispatch(customFetchImages(obj));
      }
    }
  };

  const handleSelectedImageListViewToggle = (state) => {
    dispatch(selectedImageListViewToggle(state));
  };

  const handleResetGalleryData = (state) => {
    dispatch(resetGalleryData(state));
  };

  const handleDownloadUserSelectedFiles = (state) => {
    const packet = {
      url: "/download-zip",
      album_id: currentFolderDetails?.id,
    };
    dispatch(downloadUserSelectedFiles(packet))
      .unwrap()
      .then((res) => {
        if (res.data.success === true) {
          const url = res.data.result;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Demo.csv`);
          document.body.appendChild(link);
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => { });
  };

  const handleClearGallery = (state) => {
    dispatch(clearGallery(state));
  };

  const handleUpdateShareLinkStatus = (status, setIsSelected) => {
    let formdata = {
      id: currentFolderDetails?.id,
      link_status: status,
    };
    dispatch(updateShareLinkStatus(formdata))
      .unwrap()
      .then((data) => {
        if (data.data.result === 1) {
          if (JSON.parse(data.config.data)?.link_status === 1) {
            setIsSelected(true);
          } else {
            setIsSelected(false);
          }
        }
      });
  };

  const shareNotificationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password length must be a minimum of six."),
  });

  const shareNotificationSubmit = async (data, resetForm, setFieldError) => {
    const resultAction = await dispatch(postShareNotification(data));

    if (postShareNotification.fulfilled.match(resultAction)) {
      resetForm();

      setIsShowNotifyModal(false);
      toast.success("Client has been successfully notified", {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return {
    sections,
    currentTab,
    shareNotificationSchema,
    handleGalleryTab,
    isShowNotifyModal,
    getImagesForSection,
    isDataLoading,
    loadMore,
    handleSelectedImageListViewToggle,
    handleResetGalleryData,
    handleDownloadUserSelectedFiles,
    handleClearGallery,
    handleEventShareInfoPopup,
    handleUpdateShareLinkStatus,
    setIsShowNotifyModal,
    shareNotificationSubmit,
    handleSuggest,
    capitalizeFirstLetter,
  };
};

export default useGallery;
