import React, { useEffect, useState } from "react";
import Icon from "../../components/Layouts/Icons";
import Style from "./SearchBar.module.scss";
import useProjects from "../../Logic/useProjects";
const SearchBar = () => {
  //   useEffect(() => {
  //     text.length > 0 ? setResetVisible(true) : setResetVisible(false);

  //     if (text.length > 0) {
  //       //fetchAllProjects(currentTab, text);
  //     }
  //   }, [text]);

  //   useEffect(() => {
  //     if (sections?.[currentTab]?.["search"] != undefined) {
  //       setText(sections?.[currentTab]?.["search"]);
  //     }
  //   }, [currentTab]);

  return (
    <>
      {/* <form
        className={`${Style.SearchBar} ${isFocused ? Style.focused : ""} ${
          resetVisible ? Style.hasValue : ""
        }`}
      >
        <div className={Style.SearchBar__searchIcon}>
          <Icon size={18} icon={"icon-search"} />
        </div>
        <input
          //   value={text}
          type="text"
          name="search"
          placeholder="Search here"
          className={Style.SearchBar__searchInput}
          autoComplete="new-password"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        //   onChange={(e) => handleChange(e)}
        />
        <div className={Style.SearchBar__searchReset} onClick={handleClear}>
          <Icon size={12} icon={"icon-close"} />
        </div>
      </form> */}
    </>
  );
};

export default SearchBar;
// handleResetVisible(e);
// setText(e.target.value);
// // fetchAllProjects(currentTab, e.target.value);
