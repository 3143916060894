import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Style from "./HomeClients.module.scss";
import { Autoplay } from "swiper";
import { useHome } from "../../../Logic/useHome";
import "swiper/css";
import { useAnimations } from "../../../Logic/Animations";

const HomeClients = () => {
  const { ourClients, ourClientsData } = useHome();
  const { slideTopRef } = useAnimations();

  useEffect(() => {
    ourClients();
  }, []);


  return (
    <section className={Style.HomeClients}>
      <Container>
        <div className={`section-title-block center`}>
          <h2 ref={slideTopRef}>Our Clients</h2>
        </div>
        <div ref={slideTopRef}>
          {ourClientsData?.length > 0 ? (
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={15}
              loop={true}
              allowTouchMove={false}
              autoplay={{
                delay: 1,
              }}
              freeMode={true}
              speed={5000}
              modules={[Autoplay]}
              breakpoints={{
                768: {
                  spaceBetween: 50,
                },
                1200: {
                  spaceBetween: 80,
                },
                1600: {
                  spaceBetween: 100,
                },
              }}
              className={Style.HomeClients__clientSlider}
            >
              {ourClientsData?.map((data) => {
                return (
                  <SwiperSlide key={data?.id}>
                    <figure className={Style.HomeClients__clientLogo}>
                      <img src={data?.logo_url} alt="client-logo" />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            ""
          )}
        </div>
      </Container>
    </section>
  );
};

export default HomeClients;

// const clientsData = [
//   {
//     id: 1,
//     logo: Assets.client_1,
//   },
//   {
//     id: 2,
//     logo: Assets.client_2,
//   },
//   {
//     id: 3,
//     logo: Assets.client_3,
//   },
//   {
//     id: 4,
//     logo: Assets.client_1,
//   },
//   {
//     id: 5,
//     logo: Assets.client_4,
//   },
//   {
//     id: 6,
//     logo: Assets.client_5,
//   },
// ];
