import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCounts } from "../../../store/slices/Dashboard/getDashboardSlice";

import { currentTabHandler } from "../../../store/slices/Project/ProjectSlice";

const useDashboardCount = () => {
  const dispatch = useDispatch();

  const {
    activeProjectCount,
    completedProjectCount,
    permanentStaffCount,
    contractStaffCount,
  } = useSelector((state) => state.dashborad.allCount);

  const { allCount } = useSelector((state) => state.dashborad);

  let projectstatus = [
    {
      title: "Active projects",
      value: activeProjectCount,
      link: "project",
      tab: "activeTab",
      type: "active_projects",
    },
    {
      title: "Completed projects",
      value: completedProjectCount,
      link: "project",
      tab: "completedTab",
      type: "completed_projects",
    },
    {
      title: "Permanent staff",
      value: permanentStaffCount,
      link: "/dashboard/staffs/permanant",
      type: "permanent_staff",
    },
    {
      title: "Contract staff",
      value: contractStaffCount,
      link: "/dashboard/staffs/contract",
      type: "contract_staff",
    },
  ];

  useEffect(() => {
    if (Object.keys(allCount).length === 0) {
      handleGetCounts();
    }
  }, []);

  const handleGetCounts = () => {
    dispatch(getCounts());
  };

  const handleSetCurrentTabForProject = (tab) => {
    dispatch(currentTabHandler(tab));
  };

  return {
    handleGetCounts,
    activeProjectCount,
    completedProjectCount,
    permanentStaffCount,
    contractStaffCount,
    projectstatus,
    handleSetCurrentTabForProject,
  };
};

export default useDashboardCount;
