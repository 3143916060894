import React from "react";
import CommonInput from "../../../Layouts/CommonInput";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Style from "./ResetPasswordForm.module.scss";
import * as Yup from "yup";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";

let hasErrors = false;

const ResetPasswordForm = ({ initData, resetPassword,passwordCheck,hasPasswordUpdated,isLoading }) => {

  const SignupSchema = Yup.object().shape({
      newPassword: Yup.string()
      .required("Password is required.")
      .min(6, "Password length must be a minimum of six."),
      confirmPassword: Yup.string()
      .required("Password is required.")
      .min(6, "Password length must be a minimum of six."),
  });

 
  return (
    <Formik
      initialValues={initData}
      onSubmit={(values, { resetForm }) => {
        resetPassword(values, { resetForm });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;
        return (
          <>
            <CommonInput
              label="Enter Password"
              variant="password-visibility-toggle"
              placeholder="Enter password"
              id="newPassword"
              value={values?.newPassword}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("newPassword", e.target.value);
              }}
              hasError={errors?.newPassword}
              errorMsg={errors?.newPassword}
            />

            <CommonInput
              label="Re-enter Password"
              variant="password-visibility-toggle"
              placeholder="Re-enter Password"
              id="confirmPassword"
              value={values?.confirmPassword}
              onBlur={handleBlur}
              onChange={(e) => {
                setFieldValue("confirmPassword", e.target.value);
              }}
              hasError={errors?.confirmPassword}
              errorMsg={errors?.confirmPassword}
            />
            <p>{ passwordCheck === "error" && "Passwords are not matching."}</p>
            <p>{ hasPasswordUpdated && "Your password has been reset successfully."}</p>
            <button
              onClick={() => handleSubmit()}
              type="button"
              disabled={!isValid || !dirty}
              className={`${Style.ForgotPassword__button} btn btn-primary w-100`}
            >
             {isLoading === "loading" ? <InfiniteDotLoader /> : "Reset Password" } 
            </button>
          </>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
