import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authModalHandler,
  getSocialMedia,
  postSocialMedia,
} from "../../store/slices/Preference/studioDetailstSlice";
import { toast } from "react-toastify";
import { updateSocialMedia } from "../../store/slices/GetStarted/userBasicDataSlice";
import { object } from "yup";

export const useSocialMedia = () => {
  const dispatch = useDispatch();
  const [socialArray, setSocialArray] = useState([]);

  const { social_media } = useSelector(
    (state) => state.userData.userProfileData.preference
  );

  const getSocialMediaData = () => {
    dispatch(getSocialMedia());
  };

  const inputName = (index, event) => {
    const tempValues = [...socialArray];
    tempValues[index].slug = event;
    setSocialArray(tempValues);
  };

  const handleClickSubmit = async (inputValue) => {

    const formData = [
      {
        type_id: 1,
        slug: inputValue[0],
      },
      {
        type_id: 2,
        slug: inputValue[1],
      },
      {
        type_id: 3,
        slug: inputValue[2],
      },
      {
        type_id: 4,
        slug: inputValue[3],
      },
      {
        type_id: 5,
        slug: inputValue[4],
      },
    ];

    const payload = { social_media_links: formData };

    const resultAction = await dispatch(postSocialMedia(payload));

    if (postSocialMedia.fulfilled.match(resultAction)) {
      if (resultAction?.payload?.data?.validation?.errors != undefined) {
        var newArray = [];

        socialArray.forEach((item, index) => {
          if (
            resultAction?.payload?.data?.validation?.errors?.[
              `slug.${index}`
            ] != undefined
          ) {
            if (
              resultAction?.payload?.data?.validation?.errors?.[`slug.${index}`]
                .length > 0
            ) {
              newArray.push({
                ...item,
                error: true,
                message: "Sorry Invalid URL",
                value: formData[index].slug,
              });
            }
          } else {
            delete item.error;
            delete item.message;
            newArray.push({ ...item, ...{ value: formData[index].slug } });
          }
        });

        setSocialArray(newArray);
      } else {
        dispatch(authModalHandler(false));

        let up = Object.assign({}, social_media);
        let updated_social_media = [];
        Object.keys(up).forEach((i) => {
          let temp = Object.assign({}, up[i]);
          formData.map((item) => {
            if (temp.social_media_type.id === item.type_id) {
              temp.link = item.slug;
            }
          });
          updated_social_media.push(temp);
        });

        dispatch(updateSocialMedia(updated_social_media));

        toast.success("Social media links has been updated.", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return {
    getSocialMediaData,
    socialArray,
    setSocialArray,
    inputName,
    handleClickSubmit,
  };
};
