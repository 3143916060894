import React, { useEffect, useState } from "react";
import { Col, Dropdown } from "react-bootstrap";
import { useDimensions } from "../../../../Logic/Dimensions";
import CustomBreadcrumb from "../../../CustomBreadcrumb";
import Icon from "../../../Layouts/Icons";
import CommonModal from "../../../Modals/CommonModal";
import Style from "./../ContentHeader.module.scss";
import { useDispatch, useSelector } from "react-redux";
import useGallery from "../../../../Logic/useGallery";
import { useGalleryTab } from "../../../../Logic/useGalleryTab";
import { useProjectSingle } from "../../../../Logic/useProjectSingle";
import { useParams } from "react-router-dom";
import ShareInfoPopup from "../../ProjectCard/ShareInfoPopup";
import useProjects from "../../../../Logic/useProjects";
import CommonInput from "../../../Layouts/CommonInput";
import { Formik } from "formik";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";
import {
  openMinimizedBox,
  uploadModalAction,
} from "../../../../store/slices/Gallery/gallerySlice";
import ToggleSwitch from "../../../../utils/ToggleSwitch";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { updateUploadMode } from "../../../../store/slices/Gallery/fileUploadSlice";
import CustomPasswordInput from "../../../Layouts/CustomPasswordInput";

const ProjectGalleryHeader = ({ isGrid, setIsGrid }) => {
  const {
    handleGalleryTab,
    handleSelectedImageListViewToggle,
    handleDownloadUserSelectedFiles,
    handleEventShareInfoPopup,
    handleUpdateShareLinkStatus,
    shareNotificationSchema,
    isShowNotifyModal,
    setIsShowNotifyModal,
    shareNotificationSubmit,
    capitalizeFirstLetter,
  } = useGallery();

  const {
    handleClickTab,
    activeTab,
    setActiveTab,
    isChecked,
    setIsChecked,
    activeTabLeft,
    setActiveTabLeft,
    activeTabWidth,
    setActiveTabWidth,
    switchActiveTab,
    selectedElement,
    suggestedElement,
    currentFolderDetails,
    handlePreSelection,
    handleclosePreSelect,
    handleSelectAllImages,
    handledeSelectAllImages,
    uploadModal,
    uploadFPAModal,
    handleDeleteSelectedImages,
    openDeletePopup,
    handleOrderChange,
  } = useGalleryTab();

  const { setOpenShareModal } = useProjects();
  const windowDimensions = useDimensions();
  const dispatch = useDispatch();

  const { selectedImageListView, status } = useSelector(
    (state) => state.gallery
  );

  const { getCurrentProjectDetails, allFolders, postShareStatus } = useSelector(
    (state) => state.projectSinglePage
  );

  const { tokenDetails } = useSelector((state) => state.FPAupload);

  const { sections, currentTab } = useSelector((state) => state.gallery);
  const { project } = useSelector((state) => state);
  const { currentPageDetails } = getCurrentProjectDetails;

  const { handleGetEventShareInfoPopup } = useProjectSingle();

  const { projectID, folderID } = useParams();

  const [isSelected, setIsSelected] = useState();

  useEffect(() => {
    if (currentFolderDetails?.images_count == 0) {
      setIsSelected(false)
    } else if (currentFolderDetails?.images_count > 0) {
      setIsSelected(currentFolderDetails?.link_status === 1 ? true : false);

    }
  }, [currentFolderDetails?.link_status]);

  return (
    <>
      {windowDimensions.width > 992 ? (
        <>
          <CustomBreadcrumb
            levels={[
              { title: "Project", path: "/dashboard/project" },
              {
                title: capitalizeFirstLetter(currentPageDetails?.name),
                path: `/dashboard/project/${currentPageDetails?.id}`,
              },
              {
                title: capitalizeFirstLetter(currentFolderDetails?.name),
                path: "",
              },
            ]}
          />
        </>
      ) : (
        <></>
      )}

      <div className={Style.ContentHeader__wrap}>
        <div className={Style.ContentHeader__left}>
          <h1 className={Style.ContentHeader__title}>
            {capitalizeFirstLetter(currentFolderDetails?.name)}
          </h1>
          <ul className={Style.ContentHeader__navList}>
            <li
              className={activeTab === "all" ? "tabActive" : ""}
              onClick={(e) => {
                if (status !== "loading") {
                  handleClickTab(e, "all");
                }
              }}
            >
              All {windowDimensions.width < 1800 ? "" : "Photos"} (
              {currentFolderDetails?.images_count === undefined
                ? 0
                : currentFolderDetails?.images_count}
              )
            </li>
            <li
              ref={suggestedElement}
              className={activeTab === "suggested" ? "tabActive" : ""}
              onClick={(e) => {
                if (status !== "loading") {
                  handleClickTab(e, "suggested");
                }
              }}
            >
              Suggested {windowDimensions.width < 1800 ? "" : "Photos"} (
              {currentFolderDetails?.suggested_images_count === undefined
                ? 0
                : currentFolderDetails?.suggested_images_count}
              )
            </li>
            <li
              ref={selectedElement}
              className={activeTab === "selected" ? "tabActive" : ""}
              onClick={(e) => {
                if (status !== "loading") {
                  handleClickTab(e, "selected");
                }
              }}
            >
              Selected {windowDimensions.width < 1800 ? "" : "Photos"} (
              {currentFolderDetails?.selected_images_count === undefined
                ? 0
                : currentFolderDetails?.selected_images_count}
              )
            </li>
            <li
              className={Style.activeTab__indicator}
              style={{
                transform: `translateX(${activeTabLeft}px)`,
                width: `${activeTabWidth}px`,
              }}
            ></li>
          </ul>
        </div>
        <div className={Style.ContentHeader__right}>
          {project.currentTab !== "completedTab" && (
            <ul
              className={`${Style.ContentHeader__actionsBtn} ${!sections[currentTab]?.currentAction?.preSelect
                ? Style.ContentHeader__mainBtn
                : ""
                }`}
            >
              {activeTab === "all" ? (
                <>
                  {!sections[currentTab]?.currentAction?.preSelect && ( // CHECKING DATA LENGTH > 0
                    <>
                      <li>
                        <button
                          className="btn btn-border-grey btn-sm btn-theme"
                          onClick={() => {
                            // uploadModal(true);
                            dispatch(uploadModalAction(true));
                            dispatch(openMinimizedBox(false));
                            dispatch(updateUploadMode("photoFind"))
                          }}
                        >
                          {windowDimensions.width >= 1200 ? (
                            <>Add Photos</>
                          ) : (
                            <Icon
                              color={"currentColor"}
                              size={16}
                              icon={"icon-plus"}
                            />
                          )}
                        </button>
                      </li>

                      {windowDimensions.width >= 1600 ? (
                        <>
                          <li>
                            <button
                              disabled={
                                allFolders.list.plan_payment_status !== 1 ||
                                currentFolderDetails?.images_count == 0
                              }
                              className="btn btn-border-grey btn-sm btn-theme"
                              onClick={() => {
                                handleGetEventShareInfoPopup(folderID);
                              }}
                            >
                              {windowDimensions.width >= 1600 ? (
                                <>Share</>
                              ) : (
                                <Icon
                                  color={"currentColor"}
                                  size={16}
                                  icon={"share2"}
                                  className="me-0"
                                />
                              )}
                            </button>
                          </li>

                          {/*  {currentFolderDetails?.link_status == 1 && ( */}
                          <li>
                            <div
                              className={Style.shared_url_wrap}
                            //onClick={() => setIsSelected(!isSelected)}
                            >
                              <span>Shared URL</span>
                              <ToggleSwitch
                                firstBtn="ON"
                                secondBtn="OFF"
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                                className={Style.shared_url_button}
                                handleUpdateShareLinkStatus={
                                  handleUpdateShareLinkStatus
                                }
                                disabled={
                                  allFolders?.list?.plan_payment_status !=
                                  true ||
                                  currentFolderDetails?.images_count == 0
                                }
                              />
                            </div>
                          </li>

                          {sections[currentTab]?.currentAction?.preSelect !=
                            true &&
                            tokenDetails?.code != undefined && ( // CHECKING DATA LENGTH > 0
                              <>
                                <li>
                                  <button
                                    disabled={
                                      allFolders.list.plan_payment_status !== 1
                                    }
                                    className="btn btn-border-grey btn-sm btn-theme"
                                    onClick={() => {
                                      uploadFPAModal(true);
                                      dispatch(updateUploadMode("FPA"))
                                    }}
                                  >
                                    {windowDimensions.width >= 1200 ? (
                                      <>Upload to FPA</>
                                    ) : (
                                      <Icon
                                        color={"currentColor"}
                                        size={16}
                                        icon={"upload2"}
                                      />
                                    )}
                                  </button>
                                </li>
                              </>
                            )}

                          <li>
                            <button
                              disabled={
                                allFolders.list.plan_payment_status !== 1
                              }
                              onClick={() => setIsShowNotifyModal(true)}
                              className="btn btn-border-grey btn-sm btn-theme"
                            >
                              Notify
                            </button>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {/* PRE-STATE ENABLE OR DISABLE BUTTON */}

                  <li>
                    {!sections[currentTab]?.currentAction?.preSelect && // CHECKING PRE-SELECT IS TRUE OT NOT
                      sections[currentTab] != undefined && // CHECKING DATA IS NOT UNDEFINED
                      sections[currentTab]["data"]?.length > 0 && ( // CHECKING DATA LENGTH > 0
                        <button
                          disabled={
                            sections[currentTab] != undefined &&
                              sections[currentTab]["data"]?.length > 0
                              ? false
                              : true
                          }
                          className="btn btn-border-grey btn-sm"
                          onClick={() => {
                            handlePreSelection();
                          }}
                        >
                          <Icon
                            size={18}
                            icon={
                              !sections[currentTab]?.currentAction?.preSelect
                                ? "icon-unchecked"
                                : "icon-checked"
                            }
                          />
                          {windowDimensions.width >= 1200 && <>Select</>}
                        </button>
                      )}

                    {/* DE-SELECT AND SELECT ALL BUTTONS */}

                    {sections[currentTab]?.currentAction?.preSelect && ( // CHECKING PRE-SELECT IS TRUE OT NOT
                      <button
                        className="btn btn-border-grey btn-sm"
                        onClick={() => {
                          if (
                            sections[currentTab]?.selectedImages?.length ===
                            sections[currentTab]?.data?.length
                          ) {
                            handledeSelectAllImages();
                          } else {
                            handleSelectAllImages();
                          }
                        }}
                      >
                        <Icon
                          size={18}
                          icon={
                            sections[currentTab]?.selectedImages?.length !==
                              sections[currentTab]?.data?.length
                              ? "icon-unchecked"
                              : "icon-checked"
                          }
                        />

                        {windowDimensions.width >= 1200 && (
                          <>
                            {" "}
                            {sections[currentTab]?.selectedImages?.length ===
                              sections[currentTab]?.data?.length
                              ? "Deselect all"
                              : "Select all"}
                          </>
                        )}
                      </button>
                    )}
                  </li>
                  {/* <li onClick={()=>{

                handlePreSelection();

              }}>
                <button
                  className="btn btn-border-grey btn-sm"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  <Icon
                    size={18}
                    icon={!isChecked ? "icon-unchecked" : "icon-checked"}
                  />
                  {windowDimensions.width >= 1280 && (
                    <>{!isChecked ? "Select All" : "Deselect All"}</>
                  )}
                </button>
              </li> */}

                  {/* <li>
                <button className="btn btn-border-grey btn-sm">
                  <Icon size={18} icon={"icon-download"} />
                  {windowDimensions.width >= 1280 && <>Download</>}
                </button>
              </li>
              <li>
                <button className="btn btn-border-grey btn-sm">
                  <Icon size={18} icon={"icon-rearrange"} />
                  {windowDimensions.width >= 1280 && <>Re-Arrange</>}
                </button>
              </li> */}

                  {!sections[currentTab]?.currentAction?.preSelect &&
                    sections[currentTab] != undefined &&
                    sections[currentTab]["data"]?.length > 0 && sections[currentTab]["data"]?.length != 1 && (
                      <li>


                        <Dropdown
                          className={`${Style.ContentHeader__filter_dropdown}`}
                        >
                          <Dropdown.Toggle className="btn btn-border-grey btn-sm">
                            {sections[currentTab]?.["order"] === undefined ||
                              sections[currentTab]?.["order"] === "desc"
                              ? "Latest"
                              : "Oldest"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              disabled={
                                sections[currentTab]?.["order"] === undefined ||
                                  sections[currentTab]?.["order"] === "desc"
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                handleOrderChange("desc");
                              }}
                            >
                              Latest
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={
                                sections[currentTab]?.["order"] === "asc"
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                handleOrderChange("asc");
                              }}
                            >
                              Oldest
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    )}

                  {!sections[currentTab]?.currentAction?.preSelect && (
                    <>
                      {windowDimensions.width < 1600 ? (
                        <>
                          <li>
                            <Dropdown className="options-dropdown">
                              <Dropdown.Toggle className="btn btn-border-grey btn-sm btn-three-dots correntColor">
                                <Icon
                                  color={"currentColor"}
                                  size={16}
                                  icon={"icon-three-dots"}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    handleGetEventShareInfoPopup(folderID);
                                  }}
                                  disabled={
                                    allFolders.list.plan_payment_status !== 1 ||
                                    currentFolderDetails?.images_count == 0
                                  }
                                >
                                  <>Share</>
                                </Dropdown.Item>

                                <div
                                  className={Style.shared_url_wrap}
                                  onClick={() => setIsSelected(!isSelected)}
                                >
                                  <span>Shared URL</span>

                                  <ToggleSwitch
                                    firstBtn="ON"
                                    secondBtn="OFF"
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    className={Style.shared_url_button}
                                    handleUpdateShareLinkStatus={
                                      handleUpdateShareLinkStatus
                                    }
                                    disabled={
                                      allFolders?.list?.plan_payment_status !=
                                      true ||
                                      currentFolderDetails?.images_count == 0
                                    }
                                  />
                                </div>

                                <Dropdown.Item
                                  onClick={() => setIsShowNotifyModal(true)}
                                >
                                  Notify
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {/* DELETE BUTTON */}

                  {sections[currentTab]?.currentAction?.preSelect &&
                    sections[currentTab] != undefined &&
                    sections[currentTab]?.selectedImages?.length > 0 && ( // CHECKING SELECTED IMAGES LENGTH IS > 0
                      <li>
                        <button
                          className="btn btn-border-grey btn-sm"
                          onClick={() => {
                            openDeletePopup(true);
                          }}
                        >
                          <Icon size={16} icon={"icon-trash"} />
                          {windowDimensions.width >= 1280 && <>Delete</>}
                        </button>
                      </li>
                    )}

                  {/* CLOSE BUTTON  */}

                  {sections[currentTab]?.currentAction?.preSelect && ( // CHECKING PRE-SELECT IS TRUE OT NOT
                    <li>
                      <button
                        className="btn btn-border-grey btn-sm btn-three-dots"
                        onClick={() => {
                          handleclosePreSelect();
                        }}
                      >
                        <Icon color={""} size={14} icon={"icon-close"} />
                      </button>
                    </li>
                  )}
                </>
              ) : (
                <>
                  {sections[currentTab]?.data?.length > 0 &&
                    currentTab === "selected" && ( // CHECKING DATA LENGTH > 0
                      <li>
                        <button
                          className="btn btn-primary btn-sm btn-theme"
                          onClick={() => {
                            handleDownloadUserSelectedFiles(true);
                          }}
                        >
                          {windowDimensions.width >= 1280 ? (
                            <>
                              {sections[currentTab]?.download?.status ===
                                "loading"
                                ? "Downloading..."
                                : "Download"}
                            </>
                          ) : (
                            <Icon
                              color={"#fff"}
                              size={16}
                              icon={"icon-download"}
                            />
                          )}
                        </button>
                      </li>
                    )}
                  {currentTab === "selected" && (
                    <li>
                      <button
                        disabled={
                          sections[currentTab]?.data?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-border-grey btn-sm"
                        onClick={() =>
                          handleSelectedImageListViewToggle(
                            !selectedImageListView
                          )
                        }
                      >
                        <Icon
                          size={18}
                          icon={
                            !selectedImageListView ? "icon-list" : "icon-grid"
                          }
                        />
                        {windowDimensions.width >= 1280 && (
                          <>{!selectedImageListView ? "List" : "Grid"}</>
                        )}
                      </button>
                    </li>
                  )}
                </>
              )}
            </ul>
          )}
        </div>
        {activeTab === "selectedTab" && (
          <div className={Style.ContentHeader__lastUpdatedDate}>
            Last updated on: 21-11-2022
          </div>
        )}
      </div>


      <>
        <CommonModal
          // backdrop="static"
          keyboard={false}
          showModal={isShowNotifyModal}
          setShowModal={() => {
            setIsShowNotifyModal(false);
          }}
          centered={true}
          className={"create-project-modal"}
          title={<h4>Share Notification</h4>}
          content={

            <Formik
              validationSchema={shareNotificationSchema}
              initialValues={{
                password: "",
              }}
              onSubmit={(values, { resetForm, setFieldError }) => {
                let FormData = {
                  lock_password: values?.password,
                  project_id: projectID,
                  album_id: folderID,
                };

                shareNotificationSubmit(FormData, resetForm, setFieldError);
              }}
            >
              {({
                errors,
                values,
                touched,
                handleSubmit,
                setFieldValue,
                handleBlur,
                resetForm,
                isValid,
                dirty,
              }) => {

                console.log(values, errors, touched, "val");
                return (
                  <>
                    <p className="mb-3">
                      Please create a password to lock the album in Family Photo
                      Album.
                    </p>

                    <Col lg={12}>
                      <CustomPasswordInput
                        name="password"
                        label="Enter Password"
                        floatingLabel={true}
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("password", e.target.value);
                        }}
                        hasError={touched?.password}
                        errorMsg={errors?.password}

                      />

                    </Col>

                    <div className={`text-end`}>
                      <button
                        disabled={errors?.password || !dirty}
                        className={`btn btn-primary`}
                        onClick={() => handleSubmit()}
                      >
                        {postShareStatus === "loading" ? (
                          <InfiniteDotLoader />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </>
                );
              }}





            </Formik>
          }
        />
      </>

      {/* Share info Popup */}

      <ShareInfoPopup
        openShareModal={allFolders?.eventInfo?.shareInfo?.status === true}
        setOpenShareModal={handleEventShareInfoPopup}
      />
    </>
  );
};

export default ProjectGalleryHeader;

