import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPermanentStaffList,
  updatePermanentStaffSort,
  updateContractStaffSort,
} from "../../../store/slices/Dashboard/getDashboardSlice";
import {
  toggleAssignedProjects,
  setAssignedProjects,
} from "../../../store/slices/Staffs/staffsSlice";

const usePermanentStaffList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { permanentStaffList, permanentStaffSort } = useSelector(
    (state) => state.dashborad
  );

  let permanentStaffData = {
    title: "Permanent Staff information",
    type: "dashboard-table",
    view_all: "",
    data: permanentStaffList?.data,
  };

  useEffect(() => {
    if (Object.keys(permanentStaffList).length === 0) {
      fetchStaffs();
    }
  }, []);

  useEffect(() => {

      fetchStaffs();
    
  }, [permanentStaffSort]);

  const fetchStaffs = (slug, query = false) => {
    let obj = {
      url: "permanent-staff-list",
      page: 1,
      per_page: 10,
    };

    if (permanentStaffSort != "") {
      obj.sort = permanentStaffSort;
    }

    return dispatch(getPermanentStaffList(obj));
  };

  const viewAllButtonPermanant = () => {
    navigate("/dashboard/staffs/permanant");
  };

  const handletoggleAssignedProjects = (status) => {
    dispatch(toggleAssignedProjects(status));
  };

  const handlesetAssignedProjects = (data) => {
    dispatch(setAssignedProjects(data));
  };

  const handleUpdatePermanentStaffSort = (status) => {
    dispatch(updatePermanentStaffSort(status));
  };

  return {
    permanentStaffData,
    viewAllButtonPermanant,
    handletoggleAssignedProjects,
    handlesetAssignedProjects,
    handleUpdatePermanentStaffSort,
  };
};

export default usePermanentStaffList;
