import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";

const ProjectEventInfoPopup = ({
  openEventInfoModal,
  setOpenEventInfoModal,
  setOpenShareModal,
  setOpenProjectInfoModal,
  handleGetEventShareInfoPopup,
}) => {
  const { getCurrentProjectDetails, allFolders } = useSelector(
    (state) => state.projectSinglePage
  );
  const [loading, setLoading] = useState(false);

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={openEventInfoModal}
      setShowModal={setOpenEventInfoModal}
      centered={true}
      className={"ProjectInfo__modal"}
      title={<h4>Event Info</h4>} 
      content={
        <>
          <ul className={`${"ProjectInfo"} row`}>
            <li className={`${"ProjectInfo__item"} col-lg-6`}>
              <h5 className={"ProjectInfo__title"}>Event Name</h5>
              <span className={"ProjectInfo__content"}>
                {allFolders.eventInfo.data?.name}
              </span>
            </li>
            <li className={`${"ProjectInfo__item"} col-lg-6`}>
              <h5 className={"ProjectInfo__title"}>Client Name</h5>
              <span className={"ProjectInfo__content"}>
                {allFolders.eventInfo.data?.project?.get_clients?.[0]?.["name"]}
              </span>
            </li>
            <li className={`${"ProjectInfo__item"} col-lg-6`}>
              <h5 className={"ProjectInfo__title"}>Email Address</h5>
              <span className={"ProjectInfo__content"}>
                {
                  allFolders.eventInfo.data?.project?.get_clients?.[0]?.[
                    "email"
                  ]
                }
              </span>
            </li>
            <li className={`${"ProjectInfo__item"} col-lg-6`}>
              <h5 className={"ProjectInfo__title"}>Mobile Number</h5>
              <span className={"ProjectInfo__content"}>
                {
                  allFolders.eventInfo.data?.project?.get_clients?.[0]?.[
                    "mobile"
                  ]
                }
              </span>
            </li>
            <li className={`${"ProjectInfo__item"} col-lg-6`}>
              <h5 className={"ProjectInfo__title"}>Location</h5>
              <span className={"ProjectInfo__content"}>
                {allFolders.eventInfo.data?.project?.location_name}
              </span>
            </li>
            <li className={`${"ProjectInfo__item"} col-lg-6`}>
              <h5 className={"ProjectInfo__title"}>Date</h5>
              <span className={"ProjectInfo__content"}>
                {" "}
                {allFolders.eventInfo.data?.date}
              </span>
            </li>
            {allFolders.eventInfo.data?.lock_password != null && (
              <li className={`${"ProjectInfo__item"} col-lg-6`}>
                <h5 className={"ProjectInfo__title"}>Client Password</h5>
                <span className={"ProjectInfo__content"}>
                  {" "}
                  {allFolders.eventInfo.data?.lock_password}
                </span>
              </li>
            )}
          </ul>
          <div className="modal-footer">
            {/* <button
              className="btn btn-primary"
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  handleGetEventShareInfoPopup();
                  setOpenEventInfoModal(false);
                  setLoading(false);
                }, 800);
              }}
            >
              {loading === true ? <InfiniteDotLoader /> : "Share"}
            </button> */}
          </div>
        </>
      }
    />
  );
};

export default ProjectEventInfoPopup;
