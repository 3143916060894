import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountryCodeInput from "../../../../utils/CountryCodeInput";
import LocationInput from "../../../../utils/LocationInput";
import PasswordInput from "../../../../utils/PasswordInput";
import Icon from "../../../Layouts/Icons";
import Style from "../Register.module.scss";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CommonInput from "../../../Layouts/CommonInput";
import { useRegister } from "../../../../Logic/useRegister";
import InfiniteDotLoader from "../../../../utils/InfiniteDotLoader";

let hasErrors = false;

const RegisterForm = () => {
  const {
    registerHandler,
    currentAuthData,
    showErrors,
    initialInput,
    setInitialInputs,
    RegFormErrorObject,
    registerStatus,
  } = useRegister();

  const errorToArray = (errors) => {
    let erArray = [];
    for (const prop in errors) {
      erArray.push(errors[prop]);
    }
    return erArray;
  };

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const SignupSchema = Yup.object().shape({
    studio_name: Yup.string().required("Studio name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required")
      .min(7, "Min 7 digits is required")
      .max(14, "Max 14 digits is required"),
    location: Yup.string().required("Location is required"),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password length must be a minimum of six."),
  });

  return (
    <Formik
      initialValues={initialInput}
      onSubmit={(values, { resetForm }) => {
        registerHandler(values, { resetForm });
      }}
      validationSchema={SignupSchema}
      enableReinitialize={true}
    >
      {({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        hasErrors = Object.keys(errors).length == 0;

        return (
          <>
            <h1 className={Style.Register__title}>Create Account</h1>
            <div className={Style.Register__form}>
              <CommonInput
                type="text"
                label="Studio Name"
                placeholder="Enter Studio Name"
                variant="input"
                name="studio_name"
                id="studio_name"
                value={values.studio_name}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue(
                    "studio_name",
                    e.target.value?.length === 1
                      ? e.target.value.toUpperCase()
                      : e.target.value
                  );
                }}
                hasError={errors?.studio_name && touched?.studio_name}
                errorMsg={errors?.studio_name}
              />

              <CommonInput
                type="text"
                label="Email"
                placeholder="Enter your email"
                variant="input"
                name="email"
                id="email"
                value={values.email}
                autoComplete="new-email"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("email", e.target.value);
                }}
                hasError={errors?.email && touched?.email}
                errorMsg={errors?.email}
              />

              <CommonInput
                label="Phone Number"
                variant="phone-with-country"
                id="input_phone"
                placeholder="Enter your phone number"
                default_country="IN"
                name="phone_number"
                value={values.phone_number}
                country_dial_code={values.country_dial_code}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("phone_number", e.target.value);
                }}
                callback={function (country) {
                  setFieldValue("country_dial_code", country.dial_code);
                }}
                hasError={errors?.phone_number && touched?.phone_number}
                errorMsg={errors?.phone_number}
              />

              <CommonInput
                type="text"
                label="Location"
                placeholder="Enter your location"
                variant="input"
                id="input_location"
                name="location"
                value={values.location}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue(
                    "location",
                    e.target.value
                  );
                }}
                hasError={errors?.location && touched?.location}
                errorMsg={errors?.location}
              />

              <CommonInput
                label="Password"
                variant="password-visibility-toggle"
                placeholder="Enter password"
                id="input_password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={(e) => {
                  var unicode = e.keyCode ? e.keyCode : e.charCode;
                  
                  setFieldValue("password", e.target.value);
                }}
                hasError={errors?.password && touched?.password}
                errorMsg={errors?.password}
              />
            </div>

            {RegFormErrorObject?.data?.validation?.status_code == 422
              ? errorToArray(RegFormErrorObject?.data?.validation?.errors).map(
                  (ele) => {
                    return <p className="form-error-note">{ele}</p>;
                  }
                )
              : ""}

            <button
              type="submit"
              disabled={!isValid || !dirty}
              className={`${Style.Register__button} btn btn-primary w-100`}
              onClick={() => handleSubmit()}
            >
              {registerStatus === "loading" ? (
                <InfiniteDotLoader />
              ) : (
                "Create Account"
              )}
            </button>
          </>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
