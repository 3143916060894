import { useDispatch, useSelector } from "react-redux";
import {
  authModalHandler,
  postStudioEmail,
  postStudioMobile,
  verifyMobileOtp,
  postResendStudioPhone,
  isUpdatingPhoneStatus,
} from "../../store/slices/Preference/studioDetailstSlice";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { updatePreferenceList } from "../../store/slices/GetStarted/userBasicDataSlice";

export const usePhoneVerify = () => {
  const [code, setCode] = useState("");
  const [showErrors, setShowErrors] = useState("");
  const [showOtpErrors, setshowOtpErrors] = useState(null);
  const [otpCounter, setOtpCounter] = useState(30);
  const [getCountryCode, setgetCountryCode] = useState("");

  const dispatch = useDispatch();
  const { isUpdatingPhone } = useSelector((state) => state.preference);

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  const phoneValidationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required")
      .min(10, "Invalid  phone number")
      .max(10, "Invalid  phone number"),
  });
  const mobileSubmit = async (FormData, resetForm) => {
    setgetCountryCode(FormData?.country_dial_code);
    const resultAction = await dispatch(postStudioMobile(FormData));
  };

  const onPhoneVerifyOtp = async (code, mobile, ) => {
    setshowOtpErrors("");

    setCode(code);
    if (code?.length == 4) {
      const params = {
        otp: code,
        mobile: mobile,
        country_dial_code: getCountryCode,
      };

      const resultAction = await dispatch(verifyMobileOtp(params));

      if (verifyMobileOtp.fulfilled.match(resultAction)) {
        if (resultAction?.payload?.data?.status_code == "200") {
          dispatch(
            updatePreferenceList({
              type: "mobile",
              data: mobile,
            })
          );

          toast.success("Mobile Number Changed Successfully", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(authModalHandler(false));
          dispatch(isUpdatingPhoneStatus(false));
        } else if (resultAction?.payload?.data?.status_code == "422") {
          setshowOtpErrors(resultAction?.payload?.data?.message);
        }
      }
    }
  };

  const resendOtp = async (mobile, getCountryCode) => {
    setCode("");
    const resultAction = await dispatch(
      postResendStudioPhone({
        mobile: mobile,
        country_dial_code: getCountryCode,
      })
    );
  };
  return {
    code,
    showErrors,
    setShowErrors,
    setshowOtpErrors,
    showOtpErrors,
    phoneValidationSchema,
    mobileSubmit,
    otpCounter,
    resendOtp,
    isUpdatingPhone,
    onPhoneVerifyOtp,
    setOtpCounter,
    getCountryCode,
    setgetCountryCode,
  };
};
