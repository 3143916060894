import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDimensions } from "../../../../Logic/Dimensions";
import { useOrder } from "../../../../Logic/FilterHeaders/ProjectHeader/useOrder";
import { useSearch } from "../../../../Logic/FilterHeaders/ProjectHeader/useSearch";
import { useSelection } from "../../../../Logic/FilterHeaders/ProjectHeader/useSelection";
import { useTab } from "../../../../Logic/FilterHeaders/ProjectHeader/useTab";
import useProjects from "../../../../Logic/useProjects";
import CommonInput from "../../../Layouts/CommonInput";
import { Title } from "../Title";
import { AddNewProjectModal } from "./../AddNewProjectModal";
import { ButtonGroups } from "./../ButtonGroups";
import Style from "./../ContentHeader.module.scss";
import { Search } from "./../Search";
import { Tabs } from "./../Tabs";

const ProjectHeader = () => {
  const {
    handleProjectOrder,
    projectOrder,
    setProjectOrder,
    status,
    openCreateProjectModal,
    setOpenCreateProjectModal,
    addProjectStepOne,
    setAddProjectStepOne,
    addProjectStepTwo,
    setAddProjectStepTwo,
    handleShowAddProject,
    popupAddProject,
    handleSetAddProjectStepOne,
    handleSetAddProjectStepTwo,
    handleClearStepOneFormData,
  } = useProjects();
  const {
    activeTab,
    isChecked,
    activeTabLeft,
    activeTabWidth,
    setIsChecked,
    setActiveTabLeft,
    handleClickTab,
    currentTab,
  } = useTab();

  const {
    handlePreSelectionhandler,
    handleSelectAllProjects,
    handledeSelectAllProjects,
    handleDeleteSelectedProjects,
    handleCancelSelections,
  } = useSelection();

  const {
    handleChangeInput,
    searchText,
    handleClear,
    isFocused,
    setIsFocused,
    resetVisible,
    setResetVisible,
    handleResetVisible,
  } = useSearch();

  const { handleChangeOrder, getOrderLabel } = useOrder();

  const [isMobileSearch, setIsMobileSearch] = useState(null);
  const windowDimensions = useDimensions();

  return (
    <>
      <div className={Style.ContentHeader__wrap}>
        {/* Active Projects,Completed Projects */}

        <Tabs
          activeTab={activeTab}
          activeTabLeft={activeTabLeft}
          activeTabWidth={activeTabWidth}
          handleClickHandler={handleClickTab}
          setActiveTabLeft={setActiveTabLeft}
          setActiveTabWidth={setActiveTabLeft}
          currentTab={currentTab}
          tabdata={tabdata}
          tabTitle={
          <>
          <Title />
          </>}
        />
        
        <div className={Style.ContentHeader__right}>
          {/* Buttons --> Add Project,Select All,Filters */}

          <ButtonGroups
            windowDimensions={windowDimensions}
            setIsMobileSearch={setIsMobileSearch}
            isMobileSearch={isMobileSearch}
            handlePreSelectionhandler={handlePreSelectionhandler}
            handleSelectAllProjects={handleSelectAllProjects}
            handledeSelectAllProjects={handledeSelectAllProjects}
            handleDeleteSelectedProjects={handleDeleteSelectedProjects}
            handleCancelSelections={handleCancelSelections}
            setOpenCreateProjectModal={setOpenCreateProjectModal}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            setProjectOrder={setProjectOrder}
            projectOrder={projectOrder}
            setAddProjectStepOne={handleSetAddProjectStepOne}
            setAddProjectStepTwo={handleSetAddProjectStepTwo}
            isLoading={status}
            handleProjectOrder={handleProjectOrder}
            handleChangeInput={handleChangeInput}
            searchText={searchText}
            handleClear={handleClear}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            resetVisible={resetVisible}
            setResetVisible={setResetVisible}
            handleResetVisible={handleResetVisible}
            handleShowAddProject={handleShowAddProject}
            handleChangeOrder={handleChangeOrder}
            getOrderLabel={getOrderLabel}
          />
        </div>
        
      </div>
      {/* Add New Project Popup */}
      <AddNewProjectModal
        handleShowAddProject={handleShowAddProject}
        popupAddProject={popupAddProject}
        openCreateProjectModal={openCreateProjectModal}
        setOpenCreateProjectModal={setOpenCreateProjectModal}
        addProjectStepOne={addProjectStepOne}
        CommonInput={CommonInput}
        addProjectStepTwo={addProjectStepTwo}
        setAddProjectStepOne={handleSetAddProjectStepOne}
        setAddProjectStepTwo={handleSetAddProjectStepTwo}
        handleClearStepOneFormData={handleClearStepOneFormData}
      />
    </>
  );
};

let tabdata = [
  {
    name: "Active Projects",
    type: "activeTab",
  },
  {
    name: "Completed Projects",
    type: "completedTab",
  },
];

export default ProjectHeader;
