import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { REGISTER } from "redux-persist";
import { useDimensions } from "./Dimensions";

export const useProjectCard = () => {
  const nameRef = useRef();
  const windowDimensions = useDimensions();
  const location = useLocation();
  const [charLength, setCharLength] = useState("");
  const { currentTab } = useSelector((state) => state.project);
  const [isImageLoad, setIsImageLoad] = useState(false);

  useEffect(() => {
    setCharLength(nameRef?.current?.offsetWidth / 11);
  }, [windowDimensions.width]);

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string") {
      return false;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const stopPropagationProcess = (e) => {
    e.stopPropagation();
  };
  return {
    nameRef,
    windowDimensions,
    location,
    charLength,
    setCharLength,
    currentTab,
    capitalizeFirstLetter,
    stopPropagationProcess,
    isImageLoad,
    setIsImageLoad,
  };
};
