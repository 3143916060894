import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import Icon from "../../../Layouts/Icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useShareInfoPopup } from "../../../../Logic/useShareInfoPopup";
import Style from "./ShareInfoPopup.module.scss";

const ShareInfoPopup = ({ openShareModal, setOpenShareModal }) => {
  const { allFolders } = useSelector((state) => state.projectSinglePage);

  const {
    copyToClipboard,
    clientURL,
    handleWhatsAppShare,
    loading,
    setLoading,
    handleEmailShare,
    handleCopyClientURL,
    handleCopyClick,
    isCopied,
  } = useShareInfoPopup({ setOpenShareModal: setOpenShareModal });

  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={openShareModal}
      setShowModal={setOpenShareModal}
      centered={true}
      className={"Share__modal"}
      title={"Share to Client"}
      content={
        <>
          <ul className={`${"Share__list"}`}>
            <li
              style={{ cursor: "pointer" }}
              onClick={handleWhatsAppShare}
              className={`${"Share__item"}`}
            >
              <Icon size={17} icon={"icon-whatsapp"} />
              <span className={"Share__content"}>
                {loading === "sharingw" ? "Sharing..." : "Share to Whatsapp "}
              </span>
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={handleEmailShare}
              className={`${"Share__item"}`}
            >
              <Icon size={17} icon={"icon-mail"} />
              <span className={"Share__content"}>
                {" "}
                {loading === "sharinge" ? "Sharing..." : "Share Via Email"}
              </span>
            </li>
            <li
              style={{ cursor: "pointer" }}
              // onClick={handleCopyClick}
              onClick={() => {
                handleCopyClick(
                  `https://photofind-userflow.webc.in/${allFolders.eventInfo.shareInfo.data.uuid}`
                );
              }}
              className={`${"Share__item"}`}
            >
              <Icon size={17} icon={"icon-link"} />
              <span className={"Share__content"}>
                {/* {loading === "copying" ? "Copying..." : "Copy Link"} */}
                Copy Link
                {isCopied && (
                  <span className={Style.clip_to_board}>copied</span>
                )}
              </span>
            </li>
          </ul>
        </>
      }
    />
  );
};

export default ShareInfoPopup;
