import { useEffect, useState } from "react";
import Icon from "../../Layouts/Icons";
import Style from "./SelectImageCard.module.scss";

const SelectImageCard = ({
  themeImage,
  index,
  id,
  title,
  setRadioActive,
  radioActive,
  setIsContentLoad,
  isContentLoad,
}) => {
  const [isImageload, setIsImageload] = useState(false);
  useEffect(() => {
    setIsContentLoad(true);
  }, [isImageload]);
  return (
    <div
      className={`${Style.select_image_card} 
      ${index === radioActive ? Style.select_image_card_active : ""}
    `}
      onClick={() => setRadioActive(index)}
    >
      <div className={Style.select_image_card_imgwrap}>
        <figure
          className={`${
            isImageload ? "img-loading-2-loaded" : "img-loading img-loading-2"
          } ${Style.select_image_card_figure}`}
        >
          <img src={themeImage} alt="" onLoad={() => setIsImageload(true)} />
        </figure>
        {index === radioActive ? (
          <span className={`${Style.select_image_card_radio_active} `}>
            <Icon color={"#fff"} size={12} icon={"icon-tick"} />
          </span>
        ) : (
          <span className={`${Style.select_image_card_radio}`}></span>
        )}
      </div>
      <div className={`text-center ${Style.select_image_card_footer}`}>
        <h4
          className={`${Style.select_image_card_title} ${
            isImageload ? Style.select_img_loaded : ""
          }`}
        >
          {title}
        </h4>
        {/* <button className={`btn btn-outline-dark ${Style.select_image_card_btn}`}>Preview</button> */}
      </div>
    </div>
  );
};

export default SelectImageCard;
