import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getStaffDetails,
  getEventCategory,
  getEventNames,
  resetStaffArray,
} from "../store/slices/Project/ProjectSingle";

export const useAddNewFolder = () => {
  const dispatch = useDispatch();
  const [customFieldName, setCustomFieldName] = useState(false);

  const { createNewFolder, allFolders } = useSelector(
    (state) => state.projectSinglePage
  );



  useEffect(() => {
    if (createNewFolder?.staffList?.length === 0) {
      getAllStaffs();
    }

    if (createNewFolder?.eventCategories.length === 0) {
      getAllEventCategories();
    }

    if (createNewFolder?.events.length === 0) {
      getAllEvents();
    }
    
    return ()=>{
      dispatch(resetStaffArray());
    }

  }, []);

  const [initialInput, setInitialInputs] = useState({
    eventName: Number.isInteger(allFolders?.editFolder?.details?.album_name_id)
      ? allFolders?.editFolder?.details?.album_name_id
      : allFolders?.editFolder?.status === true
      ? "custom"
      : "",
    location:
      allFolders?.editFolder?.status === true
        ? allFolders?.editFolder?.details?.location_name
        : "",
    date:
      allFolders?.editFolder?.status === true
        ? allFolders?.editFolder?.details?.date
        : "",
    eventCategory:
      allFolders?.editFolder?.status === true
        ? allFolders?.editFolder?.details?.event_category_id
        : "",
    customName:
      Number.isInteger(allFolders?.editFolder?.details?.album_name_id) === false
        ? allFolders?.editFolder?.details?.name
        : "",
    staff:
      allFolders?.editFolder?.status === true
        ? allFolders?.editFolder?.details?.staffIDs.join(",")
        : "",
  });

  useEffect(() => {
    if (
      allFolders?.editFolder?.details?.album_name_id !== undefined &&
      allFolders?.editFolder?.details?.album_name_id !== null
    ) {
      if (
        Number.isInteger(allFolders?.editFolder?.details?.album_name_id) ===
        true
      ) {
        setCustomFieldName(false);
      } else {
        setCustomFieldName(true);
      }
    } else {
      if (allFolders?.editFolder?.status === false) {
        setCustomFieldName(false);
      } else {
        setCustomFieldName(true);
      }
    }
  }, [allFolders?.editFolder?.details?.album_name_id]);

  const getSelectedEventCategory = (createNewFolder) => {
    try {
      return createNewFolder?.eventCategories?.filter((option) => {
        return (
          allFolders?.editFolder?.details?.event_category?.id === option.value
        );
      })?.[0];
    } catch (error) {
      return false;
    }
  };

  const getSelectedStaffsArray = () => {
    let starray = "";
    if (createNewFolder.staffList.length > 0) {
      starray = createNewFolder.staffList?.filter((item) => {
        return allFolders?.editFolder?.details?.staffIDs?.includes(item.value);
      });
    }
    return starray;
  };

  const getAllStaffs = () => {
    return dispatch(getStaffDetails());
  };

  const getAllEventCategories = () => {
    return dispatch(getEventCategory());
  };

  const getAllEvents = () => {
    return dispatch(getEventNames());
  };

  const capitalizeFirstLetter = (s) => s && s[0].toUpperCase() + s.slice(1);

  return {
    initialInput,
    setInitialInputs,
    customFieldName,
    setCustomFieldName,
    getSelectedEventCategory,
    getSelectedStaffsArray,
    capitalizeFirstLetter,
    createNewFolder,
    allFolders,
  };
};
