import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useAnimations } from "../../../Logic/Animations";
import Assets from "../Assets";
import Icons from "../Icons";
import Style from "./MainFooter.module.scss";

const MainFooter = () => {
  const { slideTopFooterRef } = useAnimations();

  const location = useLocation();



  return (
    <footer className={Style.MainFooter}>
      <div className={Style.MainFooter__top}>
        <Container>
          <Link to="/">
            <figure className={Style.MainFooter__logo}>
              <img
                src={Assets.photofind_logo_dark}
                alt="photofind-logo"
                ref={slideTopFooterRef}
              />
            </figure>
          </Link>

          <nav className={Style.MainFooter__navigation}>
            <dl className={`${Style.MainFooter__navigation_list} default`}>
              <dt className={Style.list_title} ref={slideTopFooterRef}>
                QUICK LINKS
              </dt>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/"
                  className={`${location.pathname === "/" ? "active" : ""}`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Home
                </Link>
              </dd>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/contact"
                  className={`${
                    location.pathname === "/contact" ||
                    location.pathname === "/contact/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Contact
                </Link>
              </dd>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/plans"
                  className={`${
                    location.pathname === "/plans" ||
                    location.pathname === "/plans/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Plans
                </Link>
              </dd>
            </dl>
            <dl className={`${Style.MainFooter__navigation_list} default`}>
              <dt className={Style.list_title} ref={slideTopFooterRef}>
                LEGAL
              </dt>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/guidelines-and-terms"
                  className={`${
                    location.pathname === "/guidelines-and-terms" ||
                    location.pathname === "/guidelines-and-terms/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Guidelines & Terms of Service
                </Link>
              </dd>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/privacy-policy"
                  className={`${
                    location.pathname === "/privacy-policy" ||
                    location.pathname === "/privacy-policy/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Privacy Policy
                </Link>
              </dd>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/cancellation-and-refund-policy"
                  className={`${
                    location.pathname === "/cancellation-and-refund-policy" ||
                    location.pathname === "/cancellation-and-refund-policy/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Cancellation and Refund Policy
                </Link>
              </dd>
              <dd ref={slideTopFooterRef}>
                <Link
                  to="/faq"
                  className={`${
                    location.pathname === "/faq" ||
                    location.pathname === "/faq/"
                      ? "active"
                      : ""
                  }`}
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  FAQ
                </Link>
              </dd>
            </dl>
            <dl
              className={`${Style.MainFooter__navigation_list} ${Style.list_horizontal} ${Style.list_socials} default`}
            >
              <dt className={Style.list_title} ref={slideTopFooterRef}>
                FOLLOW US
              </dt>
              <dd ref={slideTopFooterRef}>
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <Icons color={"#fff"} icon={"icon-fb"} size={17} />
                </a>
              </dd>
              <dd ref={slideTopFooterRef}>
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <Icons color={"#fff"} icon={"icon-twitter"} size={17} />
                </a>
              </dd>
              <dd ref={slideTopFooterRef}>
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <Icons color={"#fff"} icon={"icon-linkedin"} size={17} />
                </a>
              </dd>
            </dl>
          </nav>
        </Container>
      </div>
      <div className={Style.MainFooter__bottom}>
        <Container>
          <div className={Style.MainFooter__copyrights} ref={slideTopFooterRef}>
            {/* Copyright {new Date().getFullYear()} &#169; */}
            <a
              href="http://www.photofind.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              photofind.in
            </a>
            . All rights reserved.
          </div>
          <div className={Style.MainFooter__design} ref={slideTopFooterRef}>
            Designed by
            <a
              href="http://www.webandcrafts.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Webandcrafts
            </a>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default MainFooter;
