import { useDispatch, useSelector } from "react-redux";
import { getCountry, postCountry } from "../../store/slices/Home/HomeSlice";
import * as Yup from "yup";
import { toast } from "react-toastify";

export const useContactForm = () => {
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state.home);

  const getUserFlowData = () => {
    if (countryData?.countryStatus === "idle") {
      dispatch(getCountry());
    }
  };

  const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
  const countryValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required."),
    phone: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .required("Phone number is required")
      .min(7, "Min 7 digits is required")
      .max(14, "Max 14 digits is required"),
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Country is required"),
  });

  const countrySubmit = async (data, resetForm) => {
    // let dataCountry = data?.country_dial_code;

    const resultAction = await dispatch(postCountry(data));

    if (postCountry.fulfilled.match(resultAction)) {
      resetForm();

      // dataCountry("");
      toast.success("Form Submitted Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return {
    getUserFlowData,
    countryValidationSchema,
    countrySubmit,
  };
};
