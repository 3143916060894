import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../Modals/CommonModal";
import Icon from "../../../Layouts/Icons";
import {useSelector } from "react-redux";
const CompleteProjectStatus = ({
  changeProjectStatusModal,
  setChangeProjectStatusModal,
  handleUpdateProjectStatus,
}) => {
  const { currentTab } = useSelector((state) => state.project);
  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={changeProjectStatusModal}
      setShowModal={setChangeProjectStatusModal}
      centered={true}
      className={"type-sm info-modal"}
      title={<h4>Move to {currentTab === "activeTab" ?"Complete" : "Active"}</h4>}
      content={
        <>
          <p>Are you sure you want to move this project to {currentTab === "activeTab" ?"complete" : "active"}?</p>
          <div className="modal-footer">
            <button
              className="btn btn-green"
              onClick={() => {
                handleUpdateProjectStatus();
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-border-grey"
              onClick={() => setChangeProjectStatusModal(false)}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
};

export default CompleteProjectStatus;
