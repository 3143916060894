import CommonModal from "../CommonModal";
import Style from "./MiniFileUploadModal.module.scss";
import Assets from "../../Layouts/Assets";
import { useEffect, useRef, useState } from "react";
import { useMiniFileUploadModal } from "../../../Logic/Uploaded/useMiniFileUploadModal";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../utils/Crop/ImageCrop";
import { Slider } from "antd";
import ImageCropr from "../../ImageCropr";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import "antd/dist/antd.css";

const MiniFileUploadModal = ({
  showChangeLogoModal,
  setShowChangeLogoModal,
  setStepsData,
  stepsData,
  seterrorMessage,
  seterrorMessageLogo,
}) => {
  const {
    setSelectedImage,
    selectedImage,
    inputFileForm,
    handleImageChange,
    handleDragOver,
    handleDrop,
    handleDragOut,
    onSubmitUploadHandler,
    loader,
    crop,
    setCrop,
    cropselection,
    SetcropSelection,
    aspect,
    SetAspect,
    cordX,
    cordY,
    imgRef,
    ImageSrc,
    currentValue,
    setCurrentValue,
    setUploadtype,
    error,
  } = useMiniFileUploadModal(
    setShowChangeLogoModal,
    setStepsData,
    stepsData,
    seterrorMessage,
    seterrorMessageLogo
  );

  console.log(crop, cordX,cordY,"yyyyy crop");
  return (
    <CommonModal
      backdrop="static"
      keyboard={false}
      showModal={showChangeLogoModal.isOpen}
      setShowModal={setShowChangeLogoModal}
      centered={true}
      className={`${Style.MiniFileUploadModal}`}
      title={<h4>{showChangeLogoModal?.title}</h4>}
      setSelectedImage={setSelectedImage}
      content={
        <>
          <div className={Style.MiniFileUploadModal__contents}>
            {selectedImage ? (
              <>
                <div className={Style.ReactCrop_wrap}>
                  <ReactCrop
                    cropIsActive={true}
                    //  aspect={aspect}
                    crop={crop}
                    // minWidth={50}
                    // minHeight={50}
                    // maxWidth={300}
                    // maxHeight={300}
                    onChange={(c) => {
                      setCrop(c);
                      SetcropSelection(c);
                    }}
                    // onDragEnd={(c) => {
                    //   setCrop(c);
                    //   SetcropSelection(c);
                    // }}
                    onComplete={(c) => {
                      setCrop(c);
                      SetcropSelection(c);
                    }}
                    className="react-crop"
                  >
                    <img
                      //src={URL.createObjectURL(selectedImage)}
                      src={ImageSrc}
                      ref={imgRef}
                      style={{ objectPosition: `${cordX}% ${cordY}%` }}
                    />
                  </ReactCrop>
                </div>
                {showChangeLogoModal?.type == "waterMark" && (
                  <>
                    <span className={Style.MiniFileUploadModal_opacity_label}>
                      Opacity
                    </span>
                    <Slider
                      defaultValue={100}
                      disabled={false}
                      min={0}
                      max={100}
                      onChange={(value) => {
                        setCurrentValue(value);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <div
                className={Style.MiniFileUploadModal__uploadBox}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragOut}
              >
                <label htmlFor="uploadImg">
                  <figure>
                    <img src={Assets.img_placeholder} alt="" />
                  </figure>
                  <p>
                    Drag and drop <br /> an image, <span>or Browse</span>
                  </p>
                  {error != "" && <p className={Style.fileSizeError}>{error}</p>}
                  <form ref={inputFileForm}>
                    <input
                      accept={
                        showChangeLogoModal?.type == "waterMark"
                          ? "image/png"
                          : "image/apng, image/avif, image/gif, image/jpeg, image/png"
                      }
                      type="file"
                      className="d-none"
                      name="file"
                      id="uploadImg"
                      onChange={handleImageChange}
                    />
                  </form>
                </label>
              </div>
            )}
            <p className={Style.MiniFileUploadModal__uploadFileLimit}>
              {/* {limitations} */}
            </p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-border-grey"
              onClick={() => {
                setShowChangeLogoModal(false);
                setSelectedImage(null);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              disabled={!selectedImage}
              onClick={() => onSubmitUploadHandler(showChangeLogoModal?.type)}
            >
              {loader ? <InfiniteDotLoader /> : "Confirm"}
            </button>
          </div>
        </>
      }
    />
  );
};

export default MiniFileUploadModal;
