import React from 'react'
import { useAnimations } from '../../../Logic/Animations';
import Assets from '../../Layouts/Assets'
import Style from './ContactVectors.module.scss'

const ContactVectors = () => {
    const {
        slideTopRef,
    } = useAnimations();
    
  return (
    <div className={Style.vectors}>
        <figure className={`${Style.vector} ${Style.vector_spring}`}>
            <img src={Assets.vector_spring_blue} alt="" ref={slideTopRef} />
        </figure>
        <figure className={`${Style.vector} ${Style.vector_semicircle}`}>
            <img src={Assets.vector_sc_green} alt="" ref={slideTopRef} />
        </figure>
    </div>
  )
}

export default ContactVectors