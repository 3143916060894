import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { updatePreferenceList,updateTelephoneNumber } from "../../store/slices/GetStarted/userBasicDataSlice";
import {
  authModalHandler,
  postAddress,
} from "../../store/slices/Preference/studioDetailstSlice";



export const useAddress = () => {
  const dispatch = useDispatch();

  const addressValidationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
  });

  const addressSubmit = async (data, resetForm) => {
    const resultAction = await dispatch(postAddress(data));
    if (postAddress.fulfilled.match(resultAction)) {
      dispatch(updateTelephoneNumber(data?.telephone_number));
      resetForm();
      dispatch(authModalHandler(false));
      toast.success("Address Changed Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return {
    addressValidationSchema,
    addressSubmit,
  };
};
