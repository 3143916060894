import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import useAuthChecker from "../../../Logic/Auth/useAuthChecker";
import { useDimensions } from "../../../Logic/Dimensions";
import { useHeader } from "../../../Logic/Header/useHeader";
import HamburgerMenu from "./HamburgerMenu";
import HeaderAddress from "./HeaderAddress";
import HeaderLogo from "./HeaderLogo";
import LoggedInHeaderRight from "./LoggedInHeaderRight";
import LoggedInMenu from "./LoggedInMenu";
import LoggedOutHeaderRight from "./LoggedOutHeaderRight";
import LoggedOutMenu from "./LoggedOutMenu";
import Style from "./MainHeader.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const MainHeader = () => {
  let { user_token } = useParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const windowDimensions = useDimensions();
  const { userProfileData } = useHeader();
  const { isAuthCompleted } = useAuthChecker();

  useEffect(() => {
    if (
      location.pathname.startsWith("/dashboard") &&
      location.pathname !== "/user-plans"
    ) {
      document.body.setAttribute(
        "data-theme",
        localStorage.getItem("photofindtheme")
      );
    } else {
      document.body.setAttribute("data-theme", "default");
    }
  }, []);

  useEffect(() => {
    if (
      userProfileData?.theme_class_name !== undefined &&
      location.pathname.startsWith("/dashboard")
    ) {
      localStorage.setItem("photofindtheme", userProfileData?.theme_class_name);
      document.body.setAttribute(
        "data-theme",
        userProfileData?.theme_class_name
      );
    } else {
      document.body.removeAttribute("data-theme", "");
    }
  }, [userProfileData?.theme_class_name, location.pathname]);

  const htmlTagHome = document.querySelector("html");
  useEffect(() => {
    isMenuOpen
      ? htmlTagHome.classList.add("mob-menu-open")
      : htmlTagHome.classList.remove("mob-menu-open");
  }, [isMenuOpen]);

  return (
    <header
      className={`${Style.MainHeader} ${
        isMenuOpen ? Style.MainHeader__menuOpen : ""
      } ${
        location.pathname.startsWith("/dashboard") ? Style.DashboardLayout : ""
      }`}
    >
      <Container
        className={`${
          location.pathname.startsWith("/dashboard") ? "container-lg" : ""
        }`}
      >
        {windowDimensions.width < 1200 ? (
          <HamburgerMenu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        ) : (
          <></>
        )}
        <div className={Style.MainHeader__wrap}>
          {windowDimensions.width >= 1200 || location.pathname === `/` ? (
            <HeaderLogo userProfileData={userProfileData} />
          ) : (
            ""
          )}
          {windowDimensions.width >= 1200 &&
          location.pathname === `/userflow/${user_token}` ? (
            <>
              <HeaderAddress />
            </>
          ) : isAuthCompleted ? (
            <>
              <LoggedInHeaderRight
                isMenuOpen={isMenuOpen}
                userProfileData={userProfileData}
              />
              {windowDimensions.width < 1200 &&
              (location.pathname === "/" ||
                location.pathname === "/contact" ||
                location.pathname === "/contact/" ||
                location.pathname === "/plans" ||
                location.pathname === "/plans/" ||
                location.pathname === "/get-started" ||
                location.pathname === "/get-started/" ||
                location.pathname === "/select-your-theme" ||
                location.pathname === "/select-your-theme/" ||
                location.pathname === "/guidelines-and-terms" ||
                location.pathname === "/guidelines-and-terms/" ||
                location.pathname === "/privacy-policy" ||
                location.pathname === "/privacy-policy/" ||
                location.pathname === "/cancellation-and-refund-policy" ||
                location.pathname === "/cancellation-and-refund-policy/" ||
                location.pathname === "/faq" ||
                location.pathname === "/faq/") ? (
                <LoggedOutMenu
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  isAuthCompleted={isAuthCompleted}
                />
              ) : windowDimensions.width < 1200 ? (
                <LoggedInMenu
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  menuList={menuList}
                  userProfileData={userProfileData}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <LoggedOutHeaderRight isMenuOpen={isMenuOpen} />
              {windowDimensions.width < 1200 ? (
                <LoggedOutMenu
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </Container>
    </header>
  );
};

export default MainHeader;

let menuList = [
  {
    name: "Dashboard",
    iconName: "dashboard-icon",
    path: "/dashboard",
  },
  {
    name: "Projects",
    iconName: "icon-projects",
    path: "/dashboard/project",
  },
  {
    name: "Staff",
    iconName: "staff-icon",
    path: "/dashboard/staffs/permanant",
    subPages: ["/dashboard/staffs/contract"],
  },
  {
    name: "Preference",
    iconName: "icon-settings",
    path: "/dashboard/preference",
  },
];
