import axios from "axios";

const BASE_URL = process.env.REACT_APP_APIURL;
const BASE_FPAUPLOADURL = process.env.REACT_APP_FPAUPLOADAPIURL;

let user = "";
let token = "";

export const getAxiosInstance = async (FPAUPLOAD = false) => {
  let lang =
    localStorage.getItem("lang") == undefined
      ? "en"
      : localStorage.getItem("lang");
  try {
    token = window.localStorage.getItem("USER_ACCESS_TOKEN");
  } catch (e) {
  } finally {
    let obj = {
      baseURL: FPAUPLOAD === false ? BASE_URL : BASE_FPAUPLOADURL,
      params: { lang },
      headers: {
        Accept: "application/json",
      },
    };

    if (FPAUPLOAD === false) {
      obj.headers.Authorization = `Bearer ${token}`;
    }

    const instance = axios.create(obj);
    instance.interceptors.request.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
