import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useProjectSingle } from "../../../../../Logic/useProjectSingle";

const FolderMenu = ({
  data,
  setOpenEventInfoModal,
  setOpenDeleteModal,
  handleToggleEditFolder,
  deleteCard,
  handleEventPopup,
  handleGetEventPopupData,
  handlepaymentAlert,
}) => {
  let navigate = useNavigate();

  const { handleEventShareInfoPopup, handleGetEventShareInfoPopup } =
    useProjectSingle();
  return (
    <>
      <Dropdown.Item
        onClick={() => {
          handleToggleEditFolder({ status: true, id: data.id });
        }}
        title={"Edit"}
      >
        Edit
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          handleGetEventPopupData(data.id);
        }}
        title={"Event Info"}
      >
        Event Info
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => {
          handleGetEventShareInfoPopup(data.id);
        }}
        title={"Share Client URL"}
        disabled={data?.latest_image?.length === 0 ? true :false}
      >
        Share
      </Dropdown.Item>

      <Dropdown.Item
        onClick={
          () => {
            deleteCard(data.id);
          } /*setOpenDeleteModal(true)*/
        }
        title={"Delete"}
      >
        Delete
      </Dropdown.Item>
    </>
  );
};

export default FolderMenu;
