import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDimensions } from "../../../../Logic/Dimensions";
import HeaderProfileDropdown from "../HeaderProfileDropdown";
import NotificationView from "../NotificationView";
import Style from "./LoggedInHeaderRight.module.scss";
import Icon from "../../../Layouts/Icons";
import { usePageTypeChecker } from "../../../../Logic/usePageTypeChecker";
import { useSelector } from "react-redux";

const LoggedInHeaderRight = ({ userProfileData }) => {
  const windowDimensions = useDimensions();
  const location = useLocation();
  const pageTypes = usePageTypeChecker();
  return (
    <>
      <ul className={Style.LoggedInHeader__right}>
        {windowDimensions.width >= 1200 && pageTypes.commonPages ? (
          <li>
            <ul
              className={`${Style.LoggedOutHeader__navigation_links} default`}
            >
              <li className={`${location.pathname === "/" ? "active" : ""}`}>
                <Link
                  to="/"
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Home
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/plans" ||
                  location.pathname === "/plans/"
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to="/plans"
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Plans
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/dashboard" ||
                  location.pathname === "/dashboard/"
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={
                    userProfileData?.plan_payment_status === 0
                      ? "/plans"
                      : userProfileData &&
                        userProfileData?.theme_class_name == null
                      ? "/get-started"
                      : "/dashboard"
                  }
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Dashboard
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/contact" ||
                  location.pathname === "/contact/"
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to="/contact"
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                  }
                >
                  Contact
                </Link>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {location.pathname == "/contact" ||
        location.pathname == "/" ||
        location.pathname == "/plans" ? (
          ""
        ) : (
          <>
            {windowDimensions.width > 1200 &&
            userProfileData?.preference?.address &&
            !pageTypes.commonPages ? (
              <>
                <span ClassName={Style.HeaderProfile_HomeIcon_wrap}>
                  <Icon size={20} icon={"home"} />
                </span>
                <li className={`pt-1 ${Style.HeaderProfile_address}`}>
                  {userProfileData?.preference?.address}
                </li>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <li>
          <HeaderProfileDropdown />
        </li>
      </ul>
    </>
  );
};

export default LoggedInHeaderRight;
