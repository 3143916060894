import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useAnimations } from "../../Logic/Animations";
import { useHome } from "../../Logic/useHome";
import PlansCardStack from "../PlansCardStack";
import Style from "./Plans.module.scss";

const Plans = () => {
  const { slideTopRef } = useAnimations();
  const { plans, plansData,buttonCallback } = useHome();
  useEffect(() => {
    plans();
  }, []);

  return (
    <div className={Style.Plans}>
      <Container>
        {plansData.length !== 1 ? (
        <>
        <div className={`section-title-block center`}>
          <h2 ref={slideTopRef}>Choose a Plan to Start</h2>
          <p ref={slideTopRef}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt dolore.
          </p>
        </div>
        </>
        ) : (<></>)}
        <PlansCardStack
          plansData={plansData}
          buttonText={"Buy Now"}
          cardType={"main"}
          buttonCallback={buttonCallback}
        />
      </Container>
    </div>
  );
};

export default Plans;
