import React from "react";
import { Container } from "react-bootstrap";
import { useAnimations } from "../../Logic/Animations";
import { useDimensions } from "../../Logic/Dimensions";
import Assets from "../Layouts/Assets";
import Style from "./Contact.module.scss";
import ContactForm from "./ContactForm";
import ContactVectors from "./ContactVectors";

const Contact = () => {
  const { slideTopRef } = useAnimations();

  const windowDimensions = useDimensions();

  const contactEmail = "info@photofind.in";

  return (
    <div className={Style.Contact}>
      <ContactVectors />
      <section className={Style.Contact__banner}>
        <figure className={Style.Contact__bannerImage}>
          <img
            src={
              windowDimensions.width >= 576
                ? Assets.contact_banner
                : Assets.contact_banner_mob
            }
            alt=""
          />
        </figure>
      </section>
      <section className={Style.Contact__form}>
        <Container className="d-lg-flex align-items-lg-center justify-content-lg-between">
          <div className={Style.Contact__bannerHead}>
            <h1 className={Style.Contact__bannerTitle} ref={slideTopRef}>
              Get In Touch With Us
            </h1>
            <div
              htmlFor=""
              className={Style.Contact__bannerEmail}
              ref={slideTopRef}
            >
              <span>Email Us</span>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </div>
          </div>
          <div ref={slideTopRef} className={Style.Contact__main_wrap}>
            <ContactForm />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Contact;
