import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProjectCardGallery from "../ProjectCardGallery";
import Style from "./ProjectsFolders.module.scss";
import { useSelector } from "react-redux";
import BlankDataPlaceholder from "../BlankDataPlaceholder";
import ProjectCard from "../ProjectCard";
import { useProjectSingle } from "../../../Logic/useProjectSingle";
import EditFolderPopup from "../ProjectCard/EditFolderPopup";
import { AddNewFolderModal } from "../ContentHeader/AddNewFolderModal";
import DeleteProjectPopup from "../ProjectCard/DeleteProjectPopup";
import ProjectEventInfoPopup from "../ProjectCard/ProjectEventInfoPopup";
import ShareInfoPopup from "../ProjectCard/ShareInfoPopup";
import { Outlet, useOutletContext } from "react-router-dom";
import PaymentAlert from "../ProjectCard/PaymentAlert";
import InfiniteDotLoader from "../../../utils/InfiniteDotLoader";

const ProjectFolders = () => {
  const listRootRef = useRef(null);
  const listItemRef = useRef(null);
  const observer = useRef();
  const { allFolders } = useSelector((state) => state.projectSinglePage);
  const {
    handleGetAllProjects,
    setOpenEditFolderModal,
    openEditFolderModal,
    handleToggleEditFolder,
    getAllStaffs,
    getAllEvents,
    getAllEventCategories,
    loadMore,
    handleSingleSelectionFolder,
    handleSingleDeleteFolder,
    handleSelectItemToDelete,
    setOpenDeleteModal,
    handleSelectItemToDeleteCloseButton,
    handleDeleteFoldersPopupToggle,
    handleDeleteFolders,
    handleSubmitCreateNewFolder,
    handleEventPopup,
    setOpenShareModal,
    handleGetEventPopupData,
    handleGetEventShareInfoPopup,
    handleEventShareInfoPopup,
    handleOpenGalleryPage,
    handlepaymentAlert,
    getThumbnails,
    handleclearSingleProjectData,
  } = useProjectSingle();

  useEffect(() => {
    handleGetAllProjects();
  }, []);

  const [isRenderContent, setIsRenderContent] = useState(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState();

  useEffect(() => {
    return ()=>{
      handleclearSingleProjectData()
    }
  }, []);

  useEffect(() => {
    if (listItemRef.current && allFolders?.list?.length < allFolders?.total) {
      const interceptConfig = {
        root: listRootRef.current,
        rootMargin: "0px",
        threshold: 0.1,
      };
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries.every((entry) => {
            return entry.isIntersecting;
          })
        ) {
          loadMore();
        }
      });
      observer.current.observe(listItemRef.current);
      return () => {
        observer.current.disconnect();
      };
    }

    if(allFolders.status === "succeed"){
      setIsRenderContent(true)
    }

  }, [allFolders?.list]);

  
  

  const renderContent = (status) => {
    switch (status) {
      case "loading":
        return <>
        <div className={Style.projectIs_loading}>
          <InfiniteDotLoader type={"project"} />
        </div>
        </>;

      case "succeed":
        return allFolders?.list?.data?.length === 0 ? (
          <BlankDataPlaceholder
            title={"You have not added any events"}
            description={"create your folders to organise events photos"}
            //buttonText={"blankButton"}
            template={"folder"}
            onClick={() => {}}
          />
        ) : (
          ""
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={`${Style.ProjectsFolders} ${isRenderContent ? Style.ProjectsFolders_loading : ''}`}>
        <div className={Style.ProjectCardGallery} ref={listRootRef}>
          <Row>
            {allFolders?.list?.data?.length > 0 ? (
              <>
                {allFolders?.list?.data?.map((data, index) => {
                  const lastIndex = allFolders?.list?.data?.length - 1;
                  return (
                    <Col
                      key={data?.id}
                      ref={lastIndex == index ? listItemRef : null}
                      className="img-loading-2-item"
                    >
                      <div className="selection" />

                      <ProjectCard
                        preSelect={allFolders.filter.preSelect}
                        data={data}
                        openEditFolderModal={openEditFolderModal}
                        setOpenEditFolderModal={setOpenEditFolderModal}
                        handleToggleEditFolder={handleToggleEditFolder}
                        handleSingleSelection={handleSingleSelectionFolder}
                        isSelectionActive={allFolders.filter.selectedItems.includes(
                          data.id
                        )}
                        deleteCard={handleSelectItemToDelete}
                        openCard={handleOpenGalleryPage}
                        handleEventPopup={handleEventPopup}
                        handleGetEventPopupData={handleGetEventPopupData}
                        handlepaymentAlert={handlepaymentAlert}
                        getThumbnails={getThumbnails}
                        itemKey={index}
                        setIsDropdownOpened={setIsDropdownOpened}
                        isDropdownOpened={isDropdownOpened}
                      />
                    </Col>
                  );
                })}
              </>
            ) : (
              ""
            )}
            {renderContent(allFolders.status)}
          </Row>
        </div>
        {/* <Outlet /> */}
      </div>
      {/* edit folder Popup */}
      <AddNewFolderModal
        setAddNewProjectPopup={setOpenEditFolderModal}
        addNewProjectPopup={openEditFolderModal}
        getAllStaffs={getAllStaffs}
        getAllEvents={getAllEvents}
        getAllEventCategories={getAllEventCategories}
        handleSubmitCreateNewFolder={handleSubmitCreateNewFolder}
      />

      <DeleteProjectPopup
        openDeleteModal={allFolders?.deleteSingleFolder?.selection}
        modalControl={handleSelectItemToDeleteCloseButton}
        handleDeleteAction={handleSingleDeleteFolder}
        isItFolder={true}
      />

      <DeleteProjectPopup
        openDeleteModal={allFolders?.deleteMultipleFolders?.selection}
        modalControl={handleDeleteFoldersPopupToggle}
        handleDeleteAction={handleDeleteFolders}
        isItFolder={true}
        isItMulitple={allFolders?.filter?.selectedItems.length > 1 ? true : false}
      />

      <ProjectEventInfoPopup
        openEventInfoModal={allFolders?.eventInfo?.status}
        setOpenEventInfoModal={handleEventPopup}
        setOpenShareModal={setOpenShareModal}
        handleGetEventShareInfoPopup={handleGetEventShareInfoPopup}
      />

      <ShareInfoPopup
        openShareModal={allFolders.eventInfo.shareInfo?.status}
        setOpenShareModal={handleEventShareInfoPopup}
      />

      <PaymentAlert
        isPaymentAlertIsOpen={allFolders.eventInfo.paymentInfo?.status}
        setIsPaymentAlertIsOpen={handlepaymentAlert}
      />
    </>
  );
};

export default ProjectFolders;

const projectData = [
  {
    id: 1,
    image: "",
    title: "Wedding",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 2,
    image: "",
    title: "Engagement",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 3,
    image: "",
    title: "Mehendi",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 4,
    image: "",
    title: "Pre-wedding photo shoot",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 5,
    image: "",
    title: "Save the date",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 6,
    image: "",
    title: "Post-wedding photo shoot",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 7,
    image: "",
    title: "Mehendi",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 8,
    image: "",
    title: "Haldi",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
  {
    id: 9,
    image: "",
    title: "Reception",
    date: "02/07/2023",
    link: "/dashboard/photos",
  },
];
