import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Assets from "../../Assets";
import Icon from "../../Icons";
import Style from "./LoggedOutMenu.module.scss";

const LoggedOutMenu = ({ isMenuOpen, setIsMenuOpen, isAuthCompleted }) => {
  const location = useLocation();

  const { userProfileData } = useSelector((state) => state.userData);

  const handleMenuLogoClick = () => {
    setIsMenuOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav
      className={`${Style.LoggedOutMenu__mobileMenu} ${
        isMenuOpen ? Style.menuOpen : ""
      }`}
    >
      <div className={Style.LoggedOutMenu__mobileMenuHead}>
        <button
          className={Style.ham_close}
          onClick={() => setIsMenuOpen(false)}
        >
          <Icon size={22} icon={"icon-close"} />
        </button>
        <Link to="/" onClick={() => handleMenuLogoClick()}>
          <figure className={Style.LoggedOutMenu__mobileLogo}>
            <img src={Assets.photofind_logo_light} alt="photofind-logo" />
          </figure>
        </Link>
      </div>
      <div className={Style.LoggedOutMenu__mobileMenuBody}>
        <ul className={Style.LoggedOutMenu__mobileNavigation}>
          <li className={`${location.pathname === "/" ? "active" : ""}`}>
            <Link
              to="/"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              Home
            </Link>
          </li>
          <li
            className={`${
              location.pathname === "/plans" || location.pathname === "/plans/"
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/plans"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              Plans
            </Link>
          </li>

          {isAuthCompleted && (
            <li
              className={`${
                location.pathname === "/contact" ||
                location.pathname === "/contact/"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to={
                  userProfileData?.plan_payment_status === 0
                    ? "/plans"
                    : userProfileData &&
                      userProfileData?.theme_class_name == null
                    ? "/get-started"
                    : "/dashboard"
                }
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              >
                Dashboard
              </Link>
            </li>
          )}

          <li
            className={`${
              location.pathname === "/contact" ||
              location.pathname === "/contact/"
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/contact"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            >
              Contact
            </Link>
          </li>
          {!isAuthCompleted ? (
            <li
              className={`${
                location.pathname === "/login" ||
                location.pathname === "/login/"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="/login"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              >
                Login
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
        {!isAuthCompleted ? (
          <ul className={`${Style.LoggedOutMenu__navigation_buttons} default`}>
            <li>
              <a
                className="btn btn-border-primary w-100 text-center d-block mb-3"
                href="https://corporate.photofind.webc.in/"
              >
                Corporate
              </a>
            </li>
            <li>
              <Link
                to="/register"
                onClick={() =>
                  location.pathname.startsWith("/dashboard")
                    ? ""
                    : window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      })
                }
              >
                <button className="btn btn-primary">Register</button>
              </Link>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
};

export default LoggedOutMenu;
