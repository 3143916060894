import React, { useState, useEffect } from "react";
import { useDimensions } from "../../../../Logic/Dimensions";
import ImageTray from "../ImageTray";
import Style from "./MasonryCard.module.scss";
import Icon from "../../../Layouts/Icons";

const MasonryCard = ({
  data,
  setIsImagePreview,
  setSelectedImage,
  isSelectionOpen,
  lastIndex,
  dataIndex,
  preSelect,
  isCardSelected,
  handleSelectImage,
  handleSuggest,
  isLiked,
}) => {
  const windowDimensions = useDimensions();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isFavSelected, setFavIsSelected] = useState(false);

  const handleOpenImageView = (imageIndex) => {
    setIsImagePreview(true);
    setSelectedImage(imageIndex);
  };

  const handleLoadeImage = (e) => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (isLiked === 1) {
      setFavIsSelected(true);
    } else if (isLiked === 0) {
      setFavIsSelected(false);
    }else if(isLiked === undefined){
      setFavIsSelected(false);
    }
  }, [isLiked]);


  return (
    <div
      className={`${Style.FakeCard} faabb`}
      onClick={() => handleOpenImageView(dataIndex)}
    >
      <figure
        className={`${Style.FakeCard__wrap} ${
          isLoaded ? "" : "img-loading"
        } ssss`}
        data-index={dataIndex}
        data-inde={isLiked}
      >
        <img
          src={data?.src?.thumbnail_medium}
          alt=""
          onLoad={(e) => handleLoadeImage(e)}
        />
        {windowDimensions.width > 200 ? (
          <React.Fragment
            children={
              <ImageTray
                name={data?.original_name}
                data={data}
                date={data?.created_at?.split("T")[0]}
                isSelectionOpen={isSelectionOpen}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                isLoaded={isLoaded}
                preSelect={preSelect}
                isCardSelected={isCardSelected}
                handleSelectImage={handleSelectImage}
                isFavSelected={isFavSelected}
                setFavIsSelected={setFavIsSelected}
                handleSuggest={handleSuggest}
              />
            }
          />
        ) : (
          <>
            {handleSuggest !== undefined && (
              <div
                className={`${Style.MasonryCard__fav} image-tray__fav abbb`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSuggest({
                    status: !isFavSelected,
                    image_id: data.id,
                    setFavIsSelected: setFavIsSelected,
                  });
                }}
              >
                <Icon
                  color={`${isFavSelected ? "red" : ""}`}
                  stroke={`${isFavSelected ? " " : "#000"}`}
                  size={16}
                  icon={"heart-line-icon"}
                />
              </div>
            )}
          </>
        )}
      </figure>
    </div>
  );
};

export default MasonryCard;
