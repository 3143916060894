import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  projectOrderChange,
  getAllProjectsAPI,
} from "../../../store/slices/Project/ProjectSlice";

export const useOrder = () => {
  const dispatch = useDispatch();
  const { sections, currentTab } = useSelector((state) => state.project);
  const [order, setOrder] = useState("asc");

  const getOrderLabel = (text) => {
    switch (text) {
      case "desc":
        return "Latest";
        break;

      case "asc":
        return "Oldest";
        break;

      default:
        break;
    }
  };

  const getTabUrl = (slug) => {
    switch (slug) {
      case "activeTab":
        return "list-project";
        break;
      case "completedTab":
        return "list-completed-project";
        break;
    }
  };

  const getUglyFromNice = (text) => {
    switch (text) {
      case "Latest":
        return "desc";
        break;

      case "Oldest":
        return "asc";
        break;

      default:
        break;
    }
  };

  const fetchAllProjects = (slug, query = false,sort = false) => {
    let obj = {
      url: getTabUrl(slug),
      page: "1",
      sort: sort !== false ? sort : "asc",
      per_page: 20,
    };

    if (query != false) {
      obj.search = query;
    }

    return dispatch(getAllProjectsAPI(obj));
  };

  const handleChangeOrder = (order) => {
    setOrder(order);
    fetchAllProjects(currentTab, sections?.[currentTab]?.["searchText"],order);
  };

 

  return {
    handleChangeOrder,
    getOrderLabel,
  };
};
