import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  currentTab: "All",
  getFirstFlowData: {},
  userData: {},
  sections: {},
  getSelectedPic: {},
};

export const getAllPhotos = createAsyncThunk(
  "userFlow/getAllPhotos",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/share-album?uuid=${params}&&page=1`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loadAllPhotos = createAsyncThunk(
  "userFlow/loadAllPhotos",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/share-album?uuid=${params?.token}&&page=${params?.page}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postSelectPic = createAsyncThunk(
  "userFlow/postSelectPic",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/selected`, params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userFlowSlice = createSlice({
  name: "userFlow",
  initialState,
  reducers: {
    addImageHandler: (state, action) => {
      let newData = state.sections[state.currentTab]["preSelectedImage"];
      if (newData?.includes(action.payload)) {
        let filteredIndexs = newData?.filter((item, index) => {
          return item !== action.payload;
        });
        state.sections[state.currentTab]["preSelectedImage"] = filteredIndexs;
      } else {
        typeof state.sections[state.currentTab]["preSelectedImage"] !== "object"
          ? (state.sections[state.currentTab]["preSelectedImage"] = [
              ...(state.sections[state.currentTab].preSelectedImage = []),
              action.payload,
            ])
          : (state.sections[state.currentTab]["preSelectedImage"] = [
              ...(state.sections[state.currentTab].preSelectedImage = [
                ...state.sections[state.currentTab].preSelectedImage,
              ]),
              action.payload,
            ]);
      }
    },

    updatePreselect: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentAction: {
          preSelect: action.payload,
        },
      };
    },

    currentTabAction: (state, action) => {
      state.currentTab = action.payload;
    },

    openImageModal: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentImageOpen: action.payload,
      };
    },

    selectedPhotos: (state, action) => {
      state.sections["Selected"] = {
        data: [...action.payload],
      };
    },

    clearSelectedPhotos: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentAction: {},
        preSelectedImage:[]
      };
    },
  },
  extraReducers: {
    [getAllPhotos.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [getAllPhotos.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.sections["All"] = action.payload.data.result.images;

      state.userData = action.payload.data.result.user;
    },
    [getAllPhotos.rejected]: (state, action) => {
      state.status = "error";
    },

    [postSelectPic.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [postSelectPic.fulfilled]: (state, action) => {
      state.status = "succeed";
    },
    [postSelectPic.rejected]: (state, action) => {
      state.status = "error";
    },

    [loadAllPhotos.pending]: (state, action) => {
      state.status = "loading";
      state.message = "";
    },
    [loadAllPhotos.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.sections[state.currentTab].data = [
        ...state.sections[state.currentTab].data,
        ...action.payload.data.result.images.data,
      ];

      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.data?.result?.images?.current_page,
      };
    },
    [loadAllPhotos.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const {
  updatePreselect,
  addImageHandler,
  currentTabAction,
  openImageModal,
  selectedPhotos,
  clearSelectedPhotos,
} = userFlowSlice.actions;

export default userFlowSlice.reducer;
