import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearSelectedPhotos,
  loadAllPhotos,
  postSelectPic,
  selectedPhotos,
} from "../../store/slices/UserFlow/userFlowSlice";

import {
  addImagesToSuggestion,
  removeImageFromSuggestion,
} from "../../store/slices/Gallery/gallerySlice";

import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { string } from "yup/lib/locale";
import { object } from "yup";
import { toast } from "react-toastify";

export const useFolderview = () => {
  const { folderID, projectID } = useParams();
  let { user_token } = useParams();
  const [isSelectionModal, setIsSelectionModal] = useState(false);
  const [isThanksModal, setIsThanksModal] = useState(false);

  const { currentTab, sections } = useSelector((state) => state.userFlow);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSlideKey, setIsSlideKey] = useState(null);
  const [isImagePreview, setIsImagePreview] = useState(false);

  const postSelectImages = async (data) => {
    let newData = sections[currentTab]?.data?.filter((item, index) =>
      data.includes(item.id)
    );
    dispatch(selectedPhotos(newData));
    if (dispatch(selectedPhotos(newData)).type == "userFlow/selectedPhotos") {
      toast.success("Added to selected", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        // dispatch(clearSelectedPhotos());
      }, 1000);
    }
  };

  const loadMore = () => {
    if (sections["All"]?.current_page < sections["All"]?.last_page) {
      let params = {
        token: user_token,
        page: sections["All"]?.current_page + 1,
      };
      dispatch(loadAllPhotos(params));
    }
  };

  const handleConfirm = async () => {
    let tempData = sections["Selected"]?.data?.map((data) => {
      return {
        image_id: data?.id,
        album_id: data?.album_id,
      };
    });

    const payload = { album: tempData };

    const resultAction = await dispatch(postSelectPic(payload));
    if (postSelectPic.fulfilled.match(resultAction)) {
      setIsSelectionModal(false);
      setIsThanksModal(true);
    }
  };

  const handleSuggest = (obj) => {
    const { status, image_id } = obj;
    const formData = {
      album: [
        {
          image_id: image_id,
          album_id: folderID,
        },
      ],
    };
    if (status === true) {
      dispatch(addImagesToSuggestion(formData));
    } else if (status === false) {
      dispatch(removeImageFromSuggestion(formData));
    }
  };

  return {
    isSlideKey,
    setIsSlideKey,
    postSelectImages,
    isImagePreview,
    setIsImagePreview,
    isSelectionModal,
    setIsSelectionModal,
    isThanksModal,
    setIsThanksModal,
    handleConfirm,
    loadMore,
    handleSuggest,
  };
};
