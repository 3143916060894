import React from 'react'
import Login from '../../components/AuthPages/Login'
import CommonLayout from '../../components/Layouts/CommonLayout'

const LoginPage = () => {
    return (
        <CommonLayout>
            <Login />
        </CommonLayout>
    )
}

export default LoginPage