import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountryCodeInput from "../../../utils/CountryCodeInput";
import LocationInput from "../../../utils/LocationInput";
import PasswordInput from "../../../utils/PasswordInput";
import VerifyOTPForm from "../../../utils/VerifyOTPForm";
import Icon from "../../Layouts/Icons";
import RegisterForm from "./Form";
import Style from "./Register.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useRegister } from "../../../Logic/useRegister";

const Register = () => {
  const { currentScreen, registerData, currentAuthData } = useSelector(
    (state) => state.auth
  );
  const {
    editButton,
    otp,
    setOTP,
    isOTPverifying,
    setOTPverifying,
    isOtpError,
    triggerResendCall,
    otpSubmitButton,
    loginIsOtpVerifying,
    otpCounter,
    setOtpCounter,
    otpErrorMessage
  } = useRegister();

  return (
    <div className={Style.Register}>
      <Container className="auth-container">
        {currentScreen == "otp" ? (
          <VerifyOTPForm
            phone={registerData.mobile}
            dialCode={registerData.country_dial_code}
            email={""}
            editButton={editButton}
            otp={otp}
            setOTP={setOTP}
            isOTPverifying={isOTPverifying}
            isOtpError={isOtpError}
            otpSubmitButton={otpSubmitButton}
            resendCallback={triggerResendCall}
            statusOTPcall={loginIsOtpVerifying}
            otpDetails={currentAuthData?.otp}
            otpCounter={otpCounter}
            setOtpCounter={setOtpCounter}
            otpErrorMessage={otpErrorMessage}

          />
        ) : (
          <>
            <RegisterForm />
            <p className={Style.Register__agreement}>
              By signing up, you agree to the{" "}
              <Link to="/guidelines-and-terms">
                Guidelines & Terms of Service
              </Link>{" "}
              and <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            {/* <div className={Style.Register__OAuth}>
              <span className={Style.OAuth__text}>Or continue with</span>
              <div className={Style.OAuth__list}>
                <button className={`${Style.OAuth__icon} google-icon`}>
                  <Icon icon={"google"} size={26} />
                </button>
                <button className={`${Style.OAuth__icon} fb-icon`}>
                  <Icon icon={"facebook-alt"} size={26} />
                </button>
              </div>
            </div> */}
            <div className={Style.Auth__option}>
              <span>Already have an account? </span>
              <Link to="/login" className="btn btn-link link-primary">
                Sign In
              </Link>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default Register;
