import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { getAxiosInstance } from "../../../api";

const initialState = {
  /****************************
   * REGISTER FORM REDUX VARIABLES *
   ****************************/

  status: "idle",
  registerStatus: "idle",
  loginIsOtpVerifying: false,
  currentScreen: "",
  registerData: {},
  currentAuthData: {},
  isEditMode: false,

  /************
   * LOGIN WITH EMAIL REDUX VARIABLES *
   ************/

  isLoginProgress: "",
  loginResponceData: {},
  loginError: false,
  loginErrorData: "",

  /************
   * LOGIN WITH PHONE REDUX VARIABLES *
   ************/
  otpCreateIsLoading: false,
  otpCreateProgress: false,
  otpCreateRequestData: {},
  otpCreateResponceData: {},
  otpCreateError: false,
  otpCreateErrorData: "",
  otpCreateScreen: "phone",
  otpCreateisEditMode: false,

  /************
   * LOGIN WITH PHONE OTP SCREEN REDUX VARIABLES *
   * LWP = LOGIN WITH PHONE
   ************/

  LWPotpIsLoading: false,
  LWPotpIsButtonEnable: false,
  LWPotpRequestData: {},
  LWPotpResponceData: {},
  LWPotpError: false,
  LWPotpErrorData: "",
  LWPotpScreen: "phone",
  LWPotpIsEditMode: false,

  /************
   * FORGOT PASSWORD FORM REDUX VARIABLES *
   * FPF = FORGOT PASSWORD FORM
   ************/

  FPFisLoading: "",
  FPFisButtonEnable: false,
  FPFrequestData: {},
  FPFresponceData: {},
  FPFerror: false,
  FPFscreen: "phone",
  FPFisEditMode: false,
  FPFsubmittedEmail: "",

  /************
   * FORGOT PASSWORD OTP FORM REDUX VARIABLES *
   * FPFOTP = FORGOT PASSWORD OTP FORM
   ************/

  FPFOTPisLoading: "",
  FPFOTPisButtonEnable: false,
  FPFOTPresponceToken: "",
  FPFOTPerror: false,
  FPFOTPisEditMode: false,
  FPFOTPsubmittedEmail: "",
};

/*****************
 * REGISTER FORM *
 *****************/

export const getRegisterData = createAsyncThunk(
  "auth/getRegisterData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("user-register", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("verify-user", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendRegOTP = createAsyncThunk(
  "auth/resendRegOTP",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("resend-register-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*****************
 * LOGIN FORM *
 *****************/

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("login", params);
      localStorage.setItem("userData", JSON.stringify(response?.data.data));
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginWithPhone = createAsyncThunk(
  "auth/loginWithPhone",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("create-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*****************
 * LOGIN FORM OTP SCREEN *
 *****************/

export const LWPverifyOtp = createAsyncThunk(
  "auth/LWPverifyOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("otp-login", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const LWPresendOtp = createAsyncThunk(
  "auth/LWPresendOtp",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("create-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*****************
 * FORGOT PASSWORD FORM *
 *****************/

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("forgot-password", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*****************
 * FPFOTP = FORGOT PASSWORD OTP FORM
 *****************/

export const FPFOTPverifyOTP = createAsyncThunk(
  "auth/FPFOTPforgotPassword",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("verify-otp", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

/*****************
 * FPF = FORGOT PASSWORD SET PASSWORD
 *****************/

export const forgotPasswordFinal = createAsyncThunk(
  "auth/FPFOTPforgotPassword",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `reset-password?token=${params.token}`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /*****************
     * REGISTERATION FORM FUNCTIONS*
     *****************/

    logout: (state, action) => {
      state.registerStatus = "idle";
      state.currentScreen = "";
      state.registerData = {};
      state.currentAuthData = {};
      state.isEditMode = false;
      localStorage.removeItem("USER_ACCESS_TOKEN");
      storage.removeItem("persist:root");
    },

    storeCurrentAuthData: (state, action) => {
      state.currentAuthData = action.payload.auth;
    },

    storeCurrentRegisterData: (state, action) => {
      state.registerData = action.payload.formData;
    },

    editMode: (state, action) => {
      state.isEditMode = action.payload;
    },

    otpButtonEnable: (state, action) => {
      state.loginIsOtpVerifying = false;
    },

    changeScreen: (state, action) => {
      state.currentScreen = action.payload;
    },

    /*****************
     * LOGIN FORM WITH EMAIL *
     *****************/

    invalidLoginDetails: (state, action) => {
      state.loginError = action.payload;
    },

    UpdateErrorData: (state, action) => {
      state.loginErrorData = action.payload;
    },

    /*****************
     * LOGIN FORM WITH PHONE *
     *****************/

    createOTPstatusUpdate: (state, action) => {
      state.otpCreateProgress = action.payload;
    },

    createOTPinvalidPhoneNumber: (state, action) => {
      state.otpCreateError = action.payload;
    },

    createOTPUpdateErrorData: (state, action) => {
      state.otpCreateErrorData = action.payload;
    },

    createOTPUpdateScreen: (state, action) => {
      state.otpCreateScreen = action.payload;
    },

    createOTPSaveRequestData: (state, action) => {
      state.otpCreateRequestData = action.payload;
    },

    createOTPEditModeSwitch: (state, action) => {
      state.otpCreateisEditMode = action.payload;
    },

    /*****************
     * LOGIN FORM WITH PHONE OTP SCREEN *
     *****************/

    LWPOTPupdateButtonStatus: (state, action) => {
      state.LWPotpIsButtonEnable = action.payload;
    },

    /*****************
     * FORGOT PASSWORD FORM *
     *****************/

    FPFupdateEditMode: (state, action) => {
      state.FPFisEditMode = action.payload;
    },

    FPFupdateEmail: (state, action) => {
      state.FPFsubmittedEmail = action.payload;
    },

    /*****************
     * FORGOT PASSWORD OTP FORM  *
     *****************/

    FPFOTPerrorStatusUpdate: (state, action) => {
      state.FPFOTPerror = action.payload;
    },

    FPFOTPstoreTokenValue: (state, action) => {
      state.FPFOTPresponceToken = action.payload;
    },
  },
  extraReducers: {
    /****************************************
     * USER REGISTER FIRST STEP API CALL STATUS *
     ****************************************/

    [getRegisterData.pending]: (state, action) => {
      state.registerStatus = "loading";
    },
    [getRegisterData.fulfilled]: (state, action) => {
      state.registerStatus = "succeded";
    },
    [getRegisterData.rejected]: (state, action) => {
      state.registerStatus = "error";
    },

    /****************************************
     * USER REGISTER FINAL STEP CALL STATUS *
     ****************************************/

    [verifyOtp.pending]: (state, action) => {
      state.status = "loading";
      state.loginIsOtpVerifying = true;
    },
    [verifyOtp.fulfilled]: (state, action) => {
      if (action.payload?.data?.success === true) {
        state.loginIsOtpVerifying = false;
        state.registerData = {
          ...state.registerData,
          ...action.payload?.data?.result,
        };
        state.currentAuthData = {};
        localStorage.setItem(
          "USER_ACCESS_TOKEN",
          action.payload?.data?.result?.access_token
        );
      }
    },
    [verifyOtp.rejected]: (state, action) => {
      state.status = "error";
      state.loginIsOtpVerifying = false;
    },

    /****************************************
     * LOGIN FORM *
     ****************************************/

    [loginUser.pending]: (state, action) => {
      state.isLoginProgress = "loading";
    },
    [loginUser.fulfilled]: (state, action) => {
      if (action.payload?.data?.success === true) {
        state.registerData = {
          ...state.registerData,
          ...action.payload?.data?.result,
        };
        state.currentAuthData = {};
        localStorage.setItem(
          "USER_ACCESS_TOKEN",
          action.payload?.data?.result?.access_token
        );
      }
      state.isLoginProgress = "succeded";
    },
    [loginUser.rejected]: (state, action) => {
      state.isLoginProgress = "error";
    },

    /****************************************
     * LOGIN FORM WITH PHONE OTP *
     ****************************************/

    [loginWithPhone.pending]: (state, action) => {
      state.otpCreateIsLoading = "loading";
    },
    [loginWithPhone.fulfilled]: (state, action) => {
      state.otpCreateIsLoading = "succeded";
    },
    [loginWithPhone.rejected]: (state, action) => {
      state.otpCreateIsLoading = "error";
    },

    [LWPverifyOtp.pending]: (state, action) => {
      state.LWPotpIsLoading = "loading";
    },
    [LWPverifyOtp.fulfilled]: (state, action) => {
      if (action.payload?.data?.success === true) {
        state.loginIsOtpVerifying = false;
        state.registerData = {
          ...state.registerData,
          ...action.payload?.data?.result,
        };
        state.currentAuthData = {};
        localStorage.setItem(
          "USER_ACCESS_TOKEN",
          action.payload?.data?.result?.access_token
        );
      }
      state.LWPotpIsLoading = "succeded";
    },
    [LWPverifyOtp.rejected]: (state, action) => {
      state.LWPotpIsLoading = "error";
    },

    /****************************************
     * FORGOT PASSWORD EMAIL FORM *
     ****************************************/
    [forgotPassword.pending]: (state, action) => {
      state.FPFisLoading = "loading";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.FPFisLoading = "succeded";
    },
    [forgotPassword.rejected]: (state, action) => {
      state.FPFisLoading = "error";
    },

    /****************************************
     * FORGOT PASSWORD EMAIL FORM *
     ****************************************/
    [forgotPasswordFinal.pending]: (state, action) => {
      state.FPFOTPisLoading = "loading";
    },
    [forgotPasswordFinal.fulfilled]: (state, action) => {
      state.FPFOTPisLoading = "succeded";
    },
    [forgotPasswordFinal.rejected]: (state, action) => {
      state.FPFOTPisLoading = "error";
    },
  },
});

export const {
  logout,
  storeCurrentAuthData,
  showSignInState,
  showforgotpasswordState,
  editMode,
  changeScreen,
  callFunction,
  storeCurrentRegisterData,
  otpButtonEnable,
  invalidLoginDetails,
  UpdateErrorData,
  createOTPstatusUpdate,
  createOTPUpdateErrorData,
  createOTPinvalidPhoneNumber,
  createOTPUpdateScreen,
  createOTPSaveRequestData,
  createOTPEditModeSwitch,
  LWPOTPupdateButtonStatus,
  LWPotpIsLoading,
  LWPotpIsButtonEnable,
  FPFupdateEditMode,
  FPFupdateEmail,
  FPFOTPerrorStatusUpdate,
  FPFOTPstoreTokenValue,
} = authSlice.actions;

export default authSlice.reducer;
