import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";


const initialState = {
    openMiniBox: false,
    openAlbumUploadModal: {
        isOpen: false,
    },
    openUploadModal: {
        isOpen: false,
        mode: "",
    },
    allUploadedImagesStatus: "idle",
    allFPAUploadedImagesStatus: "idle",
    allUploadedImages: [],
    allFPAUploadedImages: [],
    uploadMode: "photoFind"
};


export const getAllUploadedImages = createAsyncThunk(
    "fileUpload/getAllUploadedImages",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance();
        try {
            const response = await api.get(
                `album/album-duplicate-images?id=${params}`
            );
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const getAllFPAUploadedImages = createAsyncThunk(
    "gallery/getAllFPAUploadedImages",
    async (params, { rejectWithValue }) => {
        const api = await getAxiosInstance(true);
        try {
            const response = await api.post(`/v1/list-images`, params);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const fileUploadSlice = createSlice({
    name: "fileUpload",
    initialState,
    reducers: {
        updateAllUploadedImages: (state, action) => {
            state.allUploadedImages = [
                ...state.allUploadedImages,
                {
                    id: action.payload?.uploadResponse?.data?.result.image_info?.id,
                    name: action.payload?.uploadResponse?.data?.result.image_info?.original_name,
                },
            ];
        },

        updateAllFPAUploadedImages: (state, action) => {
            state.allFPAUploadedImages = [
                ...state.allFPAUploadedImages,
                {
                    id: action.payload?.uploadResponse?.data?.result.image?.id,
                    name: action.payload?.uploadResponse?.data?.result.image?.original_name,
                },
            ];
        },

        updateUploadMode: (state, action) => {
            state.uploadMode = action.payload
        }

    },
    extraReducers: {
        [getAllUploadedImages.pending]: (state) => {
            state.allUploadedImagesStatus = "pending";
        },
        [getAllUploadedImages.fulfilled]: (state, action) => {
            state.allUploadedImages = action.payload.data.result;
            state.allUploadedImagesStatus = "success";
        },
        [getAllUploadedImages.rejected]: (state) => {
            state.allUploadedImagesStatus = "failed";
        },

        [getAllFPAUploadedImages.pending]: (state, action) => {
            state.allFPAUploadedImagesStatus = "pending";
        },
        [getAllFPAUploadedImages.fulfilled]: (state, action) => {
            state.allFPAUploadedImages = action.payload.data.result;
            state.allFPAUploadedImagesStatus = "success";
        },
        [getAllFPAUploadedImages.rejected]: (state, action) => {
            state.allFPAUploadedImagesStatus = "failed";
        },
    },
});

export const {
    updateAllUploadedImages,
    updateUploadMode,
    updateAllFPAUploadedImages
} = fileUploadSlice.actions;

export default fileUploadSlice.reducer;
