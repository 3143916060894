import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  status: "idle",
  currentPage: 0,
  totalPages: "",
  order: "asc",
  selectedImageListView: false,
  currentTab: "all",
  currentFolderDetails: "",
  sections: {},
  activeUploadIndex: 0,
  activeUploadPercent: 0,
  isSingleImageUpload: false,
  openUploadModal: false,
  openUploadFPAModal: false,
  openMiniBox: false,
  thumbs: [],
  allUploadedImages: [],

  duplicateImages_: [],
  invalidImages_: [],
  allFiles_: [],
  files_: [],
  images_: [],
  newFiles_: [],
  uploadIndex_: 0,
  isUploading_: false,
};

export const getGalleryImages = createAsyncThunk(
  "gallery/getGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };

      const response = await api.get(
        `${params.url}?${new URLSearchParams(removeURL(params)).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const customFetchImages = createAsyncThunk(
  "gallery/customFetchImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };

      const response = await api.get(
        `${params.url}?${new URLSearchParams(removeURL(params)).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFolderDetails = createAsyncThunk(
  "gallery/getFolderDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };

      const response = await api.get(
        `${params.url}?${new URLSearchParams(removeURL(params)).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGalleryImages = createAsyncThunk(
  "gallery/deleteGalleryImages",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };
      const response = await api.post(`${params.url}`, params.formdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadUserSelectedFiles = createAsyncThunk(
  "gallery/downloadUserSelectedFiles",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const removeURL = () => {
        let obj = { ...params };
        delete obj.url;
        return obj;
      };
      const response = await api.post(params.url, removeURL());
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// {{url}}/v1/pf-upload

/**********************************************************
 * GETTING FPA UPLOAD TOKEN FETTING FROM FAMILYPHOTOS API *
 **********************************************************/

export const getFPAtoken = createAsyncThunk(
  "gallery/getFPAtoken",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance("https://admin-familyphotos.webc.in/");
    try {
      const response = await api.post("v1/pf-upload", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateShareLinkStatus = createAsyncThunk(
  "gallery/updateShareLinkStatus",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("album/link-status", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addImagesToSuggestion = createAsyncThunk(
  "gallery/addImagesToSuggestion",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("album/suggested", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeImageFromSuggestion = createAsyncThunk(
  "gallery/removeImageFromSuggestion",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("album/remove-suggested", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const getAllUploadedImages = createAsyncThunk(
//   "gallery/getAllUploadedImages",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(
//         `album/album-duplicate-images?id=${params}`
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    currentTabHandler: (state, action) => {
      state.currentTab = action.payload;
    },
    openPreSelectionhandler: (state, action) => {
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        currentAction: {
          preSelect: true,
        },
      };
    },

    selectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    unSelectAllImages: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [
        ...new Array(0).keys(),
      ];
    },

    selectSingleImage: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    deSelectSingleImage: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = action.payload;
    },

    closePreSelect: (state, action) => {
      state.sections[state.currentTab]["currentAction"] = {
        ...state.sections[state.currentTab]["currentAction"],
        preSelect: false,
      };
    },

    closeFilterActions: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["currentAction"] = {};
    },

    deleteActionSuccess: (state, action) => {
      state.sections[state.currentTab]["selectedImages"] = [];
      state.sections[state.currentTab]["currentAction"] = {};
      state.sections[state.currentTab]["data"] = action.payload;
    },

    addToThumbs: (state, action) => {
      state.thumbs = [...state.thumbs, action.payload];
    },

    updateActiveUploadPercentage: (state, action) => {
      state.activeUploadPercent = action.payload;
    },

    // updateUploadedImage: (state, action) => {
    //   state.sections["all"].data = [
    //     action.payload.data,
    //     ...state.sections["all"].data,
    //   ];
    // },


    updateActiveIndex: (state, action) => {
      state.activeUploadIndex = action.payload;
    },

    uploadModalAction: (state, action) => {
      state.openUploadModal = action.payload;
    },

    uploadFPAModalAction: (state, action) => {
      state.openUploadFPAModal = action.payload;
    },

    openMinimizedBox: (state, action) => {
      state.openMiniBox = action.payload;
    },

    callFunction: (state, action) => {
      action.payload(state, action);
    },

    openDeleteImagesPopup: (state, action) => {
      state.sections[state.currentTab]["currentAction"] = {
        preSelect: true,
        deleteWarningPopup: action.payload,
      };
    },

    selectedImageListViewToggle: (state, action) => {
      state.selectedImageListView = action.payload;
    },

    resetGalleryData: (state, action) => {
      // state.sections = {};
    },

    orderChangeGallery: (state, action) => {
      state.sections[state.currentTab]["order"] = action.payload;
    },
    updateImageCountGallery: (state, action) => {
      state.currentFolderDetails.images_count = action.payload;
    },
    updateSuggestedImageCount: (state, action) => {
      state.currentFolderDetails.suggested_images_count = action.payload;
    },
    clearGallery: (state, action) => {
      state.sections = {};
    },

    statusChangeLinkShare: (state, action) => {
      state.currentFolderDetails.link_status = action.payload;
    },
    clearThumbs: (state, action) => {
      state.thumbs = [];
      state.activeUploadPercent = 0;
      state.duplicatedImageIndexs = [];
    },
    removeImageFromSuggested: (state, action) => {
      state.sections["suggested"]["data"] = action.payload;
    },
    updateImageSuggestionStatus: (state, action) => {
      state.sections["all"]["data"] = action.payload;
    },
    clearImageSuggestion: (state, action) => {
      delete state.sections["suggested"];
    },
    // updateAllUploadedImages: (state, action) => {
    //   console.log(action.payload, "check this uplaod...");

    //   state.allUploadedImages = [
    //     ...state.allUploadedImages,
    //     {
    //       id: action.payload?.uploadResponse?.data?.result?.image_info?.id,
    //       original_name:
    //         action.payload?.uploadResponse?.data?.result?.image_info
    //           ?.original_name,
    //       file_size:
    //         action.payload?.uploadResponse?.data?.result?.image_info?.file_size,
    //       src: action.payload?.uploadResponse?.data?.result?.image_info?.src,
    //     },
    //   ];
    // },

    updateDuplicateImages: (state, action) => {
      state.duplicateImages_ = action.payload;
    },

    updateInvalidImages: (state, action) => {
      state.invalidImages_ = action.payload;
    },

    updateAllFiles: (state, action) => {
      state.allFiles_ = action.payload;
    },

    updateFiles: (state, action) => {
      state.files_ = action.payload;
    },

    updateImages: (state, action) => {
      state.images_ = action.payload;
    },

    updateNewFiles: (state, action) => {
      state.newFiles_ = action.payload;
    },

    updateUploadIndex: (state, action) => {
      state.uploadIndex_ = action.payload;
    },

    updateIsUploading: (state, action) => {
      state.isUploading_ = action.payload;
    },
  },
  extraReducers: {
    [getGalleryImages.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getGalleryImages.fulfilled]: (state, action) => {

      let cTab;
      if (action.payload.data.result.path.indexOf("suggested-images") != -1) {
        cTab = "suggested";
      } else if (
        action.payload.data.result.path.indexOf("selected-images") != -1
      ) {
        cTab = "selected";
      } else if (
        action.payload.data.result.path.indexOf("album-images") != -1
      ) {
        cTab = "all";
      }

      state.status = "succeeded";
      state.sections[cTab] = {
        ...state.sections[cTab],
        ...action?.payload?.data?.result,
      };
    },
    [getGalleryImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    /*******************
     * PAGINATION CODE *
     *******************/

    [customFetchImages.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [customFetchImages.fulfilled]: (state, action) => {
      let imgIds = action?.payload?.data?.result.data.map(
        (item, index) => item.id
      );
      state.status = "succeeded";

      state.sections[state.currentTab].data = [
        ...state.sections[state.currentTab].data,
        ...action?.payload?.data?.result.data,
      ];
      state.sections[state.currentTab] = {
        ...state.sections[state.currentTab],
        current_page: action?.payload?.data?.result?.current_page,
      };
    },
    [customFetchImages.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getFolderDetails.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFolderDetails.fulfilled]: (state, action) => {
      state.status = "success";
      state.currentFolderDetails = action?.payload?.data?.result;
    },
    [getFolderDetails.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteGalleryImages.pending]: (state, action) => { },
    [deleteGalleryImages.fulfilled]: (state, action) => { },
    [deleteGalleryImages.rejected]: (state, action) => { },

    [downloadUserSelectedFiles.pending]: (state, action) => {
      state.sections[state.currentTab].download = { status: "loading" };
    },
    [downloadUserSelectedFiles.fulfilled]: (state, action) => {
      state.sections[state.currentTab].download = { status: "success" };
    },
    [downloadUserSelectedFiles.rejected]: (state, action) => {
      state.sections[state.currentTab].download = { status: "error" };
    },

    [updateShareLinkStatus.pending]: (state, action) => {
      state.currentFolderDetails.isLinkUpdateLoading = "loading";
    },
    [updateShareLinkStatus.fulfilled]: (state, action) => {
      state.currentFolderDetails.isLinkUpdateLoading = "success";
      state.currentFolderDetails.link_status = action.meta.arg.status;
    },
    [updateShareLinkStatus.rejected]: (state, action) => {
      state.currentFolderDetails.isLinkUpdateLoading = "error";
    },

    // [getAllUploadedImages.pending]: (state, action) => { },
    // [getAllUploadedImages.fulfilled]: (state, action) => {
    //   state.allUploadedImages = action.payload.data.result;
    // },
    // [getAllUploadedImages.rejected]: (state, action) => { },



    ["fileUpload/updateAllUploadedImages"]: (state, action) => {
      console.log(action.payload?.uploadResponse?.data?.result.image_info, "hey upload check..");
      state.sections["all"] = {
        ...state.sections["all"],
        data: [action.payload?.uploadResponse?.data?.result.image_info, ...state.sections["all"].data],
        total: state.sections["all"].total + 1
      }
      state.currentFolderDetails = {
        ...state.currentFolderDetails,
        images_count: state.currentFolderDetails.images_count + 1
      }

    },
  },
});

export const {
  currentTabHandler,
  openPreSelectionhandler,
  closePreSelect,
  selectAllImages,
  unSelectAllImages,
  addToThumbs,
  clearThumbs,
  updateActiveUploadPercentage,
  updateUploadedImage,
  updateActiveIndex,
  callFunction,
  uploadModalAction,
  openMinimizedBox,
  selectSingleImage,
  deSelectSingleImage,
  closeFilterActions,
  openDeleteImagesPopup,
  deleteActionSuccess,
  selectedImageListViewToggle,
  resetGalleryData,
  orderChangeGallery,
  updateImageCountGallery,
  uploadFPAModalAction,
  clearGallery,
  statusChangeLinkShare,
  removeImageFromSuggested,
  updateSuggestedImageCount,
  updateImageSuggestionStatus,
  clearImageSuggestion,
  // updateAllUploadedImages,

  updateDuplicateImages,
  updateInvalidImages,
  updateAllFiles,
  updateFiles,
  updateImages,
  updateNewFiles,
  updateUploadIndex,
  updateIsUploading,
} = gallerySlice.actions;

export default gallerySlice.reducer;
