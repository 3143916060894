import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import InfiniteDotLoader from "../../../../../utils/InfiniteDotLoader";
import Style from "./VerifyOtpForm.module.scss";

const VerifyOtpForm = ({
  title,
  emailValue,
  code,
  showErrors,
  setshowOtpErrors,
  showOtpErrors,
  onEmailVerifyOtp,
  resendOtp,
  otpCounter,
  setOtpCounter,
  getCountryCode,
}) => {
  const { isVerify } = useSelector((state) => state.preference);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  useEffect(() => {
    const timer =
      otpCounter > 0 && setInterval(() => setOtpCounter(otpCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [otpCounter]);

  const submitBtnRef = useRef(null);
  useEffect(() => {
    setTimeout(()=>{
      if(showOtpErrors === "invalid otp"){
        submitBtnRef.current.focus();
      }
      setSubmitDisabled(showOtpErrors)
    },100);
  }, [showOtpErrors])

  return (
    <Formik
      initialValues={{ otp: "", country_dial_code: getCountryCode }}
      onSubmit={(values) => {
        if (!values.otp || values?.otp?.length < 4) {
          setshowOtpErrors("Otp can't be empty");
        }
      }}
    >
      {({
        handleChange,
        values,
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <div>
            <form className={Style.otp_inputs} onSubmit={handleSubmit}>
              <div className="form-group otp-inputs center">
                <h4>{title}</h4>

                <div className="input-holder" >
                  <OtpInput
                    value={code}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    name="otp"
                    onChange={(OTP) => {
                      onEmailVerifyOtp(OTP, emailValue);
                      setFieldValue("otp", OTP);
                    }}
                    numInputs={4}
                    onBlur={handleBlur}
                    inputStyle={`${
                      showOtpErrors ? Style.input_Style_error : ""
                    }`}
                    className="otp-field"
                  />
                </div>
              </div>
              <button
                type="submit"
                className={`${Style.Login__button} btn btn-primary w-100 mb-4`}
                // onClick={() => handleSubmit()}
                disabled={ submitDisabled ==="invalid otp"  ? true : false }
                ref={submitBtnRef}
              >
                {isVerify === "idle" ? (
                  "Submit"
                ) : isVerify === "loading" ? (
                  <InfiniteDotLoader />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
            <span className={`text-danger text-center ${Style.otp_error_message}`}>
              {showOtpErrors ? "Invalid OTP" : ""}
            </span>

            <div className={Style.Auth__option}>
              <span >Didn't Receive the OTP? </span>
              {otpCounter > 0 ? <span> 00:{otpCounter}s </span> : ""}
              {otpCounter == 0 ? (
                <button
                  className="btn btn-link link-primary"
                  onClick={() => {
                    resendOtp(emailValue, getCountryCode);
                    setshowOtpErrors(null);
                    setOtpCounter(30);
                  }}
                >
                  Resend OTP
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default VerifyOtpForm;
